<div
  class="lev-cs-slider small-slider"
  [ngClass]="[brandClass, countryClass]"
  [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
>
  <div
    *ngIf="sliderText"
    class="flex-display slider-text"
    [ngClass]="[brandClass, countryClass]"
    [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
    role="status"
    [attr.aria-label]="sliderText"
  >
    {{ sliderText }}
  </div>
  <div
    class="flex-display"
    [ngClass]="[brandClass, countryClass]"
    [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
  >
    <div
      class="currentValue"
      [ngClass]="[brandClass, countryClass]"
      [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
      role="status"
      [attr.aria-label]="defaultValue + ' ' + unit"
    >
      {{ defaultValue }}
    </div>
    <div
      class="unit"
      [ngClass]="[brandClass, countryClass]"
      [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
    >
      {{ unit }}
    </div>
  </div>
  <div
    class="contents-display"
    [ngClass]="[brandClass, countryClass]"
    [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
  >
    <mat-slider
      [attr.aria-label]="'slider-arrow-for-' + label"
      [min]="min"
      [max]="max"
      [step]="step"
      [(value)]="defaultValue"
      (input)="onChangeValue($event)"
    >
    </mat-slider>
  </div>
  <div
    *ngIf="brandClass == 'ov' && countryClass == 'gb'"
    [ngClass]="[brandClass, countryClass]"
    [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
    class="min-max-display"
  >
    <span
      class="currentValue"
      [ngClass]="[brandClass, countryClass]"
      [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
      role="status"
      [attr.aria-label]="min"
      >{{ min }}</span
    >
    <span
      class="currentValue"
      [ngClass]="[brandClass, countryClass]"
      [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
      role="status"
      [attr.aria-label]="max"
      >{{ max }}</span
    >
  </div>
</div>
