<div
  class="cards-container"
  #container
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
  (touchend)="onTouchEnd()"
>
  <!-- Add navigation arrows -->
  <!-- <button
    *ngIf="isMobile"
    class="nav-arrow prev"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
    [disabled]="activeCardIndex === 0"
    (click)="navigateCards('prev')"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15 18L9 12L15 6"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>

  <button
    *ngIf="isMobile"
    class="nav-arrow next"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
    [disabled]="activeCardIndex === 2"
    (click)="navigateCards('next')"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9 18L15 12L9 6"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button> -->
  <div
    class="cards-slider"
    [style.transform]="'translateX(' + translateX + 'px)'"
  >
    <!-- Three Years Card -->
    <div
      class="card-wrapper"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
      [class.active]="activeCardIndex === 0"
    >
      <span
        class="card-title"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
        >{{ labelCulture.sav_3_years_cost }}</span
      >
      <div
        class="card-value"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span
          class="value"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
          >{{ formatNumber(threeYearsCost.difference) }}</span
        >
        <span
          class="currency"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
          >€</span
        >
      </div>
      <div
        class="values-wrapper"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span>
          <svg
            width="13"
            height="18"
            viewBox="0 0 13 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3333 12.125C11.3333 7.25 6.08325 1.25 6.08325 1.25C6.08325 1.25 0.833252 7.25 0.833252 12.125C0.833252 17 6.08325 17 6.08325 17C6.08325 17 11.3333 17 11.3333 12.125Z"
              stroke="#17234D"
              stroke-width="1.5"
            />
          </svg>
        </span>
        <div
          class="values-texts"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <span>{{ labelCulture.sav_total_fuel_cost }}</span>
          <span>{{ formatNumber(threeYearsCost.fuelCost) }} €</span>
        </div>
      </div>
      <div
        class="values-wrapper"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span>
          <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2691 0.25L0.833252 8.78125H9.54293L1.52442 16H3.01067L12.0833 7.79688H3.37357L11.7203 0.25H10.2691Z"
              fill="#17234D"
            />
          </svg>
        </span>
        <div
          class="values-texts"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <span>{{ labelCulture.sav_total_electric_cost }}</span>
          <span>{{ formatNumber(threeYearsCost.electricityCost) }} €</span>
        </div>
      </div>
    </div>

    <!-- One Year Card -->
    <div
      class="card-wrapper"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
      [class.active]="activeCardIndex === 1"
    >
      <span
        class="card-title"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
        >{{ labelCulture.sav_1_year_cost }}</span
      >
      <div
        class="card-value"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span
          class="value"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
          >{{ formatNumber(oneYearCost.difference) }}</span
        >
        <span
          class="currency"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
          >€</span
        >
      </div>
      <div
        class="values-wrapper"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span>
          <svg
            width="13"
            height="18"
            viewBox="0 0 13 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3333 12.125C11.3333 7.25 6.08325 1.25 6.08325 1.25C6.08325 1.25 0.833252 7.25 0.833252 12.125C0.833252 17 6.08325 17 6.08325 17C6.08325 17 11.3333 17 11.3333 12.125Z"
              stroke="#17234D"
              stroke-width="1.5"
            />
          </svg>
        </span>
        <div
          class="values-texts"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <span>{{ labelCulture.sav_total_fuel_cost }}</span>
          <span>{{ formatNumber(oneYearCost.fuelCost) }} €</span>
        </div>
      </div>
      <div
        class="values-wrapper"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span>
          <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2691 0.25L0.833252 8.78125H9.54293L1.52442 16H3.01067L12.0833 7.79688H3.37357L11.7203 0.25H10.2691Z"
              fill="#17234D"
            />
          </svg>
        </span>
        <div
          class="values-texts"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <span>{{ labelCulture.sav_total_electric_cost }}</span>
          <span>{{ formatNumber(oneYearCost.electricityCost) }} €</span>
        </div>
      </div>
    </div>

    <!-- One Month Card -->
    <div
      class="card-wrapper"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
      [class.active]="activeCardIndex === 2"
    >
      <span
        class="card-title"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
        >{{ labelCulture.sav_1_month_cost }}</span
      >
      <div
        class="card-value"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span
          class="value"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
          >{{ formatNumber(oneMonthCost.difference) }}</span
        >
        <span
          class="currency"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
          >€</span
        >
      </div>
      <div
        class="values-wrapper"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span>
          <svg
            width="13"
            height="18"
            viewBox="0 0 13 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3333 12.125C11.3333 7.25 6.08325 1.25 6.08325 1.25C6.08325 1.25 0.833252 7.25 0.833252 12.125C0.833252 17 6.08325 17 6.08325 17C6.08325 17 11.3333 17 11.3333 12.125Z"
              stroke="#17234D"
              stroke-width="1.5"
            />
          </svg>
        </span>
        <div
          class="values-texts"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <span>{{ labelCulture.sav_total_fuel_cost }}</span>
          <span>{{ formatNumber(oneMonthCost.fuelCost) }} €</span>
        </div>
      </div>
      <div
        class="values-wrapper"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        <span>
          <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2691 0.25L0.833252 8.78125H9.54293L1.52442 16H3.01067L12.0833 7.79688H3.37357L11.7203 0.25H10.2691Z"
              fill="#17234D"
            />
          </svg>
        </span>
        <div
          class="values-texts"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <span>{{ labelCulture.sav_total_electric_cost }}</span>
          <span>{{ formatNumber(oneMonthCost.electricityCost) }} €</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Dots indicator -->
  <div class="carousel-dots">
    <div
      *ngFor="let dot of [0, 1, 2]; let i = index"
      class="dot"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
      [class.active]="activeCardIndex === i"
      (click)="setActiveCard(i)"
    ></div>
  </div>
</div>
