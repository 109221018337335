<div
  class="parent-container"
  [class.AP]="cs_peugeot"
  [class.AC]="cs_citroen"
  [class.FT]="cs_fiat"
  [class.FO]="cs_fiatPro"
  [class.AR]="cs_alfaRomeo"
  [class.DS]="cs_ds"
  [class.JE]="cs_jeep"
>
  <div class="block-slider-wrapper">
    <p
      role="status"
      class="block-slider-autonomy-text"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
      aria-label="Indicate the average KM DRIVEN PER YEAR"
    >
      {{ labelCulture.cs_indicate_average_km_driven_per_year }}
    </p>
  </div>
  <div
    class="average-container"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
    [class.AP]="cs_peugeot"
  >
    <span class="floor" role="status" aria-label="0%">0Km</span>
    <ngx-slider
      [value]="sliderValue"
      (valueChange)="sliderValue = $event"
      [options]="sliderOptions"
      (valueChange)="updateSliderData()"
    >
    </ngx-slider>
    <span class="ceil" role="status" aria-label="100%">50.000Km</span>
  </div>

  <div
    class="radio-container"
    [formGroup]="radioForm"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
    [class.AP]="cs_peugeot"
  >
    <div
      class="radio-group"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
      [class.AP]="cs_peugeot"
    >
      <div
        class="radio-item"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
        [class.AP]="cs_peugeot"
      >
        <input
          type="radio"
          id="privateCharge"
          value="private"
          class="input"
          formControlName="chargeType"
          (change)="updateSliderData()"
        />
        <label for="privateCharge">{{ labelCulture.sav_private_charge }}</label>
      </div>

      <div
        class="radio-item"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
        [class.AP]="cs_peugeot"
      >
        <input
          type="radio"
          id="publicStandardCharge"
          value="publicStandard"
          class="input"
          formControlName="chargeType"
          (change)="updateSliderData()"
        />
        <label for="publicStandardCharge">{{
          labelCulture.sav_public_standard_charge
        }}</label>
      </div>

      <div
        class="radio-item"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
        [class.AP]="cs_peugeot"
      >
        <input
          type="radio"
          id="publicFastCharge"
          value="publicFast"
          formControlName="chargeType"
          class="input"
          (change)="updateSliderData()"
        />
        <label for="publicFastCharge">{{
          labelCulture.sav_public_fast_charge
        }}</label>
      </div>
    </div>
  </div>
</div>

<div class="block-slider-wrapper-center">
  <p
    role="status"
    class="block-slider-autonomy-text-center"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
    aria-label="Indicate the average KM DRIVEN PER YEAR"
  >
    {{ labelCulture.you_may_save_up_to }}
  </p>
</div>

<app-fuel-saving-cards
  [threeYearsCost]="costData.threeYears"
  [oneYearCost]="costData.oneYear"
  [oneMonthCost]="costData.oneMonth"
  [labelCulture]="labelCulture"
  [cs_peugeot]="cs_peugeot"
  [cs_citroen]="cs_citroen"
  [cs_fiat]="cs_fiat"
  [cs_fiatPro]="cs_fiatPro"
  [cs_ds]="cs_ds"
  [cs_alfaRomeo]="cs_alfaRomeo"
  [cs_jeep]="cs_jeep"
  class="cards-container"
>
</app-fuel-saving-cards>
