<div
  class="lev-as-form-question-answer-slider-toggle"
  [class.d1]="isd1"
  [class.ac]="as_citroen"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
  [class.isNewOpelStyle]="isNewOpelStyle"
  [attr.id]="isspoticar ? 'spoticar' : ''"
  [ngClass]="
    isspoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
  "
  [class.ds]="as_ds"
  [class.vx]="as_vauxhall"
  [class.ov]="as_opel"
  [class.is-right-to-left]="isRightToLeft"
  [class.gr]="country.toLowerCase() === 'gr'"
>
  <div
    *ngIf="!isNewOpelStyle"
    role="status"
    [attr.aria-label]="inactive && inactive.toLowerCase()"
    class="label to-left"
    [class.is-right-to-left]="isRightToLeft"
    [class.actif]="!checked"
  >
    {{ inactive && inactive.toLowerCase() }}
  </div>
  <div
    *ngIf="!isNewOpelStyle"
    role="status"
    [attr.aria-label]="active && active.toLowerCase()"
    class="label to-right"
    [class.is-right-to-left]="isRightToLeft"
    [class.actif]="checked"
  >
    {{ active && active.toLowerCase() }}
  </div>
  <div
    *ngIf="isNewOpelStyle"
    for="LEV-AS-CUSTOM-TOGGLE"
    [class.isNewOpelStyle]="isNewOpelStyle"
    class="label to-right"
    role="status"
    [class.is-right-to-left]="isRightToLeft"
    [attr.aria-label]="
      checked
        ? active && active.toLowerCase()
        : inactive && inactive.toLowerCase()
    "
    [class.actif]="checked"
  >
    {{
      checked
        ? active && active.toLowerCase()
        : inactive && inactive.toLowerCase()
    }}
  </div>
  <button
    role="button"
    [attr.aria-label]="
      labelCulture.as_air_conditioning +
      ' checkbox ' +
      (checked
        ? active && active.toLowerCase()
        : air_conditioner === 0
        ? inactive && inactive.toLowerCase()
        : '')
    "
    class="slider-toggle"
    class="slider-toggle"
    (click)="toggle()"
    [class.isNewOpelStyle]="isNewOpelStyle"
    [class.checked]="checked"
    [disabled]="disable_toggle"
  >
    <div
      class="toggle"
      [ngClass]="
        isspoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
      "
    ></div>
  </button>

  <input
    [class.isNewOpelStyle]="isNewOpelStyle"
    type="checkbox"
    id="LEV-AS-CUSTOM-TOGGLE"
    role="checkbox"
  />
</div>
