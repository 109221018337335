// vehicle-data.service.ts
import { Injectable } from "@angular/core";
import { Observable, of, throwError, BehaviorSubject } from "rxjs";
import { catchError, map, tap, shareReplay } from "rxjs/operators";
import { Free2moveService } from "../services/free2move.service";

export interface Brand {
  value: string;
  viewValue: string;
}

export interface Vehicle {
  LCDV: string;
  label: string;
}

export interface OBC {
  value: string;
  viewValue: string;
}

export interface VehicleData {
  brands: Brand[];
  vehicles: Vehicle[];
  obcs: OBC[];
}

export interface FuelSavingData {
  VehicleId: string;
  LoadEfficiency?: number;
  UsefulCapacity?: number;
  WLTP?: number;
  Consumption?: number;
}

@Injectable({
  providedIn: "root",
})
export class VehicleDataService {
  // Cache storage
  private cachedVehicleData: { [tabType: string]: VehicleData } = {};
  private cacheExpiry: { [tabType: string]: number } = {};
  private readonly CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

  // Raw vehicle data
  private vehs: any[] = [];

  // Data subjects
  private brandsSubject = new BehaviorSubject<Brand[]>([]);
  private vehiclesSubject = new BehaviorSubject<Vehicle[]>([]);
  private obcsSubject = new BehaviorSubject<OBC[]>([]);
  private fuelSavingDataSubject = new BehaviorSubject<FuelSavingData[]>([]);

  tabSpecificVehs: { [tabType: string]: any[] } = {};

  /**
   * Maximum number of retries for loading vehicles
   */
  private readonly MAX_RETRIES = 3;

  /**
   * Retry counters for different tab types and brands
   */
  private retryCounters: { [key: string]: number } = {};

  // Observable streams
  brands$ = this.brandsSubject.asObservable();
  vehicles$ = this.vehiclesSubject.asObservable();
  obcs$ = this.obcsSubject.asObservable();
  fuelSavingData$ = this.fuelSavingDataSubject.asObservable();

  // Predefined brands list
  readonly predefinedBrands: Brand[] = [
    { value: "ar", viewValue: "Alfa Romeo" },
    { value: "ac", viewValue: "Citroen" },
    { value: "ds", viewValue: "DS" },
    { value: "ft", viewValue: "Fiat" },
    { value: "fo", viewValue: "Fiat Professional" },
    { value: "je", viewValue: "Jeep®" },
    { value: "la", viewValue: "Lancia" },
    { value: "ap", viewValue: "Peugeot" },
    { value: "ov", viewValue: "Opel" },
    { value: "vx", viewValue: "Vauxhall" },
  ];

  constructor(private free2moveService: Free2moveService) {}

  /**
   * Find a brand by its value code
   */
  findBrandByValue(value: string): Brand | undefined {
    return this.predefinedBrands.find(
      (b) => b.value.toLowerCase() === value.toLowerCase()
    );
  }

  /**
   * Load vehicle data for a specific tab type
   * @param tabType The tab type (cs, sav, as)
   * @param forceFresh Force a fresh data load, bypassing cache
   */
  loadVehicleData(tabType: string, forceFresh: boolean = false): void {
    // Determine the correct file path based on tab type
    let fileName: string;
    switch (tabType) {
      case "as":
        fileName = `levdata/levdata-as/levdata_fr_fr`;
        break;
      case "cs":
        fileName = `levdata/levdata-cs/levdata_fr_fr`;
        break;
      case "sav":
        fileName = `levdata/levdata-fuel/levdata_fr_fr`;
        break;
    }

    // Always fetch fresh data if force refresh is true
    if (forceFresh) {
      this.fetchVehicleData(fileName, tabType);
      return;
    }

    // Check cache
    const now = Date.now();
    const cachedData = this.cachedVehicleData[tabType];
    const cacheExpiration = this.cacheExpiry[tabType];

    // If cached data exists and is not expired, use it
    if (cachedData && cacheExpiration && cacheExpiration > now) {
      this.emitCachedData(tabType);
      return;
    }

    // If no cached data or cache is expired, fetch new data
    this.fetchVehicleData(fileName, tabType);
  }

  private fetchVehicleData(fileName: string, tabType: string): void {
    this.free2moveService
      .getData(fileName)
      .pipe(
        catchError((error) => {
          console.error(`Error loading vehicle data for ${tabType}:`, error);
          return of({ vehs: [] });
        })
      )
      .subscribe((data) => {
        // Process and store the data
        this.processVehicleData(data, tabType);
      });
  }

  /**
   * Load vehicles for a specific brand
   */
  loadVehiclesForBrand(tabType: string, brandValue: string): void {
    // Use tab-specific vehicles
    const tabVehicles = this.tabSpecificVehs[tabType] || [];

    // Create a unique key for this tab type and brand combination
    const retryKey = `${tabType}-${brandValue}`;

    // Initialize retry counter if not exists
    if (this.retryCounters[retryKey] === undefined) {
      this.retryCounters[retryKey] = 0;
    }

    // Declare properties
    let upperWidgetValue: string;
    let brandProperty: string;
    let typeProperty: string;
    let lcdvProperty: string;
    let labelProperty: string;

    // If no vehicles for this tab type, load the data and retry
    if (tabVehicles.length === 0) {
      console.log(
        `No vehicles found for tab type ${tabType} and brand ${brandValue}, loading data first...`
      );

      // Only retry if we haven't reached the maximum retries
      if (this.retryCounters[retryKey] < this.MAX_RETRIES) {
        // Increment retry counter
        this.retryCounters[retryKey]++;

        // Load the data and then try again after a delay
        this.loadVehicleData(tabType, true);

        // Set up a retry attempt after data should be loaded
        setTimeout(() => {
          this.loadVehiclesForBrand(tabType, brandValue);
        }, 1000); // Wait 1 second before retrying

        return;
      } else {
        console.warn(
          `Max retries reached for tab type ${tabType} and brand ${brandValue}, aborting...`
        );
        // Still emit empty results to avoid hanging UI
        this.vehiclesSubject.next([]);
        return;
      }
    }

    // Reset retry counter on successful data load
    this.retryCounters[retryKey] = 0;

    // Switch case to determine properties based on tab type
    switch (tabType) {
      case "as":
        upperWidgetValue = "AS";
        brandProperty = "LEV_AS_BRAND";
        typeProperty = "LEV_AS_TYPE";
        lcdvProperty = "LEV_AS_LCDV";
        labelProperty = "LEV_AS_label";
        break;
      case "sav":
        upperWidgetValue = "SAV";
        brandProperty = "LEV_SAV_BRAND";
        typeProperty = "LEV_SAV_TYPE";
        lcdvProperty = "LEV_SAV_LCDV";
        labelProperty = "LEV_SAV_label";
        break;
      case "cs":
      default:
        upperWidgetValue = "CS";
        brandProperty = "LEV_CS_BRAND";
        typeProperty = "LEV_CS_TYPE";
        lcdvProperty = "LEV_CS_LCDV";
        labelProperty = "LEV_CS_label";
        break;
    }

    // Filter vehicles
    const vehiclesForBrand = tabVehicles.filter((veh) => {
      const brandMatch =
        veh[brandProperty]?.toLowerCase() === brandValue.toLowerCase();
      const typeMatch = veh[typeProperty] === "BEV";
      return brandMatch && typeMatch;
    });

    // Map to vehicles
    const vehicles = vehiclesForBrand.map((veh) => ({
      LCDV: veh[lcdvProperty] || "",
      label: veh[labelProperty] || "",
    }));

    // Sort vehicles by label
    // const sortedVehicles = vehicles.sort((a, b) =>
    //   a.label.localeCompare(b.label)
    // );

    // Emit the vehicles
    this.vehiclesSubject.next(vehicles);
  }

  /**
   * Load OBCs for a specific vehicle
   */
  loadOBCsForVehicle(
    tabType: string,
    brandValue: string,
    vehicleId: string
  ): void {
    // Use tab-specific vehicles instead of this.vehs
    const tabVehicles = this.tabSpecificVehs[tabType] || [];

    // If no vehicles for this tab type, load the data
    if (tabVehicles.length === 0) {
      this.loadVehicleData(tabType);
      return;
    }
    // Filter vehicles based on brand, vehicle ID, and tab type
    const filteredVehicles = tabVehicles.filter(
      (veh) =>
        veh[`LEV_CS_BRAND`]?.toLowerCase() === brandValue.toLowerCase() &&
        veh[`LEV_CS_LCDV`] === vehicleId
    );

    // Extract OBCs from filtered vehicles
    const obcs = filteredVehicles.flatMap((veh) => {
      const obcArray = veh?.[`LEV_CS_OBC`] || [];
      return obcArray.map((obcItem: any) => ({
        value: Object.values(obcItem)[0] as string,
        viewValue: this.formatValue(Object.values(obcItem)[0] as string),
      }));
    });

    // Emit the OBCs
    this.obcsSubject.next(obcs);
  }

  /**
   * Process the vehicle data from the API
   */
  private processVehicleData(data: any, tabType: string): void {
    try {
      // Extract the vehicle data
      if (!data || !data.vehs) {
        console.warn(`No vehicle data found for tab type ${tabType}`);
        this.dataLoadedSubject.next(tabType);
        return;
      }

      // Determine the correct property prefix based on tab type
      let propertyPrefix: string;

      // Map each tab type to its corresponding property prefix
      switch (tabType.toLowerCase()) {
        case "sav":
          propertyPrefix = "LEV_SAV";
          break;
        case "cs":
          propertyPrefix = "LEV_CS";
          break;
        case "as":
          propertyPrefix = "LEV_AS";
          break;
        case "us":
          propertyPrefix = "LEV_CS"; // "us" tab uses "CS" properties
          break;
        default:
          // Default case - convert to uppercase
          propertyPrefix = `LEV_${tabType.toUpperCase()}`;
      }

      // Filter vehicles based on the current tab type
      let filteredVehs = data.vehs.filter(
        (veh: any) => veh[`${propertyPrefix}_TYPE`] === "BEV"
      );

      // Additional filter for AS tab
      if (tabType === "as") {
        filteredVehs = filteredVehs.filter(
          (veh: any) =>
            veh[`LEV_DEFAULT_VALUES`]?.["DRIVING_STYLE"] !== "as_tempere"
        );
      }

      // Always store the filtered vehs for this tab type
      this.tabSpecificVehs[tabType] = filteredVehs;

      // Update the main vehs array with the current tab's vehicles
      this.vehs = filteredVehs;

      // Extract marques
      const marques = this.extractMarques(filteredVehs, propertyPrefix);

      // Map to brands
      const brands = this.mapToBrands(marques);

      // Don't emit empty brands if we already have brands
      if (brands.length === 0 && this.brandsSubject.getValue().length > 0) {
        console.warn(
          `No brands found for tab type ${tabType}, keeping existing brands`
        );
      } else if (brands.length > 0) {
        // Only emit brands if we have brands
        this.brandsSubject.next(brands);
      }

      // Create vehicle data object with current or existing brands
      const vehicleData: VehicleData = {
        brands: brands.length > 0 ? brands : this.brandsSubject.getValue(),
        vehicles: [],
        obcs: [],
      };

      // Cache the data
      this.cachedVehicleData[tabType] = vehicleData;
      this.cacheExpiry[tabType] = Date.now() + this.CACHE_DURATION;

      // Notify that data for this tab type has been loaded
      this.dataLoadedSubject.next(tabType);
    } catch (error) {
      console.error("Error processing vehicle data:", error);
      // Don't reset the brands if there's an error
      this.tabSpecificVehs[tabType] = []; // Ensure empty array is stored
      this.vehiclesSubject.next([]);
      this.obcsSubject.next([]);

      // Still notify that loading attempt was completed
      this.dataLoadedSubject.next(tabType);
    }
  }

  /**
   * Extract unique brand codes from vehicles
   */
  private extractMarques(vehs: any[], propertyPrefix: string): string[] {
    const marques: string[] = vehs.map((veh) => {
      return veh[`${propertyPrefix}_BRAND`];
    });

    // Return unique sorted brand codes
    return Array.from(new Set(marques)).sort();
  }

  /**
   * Map brand codes to Brand objects with display values
   */
  private mapToBrands(marques: string[]): Brand[] {
    return marques
      .map((value) => {
        const brand = this.predefinedBrands.find((b) => b.value === value);
        return {
          value: value,
          viewValue: brand ? brand.viewValue : value,
        };
      })
      .sort((a, b) => a.viewValue.localeCompare(b.viewValue));
  }

  /**
   * Emit cached data to the subjects
   */
  private emitCachedData(tabType: string): void {
    const data = this.cachedVehicleData[tabType];
    if (data) {
      this.brandsSubject.next(data.brands);
    }
  }

  /**
   * Format a value for display (handle comma/decimal point)
   */
  private formatValue(value: string): string {
    const formatted = value.replace(",", ".").trim();
    return parseFloat(formatted).toString();
  }

  /**
   * Maps URL parameter values to internal tab type values
   */
  mapDefaultWidgetParam(param: string): string {
    // Convert param to lowercase for case-insensitive comparison
    const paramLower = param.toLowerCase();

    // Map the URL parameter values to internal tab type values
    switch (paramLower) {
      case "charging-simulator":
      case "charging":
      case "cs":
        return "cs";
      case "fuel-saving":
      case "fuel":
      case "sav":
      case "us":
        return "sav";
      case "range-calculator":
      case "range":
      case "as":
        return "as";
      case "help-me-choose":
      case "help":
      case "hmc":
        return "hmc";
      default:
        return "cs"; // Default to charging simulator
    }
  }

  findBrandForVehicleId(vehicleId: string, tabType: string): string | null {
    // Get the tab-specific vehicles
    const tabVehicles = this.tabSpecificVehs[tabType] || [];

    if (tabVehicles.length === 0) {
      // If no vehicles found, let's load the data and return null for now
      // The component will retry this operation when data is loaded
      this.loadVehicleData(tabType, true);
      return null;
    }

    // Determine property prefix based on tab type
    let lcdvProperty: string;
    let brandProperty: string;

    switch (tabType.toLowerCase()) {
      case "sav":
        lcdvProperty = "LEV_SAV_LCDV";
        brandProperty = "LEV_SAV_BRAND";
        break;
      case "as":
        lcdvProperty = "LEV_AS_LCDV";
        brandProperty = "LEV_AS_BRAND";
        break;
      case "cs":
      default:
        lcdvProperty = "LEV_CS_LCDV";
        brandProperty = "LEV_CS_BRAND";
        break;
    }

    // Find matching vehicle - allow for case-insensitive comparison
    const matchingVehicle = tabVehicles.find(
      (veh) => veh[lcdvProperty]?.toLowerCase() === vehicleId.toLowerCase()
    );

    return matchingVehicle ? matchingVehicle[brandProperty] : null;
  }

  private dataLoadedSubject = new BehaviorSubject<string>("");
  dataLoaded$ = this.dataLoadedSubject.asObservable();

  /**
   * Returns true if data exists for the specified tab type
   */
  hasDataForTabType(tabType: string): boolean {
    return !!(
      this.tabSpecificVehs &&
      this.tabSpecificVehs[tabType] &&
      this.tabSpecificVehs[tabType].length > 0
    );
  }

  /**
   * Resets retry counters for a fresh start
   */
  resetRetryCounters(): void {
    this.retryCounters = {};
  }

  /**
   * Safely gets the current brands
   */
  getCurrentBrands(): Brand[] {
    return this.brandsSubject.getValue() || [];
  }
}
