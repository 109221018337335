export class cs_labelCulture {
	cs_legale_mentions: string="";
	cs_obc: string="";
    cs_kw: string="";
    cs_indicate_initial_and_desired_charge: string="";
    cs_autonomy_found: string="";
    cs_km: string="";
    cs_mode4: string="";
    cs_mode3: string="";
    cs_mode23: string="";
    cs_mode2: string="";
    cs_choose_vehicle: string="";
    cs_charging_time: string="";
    cs_obc_link: string="";
    cs_select_charging_speed: string="";
    cs_loading_power: string="";
    cs_textdata_PHEV: string="";
    cs_distance_unit:string="";
  cs_houre:string="";
  cs_minute:string="";
}