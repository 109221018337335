import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-speed",
  templateUrl: "./speed-box.component.html",
  styleUrls: ["./speed-box.component.scss"],
})
export class SpeedComponent implements OnInit {
  @Input() autonomyData: any[] = [];
  @Input() defaultValueForslider: any[] = [];
  @Input() drivingStyle: string = "as_normal";
  @Input() sliderValue: number = 0;
  @Input() sliderLabel: string = "Speed";
  @Input() checkboxValue: number = 0;
  @Input() checkboxLabel: string = "CLIMATISATION";
  @Input() checkboxData: any[] = [];
  @Input("labelCulture") labelCulture: any = {};
  @Input("cs_alfaRomeo") cs_alfaRomeo: boolean = false;
  @Input("cs_fiat") cs_fiat: boolean = false;
  @Input("cs_jeep") cs_jeep: boolean = false;
  @Input("cs_peugeot") cs_peugeot: boolean = false;

  displayedValue: number = 0;
  temperatureValue: number = 0;
  vitesseValue: any;
  payloadValue: any;
  masseValue: any;
  acValue: any;
  pompeValue: any;
  pompeExist: any;

  // Flag to track if all inputs are ready
  private inputsReady: boolean = false;
  private currentSliderKey: string = "";
  private previousAutonomyValue: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.checkAndInitialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Only call checkAndInitialize if we have actual changes to relevant inputs
    if (
      changes.autonomyData ||
      changes.defaultValueForslider ||
      changes.checkboxData ||
      changes.drivingStyle
    ) {
      this.checkAndInitialize();
    }

    // Only process changes if inputs are ready
    if (this.inputsReady) {
      if (changes.autonomyData || changes.checkboxData) {
        this.resetOptionalValues();
      }
      if (changes.drivingStyle && !changes.drivingStyle.firstChange) {
        this.calculateDisplayedValue();
      }
      if (changes.checkboxLabel || changes.checkboxValue) {
        this.updateCheckboxValue();
      }
      if (changes.sliderLabel || changes.sliderValue) {
        this.updateSliderValue();
      }
    }
  }

  private checkAndInitialize(): void {
    // Check if all required inputs are available and valid
    const areInputsValid = this.validateInputs();

    if (areInputsValid && !this.inputsReady) {
      // Initialize component only when all inputs are valid
      this.inputsReady = true;

      // Check for POMPE_CHALEUR existence before initialization
      const hasPompeElement = this.checkboxData.some(
        (element) => element.key === "POMPE_CHALEUR"
      );
      if (!hasPompeElement) {
        this.pompeValue = null;
        this.pompeExist = undefined;
      }

      this.extractCheckboxValues();
      this.extractSliderValues();
      this.calculateDisplayedValue();
    }
  }
  private validateInputs(): boolean {
    // Validate autonomyData
    if (!Array.isArray(this.autonomyData) || this.autonomyData.length === 0) {
      return false;
    }

    // Validate defaultValueForslider
    if (
      !Array.isArray(this.defaultValueForslider) ||
      this.defaultValueForslider.length === 0
    ) {
      return false;
    }

    // Validate checkboxData
    if (!Array.isArray(this.checkboxData) || this.checkboxData.length === 0) {
      return false;
    }

    // Validate drivingStyle
    if (!this.drivingStyle) {
      return false;
    }

    return true;
  }

  // Rest of the component methods remain the same...
  resetOptionalValues(): void {
    if (!this.inputsReady) return;
    // console.log("defaultValueForslider:", this.defaultValueForslider);
    this.payloadValue = this.defaultValueForslider[2]?.value;
    // console.log("Payload Value:", this.payloadValue);

    // Check for POMPE_CHALEUR existence
    const hasPompeElement = this.checkboxData.some(
      (element) => element.key === "POMPE_CHALEUR"
    );
    if (!hasPompeElement) {
      this.pompeValue = null;
      this.pompeExist = undefined;
    } else {
      // Only set pompeExist if POMPE_CHALEUR exists
      this.pompeExist = this.checkboxData.some(
        (e) => e.label === "POMPE_CHALEUR" && e.value === 1
      )
        ? "true"
        : "false";
    }

    this.calculateDisplayedValue();
  }

  updateSliderValue(): void {
    if (!this.inputsReady) return;
    const sliderData = this.defaultValueForslider.find(
      (s) => s.label === this.sliderLabel
    );
    if (!sliderData) return;

    const key = sliderData.key;
    this.currentSliderKey = key;

    switch (key) {
      case "TEMPERATURE":
        this.temperatureValue = this.sliderValue;
        break;
      case "VITESSE":
        this.vitesseValue = this.sliderValue;
        break;
      case "MASSE":
        this.masseValue = this.sliderValue;
        break;
      case "PAYLOAD":
        this.payloadValue = this.sliderValue;
        break;
    }
    // console.log(`${key} updated to:`, this.sliderValue);
    this.calculateDisplayedValue();
  }

  // Method to handle changes to slider values
  updateCheckboxValue(): void {
    const checkboxData = this.checkboxData.find(
      (c) => c.label === this.checkboxLabel
    );
    if (!checkboxData) return;

    const key = checkboxData.key;
    switch (key) {
      case "CLIMATISATION":
        this.acValue = this.checkboxValue;
        break;
      case "POMPE_CHALEUR":
        this.pompeValue = this.checkboxValue;
        break;
    }
    this.extractCheckboxValues();
    this.calculateDisplayedValue();
  }

  // Extract values from checkboxData
  extractCheckboxValues(): void {
    // Reset values first
    this.acValue = undefined;
    this.pompeValue = null;

    this.checkboxData.forEach((element) => {
      switch (element.key) {
        case "CLIMATISATION":
          this.acValue = element.value;
          break;
        case "POMPE_CHALEUR":
          this.pompeValue = element.value;
          // Set pompeExist based on pompeValue
          this.pompeExist = this.pompeValue === 1 ? "true" : "false";
          break;
      }
    });

    // If POMPE_CHALEUR doesn't exist in checkboxData, ensure values are null/undefined
    if (!this.checkboxData.some((element) => element.key === "POMPE_CHALEUR")) {
      this.pompeValue = null;
      this.pompeExist = undefined;
    }
  }

  // Extract values from sliders
  extractSliderValues(): void {
    this.defaultValueForslider.forEach((element) => {
      switch (element.key) {
        case "TEMPERATURE":
          this.temperatureValue = element.value;
          break;
        case "VITESSE":
          this.vitesseValue = element.value;
          break;
        case "PAYLOAD":
          this.payloadValue = element.value;
          break;
        case "MASSE":
          this.masseValue = element.value;
          break;
      }
    });
  }

  // Calculate the displayed value based on various conditions
  calculateDisplayedValue(): void {
    const pompeElement = this.checkboxData.find(
      (e) => e.key === "POMPE_CHALEUR"
    );
    const hasPayload = this.payloadValue !== undefined;

    // Three-state logic for pompe
    let pompeState: "unavailable" | "on" | "off";

    if (!pompeElement) {
      pompeState = "unavailable";
    } else {
      pompeState = this.pompeValue === 1 ? "on" : "off";
    }

    try {
      if (pompeState === "unavailable" && !hasPayload) {
        // Case 1: No heat pump available, no payload
        this.displayedValue = this.getAutonomyValue(
          this.acValue,
          this.drivingStyle,
          this.temperatureValue,
          this.vitesseValue
        );
      } else if (pompeState === "unavailable" && hasPayload) {
        // Case 2: No heat pump available, has payload
        this.displayedValue = this.getAutonomyValue(
          this.acValue,
          this.payloadValue,
          this.drivingStyle,
          this.temperatureValue,
          this.vitesseValue
        );
      } else if (pompeState !== "unavailable" && !hasPayload) {
        // Case 3: Heat pump available (on or off), no payload
        this.displayedValue = this.getAutonomyValue(
          this.acValue,
          pompeState === "on" ? "true" : "false", // Explicitly pass "false" when off
          this.drivingStyle,
          this.temperatureValue,
          this.vitesseValue
        );
      } else {
        // Case 4: Heat pump available (on or off), has payload
        this.displayedValue = this.getAutonomyValue(
          this.acValue,
          this.payloadValue,
          pompeState === "on" ? "true" : "false", // Explicitly pass "false" when off
          this.drivingStyle,
          this.temperatureValue,
          this.vitesseValue
        );
      }
    } catch (error) {
      this.displayedValue = this.previousAutonomyValue;
    }
  }

  // Helper method to fetch the autonomy value from autonomyData
  getAutonomyValue(...params: any[]): number {
    let result = this.autonomyData[0]?.values;
    let validResult = true;

    for (const param of params) {
      if (param === undefined) continue; // Skip undefined params
      if (result && result[param] !== undefined) {
        result = result[param];
      } else {
        console.error(`Property '${param}' is undefined in autonomyData.`);
        validResult = false;
        break;
      }
    }

    if (validResult) {
      this.previousAutonomyValue = result; // Store the valid result
      return result;
    } else {
      // console.log("Using previous value:", this.previousAutonomyValue);
      return this.previousAutonomyValue; // Return the last known good value
    }
  }
}
