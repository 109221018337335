export const API_CONFIG = {
  plugSurfingUrlSurface: "https://api.plugsurfing.com/api/v4/request?cache=1",
  plugSurfingUrlV4: "https://api.plugsurfing.com/api/v4/request?cache=1",
  esolutionBackend: "https://admin.lev.awsmpsa.com/",
  plugSurfingUrlV4Route: " https://api.plugsurfing.com/api/v4/request?cache=1",
  plugSurfingUrlV3Surface: "https://api.plugsurfing.com/api/v3/request?cache=1",
  plugSurfingAuthorization: "",
  clientId: "",
  offersURL:
    "https://api.groupe-psa.com/applications/onlinefinance-simulation-offer/v1/financialsimulation/offers",
  criteriasURL:
    "https://api.groupe-psa.com/applications/onlinefinance-simulation-offer/v1/financialsimulation/offer/criteria",
  detailsURL:
    "https://api.groupe-psa.com/applications/onlinefinance-simulation/v2/financialsimulation/vehiclesimulationdetails",
  server_url: "https://www.lev.awsmpsa.com/assets/", // This key will be replaced in jenkinsfile during build
  distanceFilterConnector: 200000,

  bodySatation: {
    chargePointIds: [],
  },

  bodyRadius: {
    "station-get-radius": {
      lat: "",
      long: "",
      radius: 10000,
      filters: {
        "connector-types": [],
        payable: ["PSA_EU", "PSC_EU"],
      },
    },
  },

  bodyRoute: {
    "station-get-route": {
      corridor: {
        width: 6000,
        points: "",
      },
      maxresults: 1000,
      filters: {
        payable: ["PSA_EU", "PSC_EU"],
      },
    },
  },

  bodySurface: {
    "station-get-surface": {
      "min-lat": 42.74614299966806,
      "max-lat": 49.978726643385905,
      "min-long": -8.947687990395025,
      "max-long": 6.486181222470805,
      filters: {
        "connector-types": [],
        countries: [],
        payable: ["PSA_EU", "PSC_EU"],
      },
    },
  },

  searchPOI: {
    max_results: 10000,
    offset: 0,
    status: "AVAILABLE",
    search_area: {
      latitude: "48.864716",
      longitude: "2.349014",
      range: 1000,
    },
    connectors_standard: [],
  },

  offersBody: {
    context: {
      siteCode: "LEV",
      distributionBrand: "AP",
      countryCode: "FR",
      languageCode: "fr",
      customer: {
        clientType: "P",
      },
    },
    vehicle: {
      lcdv16Code: "1PP2A5HMT1B0BUB3",
    },
  },

  criteriaBody: {
    context: {
      siteCode: "LEV",
      distributionBrand: "AP",
      countryCode: "FR",
      languageCode: "fr",
      customer: {
        clientType: "P",
      },
    },
    vehicle: {
      lcdv16Code: "1PP2A5JESFB0A0B3",
      pricing: {
        netPriceInclTax: 30000,
      },
    },
    parameters: {
      productKey: "",
    },
  },

  detailsBody: {
    context: {
      siteCode: "LEV",
      componentCode: "TESTAPI",
      distributionBrand: "AP",
      countryCode: "FR",
      languageCode: "fr",
      customer: {
        clientType: "P",
      },
    },
    vehicle: {
      lcdv16Code: "1PP2A5JESFB0A0B3",
      pricing: {
        basicPriceInclTax: 30000,
        netPriceInclTax: 30000,
      },
      fiscalProperties: {
        co2Emissions: 99,
      },
    },
    parameters: {
      duration: 49,
      deposit: 6,
      depositAmountKind: "PCT",
      annualMileage: 10000,
      productKey: "LOA",
      services: [
        {
          serviceCode: "",
          packageCode: "",
          included: true,
        },
      ],
    },
    isShowServicePricing: true,
  },
};
