import { API_CONFIG } from "@assets/plugSurfing_params/api_url_config";
import { CssData } from "@lev/models";

export function addDynamicStyles(cssData: CssData, isd1: string) {
  const stylesD1 =
    `
    .swiper-button-prev {
      background-image: url("` +
    cssData.chevron_swiper_left +
    `") !important;
    background-repeat: no-repeat;
      width:28px;
      filter: invert(63%) sepia(65%) saturate(5515%) hue-rotate(165deg) brightness(96%) contrast(101%);
      background-size: auto !important;
    }
    .swiper-button-next {
      background-image: url("` +
    cssData.chevron_swiper_right +
    `") !important;
      width:28px;
      filter: invert(63%) sepia(65%) saturate(5515%) hue-rotate(165deg) brightness(96%) contrast(101%);
      background-size: auto !important;
      background-repeat: no-repeat;
    }
    .accordion:after {
      content: url('` +
    cssData.chevron +
    `');
      width:28px;
      filter: invert(63%) sepia(65%) saturate(5515%) hue-rotate(165deg) brightness(96%) contrast(101%);
    }
    .accordion-obc:after {
      content: url('` +
    cssData.chevron +
    `');
      width:28px;
      filter: invert(63%) sepia(65%) saturate(5515%) hue-rotate(165deg) brightness(96%) contrast(101%);
    }
    .lev-cs-opened:after {
      content: url('` +
    cssData.chevron_off +
    `');
      width:28px;
      filter: invert(63%) sepia(65%) saturate(5515%) hue-rotate(165deg) brightness(96%) contrast(101%);
    }
    .accordion:hover:after {
      content: url('` +
    cssData.chevron_hover +
    `');
      width:28px;
      filter: invert(63%) sepia(65%) saturate(5515%) hue-rotate(165deg) brightness(96%) contrast(101%);
    }
    .accordion-obc:hover:after {
      content: url('` +
    cssData.chevron_hover +
    `');
      width:28px;
      filter: invert(63%) sepia(65%) saturate(5515%) hue-rotate(165deg) brightness(96%) contrast(101%);
    }
    .lev-cs-opened:hover:after {
      content: url('` +
    cssData.chevron_off_hover +
    `');
      width:28px;
      filter: invert(63%) sepia(65%) saturate(5515%) hue-rotate(165deg) brightness(96%) contrast(101%);
    }
    .zone-accordion-ap:after {
    float: left;
    display: inline-flex;
    clear: none;
    margin-top: 7%;
    }
    .zone-accordion-obc-ap:after {
      float: right;
      clear: none;
      display: inline-flex;
      margin-top: 0%;
      margin-right: 22px;
    }
    `;
  let styles =
    `
    @font-face {
      font-family: 'DS_title_regular';
      src: url("` +
    API_CONFIG.server_url +
    `fonts/ds/DS-Title-WebSmall-Regular.woff2");
    }

    @font-face {
      font-family: 'DS_title_italic';
      src: url("` +
    API_CONFIG.server_url +
    `fonts/ds/DS-Title-WebSmall-Italic.woff2");
    }

    @font-face {
      font-family: 'DS_regular';
      src: url('` +
    API_CONFIG.server_url +
    `fonts/ds/DS-Automobiles-Regular.woff2') format('woff2'), url('` +
    API_CONFIG.server_url +
    `fonts/ds/DSAutomobiles_Regular.eot') format('eot'), url('` +
    API_CONFIG.server_url +
    `fonts/ds/DSAutomobiles_Regular.otf') format('otf'), url('` +
    API_CONFIG.server_url +
    `fonts/ds/DSAutomobiles_Regular.ttf') format('truetype');
    }

    @font-face {
      font-family: 'DS_Bold';
      src: url('` +
    API_CONFIG.server_url +
    `fonts/ds/DS-Automobiles-Bold.woff2') format('woff2'), url('` +
    API_CONFIG.server_url +
    `fonts/ds/DSAutomobiles_Bold.eot') format('eot'), url('` +
    API_CONFIG.server_url +
    `fonts/ds/DSAutomobiles-Bold.woff') format('woff'), url('` +
    API_CONFIG.server_url +
    `fonts/ds/DSAutomobiles_Bold.ttf') format('truetype');
    }
    @font-face {
      font-family: 'Vauxhall';
      src: url('` +
    API_CONFIG.server_url +
    `fonts/opel/VauxhallNeueRegular.eot') format('eot'), url('` +
    API_CONFIG.server_url +
    `fonts/opel/VauxhallNeueRegular.otf') format('otf'), url('` +
    API_CONFIG.server_url +
    `fonts/opel/VauxhallNeueRegular.ttf') format('truetype'),url('` +
    API_CONFIG.server_url +
    `fonts/opel/VauxhallNeueRegular.woff') format('woff');
    }
    @font-face {
      font-family: 'Vauxhall Bold';
      src: url('` +
    API_CONFIG.server_url +
    `fonts/opel/VauxhallNeueExtraBold.eot') format('eot'), url('` +
    API_CONFIG.server_url +
    `fonts/opel/VauxhallNeueExtraBold.otf') format('otf'), url('` +
    API_CONFIG.server_url +
    `fonts/opel/VauxhallNeueExtraBold.ttf') format('truetype'),url('` +
    API_CONFIG.server_url +
    `fonts/opel/VauxhallNeueExtraBold.woff') format('woff');
    }
    .swiper-button-prev {
      background-image: url("` +
    cssData.chevron_swiper_left +
    `") !important;
      background-size: auto !important;
      background-repeat: no-repeat;
    }
    .swiper-button-next {
      background-image: url("` +
    cssData.chevron_swiper_right +
    `") !important;
      background-size: auto !important;
      background-repeat: no-repeat;
    }
    .ov .swiper-button-next:not(.vauxhall),.ov .swiper-button-prev:not(.vauxhall) {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: #cccccc;
    }
    .ov .swiper-button-next:not(.vauxhall):hover,.ov .swiper-button-prev:not(.vauxhall):hover{
      background-color: #F7FF14;
    }
    .accordion:after {
      content: url('` +
    cssData.chevron +
    `');
      font-size: 13px;
      color: #777;
    }
    .accordion-obc:after {
      content: url('` +
    cssData.chevron +
    `');
      font-size: 13px;
      color: #777;
    }
    .zone-accordion-ap:after {
      float: left;
      clear: none;
      margin-top: 7%;
    }
    .zone-accordion-obc-ap:after {
      float: right;
      clear: none;
      margin-top: 0%;
      margin-right: 22px;
    }
    .lev-cs-opened:after {
      content: url('` +
    cssData.chevron_off +
    `');
    }
    .accordion:hover:after {
      content: url('` +
    cssData.chevron_hover +
    `');
      font-size: 13px;
      color: #777;
    }
    .accordion-obc:hover:after {
      content: url('` +
    cssData.chevron_hover +
    `');
      font-size: 13px;
      color: #777;
    }
    .lev-cs-opened:hover:after {
      content: url('` +
    cssData.chevron_off_hover +
    `');
    }`;

  const node = document.createElement("style");
  node.innerHTML = isd1 === "true" ? stylesD1 : styles;
  document.body.appendChild(node);
}

export function toggle(vehs: any) {
  if (document.getElementsByClassName("accordion-obc")[0]) {
    document
      .getElementsByClassName("accordion-obc")[0]
      .classList.remove("lev-cs-opened");
    (<HTMLElement>(
      document.getElementsByClassName("accordion-obc")[0].nextElementSibling
    )).style.maxHeight = "0px";
  }
  if (vehs.length > 1) {
    document
      .getElementsByClassName("accordion")[0]
      .classList.toggle("lev-cs-opened");
    var panel = <HTMLElement>(
      document.getElementsByClassName("accordion")[0].nextElementSibling
    );
    if (panel.style.maxHeight) {
      panel.style.maxHeight = "0px";
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
}

export function toggleOBC() {
  if (document.getElementsByClassName("accordion")[0]) {
    document
      .getElementsByClassName("accordion")[0]
      .classList.remove("lev-cs-opened");
    (<HTMLElement>(
      document.getElementsByClassName("accordion")[0].nextElementSibling
    )).style.maxHeight = "0px";
  }
  if (document.getElementsByClassName("accordion-obc")[0]) {
    document
      .getElementsByClassName("accordion-obc")[0]
      .classList.toggle("lev-cs-opened");
    var panel = <HTMLElement>(
      document.getElementsByClassName("accordion-obc")[0].nextElementSibling
    );
  }
  var panel = <HTMLElement>(
    document.getElementsByClassName("accordion-obc")[0].nextElementSibling
  );
  if (panel.style.maxHeight) {
    panel.style.maxHeight = "0px";
  } else {
    panel.style.maxHeight = panel.scrollHeight + "0px";
  }
}
