import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shiftPercent'
})
export class ShiftPercentPipe implements PipeTransform {

  transform(value: number, max: number): number {
    let 
      result: number  = value * 100 / max;
   
    return +Number(result).toFixed();
  }

}
