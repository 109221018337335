<!-- vehicle-selector.component.html -->
<div class="selection-container">
  <!-- Vehicle Selection -->
  <div class="selection">
    <h5>
      {{ labelCulture?.model_name || "Model" }}
    </h5>
    <mat-form-field appearance="outline" floatLabel="never">
      <mat-label>{{ labelCulture?.select || "Select" }}</mat-label>
      <mat-select
        [(ngModel)]="selectedVehicleId"
        name="vehiculeId"
        (ngModelChange)="onVehicleChange($event)"
        [disabled]="vehicles.length === 0"
      >
        <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.LCDV">
          {{ vehicle.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- OBC Selection (only when required) -->
  <div class="selection" *ngIf="isObcRequired">
    <h5>
      {{ labelCulture?.on_board_charger || "On-board Charger" }}
    </h5>
    <mat-form-field appearance="outline" floatLabel="never">
      <mat-label>{{ labelCulture?.select || "Select" }}</mat-label>
      <mat-select
        [(ngModel)]="selectedOBC"
        name="OBC"
        (ngModelChange)="onOBCChange($event)"
        [disabled]="!isVehicleSelected || obcs.length === 0"
      >
        <mat-option *ngFor="let obc of obcs" [value]="obc.value">
          {{ formatOBCValue(obc.viewValue) }} kW
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
