import { Component, HostListener, Input, OnInit } from "@angular/core";

import { LevServiceService } from "@lev/services/lev-service.service";
import { CssData } from "@lev/models/css-data";
import { SearcheCriteria } from "@lev/models/searchCriteria";
import { Data } from "@lev/models/data";
import { Vehicule } from "@lev/models/vehicule-as";
import { API_CONFIG } from "@assets/plugSurfing_params/api_url_config";
import { Mesure } from "@lev/as/core";

type DataLayerEvent = {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: string; // Make eventValue optional
};

@Component({
  selector: "app-autonomy-simulator",
  templateUrl: "./autonomy-simulator.component.html",
  styleUrls: ["./autonomy-simulator.component.scss"],
})
export class AutonomySimulatorComponent implements OnInit {
  temperature: Mesure = {
    value: 15,
    step: 5,
    min: -10,
    max: 35,
    unit: "°C",
  };
  distance: Mesure = {
    value: 15,
    step: 5,
    min: -10,
    max: 35,
    unit: "°C",
  };
  masse: Mesure = {
    value: 2000,
    step: 200,
    min: 2000,
    max: 3000,
    unit: "KG",
  };
  payload: Mesure = {
    value: 0,
    step: 100,
    min: 0,
    max: 700,
    unit: "KG",
  };
  vitesse: Mesure = {
    value: 70,
    step: 10,
    min: 30,
    max: 130,
    unit: " km",
  };
  checked = true;
  conducts: any = [];

  @Input()
  country: string;

  @Input()
  langage: string;

  @Input()
  brand: string;

  @Input()
  ispro: string;

  @Input("isspoticar")
  isspoticar: string;

  @Input()
  isd1: string;

  @Input()
  vehicleid: string;

  @Input()
  lcdv10: string;

  cssData: CssData;
  params: any = [];
  vehs: any = [];
  isNewOpelStyle: boolean;
  vehicule: Vehicule = new Vehicule();
  as_d1 = false;
  asHasConso = false;
  isRightToLeft = false;
  as_peugeot = false;
  as_citroen = false;
  as_vauxhall = false;
  as_spoticar = false;
  as_ds = false;
  as_opel = false;
  as_fiat = false;
  isAccess = false;
  isCoupledLcdv = false;
  isBattey_xl: string;
  isBattey_l: string;
  coupledData = [];
  vehsCouples = [];
  coupleLabel: string;
  labelCulture: any = [];
  SearcheCriteria: SearcheCriteria = new SearcheCriteria();
  modeElectricLabel = "";
  driving_style = "as_normal";
  air_conditioner = 1;
  pompe_chaleur: boolean = false;
  display_pompe_chaleur: boolean;
  display_payload: boolean = false;
  speed = 70;
  calculatedAutonomy = 0;
  calculatedAutonomyTable = [];
  calculatedConsumption = 0;
  calculatedConsumptionTable = [];
  innerWidth: any = 0;
  opened: boolean = false;
  result: any = null;
  resultConsumption: any = null;
  eventActionConduite: string =
    "Widget::LEV::Autonomy Simulator::Type de conduite";
  eventActionTemperatureExterieure: string =
    "Widget::LEV::Autonomy Simulator::Temperature exterieure";
  eventActionSpeed: string = "Widget::LEV::Autonomy Simulator::Speed";
  eventActionTemperature: string =
    "Widget::LEV::Autonomy Simulator::Temperature";
  eventActionDistance: string = "Widget::LEV::Autonomy Simulator::Distance";
  eventActionMasse: string = "Widget::LEV::Autonomy Simulator::Weight";
  eventActionSwitch: string = "Widget::Autonomy Simulator::LEV::Switch";
  eventLabel: string;
  isMultipleModels: boolean = false;
  vehicules_VS_list: any = [
    "1CK0",
    "1KC7",
    "1ACS",
    "1GK0",
    "1KG7",
    "1OVZ",
    "1PK0",
    "1K07",
    "1APT",
  ];

  displayMLFor: any = ["AT", "NL"];

  vitessev = 80;
  indexVitessev = 3;
  temperaturev = 20;
  indextemperaturev = 2;
  indexmassev = 2;
  indexpayloadv = 2;
  indexDistancev = 3;
  resultv = 0;
  vehicleIdList: string[];
  vehicleIndex: number = 0;
  vehiclesList: Vehicule[];
  map = new Map();
  vehiclesListBeforeMapping: Vehicule[];
  masse_style: any;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(private levservice: LevServiceService, private data: Data) {
    this.cssData = new CssData();
    //set values of speed and temp
    this.map.set("vitesse", [15, 30, 50, 80, 110]);
    this.map.set("temperature", [0, 10, 20, 30, 40]);
    this.map.set("masse", [2000, 2200, 2400, 2600, 2800, 3000]);
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.survey();
    this.defaultConditions();
    if (
      this.brand != undefined &&
      this.country != undefined &&
      this.langage != undefined
    ) {
      if (!this.isMultipleModels) this.getLevByBrandAndLcdv10();
      else if (this.isMultipleModels) this.getLevByBrand();
      if (this.country.toLocaleLowerCase() === "gb")
        this.map.set("payload", [0, 225, 450, 675, 900, 1100, 1325, 1550]);
      else this.map.set("payload", [0, 100, 200, 300, 400, 500, 600, 700]);
    }
    this.vitesse.unit = " " + this.labelCulture.as_distance_unit_autonomy;
  }

  checkNewOpelStyleByCulture() {
    const isOpelRequiredCondition =
      this.brand.toLocaleLowerCase() === "ov" && !this.isCoupledLcdv;
    return isOpelRequiredCondition;
  }

  //set default configuration
  defaultConditions() {
    if (!["false", "true"].includes(this.ispro)) this.ispro = "false";
    if (!["false", "true"].includes(this.isspoticar)) this.isspoticar = "false";
    if ((this.brand ? this.brand.toLowerCase() : "") === "ap")
      this.isd1 = "true";
    if (this.langage.toLowerCase() === "no") this.langage = "nb";
    this.checkIfUsageOfMultipleModels();
  }

  checkIfUsageOfMultipleModels() {
    this.vehicleIdList = this.vehicleid.split("|");
    if (this.vehicleIdList.length > 1) this.isMultipleModels = true;
  }

  getLevByBrand() {
    this.isRightToLeftChecker();
    this.vehicleid = this.vehicleIdList[0];
    this.vehicleIndex = 0;
    const nameFile =
      "levdata/levdata-as/levdata_" +
      this.country.toLowerCase() +
      "_" +
      this.langage.toLowerCase();
    this.levservice.getData(nameFile).subscribe((data) => {
      this.vehiclesListBeforeMapping = data["vehs"].filter((ele) => {
        return this.vehicleIdList.includes(ele.LEV_AS_LCDV);
      });
      this.vehiclesList = this.vehiclesListBeforeMapping.filter(
        (item) => item.LEV_AS_BRAND.toLowerCase() === this.brand.toLowerCase()
      );
      console.log(this.vehiclesList);
      this.showBrand();
      this.getLevByBrandAndLangMultipleModels();
      this.getLabelsByBrandAndLang();
      this.getCssByBrand();
      this.addDynamicStyles();
    });
  }

  initVehicle() {
    this.vehicule = new Vehicule();
    this.vehicule.LEV_AS_Picture = "";
    this.result = null;
    this.conducts = [];
  }
  getLevByBrandAndLangMultipleModels() {
    this.initVehicle();
    this.vehs = this.vehiclesList;
    this.isProVehicleId(this.vehicleid, this.vehiclesList);
    this.isNewOpelStyle = this.checkNewOpelStyleByCulture();
    this.vehicule = this.vehiclesList.filter(
      (veh) => veh.LEV_AS_LCDV === this.vehicleid
    )[0];
    if (!this.vehicule.LEV_AS_Picture.includes("http"))
      this.vehicule.LEV_AS_Picture =
        API_CONFIG.server_url + this.vehicule.LEV_AS_Picture;
    this.setDefaultValues(this.vehicule);
  }

  refreshLevByBrand() {
    this.showBrand();
    this.getLevByBrandAndLangMultipleModels();
    this.getLabelsByBrandAndLang();
    this.getCssByBrand();
    this.addDynamicStyles();
  }

  previewsVehicle() {
    const vehicleIndexSearch = this.vehicleIdList.indexOf(this.vehicleid);
    if (vehicleIndexSearch === 0) {
      this.vehicleid = this.vehicleIdList[this.vehicleIdList.length - 1];
      this.vehicleIndex = this.vehicleIdList.length - 1;
    } else {
      this.vehicleid = this.vehicleIdList[vehicleIndexSearch - 1];
      this.vehicleIndex = vehicleIndexSearch - 1;
    }
    // this.initVehicle();

    this.refreshLevByBrand();
  }

  nextVehicle() {
    const vehicleIndexSearch = this.vehicleIdList.indexOf(this.vehicleid);
    if (this.vehicleIndex === this.vehicleIdList.length - 1) {
      this.vehicleid = this.vehicleIdList[0];
      this.vehicleIndex = 0;
    } else {
      this.vehicleid = this.vehicleIdList[vehicleIndexSearch + 1];
      this.vehicleIndex = vehicleIndexSearch + 1;
    }
    // this.initVehicle();
    this.refreshLevByBrand();
  }

  //display legal mentions for NL and AT
  displayML() {
    let found = false;
    if (this.as_opel) {
      this.displayMLFor.forEach((e) => {
        if (e.toLowerCase() == this.country.toLowerCase()) {
          found = true;
        }
      });
    }
    return found;
  }

  deviceIsMobile() {
    return this.innerWidth < 812;
  }

  addDynamicStyles() {
    const styles =
      `
    .plusImg{
        content: url("` +
      API_CONFIG.server_url +
      `peugeot/Plus_Outline.png");
      }
    .minusImg{
      content: url("` +
      API_CONFIG.server_url +
      `peugeot/NegativeSign_Outline.png");
    }
    @font-face {
      font-family: 'DS_title_regular';
      src: url("` +
      API_CONFIG.server_url +
      `fonts/ds/DSTitleWebSmall_Regular.ttf");
    }
    @font-face {
      font-family: 'DS_title_italic';
      src: url("` +
      API_CONFIG.server_url +
      `fonts/ds/DSTitleWebSmall_Italic.ttf");
    }
    @font-face {
      font-family: 'DS_regular';
      src: url('` +
      API_CONFIG.server_url +
      `fonts/ds/DSAutomobiles_Regular.eot') format('eot'), url('` +
      API_CONFIG.server_url +
      `fonts/ds/DSAutomobiles_Regular.otf') format('otf'), url('` +
      API_CONFIG.server_url +
      `fonts/ds/DSAutomobiles_Regular.ttf') format('truetype');
    }
    @font-face {
      font-family: 'DS_Bold';
      src: url('` +
      API_CONFIG.server_url +
      `fonts/ds/DSAutomobiles_Bold.eot') format('eot'), url('` +
      API_CONFIG.server_url +
      `fonts/ds/DSAutomobiles-Bold.woff') format('woff'), url('` +
      API_CONFIG.server_url +
      `fonts/ds/DSAutomobiles_Bold.ttf') format('truetype');
    }`;
    const node = document.createElement("style");
    node.innerHTML = styles;
    document.body.appendChild(node);
  }

  //init input params
  survey() {
    if (
      this.brand == undefined &&
      this.langage == undefined &&
      this.country == undefined
    ) {
      this.SearcheCriteria = this.data.storage;
      this.initFromSearchCriteria();
    }
    this.setUpAccessCountries();
  }

  initFromSearchCriteria() {
    if (this.SearcheCriteria) {
      this.country = this.SearcheCriteria.countryCode;
      this.langage = this.SearcheCriteria.langageCode;
      this.brand = this.SearcheCriteria.marque;
      this.ispro = this.SearcheCriteria.isPro ? "true" : "false";
      this.isd1 = this.brand.toLowerCase() === "ap" ? "true" : "false";
      this.vehicleid = this.SearcheCriteria.mlcdv
        ? this.SearcheCriteria.mlcdv
        : this.SearcheCriteria.vehicleid;
      this.isspoticar = this.isSpoticar();
      this.lcdv10 = this.SearcheCriteria.lcdv10
        ? this.SearcheCriteria.lcdv10
        : undefined;
    }
  }
  setUpAccessCountries() {
    if ("undefined" !== typeof this.country) {
      //access countries
      if (["gp", "gf", "mq", "re"].indexOf(this.country.toLowerCase()) > -1) {
        this.isAccess = true;
      }
    }
  }

  isSpoticar() {
    return this.SearcheCriteria.isspoticar === "true" ? "true" : "false";
  }

  /**
   * get the type of vehicle based on VS list or LCDV
   * @return string
   */
  getTypeOfVehicle() {
    const substrVehiculeId = this.vehicleid
      ? this.vehicleid.substring(0, 1)
      : "";
    const isVS = this.vehicules_VS_list.indexOf(this.vehicleid) > -1;
    var strPrefix = "V";
    if (isVS) {
      strPrefix = strPrefix + "S";
    } else if (this.brand.toLocaleLowerCase() === "fo") {
      strPrefix = strPrefix + "FOU";
    } else if (substrVehiculeId === "1") {
      strPrefix = strPrefix + "P";
    } else if (substrVehiculeId === "2") {
      strPrefix = strPrefix + "U";
    } else if (substrVehiculeId === "D") {
      strPrefix = strPrefix + "PD";
    }
    return strPrefix;
  }

  // get labels
  getLabelsByBrandAndLang() {
    var nameFile;
    const srcFile = "labels_as/labels_as_" + this.brand.toLowerCase();
    if (this.getTypeOfVehicle() === "VP") {
      nameFile = srcFile;
    } else if (this.getTypeOfVehicle() === "VPD") {
      nameFile = srcFile + "_c";
    } else {
      nameFile = srcFile + "_" + this.getTypeOfVehicle().toLowerCase();
    }
    try {
      this.levservice
        .getDataWithPromise(nameFile)
        .then(async (data) => {
          this.labelCulture = await data[
            this.langage.toLowerCase() + "-" + this.country.toLowerCase()
          ];
          const arrayLabels = this.labelCulture.as_autonomy_in_charge
            ? this.labelCulture.as_autonomy_in_charge.split(" ")
            : this.labelCulture.as_autonomy_in_charge2.split(" ");
          this.modeElectricLabel =
            arrayLabels[0] +
            " " +
            this.labelCulture.as_autonomy_mode_eletric +
            " " +
            arrayLabels[1];
          //load object autonomies
          if (this.result == null && !this.isMultipleModels) {
            this.getResult();
          }
          if (this.result == null && this.isMultipleModels) {
            this.getResultMultipleModels();
          }
          //load object autonomies
          if (this.resultConsumption == null) {
            //this.getResultConsumption();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  isIEOrEdge() {
    // Internet Explorer 6-11
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  //get css file
  getCssByBrand() {
    const brandKey =
      (this.brand.toLocaleLowerCase() != "ov" &&
        this.country.toLocaleLowerCase() != "gb") ||
      this.brand.toLocaleLowerCase() != "vx"
        ? this.brand
        : "vauxhall";
    const nameFile = "css/css_us_" + brandKey.toLowerCase();
    this.levservice.getData(nameFile).subscribe((data) => {
      this.cssData = data;
      this.cssData.arrow_grey = API_CONFIG.server_url + this.cssData.arrow_grey;
      this.cssData.chevron = API_CONFIG.server_url + this.cssData.chevron;
      this.cssData.chevron_off =
        API_CONFIG.server_url + this.cssData.chevron_off;
      this.cssData.validate_outline =
        API_CONFIG.server_url + this.cssData.validate_outline;
      if (this.isspoticar === "true") this.as_spoticar = true;
    });
  }

  showBrand() {
    if (this.brand.toLowerCase() == "ap") {
      this.as_peugeot = true;
    } else if (this.brand.toLowerCase() == "ac") {
      this.as_citroen = true;
    } else if (this.brand.toLowerCase() == "fo") {
      this.as_fiat = true;
    } else if (this.brand.toLowerCase() == "ds") {
      this.as_ds = true;
    } else if (this.brand.toLowerCase() == "ov") {
      this.as_opel = true;
      // GB Vauxhall theme
      if (this.country.toLocaleLowerCase() == "gb") this.as_vauxhall = true;
      if (!this.isMultipleModels)
        this.vehicule.LEV_AS_Picture =
          API_CONFIG.server_url + "opel/glx-hybrid-Vauxhall.png";
    } else if (this.brand.toLowerCase() == "vx") {
      this.as_opel = true;
      this.as_vauxhall = true;
    }
    if (this.isd1 === "true") {
      this.as_d1 = true;
    }
  }
  isRightToLeftChecker() {
    this.isRightToLeft = ["ar", "he"].includes(this.langage.toLowerCase());
  }
  getLevByBrandAndLcdv10() {
    const nameFile =
      "levdata/levdata-as/levdata_" +
      this.country.toLowerCase() +
      "_" +
      this.langage.toLowerCase();
    this.isRightToLeftChecker();
    this.loadCoupledData();
    this.levservice.getData(nameFile).subscribe((data) => {
      if (!this.vehicleid && !this.isCoupledLcdv)
        data["vehs"].forEach((element) => {
          if (element.LEV_AS_BRAND === this.brand.toLowerCase()) {
            if (
              element.LEV_AS_LCDV10 !== undefined &&
              element.LEV_AS_LCDV10 !== null
            ) {
              if (element.LEV_AS_LCDV10.includes(this.lcdv10)) {
                this.vehicleid = element.LEV_AS_LCDV;
              }
            }
          }
        });
      this.showBrand();
      this.getLevByBrandAndLang();
      this.getLabelsByBrandAndLang();
      this.getCssByBrand();
      this.addDynamicStyles();
    });
  }
  //get data veh from levdata
  getLevByBrandAndLang() {
    let brand: string = this.brand.toLowerCase();
    let country: string = this.country.toLowerCase();
    let langage: string = this.langage.toLowerCase();
    const nameFile = "levdata/levdata-as/levdata_" + country + "_" + langage;
    this.levservice.getData(nameFile).subscribe(async (data) => {
      this.isProVehicleId(this.vehicleid, data["vehs"]);
      this.isCoupledVehicleId(this.vehicleid);
      this.isNewOpelStyle = this.checkNewOpelStyleByCulture();
      data["vehs"] = this.getLevDataByPro(data["vehs"], this.ispro);
      data = data["vehs"];
      this.params = data["params"];
      data.forEach((element) => {
        if (!this.isCoupledLcdv) {
          if (
            element.LEV_AS_LCDV.toLowerCase() == this.vehicleid.toLowerCase()
          ) {
            this.vehs.push(element);
          }
        } else {
          this.vehsCouples.forEach((vehId) => {
            if (element.LEV_AS_LCDV.toLowerCase() === vehId.toLowerCase()) {
              this.vehs.push(element);
            }
          });
        }
      });

      this.vehicule = await this.vehs[0];
      this.vehicule.LEV_AS_Picture =
        this.vehicule.LEV_AS_TYPE == "PHEV" && this.as_vauxhall
          ? API_CONFIG.server_url + "opel/glx-hybrid-Vauxhall.png"
          : API_CONFIG.server_url + this.vehicule.LEV_AS_Picture;

      this.setDefaultValues(this.vehicule);
    });
  }
  setDefaultValues(vehicule) {
    const vehsDefaultValues = vehicule.LEV_DEFAULT_VALUES;
    this.driving_style = vehsDefaultValues.DRIVING_STYLE;
    this.masse_style = vehsDefaultValues.MASSE_EXIST;
    this.display_payload = vehsDefaultValues.DISPLAY_PAYLOAD;
    this.air_conditioner = vehsDefaultValues.CLIMATISATION;
    this.pompe_chaleur = vehsDefaultValues.POMPE_CHALEUR;
    this.display_payload = vehsDefaultValues.DISPLAY_PAYLOAD;
    this.display_pompe_chaleur = this.pompe_chaleur;
    console.log("display_pompe_chaleur", this.display_pompe_chaleur);
    this.setDefaultTemperature(vehsDefaultValues.TEMPERATURE);
    this.setDefaultSpeed(vehsDefaultValues.VITESSE);
    this.setDefaultMasse(vehsDefaultValues.MASSE);
    this.setDefaultPayload(vehsDefaultValues?.PAYLOAD);
    this.setDefaultMapping(vehsDefaultValues.VITESSE);
    if (this.vehicule.LEV_AS_TYPE == "PHEV") {
      this.indexVitessev = 3;
      this.indextemperaturev = 2;
    }
    //initialisation des valeurs temperature vitesse et climatiseur pour les VU
    if (this.getTypeOfVehicle() === "VU" || this.isCoupledLcdv) {
      if (this.vehicleid === "2OVV") {
        this.vitesse.value = 40;
        this.masse.value = 2400;
        this.air_conditioner = 0;
      }
    }
    if (this.masse_style) {
      // this.vitesse.value = 40;
      this.masse.value = 2400;
      this.air_conditioner = 0;
    }

    if (this.air_conditioner) {
      this.calculateAutonomy(this.pompe_chaleur);
      this.calculateConsumption(this.pompe_chaleur);
    }
  }

  setDefaultTemperature(vehsTemperature) {
    this.temperature = <Mesure>{
      min:
        vehsTemperature.MIN !== 0 ? vehsTemperature.MIN : this.temperature.min,
      value:
        vehsTemperature.VALUE !== 0
          ? vehsTemperature.VALUE
          : this.temperature.value,
      max:
        vehsTemperature.MAX !== 0 ? vehsTemperature.MAX : this.temperature.max,
      step:
        vehsTemperature.STEP !== 0
          ? vehsTemperature.STEP
          : this.temperature.step,
    };
  }

  setDefaultSpeed(vehsVitesse) {
    this.vitesse = <Mesure>{
      min: vehsVitesse.MIN !== 0 ? vehsVitesse.MIN : this.vitesse.min,
      value: vehsVitesse.VALUE !== 0 ? vehsVitesse.VALUE : this.vitesse.value,
      max: vehsVitesse.MAX !== 0 ? vehsVitesse.MAX : this.vitesse.max,
      step: vehsVitesse.STEP !== 0 ? vehsVitesse.STEP : this.vitesse.step,
    };
  }

  setDefaultPayload(vehsPaylod) {
    if (this.display_payload)
      this.payload = <Mesure>{
        min: vehsPaylod.MIN !== 0 ? vehsPaylod.MIN : this.payload.min,
        value: vehsPaylod.VALUE !== 0 ? vehsPaylod.VALUE : this.payload.value,
        max: vehsPaylod.MAX !== 0 ? vehsPaylod.MAX : this.payload.max,
        step: vehsPaylod.STEP !== 0 ? vehsPaylod.STEP : this.payload.step,
      };
  }
  setDefaultMasse(vehsMasse) {
    this.masse = <Mesure>{
      min: vehsMasse.MIN !== 0 ? vehsMasse.MIN : this.masse.min,
      value: vehsMasse.VALUE !== 0 ? vehsMasse.VALUE : this.masse.value,
      max: vehsMasse.MAX !== 0 ? vehsMasse.MAX : this.masse.max,
      step: vehsMasse.STEP !== 0 ? vehsMasse.STEP : this.masse.step,
    };
  }

  setDefaultMapping(vehsVitesse) {
    var vitessMapping = [];
    if (
      vehsVitesse.MIN !== 0 &&
      vehsVitesse.MAX !== 0 &&
      vehsVitesse.STEP !== 0
    ) {
      vitessMapping[0] = vehsVitesse.MIN;
      for (var i = 0; vitessMapping[i] < vehsVitesse.MAX; i++) {
        vitessMapping[i + 1] = vitessMapping[i] + vehsVitesse.STEP;
      }
    } else if (this.vehicule["LEV_DEFAULT_VALUES"]["MAP"]) {
      vitessMapping = this.vehicule["LEV_DEFAULT_VALUES"]["MAP"];
    }

    if (vitessMapping) {
      this.map.set("vitesse", vitessMapping);
      this.indexVitessev = this.map.get("vitesse").indexOf(this.vitesse.value);
    }
    if (vehsVitesse.MAP !== null && "undefined" !== typeof vehsVitesse.MAP) {
      this.map.set("vitesse", vehsVitesse.MAP);
      this.indexVitessev = this.map.get("vitesse").indexOf(this.vitesse.value);
    }
  }

  // if pro vehicle id is selected without giving isPro flag
  // in scrip or it is equals to false then change isPro to true
  isProVehicleId(vehicleid, vehicles) {
    if (vehicleid) {
      vehicles.forEach((vehicle) => {
        if (
          vehicle.LEV_AS_LCDV === vehicleid &&
          vehicle.LEV_AS_Pro === "true"
        ) {
          this.ispro = "true";
        }
      });
    }
  }

  /**
   * if coupled vehicle id is selected
   * @param vehicleid
   */
  isCoupledVehicleId(vehicleid) {
    this.vehsCouples = [];
    this.isCoupledLcdv = false;
    this.coupledData.forEach((couple) => {
      if (vehicleid === couple.LEV_COUPLE_AS_LCDV) {
        this.isCoupledLcdv = true;
        this.coupleLabel = couple.LEV_COUPLE_AS_LABEL;
        this.vehsCouples = couple.vehs;
        this.isBattey_xl = couple.as_4x4;
        this.isBattey_l = couple.as_4x2;
      }
    });
  }

  /**
   * load coupled Data
   * @param marque
   * @param country
   * @param langue
   *
   */
  loadCoupledData() {
    let languageCode = this.langage.toLocaleLowerCase();
    let countryCode = this.country.toLocaleLowerCase();
    const marqueCode = this.brand.toLocaleLowerCase();
    this.coupledData = [];
    var nameFile =
      "coupled_levdata/coupled_data_as/coupled_data_as_" +
      marqueCode +
      "/levdata_coupled_" +
      countryCode +
      "_" +
      languageCode;
    this.levservice.getData(nameFile).subscribe(
      (data) => {
        if ("undefined" !== typeof data["LEV_COUPLES"]) {
          this.coupledData = data["LEV_COUPLES"];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // function to filter professionel vehicles
  getLevDataByPro(proData, pro) {
    if (pro === "false") {
      proData = proData.filter((ele) => {
        if (ele.LEV_AS_BRAND === this.brand.toLowerCase())
          return ele.LEV_AS_Pro !== "true";
      });
    }
    return proData;
  }

  //enable/disable clim
  togglePompe(check): void {
    this.pompe_chaleur = check;
    this.calculateAutonomy(this.pompe_chaleur);
    this.calculateConsumption(this.pompe_chaleur);
    this.pompe_chaleur == true
      ? (this.eventLabel = "oui")
      : (this.eventLabel = "non");
    this.asTag(this.eventActionSwitch, this.eventLabel);
  }

  toggle(checked): void {
    this.air_conditioner = +checked;
    if (this.air_conditioner == 0 && this.pompe_chaleur !== undefined) {
      this.pompe_chaleur = false;
    }
    this.calculateAutonomy(this.pompe_chaleur);
    this.calculateConsumption(this.pompe_chaleur);
    this.air_conditioner == 1
      ? (this.eventLabel = "actif")
      : (this.eventLabel = "inactif");
    this.asTag(this.eventActionSwitch, this.eventLabel);
  }

  // select driving style
  selectConduct(conduct): void {
    this.driving_style = conduct;
    this.opened = !this.opened;
    this.calculateAutonomy();
    this.calculateConsumption();
    if (this.getTypeOfVehicle() === "VP" || this.getTypeOfVehicle() === "VPD") {
      this.bindDrivingStyle();
      this.asTag(this.eventActionConduite, this.eventLabel);
    } else if (this.getTypeOfVehicle() === "VU") {
      this.bindTemperatureExterieureStyle();
      this.asTag(this.eventActionTemperatureExterieure, this.eventLabel);
    } else if (this.getTypeOfVehicle() === "VS") {
      this.bindMassStyle();
      this.asTag(this.eventActionMasse, this.eventLabel);
    } else if (this.getTypeOfVehicle() === "VFOU") {
      this.bindDrivingStyle(); //VFOU comment
      this.asTag(this.eventActionConduite, this.eventLabel);
    }
  }

  // increase temp or speed for BEV
  increase(key: string) {
    var speedOrPayloadMap = [],
      newValue: number;
    if ((key == "vitesse" || key == "payload") && this.map.get(key)) {
      speedOrPayloadMap = this.map.get(key);
      newValue =
        speedOrPayloadMap[speedOrPayloadMap.indexOf(this[key].value) + 1];
    } else newValue = this[key].value + this[key].step;
    switch (true) {
      case newValue >= this[key].min && newValue <= this[key].max:
        this[key].value = newValue;
        break;
      case newValue > this[key].max:
        //this[key].value = this[key].max;
        return;
    }
    this.calculateAutonomy();
    this.eventLabel = "More";
    this.googleTags(newValue);
  }

  // decrease temp or speed for BEV
  decrease(key: string) {
    var speedOrPayloadMap = [],
      newValue: number;
    if ((key == "vitesse" || key == "payload") && this.map.get(key)) {
      speedOrPayloadMap = this.map.get(key);
      newValue =
        speedOrPayloadMap[speedOrPayloadMap.indexOf(this[key].value) - 1];
    } else newValue = this[key].value - this[key].step;
    switch (true) {
      case newValue >= this[key].min && newValue <= this[key].max:
        this[key].value = newValue;
        break;
      case newValue < this[key].min:
        //this[key].value = this[key].min;
        return;
    }
    this.calculateAutonomy();
    this.eventLabel = "Less";
    this.googleTags(newValue);
  }

  googleTags(newValue) {
    if (newValue === this.vitesse.value)
      this.asTagSpeedTempMasseDistance(
        this.eventActionSpeed,
        this.eventLabel,
        newValue
      );
    else if (newValue === this.masse.value)
      this.asTagSpeedTempMasseDistance(
        this.eventActionMasse,
        this.eventLabel,
        newValue
      );
    else if (newValue === this.temperature.value)
      this.asTagSpeedTempMasseDistance(
        this.eventActionTemperature,
        this.eventLabel,
        newValue
      );
  }

  googleTagsPHEV(label) {
    if (label == "vitesse")
      this.asTagSpeedTempMasseDistance(
        this.eventActionSpeed,
        this.eventLabel,
        this.vitesse.value
      );
    else if (label == "masse")
      this.asTagSpeedTempMasseDistance(
        this.eventActionMasse,
        this.eventLabel,
        this.masse.value
      );
    else if (label == "temperature")
      this.asTagSpeedTempMasseDistance(
        this.eventActionTemperature,
        this.eventLabel,
        this.temperature.value
      );
    else if (label == "distance")
      this.asTagSpeedTempMasseDistance(
        this.eventActionTemperature,
        this.eventLabel,
        this.distance.value
      );
  }

  // increase temp or speed for PHEV
  increasev(label: string) {
    this.eventLabel = "More";
    if (label == "vitesse") {
      if (
        this.indexVitessev >= 0 &&
        this.indexVitessev < this.map.get(label).length - 1
      ) {
        this.indexVitessev++;
        this.vitesse.value = this.map.get(label)[this.indexVitessev];
        this.asTagSpeedTempMasseDistance(
          this.eventActionSpeed,
          this.eventLabel,
          this.vitesse.value
        );
      }
    } else if (label == "payload") {
      if (
        this.indexpayloadv >= 0 &&
        this.indexpayloadv < this.map.get(label).length - 1
      ) {
        this.indexpayloadv++;
        this.payload.value = this.map.get(label)[this.indexpayloadv];
      }
    } else if (label == "masse") {
      if (
        this.indexmassev >= 0 &&
        this.indexmassev < this.map.get(label).length - 1
      ) {
        this.indexmassev++;
        this.masse.value = this.map.get(label)[this.indexmassev];
      }
    } else if (label == "temperature") {
      if (
        this.indextemperaturev >= 0 &&
        this.indextemperaturev < this.map.get(label).length - 1
      ) {
        this.indextemperaturev++;
        this.temperature.value = this.map.get(label)[this.indextemperaturev];
      }
    } else if (label == "distance") {
      if (
        this.indexDistancev >= 0 &&
        this.indexDistancev < this.map.get(label).length - 1
      ) {
        this.indexDistancev++;
        this.distance.value = this.map.get(label)[this.indexDistancev];
      }
    }
    this.googleTagsPHEV(label);
    this.calculateAutonomy();
    this.calculateConsumption();
  }

  // decrease temp or speed for PHEV
  decreasev(label: string) {
    this.eventLabel = "Less";
    if (label == "vitesse") {
      if (
        this.indexVitessev > 0 &&
        this.indexVitessev < this.map.get(label).length
      ) {
        this.indexVitessev--;
        this.vitesse.value = this.map.get(label)[this.indexVitessev];
      }
    } else if (label == "payload") {
      if (
        this.indexpayloadv > 0 &&
        this.indexpayloadv < this.map.get(label).length
      ) {
        this.indexpayloadv--;
        this.payload.value = this.map.get(label)[this.indexpayloadv];
      }
    } else if (label == "masse") {
      if (
        this.indexmassev > 0 &&
        this.indexmassev < this.map.get(label).length
      ) {
        this.indexmassev--;
        this.masse.value = this.map.get(label)[this.indexmassev];
      }
    } else if (label == "temperature") {
      if (
        this.indextemperaturev > 0 &&
        this.indextemperaturev < this.map.get(label).length
      ) {
        this.indextemperaturev--;
        this.temperature.value = this.map.get(label)[this.indextemperaturev];
      }
    } else if (label == "distance") {
      if (
        this.indexDistancev > 0 &&
        this.indexDistancev < this.map.get(label).length
      ) {
        this.indexDistancev--;
        this.distance.value = this.map.get(label)[this.indexDistancev];
      }
    }
    this.googleTagsPHEV(label);
    this.calculateAutonomy();
    this.calculateConsumption();
  }

  getResult() {
    let srcFile = "autonomiesData/adata-" + this.brand.toLowerCase();
    if (
      ["uk", "gb"].indexOf(this.country.toLowerCase()) > -1 &&
      this.brand.toLowerCase() !== "vx"
    )
      srcFile += "-uk";
    let VPDCondition =
      this.getTypeOfVehicle() === "VP" || this.getTypeOfVehicle() === "VPD";
    let nameFile = VPDCondition
      ? srcFile
      : srcFile + "-" + this.getTypeOfVehicle().toLowerCase();
    console.log("nameFile", nameFile, "sr file", srcFile);
    try {
      this.levservice
        .getDataWithPromise(nameFile)
        .then(async (data) => {
          this.result = await data;
          let values0 = this.result.autonomies[0].values[0];
          if (this.getTypeOfVehicle().toLowerCase() === "vfou")
            values0 = this.result.autonomies[0].values[0][0]["false"];

          if (this.conducts.length < 1) {
            Object.keys(values0).map(
              function (conductKey) {
                this.conducts.push({
                  key: conductKey,
                  value: this.labelCulture[conductKey],
                });
              }.bind(this)
            );
          }
          this.calculateAutonomy();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  
  getResultMultipleModels() {
    let srcFile = "autonomiesData/adata-" + this.brand.toLowerCase();
    if (["uk", "gb"].indexOf(this.country.toLowerCase()) > -1) srcFile += "-uk";
    let VPDCondition =
      this.getTypeOfVehicle() === "VP" || this.getTypeOfVehicle() === "VPD";
    let nameFile = VPDCondition
      ? srcFile
      : srcFile + "-" + this.getTypeOfVehicle().toLowerCase();
    try {
      this.levservice
        .getDataWithPromise(nameFile)
        .then(async (data) => {
          this.result = await data;
          let values0 = this.result.autonomies[0].values[0];
          if (this.getTypeOfVehicle().toLowerCase() === "vfou")
            values0 = this.result.autonomies[0].values[0][0]["false"];
          if (this.conducts.length < 1) {
            Object.keys(values0).map(
              function (conductKey) {
                this.conducts.push({
                  key: conductKey,
                  value: this.labelCulture[conductKey],
                });
              }.bind(this)
            );
          }
          this.calculateAutonomy();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  getResultConsumption() {
    let srcFile = "consoData/consoData_" + this.brand.toLowerCase();
    if (["uk", "gb"].indexOf(this.country.toLowerCase()) > -1) {
      srcFile += "_uk";
    }
    try {
      this.levservice
        .getDataWithPromise(srcFile)
        .then(async (data) => {
          this.resultConsumption = await data;
          const undefinedConsumption: boolean =
            undefined !== this.resultConsumption.consumption;
          if (this.conducts.length < 1 && undefinedConsumption) {
            Object.keys(this.resultConsumption.consumption[0].values[0]).map(
              function (conductKey) {
                this.conducts.push({
                  key: conductKey,
                  value: this.labelCulture[conductKey],
                });
              }.bind(this)
            );
          }
          this.calculateConsumption();
          this.checkHasConso(this.resultConsumption.consumption);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  checkHasConso(data) {
    if (data)
      data.forEach((element) => {
        let vehicles = element.vehs;
        if (
          vehicles.includes(this.vehsCouples[0], this.vehsCouples[1]) ||
          vehicles.includes(this.vehicleid)
        )
          this.asHasConso = true;
      });
  }
  calculateAutonomy(check_pompe_chaleur?: boolean) {
    if (!this.result) return;

    this.calculatedAutonomyTable = [];
    const resultAutonomies = this.result.autonomies;
    const isVu = this.getTypeOfVehicle() === "VU";
    const isVPOrVS =
      this.getTypeOfVehicle() === "VS" || this.getTypeOfVehicle() === "VP";

    check_pompe_chaleur = this.pompe_chaleur;

    resultAutonomies.forEach((autonomy) => {
      if (this.isCoupledLcdv || autonomy.vehs.includes(this.vehicleid)) {
        this.calculateSingleAutonomy(
          autonomy,
          isVu,
          isVPOrVS,
          check_pompe_chaleur
        );
      }
    });
  }

  private calculateSingleAutonomy(
    autonomy: any,
    isVu: boolean,
    isVPOrVS: boolean,
    check_pompe_chaleur: boolean
  ) {
    const valueWithAirConditionner =
      autonomy.values[this.air_conditioner.toString()];
    const autonomyByAirConditionerAndDrivingStyle =
      this.getAutonomyByDrivingStyle(
        valueWithAirConditionner,
        check_pompe_chaleur
      );

    this.simulateAutonomy(
      autonomyByAirConditionerAndDrivingStyle,
      isVu,
      isVPOrVS
    );
  }

  private getAutonomyByDrivingStyle(
    valueWithAirConditionner: any,
    check_pompe_chaleur: boolean
  ) {
    console.log("dsfdsfsdf", this.display_payload, check_pompe_chaleur);
    if (this.display_payload && check_pompe_chaleur !== undefined) {
      console.log("First case where we have payload with Pompe");
      return valueWithAirConditionner[this.payload.value][
        check_pompe_chaleur ? "true" : "false"
      ][this.driving_style.toLowerCase()];
    } else if (!this.display_payload && check_pompe_chaleur !== undefined) {
      console.log(
        "Second case where Payload doesn't exist but we have Pompe",
        check_pompe_chaleur
      );
      return valueWithAirConditionner[check_pompe_chaleur ? "true" : "false"][
        this.driving_style.toLowerCase()
      ];
    }
    if (this.display_payload === false || this.display_payload === undefined) {
      console.log("sdfsdfsdfsdfsdfsd", this.payload.value);
      return valueWithAirConditionner[this.driving_style.toLowerCase()];
    }
    console.log(
      "here",
      valueWithAirConditionner[this.payload.value][
        this.driving_style.toLowerCase()
      ]
    );
    return valueWithAirConditionner[this.payload.value][
      this.driving_style.toLowerCase()
    ];
  }

  simulateCoupledAutonomy(
    autonomyByAirConditionerAndDrivingStyle,
    nextAutonomyByAirConditionerAndDrivingStyle = null,
    isVu = false,
    isVPOrVS = false
  ) {
    if (isVu)
      this.calculatedAutonomyTable = [
        autonomyByAirConditionerAndDrivingStyle[this.masse.value][
          this.vitesse.value
        ],
        nextAutonomyByAirConditionerAndDrivingStyle[this.masse.value][
          this.vitesse.value
        ],
      ];
    else if (isVPOrVS || this.getTypeOfVehicle() === "VPD")
      this.calculatedAutonomyTable = [
        autonomyByAirConditionerAndDrivingStyle[this.temperature.value][
          this.vitesse.value
        ],
        nextAutonomyByAirConditionerAndDrivingStyle[this.temperature.value][
          this.vitesse.value
        ],
      ];
  }

  simulateAutonomy(
    autonomyByAirConditionerAndDrivingStyle,
    isVu = false,
    isVPOrVS = false
  ) {
    if (isVu)
      this.calculatedAutonomy =
        autonomyByAirConditionerAndDrivingStyle[this.masse.value][
          this.vitesse.value
        ];
    else if (isVPOrVS)
      this.calculatedAutonomy =
        autonomyByAirConditionerAndDrivingStyle[this.temperature.value][
          this.vitesse.value
        ];
    else if (this.getTypeOfVehicle().toLowerCase() === "vfou")
      console.log(
        "this.temperature.value",
        this.temperature.value,
        this.vitesse.value
      );
    this.calculatedAutonomy =
      autonomyByAirConditionerAndDrivingStyle[this.temperature.value][
        this.vitesse.value
      ];
  }
  calculateConsumption(check_pompe_chaleur?) {
    if (this.resultConsumption) {
      this.calculatedConsumptionTable = [];
      let resultConsumptions = this.resultConsumption.consumption,
        isVu = this.getTypeOfVehicle() === "VU",
        isVPOrVS =
          this.getTypeOfVehicle() === "VS" || this.getTypeOfVehicle() === "VP";
      if (resultConsumptions) {
        for (let i = 0; i < resultConsumptions.length; i++) {
          if (!this.isCoupledLcdv) {
            if (resultConsumptions[i].vehs.indexOf(this.vehicleid) > -1) {
              if (typeof this.pompe_chaleur === "boolean") {
                let consumptionByAirConditionerAndDrivingStyle =
                  resultConsumptions[i].values[this.air_conditioner.toString()][
                    check_pompe_chaleur ? "true" : "false"
                  ][this.driving_style.toLowerCase()];
                this.simulateAutonomy(
                  consumptionByAirConditionerAndDrivingStyle,
                  isVu,
                  isVPOrVS
                );
              } else {
                {
                  let consumptionByAirConditionerAndDrivingStyle =
                    resultConsumptions[i].values[
                      this.air_conditioner.toString()
                    ][this.driving_style.toLowerCase()];
                  this.simulateAutonomy(
                    consumptionByAirConditionerAndDrivingStyle,
                    isVu,
                    isVPOrVS
                  );
                }
              }
              // let consumptionByAirConditionerAndDrivingStyle =
              //   resultConsumptions[i].values[this.air_conditioner][
              //     this.driving_style.toLowerCase()
              //   ];
              // this.simulateConsumption(
              //   consumptionByAirConditionerAndDrivingStyle,
              //   isVu,
              //   isVPOrVS
              // );
            }
          } else {
            if (
              resultConsumptions[i].vehs.includes(
                this.vehsCouples[0],
                this.vehsCouples[1]
              )
            ) {
              let consumptionByAirConditionerAndDrivingStyle =
                  resultConsumptions[i].values[this.air_conditioner][
                    this.driving_style.toLowerCase()
                  ],
                nextConsumptionByAirConditionerAndDrivingStyle =
                  resultConsumptions[i + 1].values[this.air_conditioner][
                    this.driving_style.toLowerCase()
                  ];
              this.simulateCoupledConsumption(
                consumptionByAirConditionerAndDrivingStyle,
                nextConsumptionByAirConditionerAndDrivingStyle,
                isVu,
                isVPOrVS
              );
            }
          }
        }
      }
    }
  }

  simulateCoupledConsumption(
    consumptionByAirConditionerAndDrivingStyle,
    nextConsumptionByAirConditionerAndDrivingStyle = null,
    isVu = false,
    isVPOrVS = false
  ) {
    if (isVu)
      this.calculatedConsumptionTable = [
        consumptionByAirConditionerAndDrivingStyle[this.masse.value][
          this.distance.value
        ],
        nextConsumptionByAirConditionerAndDrivingStyle[this.masse.value][
          this.distance.value
        ],
      ];
    else if (isVPOrVS || this.getTypeOfVehicle() === "VPD")
      this.calculatedConsumptionTable = [
        consumptionByAirConditionerAndDrivingStyle[this.temperature.value][
          this.distance.value
        ],
        nextConsumptionByAirConditionerAndDrivingStyle[this.temperature.value][
          this.distance.value
        ],
      ];
  }
  simulateConsumption(
    consumptionByAirConditionerAndDrivingStyle,
    isVu = false,
    isVPOrVS = false
  ) {
    if (isVu)
      consumptionByAirConditionerAndDrivingStyle[this.masse.value][
        this.distance.value
      ];
    else if (isVPOrVS)
      consumptionByAirConditionerAndDrivingStyle[this.temperature.value][
        this.distance.value
      ];
  }

  //tag without eventValue
  asTag(eventAction, eventLabel) {
    window["dataLayer"] = window["dataLayer"] || [];
    window["dataLayer"].push({
      event: "uaevent",
      eventCategory: "Widget",
      eventAction: eventAction,
      eventLabel: eventLabel,
    });
  }

  //tag with eventValue
  asTagSpeedTempMasseDistance(
    eventAction: string,
    eventLabel: string,
    eventValue: number
  ) {
    window["dataLayer"] = window["dataLayer"] || [];
    window["dataLayer"].push({
      event: "uaevent",
      eventCategory: "Widget",
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue,
    } as unknown as DataLayerEvent);
  }

  bindDrivingStyle() {
    if (this.driving_style == "as_normal") this.eventLabel = "Normale";
    else if (this.driving_style == "as_calm") this.eventLabel = "Calme";
    else this.eventLabel = "Dynamique";
  }

  /**
   * tag plan for VU Exterior temperature
   */
  bindTemperatureExterieureStyle() {
    if (this.driving_style == "as_tempere") this.eventLabel = "Tempéré";
    else if (this.driving_style == "as_froid") this.eventLabel = "Froid";
    else if (this.driving_style == "as_chaud") this.eventLabel = "Chaud";
  }

  /**
   * tag plan for VU Exterior temperature
   */
  bindMassStyle() {
    if (this.driving_style == "as_normal") this.eventLabel = "A vide";
    else if (this.driving_style == "as_calm") this.eventLabel = "Demi-charge";
    else this.eventLabel = "Pleine charge";
  }
}
