import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { API_CONFIG } from "src/assets/plugSurfing_params/api_url_config";

@Injectable({
  providedIn: "root",
})
export class Free2moveService {
  constructor(private http: HttpClient) {}

  getData(
    nameFile: string = "levdata/levdata-uc/levdata_fr_fr"
  ): Observable<any> {
    return this.http.get<any>(API_CONFIG.server_url + nameFile + ".json");
  }
}
