<div
  class="check-box-slider"
  [class.FT]="cs_fiat"
  [class.AR]="cs_alfaRomeo"
  [class.JE]="cs_jeep"
  [class.AP]="cs_peugeot"
  [style.display]="shouldHideComponent() ? 'none' : 'block'"
>
  <ngx-slider
    [(value)]="value"
    [options]="sliderOptions"
    (valueChange)="onSliderChange($event)"
  ></ngx-slider>
</div>
