import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Incentives } from "../interfaces/incentives.interface";
import { LevServiceService } from "../../../../../src/app/services";
import { tagModifyZipCode, tagReturn } from "../utility/gtm.utility";
import { TranslationsService } from "../../../../../src/app/services/translations.service";

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss"],
})
export class ResultsComponent implements OnInit {
  @Input("brand") brand: string = "";
  @Input("langage") langage: string = "";
  @Input("country") country: string = "";
  @Input("zipCode") zipCode: string = "";
  @Input() eco_price_purchasing_assistance_with_regional_return_label: string =
    "";
  @Input() eco_price_conversion_bonus_national_label: string = "";
  @Input() eco_preferential_parking_rate_label: string = "";
  @Input() eco_gray_card_certificate_of_registration_label: string = "";
  @Input() eco_help_with_the_installation_of_a_charging_station_label: string =
    "";
  @Input() eco_more_link_label: string = "";
  @Input() error_msg_no_incentives_for_actual_zip_code: string = "";
  @Input() eco_avantage_fiscaux_label: string = "";
  @Input()
  eco_contibution_nationale_aux_infrastructures_de_charge_label: string = "";
  @Input() eco_cite_credit_d_impot_transition_d_energetique_label: string = "";
  @Input() use_other_zip_code: string = "";
  @Input("index") index!: number;
  @Input("total") total: number = 0;
  @Input("results") results: any[] = [];
  @Input("resultsConfirmation") resultsConfirmation: string = "";
  @Input("isMobileResults") isMobileResults: boolean = false;
  @Output("onBackToStepOne") onBackToStepOne: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output("onBackToStepOneFromError")
  onBackToStepOneFromError: EventEmitter<boolean> = new EventEmitter<boolean>();

  eco_advisor_back_label: string = "";
  eco_advisor_list_postal_code_label: string = "";
  eco_advisor_list_postal_code_modify: string = "";
  eco_advisor_list_economy_label: string = "";
  eco_advisor_list_economy_value: string = "";
  eco_advisor_list_economy_currency: string = "";
  [key: string]: any;

  constructor(
    private levService: LevServiceService,
    private translationsService: TranslationsService
  ) {}

  ngOnInit() {
    this.getECOTranslationsByCulture();
  }

  getECOTranslationsByCulture() {
    try {
      this.translationsService
        .getTranslationsByWidgetAndBrandAndCulture(
          "ECO",
          this.brand.toUpperCase(),
          this.country.toUpperCase(),
          this.langage.toLowerCase()
        )
        .then((data) => {
          for (const [key, translation] of Object.entries(data.values)) {
            this[key] = translation;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  backToStepOne(event: any, label?: string): void {
    if (label === "return") tagReturn();
    else tagModifyZipCode();
    this.onBackToStepOne.emit(true);
  }
  backToStepOneFromError(event: any, label?: string): void {
    this.onBackToStepOneFromError.emit(true);
  }
  calculateTotalIncentives() {
    this.results.forEach((incentive, index) => {
      if (index !== 0 && !isNaN(incentive["value"]))
        this.total += incentive["value"];
    });
    return this.total;
  }
}
