<div class="main-body" *ngIf="isLabelsLoaded">
  <div class="main-text">
    <h1
      class="first_title"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    >
      {{ labelCulture.first_title }}
    </h1>
    <p
      class="second_title"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    >
      {{ labelCulture.second_title }}
    </p>
  </div>

  <div class="second-header" *ngIf="activeIndex !== 3">
    <p
      class="second-header-text"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    >
      {{ labelCulture.select_title }}
    </p>

    <div
      class="chosing-div"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    >
      <app-brand-selector
        [brands]="brands"
        [selectedBrand]="selectedBrand"
        [labelCulture]="labelCulture"
        [readOnly]="brandExists"
        [tabType]="currentWidgetValue"
        (brandSelected)="onBrandChange($event)"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      ></app-brand-selector>

      <app-vehicle-selector
        [vehicles]="vehicleIds"
        [obcs]="OBCs"
        [selectedVehicleId]="selectedCar"
        [selectedOBC]="selectedOBC"
        [labelCulture]="labelCulture"
        [tabType]="currentWidgetValue"
        (vehicleSelected)="
          onVehiculeChange($event.vehicleId); onOBCChange($event.obcValue)
        "
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      ></app-vehicle-selector>
    </div>
  </div>

  <app-tab-container
    [labelCulture]="labelCulture"
    [canRenderChargingSimulator]="canRenderChargingSimulator"
    [canRenderRangeCalculator]="canRenderRangeCalculator"
    [canRenderFuelSaving]="canRenderFuelSaving"
    [canRenderHelpMeChoose]="canRenderHelpMeChoose"
    [activeIndex]="currentTabState.index"
    [brand]="brand"
    (tabChanged)="onTabChange($event)"
  >
    <!-- Charging Simulator Tab Content -->
    <div tabContent="charging-simulator">
      <span
        class="text"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        {{ labelCulture.charging_time_inside_text }}
      </span>

      <ng-container *ngIf="canRenderComponent">
        <app-charging-time-f2mc
          [vehicleid]="selectedCar"
          [brand]="brand"
          [selectedBrand]="selectedBrand"
          [obc]="selectedOBC"
          [displaycost]="displaycost"
          [country]="country"
          [language]="language"
          [labelCulture]="labelCulture"
        ></app-charging-time-f2mc>
      </ng-container>

      <ng-container *ngIf="!canRenderComponent">
        <div
          class="cannotRender"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <svg
            fill="#17234d"
            height="50px"
            width="200px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 330 330"
            xml:space="preserve"
            style="display: block; margin: auto"
          >
            <path
              id="XMLID_224_"
              d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
              l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
              C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
            />
          </svg>
          <h3 style="text-align: center; display: block; font-size: large">
            {{ labelCulture.please_select_a_brand }}
          </h3>
        </div>
      </ng-container>
    </div>

    <!-- Fuel Saving Tab Content -->
    <div tabContent="fuel-saving">
      <span
        class="text"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        {{ labelCulture.fuel_saving_inside_text }}
      </span>

      <ng-container *ngIf="canRenderComponent">
        <app-fuel-saving
          [vehicleid]="selectedCar"
          [brand]="brand"
          [selectedBrand]="selectedBrand"
          [country]="country"
          [language]="language"
          [labelCulture]="labelCulture"
        ></app-fuel-saving>
      </ng-container>

      <ng-container *ngIf="!canRenderComponent">
        <div class="cannotRender">
          <svg
            fill="#17234d"
            height="50px"
            width="200px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 330 330"
            xml:space="preserve"
            style="display: block; margin: auto"
          >
            <path
              id="XMLID_224_"
              d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
              l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
              C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
            />
          </svg>
          <h3
            style="
              text-align: center;
              display: block;
              font-size: large;
              color: #17234d;
            "
          >
            {{ labelCulture.please_select_a_brand }}
          </h3>
        </div>
      </ng-container>
    </div>

    <!-- Range Calculator Tab Content -->
    <div tabContent="range-calculator">
      <span
        class="text"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        {{ labelCulture.range_inside_text }}
      </span>

      <ng-container *ngIf="canRenderComponent">
        <app-range-calculator
          [vehicleid]="selectedCar"
          [brand]="brand"
          [selectedBrand]="selectedBrand"
          [country]="country"
          [language]="language"
          [labelCulture]="labelCulture"
        ></app-range-calculator>
      </ng-container>

      <ng-container *ngIf="!canRenderComponent">
        <div class="cannotRender">
          <svg
            fill="#17234d"
            height="50px"
            width="200px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 330 330"
            xml:space="preserve"
            style="display: block; margin: auto"
          >
            <path
              id="XMLID_224_"
              d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
              l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
              C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
            />
          </svg>
          <h3
            style="
              text-align: center;
              display: block;
              font-size: large;
              color: #17234d;
            "
          >
            {{ labelCulture.please_select_a_brand }}
          </h3>
        </div>
      </ng-container>
    </div>

    <!-- Help Me Choose Tab Content -->
    <div tabContent="help-me-choose">
      <app-help-me-choose
        [brand]="brand"
        [country]="country"
        [language]="language"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      ></app-help-me-choose>
    </div>
  </app-tab-container>
</div>

<!-- Loading indicator while labels are loading -->
<div *ngIf="!isLabelsLoaded" class="loader-parent">
  <div
    class="loader"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
  ></div>
</div>
