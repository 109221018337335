import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  Data,
  CssData,
  SearcheCriteria,
  Vehicule,
} from "../../../../../src/app/models";
import { API_CONFIG } from "../../../../../src/assets/plugSurfing_params/api_url_config";
import { LevServiceService } from "../../../../../src/app/services";
import {
  tagEnergiticEfficiency,
  tagEnergiticElectricCost,
  tagFuelCost,
  tagInWeekEndUse,
  tagInWeekUse,
  tagWeekdaySwitcher,
  tagWeekendSwitcher,
} from "../utility/gtm.utility";

@Component({
  selector: "app-saving-calculator",
  templateUrl: "./saving-calculator.component.html",
  styleUrls: ["./saving-calculator.component.scss"],
})
export class SavingCalculatorComponent implements OnInit {
  @Input("country") country: string;

  @Input("langage") langage: string;

  @Input("brand") brand: string;

  @Input("vehicleid") vehicleid: string;

  SearcheCriteria: SearcheCriteria = new SearcheCriteria();
  cssData: CssData;
  vehs: any[any];
  params;
  vehicle;
  brandClass: string;
  countryClass: string;
  lev_sav_your_vehicle: string;
  lev_sav_deplacement_section: string;
  lev_sav_couts_vehicule_section: string;
  lev_sav_results_section: string;
  lev_sav_results_text: string;
  lev_sav_semaine: string;
  lev_sav_annuel: string;
  lev_sav_weekend: string;
  lev_sav_results_annee: string;
  lev_sav_no_economies: string;
  lev_sav_results_mois: string;
  lev_sav_results_month: string;
  sav_second_section_title: string;
  sav_second_description_mobile: string;
  icon_img;
  lev_sav_results_distance_annee: string;
  lev_sav_results_distance_month: string;
  lev_sav_weekday_slider_unit: string;
  lev_sav_weekday_slider_title: string;
  lev_sav_annual_slider_title: string;
  lev_sav_weekend_slider_unit: string;
  lev_sav_weekend_slider_title: string;
  lev_sav_weekday_switcher_title: string;
  lev_sav_weekend_switcher_title: string;
  lev_sav_weekday_number_of_daily_charge_slider_title: string;
  lev_sav_weekend_number_of_daily_charge_slider_title: string;
  lev_sav_slider_fuel_cost_title: string;
  lev_sav_slider_fuel_cost_unit: string;
  lev_sav_slider_fuel_efficiency_title: string;
  lev_sav_slider_fuel_efficiency_unit: string;
  lev_sav_slider_electricity_cost_title: string;
  lev_sav_slider_electricity_cost_unit: string;
  lev_sav_weekday_switcher_active: string;
  lev_sav_weekday_switcher_inactive: string;
  lev_sav_weekend_switcher_active: string;
  lev_sav_weekend_switcher_inactive: string;
  lev_sav_currency_big_value: string;
  lev_sav_legal_mentions: string;
  vehicleLabel: string;
  innerWidth: any = 0;
  weekDayCost: number;
  weekEndCost: number;
  kwByWeek: number;
  bevCostByYear: number;
  iceVehicleCostWeekDay: number;
  iceVehicleCostWeekEnd: number;
  iceVehicleCostByYear: number;
  savingByYear: number;
  saving4ByYear: number;
  chargesByWeek: number;
  // annualMileage:number;
  isUrbanOrMixedWeekDay: boolean;
  isUrbanOrMixedWeekEnd: boolean;
  weekDayNumberOfCharge: number;
  weekEndNumberOfCharge: number;
  PHEVCostByYear: number;
  savingByMonth: number;
  monthMileage: number;
  electricDriveWeekDay: number;
  electricDriveWeekEnd: number;
  electricDrivePercent: number;
  electricityCost: number;
  electricityCostMin: number;
  electricityCostMax: number;
  electricityCostStep: number;
  fuelCost: number;
  fuelCostMin: number;
  fuelCostMax: number;
  fuelCostStep: number;
  fuelEfficiency: number;
  fuelEfficiencyMin: number;
  fuelEfficiencyMax: number;
  fuelEfficiencyStep: number;
  weekDayMileage: number;
  weekDayMileageMin: number;
  weekDayMileageMax: number;
  weekDayMileageStep: number;
  weekEndMileage: number;
  weekEndMileageMin: number;
  weekEndMileageMax: number;
  weekEndMileageStep: number;
  weekDayNumberDailyCharge: number;
  weekDayNumberDailyChargeMin: number;
  weekDayNumberDailyChargeMax: number;
  weekDayNumberDailyChargeStep: number;
  weekEndNumberDailyCharge: number;
  weekEndNumberDailyChargeMin: number;
  weekEndNumberDailyChargeMax: number;
  weekEndNumberDailyChargeStep: number;
  isDown: boolean = false;
  isPHEV: boolean = false;

  //Params for optimized calculation
  // For BEV vehicles
  wltpRange: number = 400;
  annualMileage: number;
  annualMileageMin: number;
  annualMileageMax: number;
  annualMileageStep: number;
  choosenVehicle: any;
  selected = "option0";
  rotate: boolean = false;
  isFocus: boolean = false;
  isMultipleVehicleDisplay: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  constructor(private levservice: LevServiceService, private data: Data) {
    this.cssData = new CssData();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.survey();
    if (
      this.brand != undefined &&
      this.country != undefined &&
      this.langage != undefined
    ) {
      this.brandClass = this.brand.toLowerCase();
      this.countryClass = this.country.toLowerCase();
      this.rotate = false;
      if (this.brandClass === "vx") {
        this.brandClass = "ov";
        this.countryClass = "gb"; // gb,vx
      }
      this.getSAVDataByCulture();
      this.getSAVTranslationsByCulture();
    }
  }
  rotateFunction() {
    this.rotate = !this.rotate;
    // if (this.rotate) this.mySelect.open();
  }
  focusChange() {
    this.isFocus = !this.isFocus;
    this.rotateFunction();
  }
  changeVehicle() {
    if (this.choosenVehicle) {
      this.vehicle = this.choosenVehicle;
      // this.rotate = !this.rotate;
      try {
        this.setDefaultValues()
          .then(() => {
            this.isHybride();
            this.calculatResults();
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }
  getSAVTranslationsByCulture() {
    let brand = this.brand.toLowerCase();
    let country = this.country.toLowerCase();
    let langage = this.langage.toLowerCase();
    const nameFile = "labels_sav/labels_sav_" + brand;
    try {
      this.levservice
        .getDataWithPromise(nameFile)
        .then((data) => {
          for (const [key, translation] of Object.entries(
            data[langage + "-" + country]
          )) {
            this[key] = translation;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  deviceIsMobile() {
    return this.innerWidth < 812;
  }
  //init input params
  survey() {
    if (
      this.brand == undefined &&
      this.langage == undefined &&
      this.country == undefined
    ) {
      this.SearcheCriteria = this.data.storage;
      this.initFromSearchCriteria();
    }
  }

  initFromSearchCriteria() {
    if (this.SearcheCriteria) {
      this.country = this.SearcheCriteria.countryCode;
      this.langage = this.SearcheCriteria.langageCode;
      this.brand = this.SearcheCriteria.marque;
      this.vehicleid = this.SearcheCriteria.mlcdv
        ? this.SearcheCriteria.mlcdv
        : this.SearcheCriteria.vehicleid;
    }
  }

  getSAVDataByCulture() {
    let brand = this.brand.toLowerCase();
    let country = this.country.toLowerCase();
    let langage = this.langage.toLowerCase();
    const nameFile = "levdata/levdata-sav/levdata_" + country + "_" + langage;
    try {
      this.levservice
        .getDataWithPromise(nameFile)
        .then((data) => {
          this.vehs = data["vehs"].filter(
            (veh) => veh.LEV_SAV_BRAND.toLowerCase() === brand
          );
          this.params = data["params"];
        })
        .then(() => {
          this.getVehicleByLCDV();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  filterVehsByDisplayMode(vehicleIdArray: string[]) {
    this.vehs =
      this.vehicleid &&
      vehicleIdArray.length >= 1 &&
      this.vehicleid !== "" &&
      this.vehicleid !== undefined
        ? this.vehs.filter((item) => vehicleIdArray.includes(item.LEV_SAV_LCDV))
        : this.vehs;

    //check array of vehicle id then decide
    // about display mode
    if (vehicleIdArray.length !== 1 && this.vehs.length !== 1)
      this.isMultipleVehicleDisplay = true;
  }

  getVehicleByLCDV() {
    const vehicleIdArray = this.vehicleid ? this.vehicleid.split("|") : [];
    this.filterVehsByDisplayMode(vehicleIdArray);
    this.vehicle = this.vehs[0];
    this.choosenVehicle = this.vehicle;
    if (this.vehicle && this.vehicle.type === "BEV") this.isDown = true;
    try {
      this.setDefaultValues()
        .then(() => {
          this.isHybride();
          this.calculatResults();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  filterListOfVehicles() {
    return this.vehs.filter((item) =>
      this.choosenVehicle
        ? this.choosenVehicle.LEV_SAV_LCDV !== item.LEV_SAV_LCDV
        : this.vehicle.LEV_SAV_LCDV !== item.LEV_SAV_LCDV
    );
  }

  calculatResults() {
    // if(this.vehicle.LEV_SAV_TYPE === "BEV")this.resultsBEVCalculator();
    if (this.vehicle.LEV_SAV_TYPE === "BEV") this.optimizedResultsForBEV();
    if (this.vehicle.LEV_SAV_TYPE === "PHEV") this.resultsPHEVCalculator();
  }

  isHybride() {
    this.isPHEV = this.vehicle.LEV_SAV_TYPE === "PHEV";
  }

  setDefaultValues(): Promise<object> {
    return new Promise((resolve, reject) => {
      let vehicleDefaultValuesObject = this.vehicle.LEV_SAV_DEFAULT_VALUES;
      let vehicleDefaultElectricityObject =
        vehicleDefaultValuesObject.LEV_SAV_ELECTRICITY;
      let vehicleDefaultFuelObject = vehicleDefaultValuesObject.LEV_SAV_FUEL;
      let vehicleDefaultFuelEfficiency =
        vehicleDefaultValuesObject.LEV_SAV_FUEL_EFFICIENCY;
      // let vehicleDefaultWeekDayMileage = vehicleDefaultValuesObject.LEV_SAV_WEEKDAY_MILEAGE;
      let vehicleDefaultAnnualMileage =
        vehicleDefaultValuesObject.LEV_SAV_ANNUAL_MILEAGE;
      // let vehicleDefaultWeekEndMileage = vehicleDefaultValuesObject.LEV_SAV_WEEKEND_MILEAGE;
      // let vehicleDefaultWeekDayNumberDailyCharge = vehicleDefaultValuesObject.LEV_SAV_WEEKDAY_NUMBER_DAILY_CHARGE;
      // let vehicleDefaultWeekEndNumberDailyCharge = vehicleDefaultValuesObject.LEV_SAV_WEEKEND_NUMBER_DAILY_CHARGE;

      this.vehicleLabel = this.vehicle.LEV_SAV_LABEL;
      this.fuelCost = vehicleDefaultFuelObject.LEV_SAV_DEFAULT_FUEL_COST;
      this.fuelCostMin = vehicleDefaultFuelObject.LEV_SAV_MIN;
      this.fuelCostMax = vehicleDefaultFuelObject.LEV_SAV_MAX;
      this.fuelCostStep = vehicleDefaultFuelObject.LEV_SAV_STEP;

      this.electricityCost =
        vehicleDefaultElectricityObject.LEV_SAV_DEFAULT_ELECTRICITY_COST;
      this.electricityCostMin = vehicleDefaultElectricityObject.LEV_SAV_MIN;
      this.electricityCostMax = vehicleDefaultElectricityObject.LEV_SAV_MAX;
      this.electricityCostStep = vehicleDefaultElectricityObject.LEV_SAV_STEP;

      this.fuelEfficiency =
        vehicleDefaultFuelEfficiency.LEV_SAV_DEFAULT_FUEL_EFFICIENCY;
      this.fuelEfficiencyMin = vehicleDefaultFuelEfficiency.LEV_SAV_MIN;
      this.fuelEfficiencyMax = vehicleDefaultFuelEfficiency.LEV_SAV_MAX;
      this.fuelEfficiencyStep = vehicleDefaultFuelEfficiency.LEV_SAV_STEP;

      // this.weekDayMileage = vehicleDefaultWeekDayMileage.LEV_SAV_DEFAULT_WEEKDAY_MILEAGE;
      // this.weekDayMileageMin = vehicleDefaultWeekDayMileage.LEV_SAV_MIN;
      // this.weekDayMileageMax = vehicleDefaultWeekDayMileage.LEV_SAV_MAX;
      // this.weekDayMileageStep = vehicleDefaultWeekDayMileage.LEV_SAV_STEP;

      this.annualMileage =
        vehicleDefaultAnnualMileage.LEV_SAV_DEFAULT_ANNUAL_MILEAGE;
      this.annualMileageMin = vehicleDefaultAnnualMileage.LEV_SAV_MIN;
      this.annualMileageMax = vehicleDefaultAnnualMileage.LEV_SAV_MAX;
      this.annualMileageStep = vehicleDefaultAnnualMileage.LEV_SAV_STEP;

      // this.weekEndMileage = vehicleDefaultWeekEndMileage.LEV_SAV_DEFAULT_WEEKEND_MILEAGE;
      // this.weekEndMileageMin = vehicleDefaultWeekEndMileage.LEV_SAV_MIN;
      // this.weekEndMileageMax = vehicleDefaultWeekEndMileage.LEV_SAV_MAX;
      // this.weekEndMileageStep = vehicleDefaultWeekEndMileage.LEV_SAV_STEP;

      // if (vehicleDefaultWeekDayNumberDailyCharge && vehicleDefaultWeekEndNumberDailyCharge){
      //   this.weekDayNumberDailyCharge = vehicleDefaultWeekDayNumberDailyCharge.LEV_SAV_DEFAULT_WEEKDAY_NUMBER_DAILY_CHARGE;
      //   this.weekDayNumberDailyChargeMin = vehicleDefaultWeekDayNumberDailyCharge.LEV_SAV_MIN;
      //   this.weekDayNumberDailyChargeMax = vehicleDefaultWeekDayNumberDailyCharge.LEV_SAV_MAX;
      //   this.weekDayNumberDailyChargeStep = vehicleDefaultWeekDayNumberDailyCharge.LEV_SAV_STEP;
      //
      //   this.weekEndNumberDailyCharge = vehicleDefaultWeekEndNumberDailyCharge.LEV_SAV_DEFAULT_WEEKEND_NUMBER_DAILY_CHARGE;
      //   this.weekEndNumberDailyChargeMin = vehicleDefaultWeekEndNumberDailyCharge.LEV_SAV_MIN;
      //   this.weekEndNumberDailyChargeMax = vehicleDefaultWeekEndNumberDailyCharge.LEV_SAV_MAX;
      //   this.weekEndNumberDailyChargeStep = vehicleDefaultWeekEndNumberDailyCharge.LEV_SAV_STEP;
      // }

      this.isUrbanOrMixedWeekDay =
        vehicleDefaultValuesObject.LEV_SAV_URBAN_MIXED_WEEKDAY_CHECK;
      this.isUrbanOrMixedWeekEnd =
        vehicleDefaultValuesObject.LEV_SAV_URBAN_MIXED_WEEKEND_CHECK;

      this.lev_sav_currency_big_value = this.params.DeviseShort;
      this.icon_img = API_CONFIG.server_url + this.params.bev_sav_icon;
      if (vehicleDefaultValuesObject)
        resolve({ response: 200, msg: "Setting default values is done!!" });
      else reject("there was an error while loading default params");
    });
  }

  toggleUrbanMixedWeekDay(isMixedWeekDay) {
    this.isUrbanOrMixedWeekDay = isMixedWeekDay;
    this.isUrbanOrMixedWeekDay
      ? tagWeekdaySwitcher("mixt")
      : tagWeekdaySwitcher("urban");
    this.calculatResults();
  }
  toggleUrbanMixedWeekEnd(isMixedWeekEnd) {
    this.isUrbanOrMixedWeekEnd = isMixedWeekEnd;
    this.isUrbanOrMixedWeekEnd
      ? tagWeekendSwitcher("mixt")
      : tagWeekendSwitcher("urban");
    this.calculatResults();
  }

  changeWeekDayMileage(weekDayMileageValue) {
    tagInWeekUse();
    this.weekDayMileage = weekDayMileageValue;
    this.calculatResults();
  }

  changeAnnualMileage(annualMileageValue) {
    // tagInWeekUse();
    this.annualMileage = annualMileageValue;
    this.calculatResults();
  }
  changeWeekEndMileage(weekEndMileageValue) {
    tagInWeekEndUse();
    this.weekEndMileage = weekEndMileageValue;
    this.calculatResults();
  }
  changeWeekDayNumberDailyCharge(weekDayNumberDailyChargeValue) {
    this.weekDayNumberDailyCharge = weekDayNumberDailyChargeValue;
    this.calculatResults();
  }
  changeWeekEndNumberDailyCharge(weekEndNumberDailyChargeValue) {
    this.weekEndNumberDailyCharge = weekEndNumberDailyChargeValue;
    this.calculatResults();
  }
  changeFuelCost(fuelCostValue) {
    tagFuelCost();
    this.fuelCost = fuelCostValue;
    this.calculatResults();
  }
  changeFuelEfficiencyCost(fuelEfficiencyCostValue) {
    tagEnergiticEfficiency();
    this.fuelEfficiency = fuelEfficiencyCostValue;
    this.calculatResults();
  }
  changeElectricityCost(electricityCostValue) {
    tagEnergiticElectricCost();
    this.electricityCost = electricityCostValue;
    this.calculatResults();
  }

  /**
   * do calculation for costs related to BEV vehicles
   * to see if we need to
   * set up a service to handle this work
   *
   * @function void
   */
  resultsBEVCalculator() {
    //weekday cost calculation
    if (this.isUrbanOrMixedWeekDay)
      this.weekDayCost =
        (this.vehicle.LEV_SAV_URBAN_CONSUMPTION *
          this.electricityCost *
          this.weekDayMileage) /
        100;
    else
      this.weekDayCost =
        (this.vehicle.LEV_SAV_MIXED_CONSUMPTION *
          this.electricityCost *
          this.weekDayMileage) /
        100;

    //weekend cost calculation
    if (this.isUrbanOrMixedWeekEnd)
      this.weekEndCost =
        (this.vehicle.LEV_SAV_URBAN_CONSUMPTION *
          this.electricityCost *
          this.weekEndMileage) /
        100;
    else
      this.weekEndCost =
        (this.vehicle.LEV_SAV_MIXED_CONSUMPTION *
          this.electricityCost *
          this.weekEndMileage) /
        100;

    if (!this.isUrbanOrMixedWeekDay && !this.isUrbanOrMixedWeekEnd)
      this.kwByWeek =
        (this.weekDayMileage * 5 * this.vehicle.LEV_SAV_URBAN_CONSUMPTION +
          this.weekEndMileage * 2 * this.vehicle.LEV_SAV_URBAN_CONSUMPTION) /
        100;
    else if (!this.isUrbanOrMixedWeekDay && this.isUrbanOrMixedWeekEnd)
      this.kwByWeek =
        (this.weekDayMileage * 5 * this.vehicle.LEV_SAV_URBAN_CONSUMPTION +
          this.weekEndMileage * 2 * this.vehicle.LEV_SAV_MIXED_CONSUMPTION) /
        100;
    else if (this.isUrbanOrMixedWeekDay && !this.isUrbanOrMixedWeekEnd)
      this.kwByWeek =
        (this.weekDayMileage * 5 * this.vehicle.LEV_SAV_MIXED_CONSUMPTION +
          this.weekEndMileage * 2 * this.vehicle.LEV_SAV_URBAN_CONSUMPTION) /
        100;
    else if (this.isUrbanOrMixedWeekDay && this.isUrbanOrMixedWeekEnd)
      this.kwByWeek =
        (this.weekDayMileage * 5 * this.vehicle.LEV_SAV_MIXED_CONSUMPTION +
          this.weekEndMileage * 2 * this.vehicle.LEV_SAV_MIXED_CONSUMPTION) /
        100;

    //bev cost by year calculation
    this.bevCostByYear = this.weekDayCost * 250 + this.weekEndCost * 100;

    //ice calculation
    this.iceVehicleCostWeekDay =
      (this.weekDayMileage * this.fuelCost * this.fuelEfficiency) / 100;
    this.iceVehicleCostWeekEnd =
      (this.weekEndMileage * this.fuelCost * this.fuelEfficiency) / 100;
    this.iceVehicleCostByYear =
      this.iceVehicleCostWeekDay * 250 + this.iceVehicleCostWeekEnd * 100;

    //savings calculation
    this.savingByYear = Math.round(
      this.iceVehicleCostByYear - this.bevCostByYear
    );
    this.saving4ByYear = Math.round(
      4 * (this.iceVehicleCostByYear - this.bevCostByYear)
    );

    this.chargesByWeek = Math.round(
      this.kwByWeek / this.vehicle.LEV_SAV_BATTERY_CAPACITY
    );
    this.annualMileage = this.weekDayMileage * 250 + this.weekEndMileage * 100;
  }

  optimizedResultsForBEV() {
    const wltpConsumption =
      ((this.vehicle.LEV_SAV_BATTERY_CAPACITY / this.wltpRange) * 100) / 0.85;
    this.bevCostByYear =
      wltpConsumption * (this.annualMileage / 100) * this.electricityCost;
    this.iceVehicleCostByYear =
      (this.annualMileage / 100) * this.fuelEfficiency * this.fuelCost;
    this.saving4ByYear = Math.round(
      4 * (this.iceVehicleCostByYear - this.bevCostByYear)
    );
    this.savingByYear = Math.round(
      this.iceVehicleCostByYear - this.bevCostByYear
    );

    this.savingByMonth = Math.round(this.savingByYear / 12);
  }

  /**
   * do calculation for costs related to PHEV vehicles
   * to see if we need to
   * set up a service to handle this work
   *
   * @function void
   */
  resultsPHEVCalculator() {
    //weekday cost calculation
    if (!this.isUrbanOrMixedWeekDay)
      this.weekDayCost =
        (Math.min(
          this.weekDayMileage,
          this.vehicle.LEV_SAV_URBAN_AUTONOMY * this.weekDayNumberDailyCharge
        ) *
          this.electricityCost *
          this.vehicle.LEV_SAV_URBAN_CONSUMPTION +
          Math.max(
            0,
            this.weekDayMileage -
              this.vehicle.LEV_SAV_URBAN_AUTONOMY *
                this.weekDayNumberDailyCharge
          ) *
            this.vehicle.LEV_SAV_URBAN_FUEL_EFFICIENCY *
            this.fuelCost) /
        100;
    else
      this.weekDayCost =
        (Math.min(
          this.weekDayMileage,
          this.vehicle.LEV_SAV_MIXED_AUTONOMY * this.weekDayNumberDailyCharge
        ) *
          this.electricityCost *
          this.vehicle.LEV_SAV_MIXED_CONSUMPTION +
          Math.max(
            0,
            this.weekDayMileage -
              this.vehicle.LEV_SAV_MIXED_AUTONOMY *
                this.weekDayNumberDailyCharge
          ) *
            this.vehicle.LEV_SAV_MIXED_FUEL_EFFICIENCY *
            this.fuelCost) /
        100;

    //weekend cost calculation
    if (!this.isUrbanOrMixedWeekEnd)
      this.weekEndCost =
        (Math.min(
          this.weekEndMileage,
          this.vehicle.LEV_SAV_URBAN_AUTONOMY * this.weekEndNumberDailyCharge
        ) *
          this.electricityCost *
          this.vehicle.LEV_SAV_URBAN_CONSUMPTION +
          Math.max(
            0,
            this.weekEndMileage -
              this.vehicle.LEV_SAV_URBAN_AUTONOMY *
                this.weekEndNumberDailyCharge
          ) *
            this.vehicle.LEV_SAV_URBAN_FUEL_EFFICIENCY *
            this.fuelCost) /
        100;
    else
      this.weekEndCost =
        (Math.min(
          this.weekEndMileage,
          this.vehicle.LEV_SAV_MIXED_AUTONOMY * this.weekEndNumberDailyCharge
        ) *
          this.electricityCost *
          this.vehicle.LEV_SAV_MIXED_CONSUMPTION +
          Math.max(
            0,
            this.weekEndMileage -
              this.vehicle.LEV_SAV_MIXED_AUTONOMY *
                this.weekEndNumberDailyCharge
          ) *
            this.vehicle.LEV_SAV_MIXED_FUEL_EFFICIENCY *
            this.fuelCost) /
        100;

    //bev cost by year calculation
    this.PHEVCostByYear = this.weekDayCost * 250 + this.weekEndCost * 100;

    //ice calculation
    this.iceVehicleCostWeekDay =
      (this.weekDayMileage * this.fuelCost * this.fuelEfficiency) / 100;
    this.iceVehicleCostWeekEnd =
      (this.weekEndMileage * this.fuelCost * this.fuelEfficiency) / 100;
    this.iceVehicleCostByYear =
      this.iceVehicleCostWeekDay * 250 + this.iceVehicleCostWeekEnd * 100;

    //savings calculation
    this.savingByYear = Math.round(
      this.iceVehicleCostByYear - this.PHEVCostByYear
    );
    this.saving4ByYear = Math.round(
      4 * (this.iceVehicleCostByYear - this.PHEVCostByYear)
    );
    this.savingByMonth = Math.round(
      (this.iceVehicleCostByYear - this.PHEVCostByYear) / 12
    );

    this.annualMileage = this.weekDayMileage * 250 + this.weekEndMileage * 100;
    this.monthMileage = Math.round(this.annualMileage / 12);

    //weekday electric drive calculation
    if (this.isUrbanOrMixedWeekDay)
      this.electricDriveWeekDay = Math.min(
        this.weekDayMileage,
        this.vehicle.LEV_SAV_URBAN_AUTONOMY * this.weekDayNumberDailyCharge
      );
    else
      this.electricDriveWeekDay = Math.min(
        this.weekDayMileage,
        this.vehicle.LEV_SAV_MIXED_AUTONOMY * this.weekDayNumberDailyCharge
      );

    //weekend electric drive calculation
    if (this.isUrbanOrMixedWeekDay)
      this.electricDriveWeekEnd = Math.min(
        this.weekEndMileage,
        this.vehicle.LEV_SAV_URBAN_AUTONOMY * this.weekEndNumberDailyCharge
      );
    else
      this.electricDriveWeekEnd = Math.min(
        this.weekEndMileage,
        this.vehicle.LEV_SAV_MIXED_AUTONOMY * this.weekEndNumberDailyCharge
      );

    //electric drive in percentage
    this.electricDrivePercent = Math.round(
      ((this.electricDriveWeekDay * 5 + this.electricDriveWeekEnd * 2) /
        (this.weekDayMileage * 5 + this.weekEndMileage * 2)) *
        100
    );
  }

  /**
   * function to toggle content
   * in mobile version to make application
   * more ergonomic and easy for better user experience
   *
   * the first incentive is shown initially
   * and can be changed later by user
   *
   * @param index : number
   *
   * @return void
   */
  showOrHideContent() {
    this.isDown = !this.isDown;
  }
}
