<div class="main-container">
  <div
    class="label"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FT]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
  >
    {{ label }}
  </div>
  <div
    class="charge-container"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FT]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.JE]="cs_jeep"
  >
    <button (click)="decreaseValue()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="256"
        height="256"
        viewBox="0 0 256 256"
        xml:space="preserve"
      >
        <defs></defs>
        <g
          style="
            stroke: none;
            stroke-width: 0;
            stroke-dasharray: none;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 10;
            fill: none;
            fill-rule: nonzero;
            opacity: 1;
          "
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <path
            d="M 88.616 46.384 H 1.583 c -0.82 0 -1.484 -0.664 -1.484 -1.483 s 0.664 -1.484 1.484 -1.484 h 87.033 c 0.819 0 1.484 0.664 1.484 1.484 S 89.435 46.384 88.616 46.384 z"
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 10;
              fill: rgb(0, 0, 0);
              fill-rule: nonzero;
              opacity: 1;
            "
            transform=" matrix(1 0 0 1 0 0) "
            stroke-linecap="round"
          />
        </g>
      </svg>
    </button>
    <span>{{ value }} {{ currency }}</span>
    <button (click)="increaseValue()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="256"
        height="256"
        viewBox="0 0 256 256"
        xml:space="preserve"
      >
        <defs></defs>
        <g
          style="
            stroke: none;
            stroke-width: 0;
            stroke-dasharray: none;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 10;
            fill: none;
            fill-rule: nonzero;
            opacity: 1;
          "
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <path
            d="M 88.516 43.517 H 46.484 V 1.484 C 46.484 0.664 45.819 0 45 0 s -1.484 0.664 -1.484 1.484 v 42.033 H 1.484 C 0.664 43.517 0 44.181 0 45 s 0.664 1.483 1.484 1.483 h 42.033 v 42.033 C 43.516 89.335 44.18 90 45 90 s 1.484 -0.664 1.484 -1.484 V 46.483 h 42.033 C 89.336 46.483 90 45.82 90 45 S 89.336 43.517 88.516 43.517 z"
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 10;
              fill: rgb(0, 0, 0);
              fill-rule: nonzero;
              opacity: 1;
            "
            transform=" matrix(1 0 0 1 0 0) "
            stroke-linecap="round"
          />
        </g>
      </svg>
    </button>
  </div>
</div>
