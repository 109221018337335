import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-circular-progress",
  templateUrl: "./circular-progress.component.html",
  styleUrls: ["./circular-progress.component.scss"],
})
export class CircularProgressComponent implements OnInit {
  @Input() value: number = 0;
  @Input() lastStepValue: number = 100;
  @Input() timeString: string = ""; // New input for time string
  @Input() cs_peugeot: boolean = false;
  @Input() cs_alfaRomeo: boolean = false;
  @Input() cs_citroen: boolean = false;
  @Input() cs_fiat: boolean = false;
  @Input() cs_fiatPro: boolean = false;
  @Input() cs_ds: boolean = false;
  @Input() cs_jeep: boolean = false;
  fillerRelativePercentage: number = 0;
  formattedTime: string = "";

  @ViewChild("progressCircle", { static: true }) progressCircle!: ElementRef;

  ngOnInit(): void {
    this.updateFormattedTime(); // Initialize formatted time
    this.animateProgressBar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"] || changes["timeString"]) {
      this.updateFormattedTime(); // Update formatted time if timeString changes
      this.animateProgressBar();
    }
  }

  private animateProgressBar(): void {
    const targetValue = this.transform(this.value, this.lastStepValue);
    let startValue = this.fillerRelativePercentage;
    const duration = 700;
    const increment = (targetValue - startValue) / (duration / 50);

    const interval = setInterval(() => {
      startValue += increment;
      if (
        (increment > 0 && startValue >= targetValue) ||
        (increment < 0 && startValue <= targetValue)
      ) {
        startValue = targetValue;
        clearInterval(interval);
      }
      this.fillerRelativePercentage = Math.floor(startValue);
      this.updateValues();
      this.updateProgressCSS();
    }, 50);
  }

  private updateValues(): void {
    if (this.lastStepValue > 0) {
      this.fillerRelativePercentage = this.transform(
        this.value,
        this.lastStepValue
      );
    } else {
      this.fillerRelativePercentage = 0;
    }
  }

  private transform(value: number, max: number): number {
    // Ensure max is not zero or negative to avoid division by zero
    return max > 0 ? Math.min(100, (value / max) * 100) : 0;
  }

  private updateProgressCSS(): void {
    const progressValue = this.fillerRelativePercentage;
    // Set the --progress CSS variable dynamically
    this.progressCircle.nativeElement.style.setProperty(
      "--progress",
      progressValue.toString()
    );
  }

  private updateFormattedTime(): void {
    this.formattedTime = this.formatTime(this.timeString);
  }

  private formatTime(time: string): string {
    // Regular expression to capture hours and minutes
    const timePattern = /(\d{1,2})h(\d{1,2})m/;
    const match = time.match(timePattern);

    if (match) {
      let hours = parseInt(match[1], 10); // Convert hours to number
      let minutes = match[2]; // Keep minutes as a string

      // Remove leading zeros from hours if any
      return `${hours}h${minutes}`;
    }

    return time; // Return the original string if it doesn't match the pattern
  }
}
