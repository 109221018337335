<svg
  #progressCircle
  width="68"
  height="68"
  viewBox="0 0 68 68"
  class="circular-progress"
>
  <circle class="bg"></circle>
  <circle
    class="fg"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
  ></circle>
  <text x="50%" y="50%" text-anchor="middle" dy=".3em" class="progress-text">
    {{ formattedTime }}
  </text>
</svg>
