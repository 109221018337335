<!-- tab-container.component.html -->
<mat-tab-group
  mat-align-tabs="center"
  class="tabs"
  [selectedIndex]="activeIndex"
  (selectedTabChange)="onTabChange($event)"
  [disableRipple]="true"
  #childTabs
  [class.AP]="isPeugeot"
  [class.AC]="isCitroen"
  [class.FT]="isFiat"
  [class.FO]="isFiatPro"
  [class.AR]="isAlfaRomeo"
  [class.DS]="isDS"
  [class.JE]="isJeep"
>
  <!-- Charging Simulator Tab -->
  <mat-tab
    *ngIf="canRenderChargingSimulator"
    label="{{ labelCulture?.tab1_title || 'Charging Simulator' }}"
    class="tab1"
  >
    <ng-template matTabContent>
      <ng-content select="[tabContent='charging-simulator']"></ng-content>
    </ng-template>
  </mat-tab>

  <!-- Fuel Saving Tab -->
  <mat-tab
    *ngIf="canRenderFuelSaving"
    label="{{ labelCulture?.tab2_title || 'Fuel Saving' }}"
    class="tab2"
  >
    <ng-template matTabContent>
      <ng-content select="[tabContent='fuel-saving']"></ng-content>
    </ng-template>
  </mat-tab>

  <!-- Range Calculator Tab -->
  <mat-tab
    *ngIf="canRenderRangeCalculator"
    label="{{ labelCulture?.tab3_title || 'Range Calculator' }}"
    class="tab3"
  >
    <ng-template matTabContent>
      <ng-content select="[tabContent='range-calculator']"></ng-content>
    </ng-template>
  </mat-tab>

  <!-- Help Me Choose Tab -->
  <mat-tab
    *ngIf="canRenderHelpMeChoose"
    label="{{ labelCulture?.tab4_title || 'Help Me Choose' }}"
    class="tab4"
  >
    <ng-template matTabContent>
      <ng-content select="[tabContent='help-me-choose']"></ng-content>
    </ng-template>
  </mat-tab>
</mat-tab-group>
