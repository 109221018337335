import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";

interface CostData {
  fuelCost: number;
  electricityCost: number;
  difference: number;
}

@Component({
  selector: "app-fuel-saving-cards",
  templateUrl: "./fuel-saving-cards.component.html",
  styleUrls: ["./fuel-saving-cards.component.scss"],
})
export class FuelSavingCardsComponent {
  @Input() threeYearsCost: CostData = {
    fuelCost: 0,
    electricityCost: 0,
    difference: 0,
  };
  @Input() oneYearCost: CostData = {
    fuelCost: 0,
    electricityCost: 0,
    difference: 0,
  };
  @Input() oneMonthCost: CostData = {
    fuelCost: 0,
    electricityCost: 0,
    difference: 0,
  };
  @Input("labelCulture") labelCulture: any = {};

  @ViewChild("container") container!: ElementRef;
  @Input() cs_peugeot: boolean = false;
  @Input() cs_citroen: boolean = false;
  @Input() cs_fiat: boolean = false;
  @Input() cs_fiatPro: boolean = false;
  @Input() cs_alfaRomeo: boolean = false;
  @Input() cs_ds: boolean = false;
  @Input() cs_jeep: boolean = false;

  activeCardIndex = 0;
  translateX = 0;
  startX = 0;
  isMobile = false;

  // Touch handling variables
  private touchStartX = 0;
  private touchMoveX = 0;
  private readonly SWIPE_THRESHOLD = 50;
  private readonly CARD_WIDTH = 282.33;
  private readonly CARD_GAP = 32; // 2rem converted to pixels
  private readonly ANIMATION_DURATION = 300; // in milliseconds
  private isAnimating = false;

  constructor() {
    this.checkScreenSize();
  }

  @HostListener("window:resize")
  checkScreenSize() {
    this.isMobile = window.innerWidth <= 768;
    if (!this.isMobile) {
      this.translateX = 0;
    } else {
      this.updateTranslateX();
    }
  }

  formatNumber(value: number): string {
    return Math.round(value).toLocaleString("en-US");
  }

  private updateTranslateX() {
    if (!this.isMobile) return;

    const totalWidth = this.CARD_WIDTH + this.CARD_GAP;
    this.translateX = -this.activeCardIndex * totalWidth;
  }

  onTouchStart(event: TouchEvent) {
    if (!this.isMobile || this.isAnimating) return;
    this.touchStartX = event.touches[0].clientX;
    this.startX = this.translateX;
  }

  onTouchMove(event: TouchEvent) {
    if (!this.isMobile || this.isAnimating) return;
    this.touchMoveX = event.touches[0].clientX;
    const diff = this.touchMoveX - this.touchStartX;

    // Add resistance at the edges
    if (
      (this.activeCardIndex === 0 && diff > 0) ||
      (this.activeCardIndex === 2 && diff < 0)
    ) {
      this.translateX = this.startX + diff / 3; // Increased resistance
    } else {
      this.translateX = this.startX + diff;
    }
  }

  onTouchEnd() {
    if (!this.isMobile || this.isAnimating) return;

    const diff = this.touchMoveX - this.touchStartX;
    const absDiff = Math.abs(diff);

    // Determine direction and threshold
    if (absDiff > this.SWIPE_THRESHOLD) {
      if (diff > 0 && this.activeCardIndex > 0) {
        this.snapToCard(this.activeCardIndex - 1);
      } else if (diff < 0 && this.activeCardIndex < 2) {
        this.snapToCard(this.activeCardIndex + 1);
      } else {
        this.snapToCard(this.activeCardIndex); // Snap back if at edge
      }
    } else {
      // If swipe wasn't strong enough, snap back to current card
      this.snapToCard(this.activeCardIndex);
    }
  }

  private snapToCard(index: number) {
    this.isAnimating = true;
    this.activeCardIndex = index;
    this.updateTranslateX();

    // Reset animation lock after transition completes
    setTimeout(() => {
      this.isAnimating = false;
    }, this.ANIMATION_DURATION);
  }

  // Add method to manually navigate
  setActiveCard(index: number) {
    if (!this.isMobile || this.isAnimating) return;
    this.snapToCard(index);
  }

  navigateCards(direction: "prev" | "next") {
    if (!this.isMobile || this.isAnimating) return;

    const newIndex =
      direction === "prev"
        ? Math.max(0, this.activeCardIndex - 1)
        : Math.min(2, this.activeCardIndex + 1);

    this.snapToCard(newIndex);
  }
}
