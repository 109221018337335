<div
  class="wrapper"
  role="progressbar"
  aria-valuemin="0"
  [attr.aria-valuemax]="lastStepValue"
  [attr.aria-valuenow]="value"
>
  <div class="barContainer">
    <div class="filler" [ngStyle]="{ width: fillerRelativePercentage + '%' }">
      <div
        class="fillerBackground"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.JE]="cs_jeep"
        [class.DS]="cs_ds"
        [ngStyle]="{ width: fillerRelativePercentage + '%' }"
      ></div>
    </div>
  </div>
</div>
