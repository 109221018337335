import {
  Component,
  Input,
  ViewChild,
  NgZone,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import {
  infoWindowTemplateAP,
  infoWindowTemplateAC,
} from "@lev/us/interfaces/infowindow.interface";
import { SearcheCriteria } from "src/app/models/searchCriteria";
import { us_labelCulture } from "../models/label_culture";
import { LevServiceService } from "src/app/services/lev-service.service";
import { Data } from "src/app/models/data";
import { API_CONFIG } from "src/assets/plugSurfing_params/api_url_config";
import { EsolutionFetchService } from "@lev/services/esolution.service";
import { MatSelectionList } from "@angular/material/list";
import { LoaderService } from "../services/loader.service";
declare global {
  interface Window {
    woosmap: any; // Add this interface for TypeScript
  }
}

@Component({
  selector: "app-usage-simulator",
  templateUrl: "./usage-simulator.component.html",
  styleUrls: ["./usage-simulator.component.scss"],
  providers: [],
})
export class UsageSimulatorComponent implements AfterViewInit {
  typesOfChargers: any[] = [
    {
      id: 0,
      img: "../../assets/chargers/ccs-type-2.jpg",
      title: "Type 2 - Combo",
      value: "IEC_62196_T2_COMBO",
    },
    {
      id: 1,
      img: "../../assets/chargers/type2.jpg",
      title: "Type 2",
      value: "IEC_62196_T2",
    },
    {
      id: 2,
      img: "../../assets/chargers/chademo.jpg",
      title: "Chademo",
      value: "CHADEMO",
    },
    {
      id: 3,
      img: "../../assets/chargers/type1.jpg",
      title: "Type 1",
      value: "IEC_62196_T1",
    },
    {
      id: 4,
      img: "../../assets/chargers/ccs-type-1.jpg",
      title: "Type 1 - Combo",
      value: "IEC_62196_T1_COMBO",
    },
    {
      id: 5,
      img: "../../assets/chargers/tesla.jpg",
      title: "Tesla",
      value: "Tesla",
    },
  ];
  selectedChargers: any[] = [];
  @ViewChild(MatSelectionList) list: MatSelectionList;
  @Input("country")
  country: string;

  @Input("displaymode")
  displaymode!: string;

  @Input("langage") langage!: string;

  @Input("brand") brand!: string;

  @Input("vehicleid") vehicleid!: string;

  @Input("lcdv10") lcdv10!: string;

  @Input("type") type!: string;

  @Input("ispro") ispro!: string;

  @Input("isd1") isd1!: string;

  @Input("linkprise") linkprise!: string;

  @ViewChild("mapContainer") mapContainer!: ElementRef;
  SearcheCriteria: SearcheCriteria = new SearcheCriteria();
  public center: { lat: number; lng: number } | undefined;
  esolutionData: any[] = [];
  esolutionStationData: any[] = [];
  markers: any[] = [];
  connectors: any[] = [];
  initialCenter: any;
  markerCity: any;
  currentInfoWindow: any;
  localitiesService: any; // Adjust the type according to the Woosmap library type
  request: any; // Adjust the type according to the Woosmap library type
  debouncedLocalitiesAutocomplete: (...args: any[]) => Promise<any>;
  us_peugeot: boolean;
  barBackgroundColor: string;
  us_citroen: boolean;
  us_ds: boolean;
  us_opel: boolean;
  us_vauxhall: any;
  isspoticar: string;
  cs_spoticar: boolean;
  isLoading: boolean = false;

  constructor(
    private eSolutionsService: EsolutionFetchService,
    private ref: ChangeDetectorRef,
    private levservice: LevServiceService,
    private data: Data,
    private zone: NgZone,
    public loaderService: LoaderService
  ) {}

  ngAfterViewInit(): void {
    this.survey();
    this.setBrandAndStyle(this.brand, this.country);
    this.getLatAndLongByCountry();
    this.initializeAutocomplete();
  }

  initializeMap(initialCenter: { lat: number; lng: number }) {
    // Initialize Woosmap map
    console.log("the intial center after calling intialize map", initialCenter);
    const woosmap = window.woosmap;
    const map = new woosmap.map.Map(this.mapContainer.nativeElement, {
      center: initialCenter,
      zoom: 9,
    });

    this.esolutionData.forEach((station) => {
      this.markerCity = new woosmap.map.Marker({
        position: {
          lat: station.lat,
          lng: station.lng,
        },
        icon: {
          url: "https://images.woosmap.com/dot-marker.png",
          scaledSize: {
            height: 30,
            width: 24,
          },
        },
      });

      let infoWindow = new woosmap.map.InfoWindow({});
      this.localitiesService = new window.woosmap.map.LocalitiesService();

      this.request = {
        input: "",
        types: ["locality", "address", "postal_cde"],
      };

      this.debouncedLocalitiesAutocomplete = this.debouncePromise(
        this.localitiesService.autocomplete,
        0
      );
      let infoWindowTemplate;
      this.markerCity.addListener("click", async () => {
        let stationData = await this.getEsolutionDatabyChargingID(
          station.charging_station_id
        );

        switch (this.brand.toLowerCase()) {
          case "ap":
            infoWindowTemplate = infoWindowTemplateAP;
            break;
          case "ac":
            infoWindowTemplate = infoWindowTemplateAC;
            break;
          default:
            break;
        }
        const InfoWindowHTML = infoWindowTemplate(stationData);

        if (this.currentInfoWindow) {
          this.currentInfoWindow.close();
        }

        infoWindow.setOffset(new woosmap.map.Point(0, -20));
        infoWindow.setContent(InfoWindowHTML);

        infoWindow.open(map, { lat: station.lat, lng: station.lng });

        this.currentInfoWindow = infoWindow;
        // this.markerCity.setIcon({
        //   url: "https://images.woosmap.com/dot-marker.png",
        //   scaledSize: {
        //     height: 30,
        //     width: 24,
        //   },
        // });
      });

      map.addListener("click", () => {
        infoWindow.close();
        this.markerCity.setIcon({
          url: "https://images.woosmap.com/dot-marker.png",
          scaledSize: {
            height: 30,
            width: 24,
          },
        });
      });

      this.markerCity.setMap(map);
      // this.markers.push(markerCity);
    });
  }

  async getEsolutionDatabyChargingID(charging_station_id: any) {
    try {
      const data = await this.eSolutionsService
        .getData(charging_station_id)
        .toPromise();
      const station = data["charging_stations"][0];

      if (station) {
        return {
          lat: Number(station["coordinates"]["latitude"]),
          lng: Number(station["coordinates"]["longitude"]),
          address: station["address"],
          hotline_number: station["hotline_number"], //
          status: station["status"],
          location_name: station["location_name"],
          connectors: station["connectors"],
        };
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  getLatAndLongByCountry() {
    let countryCode = this.country.toUpperCase();
    this.levservice.getCountriesLongitudeAndLatitude(countryCode).subscribe(
      (data) => {
        this.center = data;
        this.initialCenter = this.center;
        console.log("initialCenter", this.initialCenter); // Move console.log here
        this.getDatafromEsolution(this.initialCenter);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getDatafromEsolution(
    initialCenter: { lat: number; lng: number },
    connectors?: any
  ) {
    this.initialCenter = initialCenter; //Changing the center from the default ones
    API_CONFIG.searchPOI["max_results"] = 1000;
    API_CONFIG.searchPOI["search_area"]["latitude"] =
      initialCenter.lat.toString();
    API_CONFIG.searchPOI["search_area"]["longitude"] =
      initialCenter.lng.toString();
    // API_CONFIG.searchPOI["search_area"]["range"] = 100;
    API_CONFIG.searchPOI["connectors_standard"] = connectors;
    this.eSolutionsService.postData(API_CONFIG.searchPOI).subscribe(
      (data) => {
        const stations = data["charging_stations"];
        if (stations) {
          for (const element of stations) {
            this.esolutionData.push({
              lat: Number(element["coordinates"]["latitude"]),
              lng: Number(element["coordinates"]["longitude"]),
              charging_station_id: element["charging_station_id"],
            });
          }
        }

        this.initializeMap(initialCenter);
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
  }

  survey() {
    if (
      this.brand == undefined &&
      this.langage == undefined &&
      this.country == undefined &&
      this.displaymode == undefined &&
      this.type == undefined
    ) {
      this.SearcheCriteria = this.data.storage;
      this.initFromSearchCriteria();
    }
  }

  initFromSearchCriteria() {
    if (this.SearcheCriteria) {
      this.country = this.SearcheCriteria.countryCode.toLowerCase();
      this.langage = this.SearcheCriteria.langageCode;
      this.brand = this.SearcheCriteria.marque;
      this.type = this.SearcheCriteria.typeVehicle;
      this.ispro = this.SearcheCriteria.isPro ? "true" : "false";
      this.isd1 =
        this.SearcheCriteria.isd1 === "true" ||
        this.brand.toLowerCase() === "ap"
          ? "true"
          : "false";
      this.vehicleid = this.SearcheCriteria.vehicleid;
      this.lcdv10 = this.SearcheCriteria.lcdv10;
      this.linkprise = this.validURL(this.SearcheCriteria.linkprise)
        ? this.SearcheCriteria.linkprise
        : "";
      this.displaymode = this.SearcheCriteria.displayMode;
    }
  }

  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  initializeAutocomplete(): void {
    const inputElement = document.getElementById(
      "autocomplete-input"
    ) as HTMLInputElement;
    const suggestionsList = document.getElementById(
      "suggestions-list"
    ) as HTMLUListElement;

    if (inputElement && suggestionsList) {
      inputElement.addEventListener(
        "input",
        this.handleAutocomplete.bind(this)
      );
      inputElement.addEventListener("keydown", (event) => {
        if (event.key === "Enter") {
          const firstLi = suggestionsList.querySelector("li");
          if (firstLi) {
            firstLi.click();
          }
        }
      });
    }
  }

  handleAutocomplete(): void {
    const inputElement = document.getElementById(
      "autocomplete-input"
    ) as HTMLInputElement;
    const suggestionsList = document.getElementById(
      "suggestions-list"
    ) as HTMLUListElement;

    if (inputElement && suggestionsList) {
      this.request.input = inputElement.value;
      if (this.request.input) {
        this.debouncedLocalitiesAutocomplete(this.request)
          .then((localities) => this.displaySuggestions(localities))
          .catch((error) =>
            console.error("Error autocomplete localities:", error)
          );
      } else {
        suggestionsList.style.display = "none";
      }
    }
  }

  handleDetails(publicId: string) {
    this.localitiesService
      .getDetails({ publicId })
      .then((locality) => this.displayLocality(locality.result))
      .catch((error) =>
        console.error("Error getting locality details:", error)
      );
  }

  displayLocality(locality: any): void {
    this.getDatafromEsolution(locality.geometry.location);
  }

  displaySuggestions(localitiesPredictions: any): void {
    const inputElement = document.getElementById(
      "autocomplete-input"
    ) as HTMLInputElement;
    const suggestionsList = document.getElementById(
      "suggestions-list"
    ) as HTMLUListElement;

    if (inputElement && suggestionsList) {
      suggestionsList.innerHTML = "";
      if (
        localitiesPredictions.localities.length > 0 &&
        this.request["input"]
      ) {
        localitiesPredictions.localities.forEach((locality: any) => {
          const li = document.createElement("li");
          const iconSpan = document.createElement("span");
          iconSpan.classList.add("localities-icon");
          const textSpan = document.createElement("span");
          textSpan.textContent = locality.description ?? "";
          li.classList.add("localities-item");

          li.appendChild(iconSpan);
          li.appendChild(textSpan);

          li.addEventListener("click", () => {
            inputElement.value = locality.description ?? "";
            suggestionsList.style.display = "none";
            this.handleDetails(locality.public_id);
          });
          suggestionsList.appendChild(li);
        });
        suggestionsList.style.display = "block";
      } else {
        suggestionsList.style.display = "none";
      }
    }
  }

  debouncePromise<T>(
    fn: (...args: any[]) => Promise<T>,
    delay: number
  ): (...args: any[]) => Promise<T> {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    let latestResolve: ((value: T | PromiseLike<T>) => void) | null = null;
    let latestReject: ((reason?: any) => void) | null = null;

    return (...args: any[]): Promise<T> => {
      return new Promise<T>((resolve, reject) => {
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
        latestResolve = resolve;
        latestReject = reject;
        timeoutId = setTimeout(() => {
          fn(...args)
            .then((result) => {
              if (latestResolve === resolve && latestReject === reject) {
                resolve(result);
              }
            })
            .catch((error) => {
              if (latestResolve === resolve && latestReject === reject) {
                reject(error);
              }
            });
        }, delay);
      });
    };
  }

  showSelected(data: any) {
    data.forEach((e) => {
      console.log(e.value);
    });
  }

  onSelectionChange(charger: any, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.selectedChargers.push(charger.value);
    } else {
      const index = this.selectedChargers.findIndex((s) => s === charger.value);
      if (index !== -1) {
        this.selectedChargers.splice(index, 1);
      }
    }
  }

  onSubmit() {
    console.log(this.selectedChargers);
    this.clearMarkers(); // Step 1: Clear existing markers
    this.getDatafromEsolution(this.initialCenter, this.selectedChargers);
  }

  clearMarkers() {
    if (this.markerCity) {
      this.markerCity.setMap(null);
    }
    this.esolutionData = []; // Clear the existing data array
  }

  setBrandAndStyle(brand: string, country: string) {
    if (brand === "ap") {
      this.us_peugeot = true;
      this.barBackgroundColor = "#143d5f";
    } else if (brand === "ac") {
      this.us_citroen = true;
      this.barBackgroundColor = "#007fa4";
    } else if (brand === "ds") {
      this.us_ds = true;
      this.barBackgroundColor = "#2f2727";
    } else if (brand === "ov") {
      this.us_opel = true;
      this.barBackgroundColor = "#2f2727";
      if (country === "gb") {
        this.us_vauxhall = this.us_opel;
      }
    } else if (brand === "vx") {
      this.us_opel = true;
      this.us_vauxhall = this.us_opel;
      this.barBackgroundColor = "#2f2727";
    }
    if (this.isspoticar === "true") this.cs_spoticar = true;
  }
}
