import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";
import { FormBuilder, FormGroup } from "@angular/forms";

type ChargeType = "private" | "publicStandard" | "publicFast";

interface SliderData {
  sliderValue: number;
  chargeType: ChargeType;
  fuelCost?: number;
  fuelConsumption?: number;
  electricityCost?: number;
}

export interface ElectricityPrices {
  private: number;
  publicStandard: number;
  publicFast: number;
}

interface CostData {
  fuelCost: number;
  electricityCost: number;
  difference: number;
}

interface TimePeriodCosts {
  threeYears: CostData;
  oneYear: CostData;
  oneMonth: CostData;
}

@Component({
  selector: "app-speed-slider",
  templateUrl: "./speed-slider.component.html",
  styleUrls: ["./speed-slider.component.scss"],
})
export class SpeedSliderComponent implements OnInit {
  @Input() defaultChargeType: ChargeType = "private";
  @Input() initialSliderValue: number = 25000;
  @Input() fuelSavingData: Array<any> = [];
  @Input("labelCulture") labelCulture: any = {};
  @Input() cs_peugeot: boolean = false;
  @Input() cs_citroen: boolean = false;
  @Input() cs_fiat: boolean = false;
  @Input() cs_fiatPro: boolean = false;
  @Input() cs_alfaRomeo: boolean = false;
  @Input() cs_ds: boolean = false;
  @Input() cs_jeep: boolean = false;
  @Input() brand: string = "";

  @Input() set fuelValues(values: number[]) {
    if (values?.length === 2) {
      const [newCost, newConsumption] = values;
      const fuelValuesChanged =
        this._fuelValues.costPerLiter !== newCost ||
        this._fuelValues.consumption !== newConsumption;

      this._fuelValues = {
        costPerLiter: newCost,
        consumption: newConsumption,
      };

      // Remove the initialized check to ensure initial values trigger calculations
      if (fuelValuesChanged) {
        this.calculateFuelCosts();
        this.calculateElectricityCosts();
        this.updateSliderData();
      }
    }
  }

  @Input() set electricityPrices(prices: Partial<ElectricityPrices>) {
    const pricesChanged =
      prices.private !== this._electricityPrices.private ||
      prices.publicStandard !== this._electricityPrices.publicStandard ||
      prices.publicFast !== this._electricityPrices.publicFast;

    this._electricityPrices = {
      ...this._electricityPrices,
      ...prices,
    };

    if (pricesChanged && this.initialized) {
      this.calculateFuelCosts();
      this.calculateElectricityCosts();
      this.updateSliderData();
    }
  }

  // Constants for efficiency factors
  private readonly EFFICIENCY_FACTOR_STANDARD = 1.15;
  private readonly EFFICIENCY_FACTOR_FAST = 1;

  private initialized = false;

  private _fuelValues = {
    costPerLiter: 0,
    consumption: 0,
  };

  private _electricityPrices: ElectricityPrices = {
    private: 0.25,
    publicStandard: 0.4,
    publicFast: 0.65,
  };

  costData: TimePeriodCosts = {
    threeYears: { fuelCost: 0, electricityCost: 0, difference: 0 },
    oneYear: { fuelCost: 0, electricityCost: 0, difference: 0 },
    oneMonth: { fuelCost: 0, electricityCost: 0, difference: 0 },
  };

  sliderValue: number;
  sliderOptions: Options = {
    floor: 0,
    ceil: 50000,
    step: 100,
    showSelectionBar: true,
    hideLimitLabels: true,
    translate: (value: number): string => {
      return value + " Km";
    },
  };

  radioForm: FormGroup;
  sliderData: SliderData;
  private dataReady = false;

  constructor(private formBuilder: FormBuilder) {
    this.sliderValue = this.initialSliderValue;
    this.radioForm = this.formBuilder.group({
      chargeType: [this.defaultChargeType],
    });

    this.sliderData = {
      sliderValue: this.initialSliderValue,
      chargeType: this.defaultChargeType,
    };
  }

  ngOnInit() {
    this.initialized = true;
    console.log("Speed Slider Rendered");

    // Initialize fuel values
    if (
      this._fuelValues.costPerLiter === 0 &&
      this._fuelValues.consumption === 0
    ) {
      this._fuelValues = {
        costPerLiter: 1.8,
        consumption: 6
        ,
      };
    }

    // Only calculate fuel costs initially (not dependent on fuelSavingData)
    this.calculateFuelCosts();

    // Check if we already have fuel saving data available
    if (this.fuelSavingData && this.fuelSavingData.length > 0) {
      this.dataReady = true;
      this.calculateElectricityCosts();
    } else {
      console.log("Speed Slider initialized, waiting for fuel saving data");
    }

    this.updateSliderData();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Only process if component is initialized
    if (!this.initialized) return;

    if (changes.fuelSavingData) {
      // Mark data as ready if it exists and has length
      this.dataReady = this.fuelSavingData && this.fuelSavingData.length > 0;

      if (this.dataReady) {
        console.log("Fuel saving data is now available:", this.fuelSavingData);
        this.calculateFuelCosts();
        this.calculateElectricityCosts();
        // Update slider data to reflect new calculations
        this.updateSliderData();
      }
    }

    if (changes.initialSliderValue) {
      this.sliderValue = changes.initialSliderValue.currentValue;
      this.updateSliderData();
    }

    if (changes.defaultChargeType) {
      this.radioForm.patchValue({
        chargeType: changes.defaultChargeType.currentValue,
      });
      this.updateSliderData();
    }

    if (changes.labelCulture) {
      // Update any UI elements that use labelCulture
    }

    if (changes.brand) {
      this.setBrandAndStyle(this.brand);
    }

    // No need to check fuelValues and electricityPrices as they already have setters
  }

  setBrandAndStyle(brand: string) {
    if (brand.toLocaleLowerCase() === "f2mc") {
      this.cs_peugeot = false;
      this.cs_citroen = false;
      this.cs_fiat = false;
      this.cs_fiatPro = false;
      this.cs_alfaRomeo = false;
      this.cs_ds = false;
      this.cs_jeep = false;
    }
    if (brand.toLowerCase() === "ap") {
      this.cs_peugeot = true;
    } else if (brand.toLowerCase() === "ac") {
      this.cs_citroen = true;
    } else if (brand.toLowerCase() === "ft") {
      this.cs_fiat = true;
    } else if (brand.toLowerCase() === "ar") {
      this.cs_alfaRomeo = true;
    } else if (brand.toLowerCase() === "fo") {
      this.cs_fiatPro = true;
    } else if (brand.toLowerCase() === "ds") {
      this.cs_ds = true;
    } else if (brand.toLowerCase() === "je") {
      this.cs_jeep = true;
    }
  }

  updateSliderData() {
    const currentChargeType =
      this.radioForm.get("chargeType")?.value || this.defaultChargeType;

    this.sliderData = {
      ...this.sliderData,
      sliderValue: this.sliderValue,
      chargeType: currentChargeType,
    };

    // Calculate both costs when slider data updates
    this.calculateFuelCosts();
    // Only calculate electricity costs if data is ready
    if (this.dataReady) {
      this.calculateElectricityCosts();
    }
  }

  private updateCostData() {
    const fuelCost = this.sliderData.fuelCost || 0;
    const electricityCost = this.sliderData.electricityCost || 0;
    const difference = fuelCost - electricityCost;

    // Calculate for one year
    this.costData.oneYear = {
      fuelCost,
      electricityCost,
      difference,
    };

    // Calculate for three years
    this.costData.threeYears = {
      fuelCost: fuelCost * 3,
      electricityCost: electricityCost * 3,
      difference: difference * 3,
    };

    // Calculate for one month (yearly cost divided by 12)
    this.costData.oneMonth = {
      fuelCost: fuelCost / 12,
      electricityCost: electricityCost / 12,
      difference: difference / 12,
    };
  }

  private calculateFuelCosts() {
    const distance = this.sliderData.sliderValue;
    const consumption = this._fuelValues.consumption;
    const costPerLiter = this._fuelValues.costPerLiter;

    const totalConsumption = (distance / 100) * consumption;
    const totalCost = totalConsumption * costPerLiter;

    this.sliderData = {
      ...this.sliderData,
      fuelCost: totalCost,
    };
    this.updateCostData();
  }

  private calculateElectricityCosts() {
    // Skip calculation if data isn't ready
    if (
      !this.dataReady ||
      !this.fuelSavingData ||
      this.fuelSavingData.length === 0
    ) {
      console.warn(
        "Cannot calculate electricity costs - fuel saving data not available"
      );
      return;
    }

    const distance = this.sliderData.sliderValue;
    const chargeType = this.sliderData.chargeType;

    const fuelData = this.fuelSavingData[0];
    // Additional check to ensure fuelData exists
    if (!fuelData) {
      console.warn("fuelData is undefined, cannot calculate electricity costs");
      return;
    }

    // Now safely access the consumption property with a fallback value
    const consumption = fuelData.consumption
      ? parseFloat(fuelData.consumption)
      : 16;

    let electricityPrice: number;
    let efficiencyFactor: number;

    // Rest of your method stays the same
    switch (chargeType) {
      case "private":
        electricityPrice = this._electricityPrices.private;
        efficiencyFactor = this.EFFICIENCY_FACTOR_STANDARD;
        break;
      case "publicStandard":
        electricityPrice = this._electricityPrices.publicStandard;
        efficiencyFactor = this.EFFICIENCY_FACTOR_STANDARD;
        break;
      case "publicFast":
        electricityPrice = this._electricityPrices.publicFast;
        efficiencyFactor = this.EFFICIENCY_FACTOR_FAST;
        break;
      default:
        electricityPrice = this._electricityPrices.private;
        efficiencyFactor = this.EFFICIENCY_FACTOR_STANDARD;
    }

    // Using the formula with the safely parsed consumption value
    const electricityCost =
      distance * (consumption / 100) * efficiencyFactor * electricityPrice;

    this.sliderData = {
      ...this.sliderData,
      electricityCost: electricityCost,
    };

    this.updateCostData();
  }
}
