<div
  class="range-container"
  [class.AP]="cs_peugeot"
  [class.AC]="cs_citroen"
  [class.FT]="cs_fiat"
  [class.FO]="cs_fiatPro"
  [class.AR]="cs_alfaRomeo"
  [class.DS]="cs_ds"
  [class.JE]="cs_jeep"
  *ngIf="loadUI"
>
  <div
    class="block-slider-wrapper"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
  >
    <p
      role="status"
      class="block-slider-autonomy-text"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    >
      {{ labelCulture.as_simulate_autonomy_your }} {{ carName }}
    </p>
  </div>

  <div class="slider-components-wrapper">
    <div
      class="sliders-control"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
      [class.AP]="cs_peugeot"
    >
      <div
        class="slider-parent"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
        [class.AP]="cs_peugeot"
      >
        <div
          class="slider-label"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
          [class.AP]="cs_peugeot"
        >
          <label>{{ labelCulture.as_driving_style }}</label>
        </div>
        <app-driving-style
          [cs_fiat]="cs_fiat"
          [cs_fiatPro]="cs_fiatPro"
          [cs_alfaRomeo]="cs_alfaRomeo"
          [cs_ds]="cs_ds"
          [cs_jeep]="cs_jeep"
          [cs_peugeot]="cs_peugeot"
          (drivingStyleChange)="onDrivingStyleChange($event)"
          [labelCulture]="labelCulture"
        ></app-driving-style>
      </div>
      <div *ngFor="let slider of slidersData" class="slider-parent">
        <div
          class="slider-label"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
          [class.AP]="cs_peugeot"
        >
          <label>{{ slider.label }}</label>
        </div>

        <app-slider-box
          [cs_fiat]="cs_fiat"
          [cs_fiatPro]="cs_fiatPro"
          [cs_alfaRomeo]="cs_alfaRomeo"
          [cs_ds]="cs_ds"
          [cs_jeep]="cs_jeep"
          [cs_peugeot]="cs_peugeot"
          [label]="slider.label"
          [value]="slider.value"
          [minValue]="slider.minValue"
          [maxValue]="slider.maxValue"
          [step]="slider.step"
          (valueChange)="onSliderValueChange($event)"
          [labelCulture]="labelCulture"
        ></app-slider-box>
      </div>
      <div class="group-checkboxes">
        <div
          *ngFor="let checkbox of checkBoxData"
          class="slider-parent"
          [style.display]="
            checkbox.key === 'POMPE_CHALEUR' && shouldHideComponent()
              ? 'none'
              : 'block'
          "
        >
          <div
            class="slider-label"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
            [class.AP]="cs_peugeot"
          >
            <label>{{ checkbox.label }}</label>
          </div>
          <app-check-box
            [cs_fiat]="cs_fiat"
            [cs_fiatPro]="cs_fiatPro"
            [cs_alfaRomeo]="cs_alfaRomeo"
            [cs_ds]="cs_ds"
            [cs_jeep]="cs_jeep"
            [cs_peugeot]="cs_peugeot"
            [options]="['OFF', 'ON']"
            [value]="checkbox.value"
            [label]="checkbox.label"
            (checkBoxChange)="handleCheckBoxChange($event)"
            [labelCulture]="labelCulture"
            [key]="checkbox.key"
            [vehicleid]="vehicleid"
          ></app-check-box>
        </div>
      </div>
    </div>

    <div class="data-display">
      <app-speed
        *ngIf="dataReady"
        [cs_fiat]="cs_fiat"
        [cs_fiatPro]="cs_fiatPro"
        [cs_alfaRomeo]="cs_alfaRomeo"
        [cs_ds]="cs_ds"
        [cs_jeep]="cs_jeep"
        [cs_peugeot]="cs_peugeot"
        [autonomyData]="autonomyData"
        [drivingStyle]="selectedDrivingStyle"
        [sliderLabel]="sliderLabel"
        [sliderValue]="sliderValue"
        [checkboxLabel]="checkboxLabel"
        [checkboxValue]="checkboxValue"
        [checkboxData]="checkBoxData"
        [defaultValueForslider]="defaultValueForslider"
        [labelCulture]="labelCulture"
      ></app-speed>
    </div>

    <div class="image-container" *ngIf="imageExist">
      <img
        [src]="imageSource"
        alt=""
        class="lev-result-image"
        crossorigin="anonymous"
      />
    </div>
  </div>
  <div class="disclaimer">
    <div
      class="horizontal-ligne"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    ></div>
    <p
      class="disclaimer-text"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    >
      {{ labelCulture.disclaimer_range }}
    </p>
  </div>
</div>
<div
  class="error-handling"
  [class.AP]="cs_peugeot"
  [class.AC]="cs_citroen"
  [class.FT]="cs_fiat"
  [class.FO]="cs_fiatPro"
  [class.AR]="cs_alfaRomeo"
  [class.DS]="cs_ds"
  [class.JE]="cs_jeep"
  *ngIf="!loadUI"
>
  <svg
    fill="#17234d"
    height="50px"
    width="200px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 330 330"
    xml:space="preserve"
    style="display: block; margin: auto"
  >
    <path
      id="XMLID_224_"
      d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
	l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
	C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
    />
  </svg>
  Please Select a new Model Name
</div>
