import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { Options, LabelType } from "@angular-slider/ngx-slider";

@Component({
  selector: "app-check-box",
  templateUrl: "./check-box.component.html",
  styleUrls: ["./check-box.component.scss"],
})
export class CheckBoxComponent implements OnInit {
  @Input() options: string[] = ["OFF", "ON"]; // Default options for ON and OFF
  @Input() label: string; // Add input to receive the label for each checkbox
  @Input() key: string;
  @Input() vehicleid: string;
  @Output() checkBoxChange: EventEmitter<{ label: string; value: number }> =
    new EventEmitter(); // Emit both label and value
  @Input("labelCulture") labelCulture: any = {};
  @Input("cs_alfaRomeo") cs_alfaRomeo: boolean = false;
  @Input("cs_fiat") cs_fiat: boolean = false;
  @Input("cs_jeep") cs_jeep: boolean = false;
  @Input("cs_peugeot") cs_peugeot: boolean = false;

  public value: number = 1; // Initial value is set to 1 (which represents "OFF" in this case)
  sliderOptions!: Options; // Slider options initialized in ngOnInit()

  // Mapping for new values to be emitted
  private checkBoxMap: { [key: string]: number } = {
    ON: 1,
    OFF: 0,
  };

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.sliderOptions = {
      floor: 0,
      ceil: 1, // Only two values: 0 (ON) and 1 (OFF)
      step: 1, // Slider moves in steps of 1
      showTicksValues: false, // Hide tick values
      translate: (value: number, label: LabelType): string => {
        return this.options[value]; // Translate 0 to "ON" and 1 to "OFF"
      },
    };

    // Emit the default checkbox state on initialization
    const mappedValue = this.checkBoxMap[this.options[this.value]];
    this.checkBoxChange.emit({ label: this.label, value: mappedValue }); // Emit the label and default state (off_state)
  }

  ngAfterViewInit(): void {
    this.updateGradient(); // Apply initial gradient after the view is initialized
  }

  // Function to update the gradient dynamically
  updateGradient(): void {
    const sliderBar =
      this.elRef.nativeElement.querySelectorAll(".ngx-slider-bar");
    if (sliderBar) {
      if (this.value === 1) {
        // If "ON", apply the gradient
        const percentage =
          ((this.value - this.sliderOptions.floor!) /
            (this.sliderOptions.ceil! - this.sliderOptions.floor!)) *
          100;

        // Adjust the gradient spread
        const spread = 90;
        const gradientPositionStart = Math.max(percentage - spread, 0);
        const gradientPositionEnd = Math.min(percentage + spread, 100);

        if (
          this.cs_alfaRomeo === true ||
          this.cs_fiat === true ||
          this.cs_jeep === true ||
          this.cs_peugeot === true
        ) {
          sliderBar[2].style.background = `linear-gradient(90deg, rgba(255, 255, 255, 0) ${gradientPositionStart}%,rgb(56 56 56) ${percentage}%, rgba(255, 255, 255, 0) ${gradientPositionEnd}%)`;
        } else {
          sliderBar[2].style.background = `linear-gradient(90deg, rgba(255, 255, 255, 0) ${gradientPositionStart}%, #FFFFFF ${percentage}%, rgba(255, 255, 255, 0) ${gradientPositionEnd}%)`;
        }
      } else {
        // If "OFF", remove the gradient
        sliderBar[2].style.background = "none";
      }
    }
  }

  // Triggered whenever the slider value changes
  onSliderChange(newValue: number): void {
    this.value = newValue;
    this.updateGradient(); // Update the gradient when the slider changes

    // Emit both the checkbox label and mapped checkbox state (on_state or off_state)
    const mappedValue = this.checkBoxMap[this.options[newValue]];
    this.checkBoxChange.emit({ label: this.label, value: mappedValue });
  }

  // Helper method to check if this component should be hidden
  shouldHideComponent(): boolean {
    return (
      this.key === "POMPE_CHALEUR" &&
      this.vehicleid &&
      [
        "juni",
        "ypsi",
        "aven",
        "2CXE5O",
        "2CXE5L",
        "2CXE7O",
        "2CXE7L",
        "2CXE5P",
        "2CXE5M",
        "2CXE7P",
        "2CXE7M",
        "2IXE7M",
        "2IXE7P",
        "2IXE5M",
        "2IXE5P",
        "2IXE7L",
        "2PXE7O",
        "2IXE5L",
        "2IXE5O",
        "2GXE7M",
        "2GXE7P",
        "2GXE5M",
        "2GXE5P",
        "2GXE7L",
        "2IXE7O",
        "2GXE5L",
        "2GXE5O",
        "2PXE7M",
        "2PXE7P",
        "2PXE5M",
        "2PXE5P",
        "2PXE7L",
        "2GXE7O",
        "2PXE5L",
        "2PXE5O",
      ].includes(this.vehicleid)
    );
  }
}
