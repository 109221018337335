<main>
  <div
    class="lev"
    [class.ac]="as_citroen"
    [class.ap]="as_peugeot"
    [class.fo]="as_fiat"
    [class.ds]="as_ds"
    [class.isNewOpelStyle]="isNewOpelStyle"
    [ngClass]="
      as_spoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
    "
    [class.vx]="as_vauxhall"
    [class.ov]="as_opel"
  >
    <div
      class="lev-as"
      [class.isNewOpelStyle]="isNewOpelStyle"
      [class.ac]="as_citroen"
      [class.ap]="as_peugeot"
      [class.fo]="as_fiat"
      [class.ds]="as_ds"
      [class.is-right-to-left]="isRightToLeft"
      [class.spoticar]="as_spoticar"
      [class.vx]="as_vauxhall"
      [class.ov]="as_opel"
    >
      <!-- Details to fill -->
      <div
        class="lev-as-form"
        [class.d1]="as_d1"
        [class.isNewOpelStyle]="isNewOpelStyle"
        [ngStyle]="{
          margin:
            deviceIsMobile() && vehicule.LEV_AS_TYPE == 'PHEV'
              ? '0 auto 30px'
              : null
        }"
        [class.ac]="as_citroen"
        [class.spoticar]="as_spoticar"
        [class.access-lev-widget]="as_citroen && isAccess"
        [class.is-right-to-left]="isRightToLeft"
        [class.ap]="as_peugeot"
        [class.fo]="as_fiat"
        [class.ds]="as_ds"
        [class.vx]="as_vauxhall"
        [class.ov]="as_opel"
      >
        <form>
          <!-- Question's form 1 -->
          <div
            class="row lev-as-form-question lev-as-form-question-1"
            [class.ac]="as_citroen"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.ds]="as_ds"
            [class.isNewOpelStyle]="isNewOpelStyle"
            [class.spoticar]="as_spoticar"
            [class.vx]="as_vauxhall"
            [class.ov]="as_opel"
          >
            <div
              class="row ie-ds lev-as-form-question-statment"
              [class.ac]="as_citroen"
              [class.ap]="as_peugeot"
              [class.fo]="as_fiat"
              [class.ds]="as_ds"
              [class.isNewOpelStyle]="isNewOpelStyle"
              [class.spoticar]="as_spoticar"
              [class.vx]="as_vauxhall"
              role="status"
              [class.is-right-to-left]="isRightToLeft"
              [attr.aria-label]="labelCulture.as_conduct"
              [class.ov]="as_opel"
            >
              {{ labelCulture.as_conduct }}
            </div>
            <div
              class="row row-as-answers-q1 lev-as-form-question-answers"
              [class.opened]="opened"
              [class.ac]="as_citroen"
              [class.ap]="as_peugeot"
              [class.fo]="as_fiat"
              [class.spoticar]="as_spoticar"
              [class.ds]="as_ds"
              [class.is-right-to-left]="isRightToLeft"
              [class.vx]="as_vauxhall"
              [class.ov]="as_opel"
            >
              <button
                class="lev-as-form-question-answer col-md-4 col-lg-4"
                *ngFor="let conduct of conducts; let i = index"
                (click)="selectConduct(conduct.key)"
                [class.selected]="conduct.key == driving_style"
                [class.ac]="as_citroen"
                [class.isNewOpelStyle]="isNewOpelStyle"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.spoticar]="as_spoticar"
                [class.ds]="as_ds"
                [class.is-right-to-left]="isRightToLeft"
                [class.vx]="as_vauxhall"
                [class.ov]="as_opel"
                [attr.aria-label]="conduct.value"
              >
                <div
                  *ngIf="as_spoticar && isRightToLeft"
                  class="chevron-spoticar"
                  [class.rotate]="opened"
                ></div>
                <img
                  *ngIf="!as_spoticar && isRightToLeft"
                  [class.vx]="as_vauxhall"
                  [class.ac]="as_citroen"
                  [class.spoticar]="as_spoticar"
                  [class.ap]="as_peugeot"
                  [class.fo]="as_fiat"
                  [class.ds]="as_ds"
                  [src]="opened ? cssData.chevron_off : cssData.chevron"
                  alt=""
                />
                {{ conduct.value }}
                <img
                  *ngIf="!as_spoticar && !isRightToLeft"
                  [class.vx]="as_vauxhall"
                  [class.ac]="as_citroen"
                  [class.spoticar]="as_spoticar"
                  [class.ap]="as_peugeot"
                  [class.fo]="as_fiat"
                  [class.ds]="as_ds"
                  [src]="opened ? cssData.chevron_off : cssData.chevron"
                  alt=""
                />
                <div
                  *ngIf="as_spoticar && !isRightToLeft"
                  class="chevron-spoticar"
                  [class.rotate]="opened"
                ></div>
                <div *ngIf="as_vauxhall" [class.vx-after]="as_vauxhall"></div>
              </button>
            </div>
          </div>
          <!--  -->

          <div
            class="row-adaptatio row"
            [class.ac]="as_citroen"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.spoticar]="as_spoticar"
            [class.is-right-to-left]="isRightToLeft"
            [class.ds]="as_ds"
            [class.vx]="as_vauxhall"
            [class.ov]="as_opel"
          >
            <!-- Question's form 2 -->
            <div
              class="col-lg-6 col-md-6 lev-as-form-question lev-as-form-question-2 half"
              [class.ac]="as_citroen"
              [class.ap]="as_peugeot"
              [class.fo]="as_fiat"
              [class.isNewOpelStyle]="isNewOpelStyle"
              [class.spoticar]="as_spoticar"
              [class.is-right-to-left]="isRightToLeft"
              [class.ds]="as_ds"
              [class.vx]="as_vauxhall"
              [class.ov]="as_opel"
            >
              <div
                class="lev-as-form-question-statment"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.spoticar]="as_spoticar"
                [class.isNewOpelStyle]="isNewOpelStyle"
                [class.is-right-to-left]="isRightToLeft"
                [class.ds]="as_ds"
                [class.vx]="as_vauxhall"
                role="status"
                [attr.aria-label]="labelCulture.as_speed"
                [class.ov]="as_opel"
              >
                {{ labelCulture.as_speed }}
              </div>
              <!-- Plus/Minus Readonly Input -->
              <div
                class="lev-as-form-question-answer"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.spoticar]="as_spoticar"
                [class.ds]="as_ds"
                [class.vx]="as_vauxhall"
                [class.is-right-to-left]="isRightToLeft"
                [class.ov]="as_opel"
              >
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'PHEV'"
                  role="button"
                  [attr.aria-label]="
                    labelCulture.as_speed +
                    ' decrease ' +
                    vitesse.value +
                    labelCulture.as_speed_unit
                  "
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  class="minus"
                  (click)="decreasev('vitesse')"
                >
                  -
                  <!--                  <div *ngIf="as_d1 && !isIEOrEdge() && !as_spoticar; else minus" class="minusImg"></div>-->
                  <!--                  <ng-template #minus>-</ng-template>-->
                </button>
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'BEV'"
                  role="button"
                  [attr.aria-label]="
                    labelCulture.as_speed +
                    ' decrease ' +
                    vitesse.value +
                    labelCulture.as_speed_unit
                  "
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  class="minus"
                  (click)="decrease('vitesse')"
                >
                  -
                  <!--                  <div *ngIf="as_d1 && !isIEOrEdge() && !as_spoticar; else minus" class="minusImg"></div>-->
                  <!--                  <ng-template #minus>-</ng-template>-->
                </button>
                <div
                  role="status"
                  [attr.aria-label]="
                    vitesse.value + ' ' + labelCulture.as_speed_unit
                  "
                  class="input"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [class.is-right-to-left]="isRightToLeft"
                  name="vitesse"
                >
                  {{ vitesse.value + " " + labelCulture.as_speed_unit }}
                </div>
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'PHEV'"
                  role="button"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [attr.aria-label]="
                    labelCulture.as_speed +
                    ' increase ' +
                    vitesse.value +
                    labelCulture.as_speed_unit
                  "
                  class="plus"
                  (click)="increasev('vitesse')"
                >
                  +
                  <!--                  <div *ngIf="as_d1 && !isIEOrEdge() && !as_spoticar; else plus" class="plusImg"></div>-->
                  <!--                  <ng-template #plus>+</ng-template>-->
                </button>
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'BEV'"
                  role="button"
                  [attr.aria-label]="
                    labelCulture.as_speed +
                    ' increase ' +
                    vitesse.value +
                    labelCulture.as_speed_unit
                  "
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  class="plus"
                  (click)="increase('vitesse')"
                >
                  +
                  <!--                  <div *ngIf="as_d1 && !isIEOrEdge() && !as_spoticar; else plus" class="plusImg"></div>-->
                  <!--                  <ng-template #plus>+</ng-template>-->
                </button>
              </div>
              <!--  -->
            </div>

            <!-- Question's form 3 -->
            <div
              class="col-lg-6 col-md-6 lev-as-form-question lev-as-form-question-3 half"
              [class.ac]="as_citroen"
              [class.ap]="as_peugeot"
              [class.fo]="as_fiat"
              [class.spoticar]="as_spoticar"
              [class.isNewOpelStyle]="isNewOpelStyle"
              [class.is-right-to-left]="isRightToLeft"
              [class.ds]="as_ds"
              [class.vx]="as_vauxhall"
              [class.ov]="as_opel"
            >
              <div
                class="lev-as-form-question-statment"
                [class.gr]="country.toLowerCase() === 'gr' ? true : false"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.isNewOpelStyle]="isNewOpelStyle"
                [class.spoticar]="as_spoticar"
                [class.is-right-to-left]="isRightToLeft"
                [class.ds]="as_ds"
                [class.vx]="as_vauxhall"
                role="status"
                [attr.aria-label]="labelCulture.as_environment"
                [class.ov]="as_opel"
              >
                {{ labelCulture.as_environment }}
              </div>
              <!-- Plus/Minus Readonly Input -->
              <div
                class="lev-as-form-question-answer"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.spoticar]="as_spoticar"
                [class.ds]="as_ds"
                [class.vx]="as_vauxhall"
                [class.is-right-to-left]="isRightToLeft"
                [class.ov]="as_opel"
                *ngIf="
                  getTypeOfVehicle() === 'VP' ||
                  getTypeOfVehicle() === 'VPD' ||
                  getTypeOfVehicle() === 'VS' ||
                  getTypeOfVehicle() === 'VFOU'
                "
              >
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'PHEV'"
                  role="button"
                  [attr.aria-label]="
                    labelCulture.as_environment +
                    ' decrease ' +
                    temperature.value +
                    labelCulture.as_degree_unit
                  "
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  class="minus"
                  (click)="decreasev('temperature')"
                >
                  -
                  <!--                  <div *ngIf="as_d1 && !isIEOrEdge() && !as_spoticar; else minus" class="minusImg"></div>-->
                  <!--                  <ng-template #minus>-</ng-template>-->
                </button>
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'BEV'"
                  role="button"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  class="minus"
                  [attr.aria-label]="
                    labelCulture.as_environment +
                    ' decrease ' +
                    temperature.value +
                    labelCulture.as_degree_unit
                  "
                  (click)="decrease('temperature')"
                >
                  -
                  <!--                  <div *ngIf="as_d1 && !isIEOrEdge() && !as_spoticar; else minus" class="minusImg"></div>-->
                  <!--                  <ng-template #minus>-</ng-template>-->
                </button>
                <div
                  role="status"
                  [attr.aria-label]="
                    temperature.value + labelCulture.as_degree_unit
                  "
                  class="input"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [class.is-right-to-left]="isRightToLeft"
                  name="temperature"
                >
                  {{ temperature.value + labelCulture.as_degree_unit }}
                </div>
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'PHEV'"
                  role="button"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [attr.aria-label]="
                    labelCulture.as_environment +
                    ' increase ' +
                    temperature.value +
                    labelCulture.as_degree_unit
                  "
                  class="plus"
                  (click)="increasev('temperature')"
                >
                  +
                  <!--                  <div *ngIf="as_d1 && !isIEOrEdge() && !as_spoticar; else plus" class="plusImg"></div>-->
                  <!--                  <ng-template #plus>+</ng-template>-->
                </button>
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'BEV'"
                  role="button"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [attr.aria-label]="
                    labelCulture.as_environment +
                    ' increase ' +
                    temperature.value +
                    labelCulture.as_degree_unit
                  "
                  class="plus"
                  (click)="increase('temperature')"
                >
                  +
                </button>
              </div>
              <div
                class="lev-as-form-question-answer"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.spoticar]="as_spoticar"
                [class.ds]="as_ds"
                [class.vx]="as_vauxhall"
                [class.ov]="as_opel"
                [class.is-right-to-left]="isRightToLeft"
                *ngIf="
                  getTypeOfVehicle() === 'VU' && getTypeOfVehicle() !== 'VFOU'
                "
              >
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'BEV'"
                  role="button"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [attr.aria-label]="
                    labelCulture.as_environment +
                    ' decrease ' +
                    masse.value +
                    labelCulture.as_degree_unit
                  "
                  class="minus"
                  (click)="decrease('masse')"
                >
                  -
                </button>
                <div
                  class="input"
                  role="status"
                  [attr.aria-label]="masse.value + labelCulture.as_degree_unit"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [class.is-right-to-left]="isRightToLeft"
                  name="masse"
                >
                  {{ masse.value + labelCulture.as_degree_unit }}
                </div>
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'BEV'"
                  role="button"
                  [attr.aria-label]="
                    labelCulture.as_environment +
                    ' increase ' +
                    masse.value +
                    labelCulture.as_degree_unit
                  "
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  class="plus"
                  (click)="increase('masse')"
                >
                  +
                </button>
              </div>
              <!--  -->
            </div>
            <!--  -->
            <!-- Question's form 4 MASSE -->
            <div
              class="col-lg-6 col-md-6 lev-as-form-question lev-as-form-question-2"
              [class.ac]="as_citroen"
              [class.ap]="as_peugeot"
              [class.fo]="as_fiat"
              [class.isNewOpelStyle]="isNewOpelStyle"
              [class.spoticar]="as_spoticar"
              [class.is-right-to-left]="isRightToLeft"
              [class.ds]="as_ds"
              [class.vx]="as_vauxhall"
              [class.ov]="as_opel"
              *ngIf="getTypeOfVehicle() === 'VFOU' && display_payload"
            >
              <div
                class="lev-as-form-question-statment"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.spoticar]="as_spoticar"
                [class.isNewOpelStyle]="isNewOpelStyle"
                [class.is-right-to-left]="isRightToLeft"
                [class.ds]="as_ds"
                [class.vx]="as_vauxhall"
                role="status"
                [attr.aria-label]="PAYLOAD"
                [class.ov]="as_opel"
              >
                PAYLOAD
              </div>
              <!-- Plus/Minus Readonly Input -->
              <div
                class="lev-as-form-question-answer"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.spoticar]="as_spoticar"
                [class.ds]="as_ds"
                [class.vx]="as_vauxhall"
                [class.ov]="as_opel"
                [class.is-right-to-left]="isRightToLeft"
              >
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'BEV'"
                  role="button"
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [attr.aria-label]="
                    labelCulture.as_environment +
                    ' decrease ' +
                    payload.value +
                    labelCulture.as_payload_unit
                  "
                  class="minus"
                  (click)="decrease('payload')"
                >
                  -
                </button>
                <div
                  class="input"
                  role="status"
                  [attr.aria-label]="
                    payload.value + labelCulture.as_payload_unit
                  "
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  [class.is-right-to-left]="isRightToLeft"
                  name="payload"
                >
                  {{ payload.value + labelCulture.as_payload_unit }}
                </div>
                <button
                  *ngIf="vehicule.LEV_AS_TYPE == 'BEV'"
                  role="button"
                  [attr.aria-label]="
                    labelCulture.as_environment +
                    ' increase ' +
                    payload.value +
                    labelCulture.as_payload_unit
                  "
                  [class.isNewOpelStyle]="isNewOpelStyle"
                  class="plus"
                  (click)="increase('payload')"
                >
                  +
                </button>
              </div>
              <!--  -->
            </div>
          </div>
          <div
            class="row-adaptatio row"
            [class.ac]="as_citroen"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.spoticar]="as_spoticar"
            [class.ds]="as_ds"
            [class.vx]="as_vauxhall"
            [class.ov]="as_opel"
            *ngIf="asHasConso"
          ></div>
          <!--  -->
          <!-- Question's form 4 -->
          <div
            class="row lev-as-form-question lev-as-form-question-4 half row"
            [style.display]="
              vehicule.LEV_AS_TYPE == 'PHEV' && as_citroen ? 'none' : null
            "
            [class.ac]="as_citroen"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.isNewOpelStyle]="isNewOpelStyle"
            [class.spoticar]="as_spoticar"
            [class.ds]="as_ds"
            [class.ov]="as_opel"
            [class.vx]="as_vauxhall"
            [class.half]="as_citroen"
            [class.to-right]="!(as_ds || as_citroen)"
            [class.to-left]="as_ds || as_citroen"
            *ngIf="!display_pompe_chaleur"
          >
            <!-- Slider Toggle Tag for Climatisation -->
            <div
              class="lev-as-form-question-statment"
              [class.ac]="as_citroen"
              [class.ap]="as_peugeot"
              [class.fo]="as_fiat"
              [class.isNewOpelStyle]="isNewOpelStyle"
              [class.spoticar]="as_spoticar"
              [class.is-right-to-left]="isRightToLeft"
              [class.ds]="as_ds"
              [class.ov]="as_opel"
              role="status"
              [attr.aria-label]="
                labelCulture.as_air_conditioning +
                (vehicule.LEV_AS_TYPE == 'PHEV' && !as_citroen
                  ? ' (' + labelCulture.as_enable + ')'
                  : '')
              "
              [class.vx]="as_vauxhall"
            >
              {{
                labelCulture.as_air_conditioning +
                  (vehicule.LEV_AS_TYPE == "PHEV" && !as_citroen
                    ? " (" + labelCulture.as_enable + ")"
                    : "")
              }}
            </div>
            <app-slider-toggle
              [style.display]="vehicule.LEV_AS_TYPE == 'PHEV' ? 'none' : null"
              [ac]="as_citroen"
              [isNewOpelStyle]="isNewOpelStyle"
              [isd1]="as_d1"
              [ap]="as_peugeot"
              [ft]="as_fiat"
              [ds]="as_ds"
              [isspoticar]="as_spoticar"
              [as_vauxhall]="as_vauxhall"
              [isRightToLeft]="isRightToLeft"
              [ov]="as_opel"
              [country]="country"
              [checked]="air_conditioner == 1"
              (onCheck)="toggle($event)"
              [active]="labelCulture.as_enable"
              [vehsId]="vehicleid"
              [labelCulture]="labelCulture"
              [inactive]="labelCulture.as_disable"
            ></app-slider-toggle>
          </div>
          <div
            class="row lev-as-form-question lev-as-form-question-4 half row"
            [style.display]="
              vehicule.LEV_AS_TYPE == 'PHEV' && as_citroen ? 'none' : null
            "
            [class.ac]="as_citroen"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.isNewOpelStyle]="isNewOpelStyle"
            [class.spoticar]="as_spoticar"
            [class.ds]="as_ds"
            [class.ov]="as_opel"
            [class.vx]="as_vauxhall"
            [class.half]="as_citroen"
            [class.to-right]="!(as_ds || as_citroen)"
            [class.to-left]="as_ds || as_citroen"
            *ngIf="display_pompe_chaleur"
            [ngStyle]="{
              display: 'grid',
              'grid-template-columns': 'repeat(2, 1fr)',
              'grid-template-rows': '1fr',
              'grid-column-gap': 'calc(5%)'
            }"
          >
            <!-- Slider Toggle Tag for Climatisation -->
            <div
              [ngStyle]="{
                'max-width': 'max-content',
                'grid-area': '1 / 1 / 2 / 2'
              }"
            >
              <div
                class="lev-as-form-question-statment"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.isNewOpelStyle]="isNewOpelStyle"
                [class.spoticar]="as_spoticar"
                [class.is-right-to-left]="isRightToLeft"
                [class.ds]="as_ds"
                [class.ov]="as_opel"
                role="status"
                [attr.aria-label]="
                  labelCulture.as_air_conditioning +
                  (vehicule.LEV_AS_TYPE == 'PHEV' && !as_citroen
                    ? ' (' + labelCulture.as_enable + ')'
                    : '')
                "
                [class.vx]="as_vauxhall"
              >
                {{
                  labelCulture.as_air_conditioning +
                    (vehicule.LEV_AS_TYPE == "PHEV" && !as_citroen
                      ? " (" + labelCulture.as_enable + ")"
                      : "")
                }}
              </div>
              <app-slider-toggle
                [style.display]="vehicule.LEV_AS_TYPE == 'PHEV' ? 'none' : null"
                [ac]="as_citroen"
                [ft]="as_fiat"
                [isNewOpelStyle]="isNewOpelStyle"
                [isd1]="as_d1"
                [ap]="as_peugeot"
                [ds]="as_ds"
                [isspoticar]="as_spoticar"
                [as_vauxhall]="as_vauxhall"
                [isRightToLeft]="isRightToLeft"
                [ov]="as_opel"
                [country]="country"
                [checked]="air_conditioner == 1"
                (onCheck)="toggle($event)"
                [active]="labelCulture.as_enable"
                [vehsId]="vehicleid"
                [labelCulture]="labelCulture"
                [inactive]="labelCulture.as_disable"
                [air_conditioner]="air_conditioner"
              ></app-slider-toggle>
            </div>
            <!-- Slider Toggle Tag for Pompe -->
            <div
              [ngStyle]="{
                'max-width': 'max-content',
                'grid-area': '1 / 2 / 2 / 3'
              }"
            >
              <div
                class="lev-as-form-question-statment"
                [class.ac]="as_citroen"
                [class.ap]="as_peugeot"
                [class.fo]="as_fiat"
                [class.isNewOpelStyle]="isNewOpelStyle"
                [class.spoticar]="as_spoticar"
                [class.is-right-to-left]="isRightToLeft"
                [class.ds]="as_ds"
                [class.ov]="as_opel"
                role="status"
                [attr.aria-label]="
                  labelCulture.as_pompe_chaleur +
                  (vehicule.LEV_AS_TYPE == 'PHEV' && !as_citroen
                    ? ' (' + labelCulture.as_enable_pompe + ')'
                    : '')
                "
                [class.vx]="as_vauxhall"
              >
                {{
                  labelCulture.as_pompe_chaleur +
                    (vehicule.LEV_AS_TYPE == "PHEV" && !as_citroen
                      ? " (" + labelCulture.as_enable_pompe + ")"
                      : "")
                }}
              </div>
              <app-slider-toggle
                [style.display]="vehicule.LEV_AS_TYPE == 'PHEV' ? 'none' : null"
                [ac]="as_citroen"
                [style.opacity]="air_conditioner === 0 ? '0.5' : '1'"
                [isNewOpelStyle]="isNewOpelStyle"
                [isd1]="as_d1"
                [ap]="as_peugeot"
                [ds]="as_ds"
                [ft]="as_fiat"
                [isspoticar]="as_spoticar"
                [as_vauxhall]="as_vauxhall"
                [isRightToLeft]="isRightToLeft"
                [ov]="as_opel"
                [country]="country"
                [checked]="pompe_chaleur"
                (onCheck)="togglePompe($event)"
                [active]="labelCulture.as_enable_pompe || 'Yes'"
                [vehsId]="vehicleid"
                [labelCulture]="labelCulture"
                [inactive]="labelCulture.as_disable_pompe || 'No'"
                [air_conditioner]="air_conditioner"
                [disable_toggle]="air_conditioner === 0"
              ></app-slider-toggle>
            </div>
          </div>
        </form>
      </div>
      <!--  -->

      <!-- Result depending on the details filled in the form -->
      <div
        class="lev-as-result"
        [class.ac]="as_citroen"
        [class.ap]="as_peugeot"
        [class.fo]="as_fiat"
        [class.is-right-to-left]="isRightToLeft"
        [class.isNewOpelStyle]="isNewOpelStyle"
        [class.spoticar]="as_spoticar"
        [class.ds]="as_ds"
        [class.vx]="as_vauxhall"
        [class.ov]="as_opel"
      >
        <!-- Speed Box Tag -->
        <app-speed-box
          *ngIf="!deviceIsMobile()"
          [speed]="calculatedAutonomy"
          [speedS]="calculatedAutonomyTable[0]"
          [speedXl]="calculatedAutonomyTable[1]"
          [isRightToLeft]="isRightToLeft"
          [label]="
            calculatedAutonomyTable.length >= 2
              ? labelCulture.as_autonomy_in_charge2
              : labelCulture.as_autonomy_in_charge
          "
          [modeElectricLabel]="modeElectricLabel"
          [labelParoursEurope]="labelCulture.as_parcours_client_moyen_europeen"
          [levAsPicture]="vehicule.LEV_AS_Picture"
          [mentionsLegalForConsumption]="
            labelCulture.as_consumption_legale_mentions
          "
          [labelVehicle]="
            calculatedAutonomyTable.length > 1
              ? coupleLabel
              : vehicule.LEV_AS_label
          "
          [consumption]="calculatedConsumption"
          [consumptionS]="calculatedConsumptionTable[0]"
          [consumptionXl]="calculatedConsumptionTable[1]"
          [as_hybridModeConsumption]="labelCulture.as_hybrid_mode_consumption"
          [batteryS]="isBattey_l ? isBattey_l : labelCulture.as_battery_s"
          [batteryXl]="isBattey_xl ? isBattey_xl : labelCulture.as_battery_xl"
          [isd1]="as_d1"
          [isNewOpelStyle]="isNewOpelStyle"
          [isspoticar]="as_spoticar"
          [asHasConso]="asHasConso"
          [as_citroen]="as_citroen"
          [as_peugeot]="as_peugeot"
          [as_fiat]="as_fiat"
          [as_vauxhall]="as_vauxhall"
          [as_ds]="as_ds"
          [as_opel]="as_opel"
          [unit]="labelCulture.as_distance_unit_autonomy"
          [as_consoUnits]="labelCulture.as_conso_units"
        ></app-speed-box>
        <!--  -->
        <div class="autonomy-slider">
          <button
            *ngIf="isMultipleModels"
            class="slider-btn previews"
            [class.d1]="as_d1"
            [class.ac]="as_citroen"
            [class.spoticar]="as_spoticar"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.ds]="as_ds"
            [class.is-right-to-left]="isRightToLeft"
            [class.vx]="as_vauxhall"
            [class.ov]="as_opel"
            (click)="previewsVehicle()"
          >
            <div
              class="previews"
              [class.d1]="as_d1"
              [class.ac]="as_citroen"
              [class.spoticar]="as_spoticar"
              [class.ap]="as_peugeot"
              [class.fo]="as_fiat"
              [class.ds]="as_ds"
              [class.ov]="as_opel"
              [class.is-right-to-left]="isRightToLeft"
              [class.vx]="as_vauxhall"
            ></div>
          </button>
          <img
            *ngIf="
              (!asHasConso && !isNewOpelStyle) ||
              (!asHasConso && isNewOpelStyle && !deviceIsMobile())
            "
            class="lev-as-result-image"
            [src]="vehicule.LEV_AS_Picture"
            alt=""
            [class.ac]="as_citroen"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.spoticar]="as_spoticar"
            [class.ds]="as_ds"
            [class.vx]="as_vauxhall"
            [class.ov]="as_opel"
          />
          <button
            *ngIf="isMultipleModels"
            class="slider-btn next"
            [class.d1]="as_d1"
            [class.ac]="as_citroen"
            [class.spoticar]="as_spoticar"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.ds]="as_ds"
            [class.is-right-to-left]="isRightToLeft"
            [class.vx]="as_vauxhall"
            [class.ov]="as_opel"
            (click)="nextVehicle()"
          >
            <div
              class="next"
              [class.d1]="as_d1"
              [class.ac]="as_citroen"
              [class.spoticar]="as_spoticar"
              [class.ap]="as_peugeot"
              [class.fo]="as_fiat"
              [class.ds]="as_ds"
              [class.is-right-to-left]="isRightToLeft"
              [class.ov]="as_opel"
              [class.vx]="as_vauxhall"
            ></div>
          </button>
        </div>

        <div
          *ngIf="!asHasConso && !isNewOpelStyle"
          class="lev-as-result-name"
          [class.d1]="as_d1"
          [class.ac]="as_citroen"
          [class.spoticar]="as_spoticar"
          [class.ap]="as_peugeot"
          [class.fo]="as_fiat"
          [class.ds]="as_ds"
          [class.is-right-to-left]="isRightToLeft"
          [class.vx]="as_vauxhall"
          role="status"
          [attr.aria-label]="
            calculatedAutonomyTable.length > 1
              ? coupleLabel
              : vehicule.LEV_AS_label
          "
          [class.ov]="as_opel"
        >
          {{
            calculatedAutonomyTable.length > 1
              ? coupleLabel
              : vehicule.LEV_AS_label
          }}
        </div>
        <div
          class="autonomy-slider-cerles"
          [class.d1]="as_d1"
          [class.ac]="as_citroen"
          [class.spoticar]="as_spoticar"
          [class.ap]="as_peugeot"
          [class.fo]="as_fiat"
          [class.ds]="as_ds"
          [class.is-right-to-left]="isRightToLeft"
          [class.ov]="as_opel"
          *ngIf="isMultipleModels"
        >
          <div
            class="cercle-step"
            [class.d1]="as_d1"
            [class.ac]="as_citroen"
            [class.spoticar]="as_spoticar"
            [class.ap]="as_peugeot"
            [class.fo]="as_fiat"
            [class.ds]="as_ds"
            [class.is-right-to-left]="isRightToLeft"
            [class.ov]="as_opel"
            *ngFor="let i of vehiclesList; let index = index"
            [class.active]="index === vehicleIndex"
          ></div>
        </div>
      </div>

      <!--  -->
    </div>

    <!-- Speed placed on bottom for mobile -->
    <div
      class="lev-as-mobile-zone"
      [class.ac]="as_citroen"
      [class.ap]="as_peugeot"
      [class.fo]="as_fiat"
      [class.isNewOpelStyle]="isNewOpelStyle"
      [ngClass]="
        isspoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
      "
      [class.ds]="as_ds"
      [class.ov]="as_opel"
    >
      <app-speed-box
        *ngIf="deviceIsMobile()"
        [speed]="calculatedAutonomy"
        [isRightToLeft]="isRightToLeft"
        [label]="
          calculatedAutonomyTable.length >= 2
            ? labelCulture.as_autonomy_in_charge2
            : labelCulture.as_autonomy_in_charge
        "
        [modeElectricLabel]="modeElectricLabel"
        [levAsPicture]="vehicule.LEV_AS_Picture"
        [labelParoursEurope]="labelCulture.as_parcours_client_moyen_europeen"
        [labelVehicle]="
          calculatedAutonomyTable.length > 1
            ? coupleLabel
            : vehicule.LEV_AS_label
        "
        [mentionsLegalForConsumption]="
          labelCulture.as_consumption_legale_mentions
        "
        [as_citroen]="as_citroen"
        [as_peugeot]="as_peugeot"
        [as_fiat]="as_fiat"
        [as_ds]="as_ds"
        [isd1]="as_d1"
        [isNewOpelStyle]="isNewOpelStyle"
        [isspoticar]="as_spoticar"
        [consumption]="calculatedConsumption"
        [consumptionS]="calculatedConsumptionTable[0]"
        [consumptionXl]="calculatedConsumptionTable[1]"
        [as_hybridModeConsumption]="labelCulture.as_hybrid_mode_consumption"
        [asHasConso]="asHasConso"
        [speedS]="calculatedAutonomyTable[0]"
        [speedXl]="calculatedAutonomyTable[1]"
        [batteryS]="isBattey_l ? isBattey_l : labelCulture.as_battery_s"
        [batteryXl]="isBattey_xl ? isBattey_xl : labelCulture.as_battery_xl"
        [mobile]="deviceIsMobile()"
        [as_vauxhall]="as_vauxhall"
        [as_opel]="as_opel"
        [unit]="labelCulture.as_distance_unit_autonomy"
      ></app-speed-box>
    </div>
    <!--  -->

    <!-- Legal mention -->
    <div
      class="lev-legal-mention"
      [class.d1]="as_d1"
      [class.ac]="as_citroen"
      [class.ap]="as_peugeot"
      [class.fo]="as_fiat"
      [ngClass]="
        isspoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
      "
      [class.ds]="as_ds"
      [class.vx]="as_vauxhall"
      [class.ov]="as_opel"
      [class.is-right-to-left]="isRightToLeft"
      role="status"
      [attr.aria-label]="labelCulture.as_legale_mentions"
      *ngIf="displayML()"
    >
      {{ labelCulture.as_legale_mentions }}
    </div>
  </div>
</main>
