import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
  selector: "app-slider-toggle",
  templateUrl: "./slider-toggle.component.html",
  styleUrls: ["./slider-toggle.component.scss"],
})
export class SliderToggleComponent implements OnInit {
  @Input() active: string = "";
  @Input() inactive: string = "";
  @Input("isd1") isd1: boolean = false;
  @Input("isNewOpelStyle") isNewOpelStyle: boolean = false;
  @Input("isspoticar") isspoticar: boolean = false;
  @Input("ac") as_citroen: boolean = false;
  @Input("ap") as_peugeot: boolean = false;
  @Input("ft") as_fiat: boolean = false;
  @Input("ds") as_ds: boolean = false;
  @Input("ov") as_opel: boolean = false;
  @Input("isRightToLeft") isRightToLeft: boolean = false;
  @Input("country") country: string = "";
  @Input() as_vauxhall: boolean = false;
  @Input("vehsId") vehsId: string = "";
  @Input() checked: boolean = false;
  @Input("labelCulture") labelCulture: any;
  @Input("air_conditioner") air_conditioner: number;
  @Input() disable_toggle: boolean = false;
  @Output() onCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {}

  toggle(): void {
    if (this.air_conditioner === 0 && this.disable_toggle) {
      this.checked = false;
    } else {
      this.checked = !this.checked;
      // Emit the checked state
      this.onCheck.emit(this.checked);
    }
  }
}
