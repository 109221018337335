<button
  [class.AP]="cs_peugeot"
  [class.AC]="cs_citroen"
  [class.FT]="cs_fiat"
  [class.FO]="cs_fiatPro"
  [class.AR]="cs_alfaRomeo"
  [class.DS]="cs_ds"
  [class.JE]="cs_jeep"
  [ngClass]="{ 'discover-button': !isStyled, 'styled-button': isStyled }"
  (click)="navigateToUrl()"
>
  {{ buttonText }}
</button>
