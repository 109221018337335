import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { LevServiceService } from "src/app/services/lev-service.service";
import { CssData } from "src/app/models/css-data";
import { SearcheCriteria } from "src/app/models/searchCriteria";
import { Data } from "src/app/models/data";
import { API_CONFIG } from "src/assets/plugSurfing_params/api_url_config";
import { tec_labelCulture } from "../models/label_culture";
import { Autonomy } from "../models/autonomy";
import { addDynamicStyles } from "../utility/compatibility-test.d-styles";
import {
  split,
  tagPlanEvent,
  tagPlanKm,
  isIE,
  checkNavView,
  getLevDataByPro,
  filterIt,
} from "../utility/compatibility-test.utility";
import { TranslationsService } from "@lev/services/translations.service";
@Component({
  selector: "app-compatibility-test",
  templateUrl: "./compatibility-test.component.html",
  styleUrls: ["./compatibility-test.component.scss"],
})
export class CompatibilityTestComponent implements OnInit {
  tec_blk_q1: string;
  tec_blk_q2: string;
  tec_blk_q3: string;
  tec_blk_1: number;
  tec_blk_3: number;
  tec_q2_r1: string;

  @ViewChild("field", { read: ElementRef }) field: ElementRef;

  @Input()
  country: string;

  @Input()
  langage: string;

  @Input()
  brand: string;

  @Input()
  lcdv10: string;

  @Input()
  vehicleid: string;

  @Input()
  ispro: string;

  @Input()
  isd1: string;

  @Input()
  cta: string;
  autonomy: Autonomy[];
  labelCulture: tec_labelCulture = new tec_labelCulture();
  SearcheCriteria: SearcheCriteria = new SearcheCriteria();
  cssData: CssData;
  tec_peugeot: boolean = false;
  tec_citroen: boolean = false;
  tec_ds: boolean = false;
  tec_opel: boolean = false;
  isIE: boolean = false;
  tec_vauxhall: boolean = false;
  tec_blk_q1_show: boolean = true;
  tec_blk_q2_show: boolean = false;
  tec_blk_q3_show: boolean = false;
  tec_results_ok: boolean = false;
  dots: any = Array(3);
  results: any[] = [];
  vehIdsToChangeImges: any[] = [];
  isImgeChenged: boolean = false;
  vehIdsToChangeLabels: string[] = ["1PP1", "1GQO"];
  tec_q1_algo: boolean;
  tec_q2_algo: boolean;
  tec_res_1: boolean = false;
  tec_res_2: boolean = false;
  tec_res_3: boolean = false;
  tec_res_4: boolean = false;
  kmClicked: boolean = false;
  cta1: string;
  cta2: string;
  cta3: string;
  cta4: string;
  virtualPageURL: string;
  siteTypeLevel1 = "";
  mobileDesktop: string;
  mainStepIndicator: number;
  mainStepName: string;
  eventContinue: string;
  eventBack: string;
  eventRestart: string;
  eventCta: string;
  eventQst1: string;
  viewPort: any = window.matchMedia("(max-width: 700px)");
  temperature: any;
  choice: string = 1 + "";
  vehiculeLabel: string;
  vehiculeDistance: string;

  constructor(
    private levservice: LevServiceService,
    private data: Data,
    private translationsService: TranslationsService
  ) {
    this.cssData = new CssData();
  }
  ngOnInit() {
    //this.brand = "DS"; this.country = "CH"; this.langage = "de"; this.vehicleid = "1SD3";
    this.isIE = isIE();
    this.survey();
    this.someChecks();
    if (
      this.brand !== undefined &&
      this.country !== undefined &&
      this.langage !== undefined
    ) {
      this.getLevByBrandAndLcdv10();
      this.chargeSpecialQevaVehs();
      split(this,this.cta);
      this.showBrand();
      this.getLabelsByBrandAndLang();
      this.getCssByBrand();
      this.getcompatibility();
      addDynamicStyles();
    }
    this.checkNavView();
    this.virtualPageURL = "/testcompatibilité/typeroute";
    this.mainStepIndicator = 1;
    this.mainStepName = "type route";
    this.tec_blk_1 = 1;
    //for accessibility work
    this.tec_blk_q1 = "1";
    this.radioTag(1);
  }
  checkNavView() {
    this.mobileDesktop = checkNavView(this.viewPort);
  }
  someChecks() {
    if (!["false", "true"].includes(this.ispro)) this.ispro = "false";
    if (!["false", "true"].includes(this.isd1)) this.isd1 = "false";
    if (this.brand.toLocaleLowerCase() === "ap")
      this.isd1 = "true";
    if (this.langage.toLowerCase() === "no") this.langage = "nb";
  }
  survey() {
    if (this.data.storage) {
      this.SearcheCriteria = this.data.storage;
      this.country = this.SearcheCriteria.countryCode;
      this.langage = this.SearcheCriteria.langageCode;
      this.brand = this.SearcheCriteria.marque;
      this.cta = this.SearcheCriteria.cta;
      this.ispro = this.SearcheCriteria.isPro ? "true" : "false";
      this.isd1 =
        this.SearcheCriteria.isd1 === "true" ||
          this.brand.toLowerCase() === "ap"
          ? "true"
          : "false";
      this.vehicleid = this.SearcheCriteria.vehicleid;
      this.lcdv10 = this.SearcheCriteria.lcdv10
      ? this.SearcheCriteria.lcdv10
      : undefined;
    }
  }

  focusKm() {
    this.kmClicked = true;
  }

  showBrand() {
    const brands = {
      ap: { tec_peugeot: true },
      ac: { tec_citroen: true },
      ds: { tec_ds: true },
      ov: { tec_opel: true },
      vx: { tec_opel: true, tec_vauxhall: true },
    };

    const brandName = this.brand.toLowerCase();
    if (brands[brandName]) {
      Object.assign(this, brands[brandName]);
      if (brandName === "ov" && ["gb", "uk"].includes(this.country.toLowerCase())) {
        this.tec_vauxhall = true;
      }
    }
  }

  chargeSpecialQevaVehs() {
    //let nameFile = "special_qeva_vehs/special_vehs";
    let nameFile = "levdata/levdata-tec/levdata_" + this.country.toLowerCase() + "_" + this.langage.toLowerCase();
    this.levservice.getData(nameFile).subscribe((data) => {
      this.vehIdsToChangeImges = data["vehs"];
      Object.values(data["vehs"]).forEach((key: any) => {
        if (key["LEV_TEC_LCDV"] === this.vehicleid ) {
          this.vehiculeLabel = key["LEV_TEC_label"];
          this.vehiculeDistance = key["wltp"];
        }
      });
    });
  }
  getLevByBrandAndLcdv10() {
    const nameFile =
      "levdata/levdata-tec/levdata_" +
      this.country.toLowerCase() +
      "_" +
      this.langage.toLowerCase();
    this.levservice.getData(nameFile).subscribe((data) => {
      if (!this.vehicleid)
        data["vehs"].forEach((element) => {
          if (element.LEV_TEC_BRAND === this.brand.toLowerCase()) {
            if (
              element.LEV_TEC_LCDV10 !== undefined &&
              element.LEV_TEC_LCDV10 !== null
            ) {
              if (element.LEV_TEC_LCDV10.includes(this.lcdv10)) {
                this.vehicleid = element.LEV_TEC_LCDV;
                console.log(this.vehicleid)
              }
            }
          }
        });
      this.showBrand();
      this.getLabelsByBrandAndLang();
      this.getCssByBrand();
    });
  }
  getCssByBrand() {
    let nameFile = "css/css_us_" + this.brand.toLowerCase();
    this.levservice.getData(nameFile).subscribe((data) => {
      this.cssData = data;
      this.changeImgveh();
      if (!this.isImgeChenged) {
        this.cssData.img_top_md =
          API_CONFIG.server_url + this.cssData.img_top_md;
        this.cssData.img_top_xs =
          API_CONFIG.server_url + this.cssData.img_top_xs;
      }
      this.cssData.img_picto = API_CONFIG.server_url + this.cssData.img_picto;
      this.cssData.marker_icon_plugsurfing =
        API_CONFIG.server_url + this.cssData.marker_icon_plugsurfing;
      this.cssData.marker_icon_tomtom =
        API_CONFIG.server_url + this.cssData.marker_icon_tomtom;
      this.cssData.loader = API_CONFIG.server_url + this.cssData.loader;
      this.cssData.arrow_black =
        API_CONFIG.server_url + this.cssData.arrow_black;
      this.cssData.arrow_white =
        API_CONFIG.server_url + this.cssData.arrow_white;
      this.cssData.arrow_actif_right =
        API_CONFIG.server_url + this.cssData.arrow_actif_right;
      this.cssData.arrow_actif_left =
        API_CONFIG.server_url + this.cssData.arrow_actif_left;
      this.cssData.arrow_disabled_right =
        API_CONFIG.server_url + this.cssData.arrow_disabled_right;
      this.cssData.arrow_disabled_left =
        API_CONFIG.server_url + this.cssData.arrow_disabled_left;
      this.cssData.arrow_white_opposite =
        API_CONFIG.server_url + this.cssData.arrow_white_opposite;
      this.cssData.arrow_grey = API_CONFIG.server_url + this.cssData.arrow_grey;
    });
  }

  changeImgveh() {
    this.vehIdsToChangeImges.forEach((vehi) => {
      if (this.vehicleid === vehi.LEV_TEC_LCDV) {
        this.cssData.img_top_md = API_CONFIG.server_url + vehi.LEV_TEC_Picture;
        this.cssData.img_top_xs = API_CONFIG.server_url + vehi.LEV_TEC_Picture;
        /*this.cssData.img_top_md = API_CONFIG.server_url + vehi.img_top_md;
        this.cssData.img_top_xs = API_CONFIG.server_url + vehi.img_top_xs;*/
        this.isImgeChenged = true;
      }
    });
  }
  changeVariables() {
    let keys = filterIt(this.labelCulture, 'VEHICULELABELVARIABLE');
    keys.forEach((key) => {
      this.labelCulture[key] = this.labelCulture[key].replace(/VEHICULELABELVARIABLE/g, this.vehiculeLabel);
    });
    let distancekeys = filterIt(this.labelCulture, 'AUTONOMYVARIABLE');
    distancekeys.forEach((key) => {
      this.labelCulture[key] = this.labelCulture[key].replace(/AUTONOMYVARIABLE/g, this.vehiculeDistance);
    });
  }

  /*getLabelsByBrandAndLang() {
    let nameFile = "labels_tec/labels_tec_" + this.brand.toLowerCase();
    if (this.vehIdsToChangeLabels.includes(this.vehicleid))
      nameFile += "_" + this.vehicleid;
    this.levservice.getData(nameFile).subscribe((data) => {
        this.labelCulture =
          data[
          this.langage.toLowerCase() + "-" + this.country.toLowerCase()
          ];
      this.changeVariables();
    });
    //this.changeVariables();
    // try {
    //   this.translationsService
    //     .getTranslationsByWidgetAndBrandAndCulture(
    //       "QEVA",
    //       this.brand.toUpperCase(),
    //       this.country.toUpperCase(),
    //       this.langage.toLowerCase()
    //     )
    //     .then((data) => {
    //       if (!data.message) this.labelCulture = data.values;
    //       else
    //         this.levservice.getData(nameFile).subscribe((data) => {
    //           this.labelCulture =
    //             data[
    //               this.langage.toLowerCase() + "-" + this.country.toLowerCase()
    //             ];
    //         });
    //         this.changeVariables();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  }*/
  getLabelsByBrandAndLang() {
    let nameFile = "labels_tec/labels_tec_" + this.brand.toLowerCase();
    if (this.vehIdsToChangeLabels.includes(this.vehicleid))
      nameFile += "_" + this.vehicleid;
    try {
      this.translationsService
        .getTranslationsByWidgetAndBrandAndCulture(
          "QEVA",
          this.brand.toUpperCase(),
          this.country.toUpperCase(),
          this.langage.toLowerCase()
        )
        .then((data) => {
          if (!data.message) {this.labelCulture = data.values; console.log(this.labelCulture)}
          else
            this.levservice.getData(nameFile).subscribe((data) => {
              this.labelCulture =
                data[
                  this.langage.toLowerCase() + "-" + this.country.toLowerCase()
                ];

            });
            this.changeVariables();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  getcompatibility() {
    let nameFile = "levdata/levdata-tec/levdata_" + this.country.toLowerCase() + "_" + this.langage.toLowerCase();
    this.levservice.getData(nameFile).subscribe((data) => {
      this.isProVehicleId(this.vehicleid, data["vehs"]);
      data["vehs"] = getLevDataByPro(data["vehs"], this.ispro);
      this.autonomy = data["vehs"];
      this.temperature = data["temperature"];
    });
  }
  // if pro vehicle id is selected without giving isPro flag
  // in scrip or it is equals to false then change isPro to true
  isProVehicleId(vehicleid, vehicles) {
    if (vehicleid) {
      vehicles.forEach((vehicle) => {
        if (vehicle.LEV_TEC_LCDV === vehicleid && vehicle.isPro === "true") {
          this.ispro = "true";
        }
      });
    }
  }
  continue() {
    if (this.tec_blk_q1_show == true) {
      console.log('Question 1 is true')
      this.tec_blk_q1_show = false;
      this.tec_blk_q2_show = true;
      this.tec_blk_q3_show = false;
      this.tec_results_ok = false;
      this.virtualPageURL = "/testcompatibilité/kmparcourus";
      this.mainStepIndicator = 2;
      this.mainStepName = "distance parcourue";
      this.eventContinue = "Step" + this.mainStepIndicator + "::Continue";
      tagPlanEvent(this.eventContinue);
    } else if (this.tec_blk_q2_show == true) {
      //this is for accessibility work
      this.tec_blk_q3 = "5";
      this.radioTag(5);
      console.log('Question 2 is true')
      this.tec_blk_q1_show = false;
      this.tec_blk_q2_show = false;
      this.tec_blk_q3_show = true;
      this.tec_results_ok = false;
      this.virtualPageURL = "/testcompatibilité/dispositionbornes";
      this.mainStepIndicator = 3;
      this.mainStepName = "bornes à disposition";
      this.eventContinue = "Step" + this.mainStepIndicator + "::Continue";
      tagPlanEvent(this.eventContinue);
      if (this.tec_q2_r1) tagPlanKm();
    }
  }

  save() {
    console.log('im in save function', this.tec_blk_q1_show, this.tec_blk_q2_show, this.tec_results_ok)
    
    this.algo();
    this.tec_blk_q3_show = false;
    this.tec_results_ok = true;
    this.tec_blk_q1 = null;
    this.tec_q2_r1 = "";
    this.tec_blk_q3 = null;
    this.virtualPageURL = "/testcompatibilité/résultas";
    this.mainStepIndicator = 4;
    this.mainStepName = "résultas";
    this.eventContinue = "Step" + this.mainStepIndicator + "::Continue";
    tagPlanEvent(this.eventContinue);
  }
  algo() {
    const filteredAutonomy = this.autonomy.filter(
      element =>element.LEV_TEC_LCDV.toLowerCase() === this.vehicleid.toLowerCase() && element.type
    );

    this.processFilteredAutonomy(filteredAutonomy, 0);
    this.tec_q2_algo = this.tec_blk_q3 == "1";
    //(this.tec_blk_q3 ? this.tec_blk_q3.toLowerCase() : "") == "1";
    this.tecAlgoCalculator();
  }
  processFilteredAutonomy(arr, index) {
    if (index >= arr.length) {
      return;
    }
    const element = arr[index];
    const val = element.type.find(val => this.tec_blk_q1 === val.id);
    if (val) {
      this.tec_q1_algo = parseInt(this.tec_q2_r1) > parseInt(val.distance);
    }
    this.processFilteredAutonomy(arr, index + 1);
  }

  tecAlgoCalculator() {
    const mapping = {
      "true,true": ["cta3", false, false, true, false],
      "true,false": ["cta4", false, false, false, true],
      "false,true": ["cta2", false, true, false, false],
      "false,false": ["cta1", true, false, false, false]
    };
    const key = `${this.tec_q1_algo},${this.tec_q2_algo}`;
    const [cta, val1, val2, val3, val4] = mapping[key];
    this.algoFastConf(val1, val2, val3, val4, cta);
  }

  algoFastConf(tec_res_1, tec_res_2, tec_res_3, tec_res_4, cta) {
    this.tec_res_1 = tec_res_1;
    this.tec_res_2 = tec_res_2;
    this.tec_res_3 = tec_res_3;
    this.tec_res_4 = tec_res_4;
    split(this,this[cta]);
    this.eventCta = this[cta];
  }
  back() {
    if (this.tec_blk_q3_show == true) {
      this.virtualPageURL = "/testcompatibilité/kmparcourus";
      this.mainStepIndicator = 2;
      this.mainStepName = "distance parcourue";
      this.eventBack = "Step" + this.mainStepIndicator + "::back";
      tagPlanEvent(this.eventBack);
      this.tec_blk_q3_show = false;
      this.tec_blk_q2_show = true;
      this.tec_blk_q1_show = false;
    } else if (this.tec_blk_q2_show == true) {
      this.virtualPageURL = "/testcompatibilité/typeroute";
      this.mainStepIndicator = 1;
      this.mainStepName = "type route";
      this.eventBack = "Step" + this.mainStepIndicator + "::back";
      tagPlanEvent(this.eventBack);
      this.tec_blk_q2_show = false;
      this.tec_blk_q3_show = false;
      this.tec_blk_q1_show = true;
    } else if (this.tec_results_ok == true) {
      this.virtualPageURL = "/testcompatibilité/dispositionbornes";
      this.mainStepIndicator = 3;
      this.mainStepName = "bornes à disposition";
      this.eventBack = "Step" + this.mainStepIndicator + "::back";
      tagPlanEvent(this.eventBack);
      this.tec_results_ok = false;
      this.tec_blk_q3_show = true;
      this.tec_blk_q2_show = false;
    }
  }
  restart() {
    this.kmClicked = false;
    this.tec_results_ok = false;
    this.tec_blk_q3_show = false;
    this.tec_blk_q2_show = false;
    this.tec_blk_q1_show = true;
    this.virtualPageURL = "/testcompatibilité/typeroute";
    this.mainStepIndicator = 1;
    this.mainStepName = "type route";
    this.eventRestart = "Step" + this.mainStepIndicator + "::restart";
    tagPlanEvent(this.eventRestart);
    //for accessibility work
    this.tec_blk_q1 = "1";
    this.radioTag(1);
  }

  ctaTag(link) {
    tagPlanEvent(this.eventCta);
    window.open(link, "_blank");
  }

  radioTag(nbr) {
    this.tec_blk_1 = nbr;
    this.tec_blk_3 = nbr;
    this.get_tec_blk_q();
    tagPlanEvent(this.eventQst1);
  }

  kmTag() {
    if (this.tec_q2_r1) tagPlanKm();
  }

  get_tec_blk_q() {
    let tableOfQuestions: string[] = [
      "",
      "urbain",
      "Rurales",
      "Autoroutes",
      "Plusieurs types",
      "Oui",
      "Non",
      "Je ne sais pas",
    ];
    tableOfQuestions.forEach((element, index) => {
      if ([this.tec_blk_1, this.tec_blk_3].includes(index)) {
        this.eventQst1 = element;
      }
    });
  }
}