<div id="page" [class.AC]="us_citroen" [class.AP]="us_peugeot" [class.DS]="us_ds" [class.SPOTICAR]="cs_spoticar"
    [class.OV]="us_opel" [class.VX]="us_vauxhall">
    <div class="searchContainer" [class.AC]="us_citroen" [class.DS]="us_ds" [class.AP]="us_peugeot"
        [class.SPOTICAR]="cs_spoticar" [class.OV]="us_opel" [class.VX]="us_vauxhall">
        <div id="autocomplete-container" [class.AC]="us_citroen" [class.DS]="us_ds" [class.SPOTICAR]="cs_spoticar"
            [class.AP]="us_peugeot" [class.OV]="us_opel" [class.VX]="us_vauxhall">
            <input type="text" id="autocomplete-input" placeholder="Search an address..." autocomplete="list"
                autocomplete="off" />
            <ul id="suggestions-list"></ul>
        </div>
    </div>
    <div class="ChargersContainer">
        <div class="charger" *ngFor="let charger of typesOfChargers" [class.AC]="us_citroen" [class.DS]="us_ds"
            [class.AP]="us_peugeot" [class.SPOTICAR]="cs_spoticar" [class.OV]="us_opel" [class.VX]="us_vauxhall">
            <input type="checkbox" (change)="onSelectionChange(charger, $event)" [value]="charger.value" />
            <img class="chargerimage" [src]="charger.img" alt="{{ charger.title }}" />
            {{ charger.title }}
        </div>
    </div>
    <button class="submit-btn" [class.AC]="us_citroen" [class.AP]="us_peugeot" [class.DS]="us_ds"
        [class.SPOTICAR]="cs_spoticar" [class.OV]="us_opel" [class.VX]="us_vauxhall" (click)="onSubmit()">
        Submit
    </button>
</div>


<div *ngIf="this.loaderService.getLoading()" class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
</div>

<div #mapContainer class="mapContainer"></div>
