import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { CountryInformation } from "src/app/models/countryInformation";
import { SearcheCriteria } from "src/app/models/searchCriteria";
import { LevServiceService } from "../services/lev-service.service";
import { Data } from "../models/data";
import { Vehicule } from "@lev/models/vehicule";
import { BrandMvsspe } from "@lev/interfaces/brandmvsspe.inteface";
import { Lcdv10Iterface } from "@lev/interfaces/lcdv10Iterface.interface";
import { CountryInterface } from "@lev/interfaces/countryInterface.interface";
import { LanguageInterface } from "@lev/interfaces/languageInterface.interface";

interface Couple {
  // Define the specific keys and their types
  key1: string;
  key2: string;
  key3: string;
  // Add more keys if needed
}

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent implements OnInit {
  vehs: any = [];
  sideBarOpen = true;
  vehicleId: any;
  lcdv10WithLabel: Lcdv10Iterface[] = [];
  km: string[] = [];
  name: string[] = [];
  params: any = [];
  title = "LEV Standalone";
  logo_image = "assets/shared/logolev.png";
  subtitle =
    " La version Stand-alone (SA) permet de tester et confirmer la présence des voitures sur LEV widgets ainsi que leur disponibilité sur les différents pays et les marques du groupe Stellantis.";
  //to be organised later ...
  brandmvsspe: BrandMvsspe[] = [
    { label: "category M1 - passenger BEV", code: "000000000000" },
    { label: "category M2 - BEV commercial vehicles", code: "999999999999" },
    { label: "category JJ – Passenger PHEV", code: "555555555555" },
  ];
  languages: LanguageInterface[] = [];
  countries: CountryInterface[] = [];
  marques: string[] = [];
  listCountry: Array<CountryInformation> = [];
  searchCriteria: SearcheCriteria = new SearcheCriteria();
  vehicule: Vehicule = new Vehicule();
  usBloc: boolean = false;
  tecBloc: boolean = false;
  csBloc: boolean = false;
  asBloc: boolean = true;
  savBloc: boolean = false;
  ecoBloc: boolean = false;
  radioValue: number = 1;
  isSpoticar: boolean = false;
  open: boolean = true;
  TogeleOpen: boolean = true;
  MainOpen: boolean = false;
  [key: string]: any;
  constructor(
    private router: Router,
    private levservice: LevServiceService,
    private data: Data
  ) {}

  ngOnInit() {
    this.pushCoutries();
    this.pushBrands();
    this.showBrands();
  }

  pushCoutries() {
    this.levservice.getCountryinformation().subscribe((data) => {
      this.listCountry = data;
      this.listCountry.forEach((element) => {
        this.countries.push({
          code: element.countryCode,
          labelle: element.coutryLabel,
        });
      });
      this.countries.sort((a, b) =>
        a.labelle.toLowerCase().localeCompare(b.labelle.toLowerCase())
      );
    });
  }
  openMenu() {
    this.sideBarOpen = !this.sideBarOpen;
    this.MainOpen = !this.MainOpen;
    this.TogeleOpen = !this.TogeleOpen;
  }

  pushBrands() {
    let nameFile = "levdata/levdata-" + this.checkWidget() + "/levdata_fr_fr";
    this.levservice.getData(nameFile).subscribe((data) => {
      this.vehs = data["vehs"];
      // if (this.ucBloc) this.params = data["Params"];
      this.vehs.forEach((veh: any) => {
        let LEV_BRAND = Object.keys(veh);
        if (this.marques.indexOf(veh[LEV_BRAND[0]]) == -1)
          this.marques.push(veh[LEV_BRAND[0]]);
      });
      this.marques.sort();
      this.psuhLcdv10WithLabel();
    });
  }
  marqueChanged() {
    this.vehs = [];
    let nameFile = "levdata/levdata-" + this.checkWidget() + "/levdata_fr_fr";
    let marque = this.searchCriteria.marque
      ? this.searchCriteria.marque.toLowerCase()
      : null;
    this.levservice.getData(nameFile).subscribe((data) => {
      // if (this.ucBloc) this.params = data["Params"][0];
      this.pushVehicles(data, marque);
      if (undefined !== this.searchCriteria.marque) this.psuhLcdv10WithLabel();
    });
    if (null !== this.searchCriteria.countryCode) this.selectLangage();
  }

  pushVehicles(data: any, marque: any) {
    if (undefined !== marque && marque) {
      data["vehs"].forEach((element: any) => {
        var LEV_BRAND = Object.keys(element);
        if (element[LEV_BRAND[0]] == marque) {
          this.vehs.push(element);
        }
      });
    } else this.vehs = data["vehs"];
  }

  containsLCDV10() {
    return this.asBloc || this.csBloc || this.usBloc || this.tecBloc;
  }

  psuhLcdv10WithLabel() {
    if (this.containsLCDV10() && this.searchCriteria.marque) {
      this.lcdv10WithLabel = [];
      const lcdvKeyByWidget =
        "LEV_" + this.checkWidget().toUpperCase() + "_LCDV";
      this.vehs.forEach((element: any) => {
        var LEV_KEYS = Object.keys(element),
          LEV_LCDV,
          LEV_LCDV10,
          LEV_LABEL,
          LEV_BRAND;
        this.optimizedValuesCollection(LEV_KEYS, lcdvKeyByWidget, element);
      });
    }
  }

  optimizedValuesCollection(LEV_KEYS: any, lcdvKeyByWidget: any, element: any) {
    var LEV_LCDV, LEV_LCDV10, LEV_LABEL, LEV_BRAND;
    Object.keys(LEV_KEYS).forEach((key) => {
      let LEV_PARAMS = LEV_KEYS[key];
      if (LEV_PARAMS === lcdvKeyByWidget) LEV_LCDV = LEV_PARAMS;
      else if (LEV_PARAMS.includes("LCDV10")) LEV_LCDV10 = LEV_PARAMS;
      if (LEV_PARAMS.includes("BRAND")) LEV_BRAND = LEV_PARAMS;
      if (LEV_PARAMS.includes("label")) LEV_LABEL = LEV_PARAMS;
    });
    this.chargeLcdv10(element, LEV_BRAND, LEV_LCDV, LEV_LCDV10, LEV_LABEL);
  }

  chargeLcdv10(
    element: any,
    LEV_BRAND: any,
    LEV_LCDV?: any,
    LEV_LCDV10?: any,
    LEV_LABEL?: any
  ) {
    if (
      element[LEV_BRAND] == this.searchCriteria.marque.toLowerCase() &&
      element[LEV_LCDV10]
    ) {
      element[LEV_LCDV10].forEach((lcdv10: any) => {
        let vehicleToPush = { lcdv10: lcdv10, lcdv10Label: element[LEV_LABEL] };
        if (!this.vehicleId) this.lcdv10WithLabel.push(vehicleToPush);
        else if (this.vehicleId === element[LEV_LCDV]) {
          this.lcdv10WithLabel.push(vehicleToPush);
        }
      });
    }
  }
  lcdv10Change(lcdv10Value: string) {
    this.searchCriteria.lcdv10 = lcdv10Value;
  }
  mlcdvChange(mlcdv: string) {
    this.searchCriteria.mlcdv = mlcdv;
  }
  showBrands() {
    this.levservice.getAll().subscribe((data) => {
      this.searchCriteria.marque = data["vehs"].LEV_US_BRAND;
    });
  }
  brandmvsspeChange(value: any) {
    this.searchCriteria.brandmvsspe = value;
  }
  checkWidget() {
    let widgetTable = ["us", "as", "cs", "uc", "sav", "eco", "tec"];
    var widgetValue = "us";
    widgetTable.forEach((widget) => {
      if (this[widget + "Bloc"]) widgetValue = widget;
    });
    return widgetValue;
  }

  vehiculeChanged(v: any) {
    for (let i = 0; i < this.vehs.length; i++) {
      if (
        this.vehs[i].LEV_UC_LCDV_VT == v ||
        this.vehs[i].LEV_UC_LCDV_VE == v
      ) {
        this.searchCriteria.priceVT = this.vehs[i].LEV_UC_PRICE_VT;
        this.searchCriteria.priceVE = this.vehs[i].LEV_UC_PRICE_VE;
        this.searchCriteria.vehicleVE = this.vehs[i].LEV_UC_LCDV_VE;
        this.searchCriteria.vehicleVT = this.vehs[i].LEV_UC_LCDV_VT;
        this.searchCriteria.carbPrice = this.params.LEV_UC_FUEL_PRICE;
        this.searchCriteria.energyPrice = this.params.LEV_UC_ENERGY_PRICE;
        this.searchCriteria.localBring = this.params.LEV_UC_LOCAL_INITIAL_BRING;
        this.searchCriteria.localBonus =
          this.params.LEV_UC_LOCAL_BONUS_PURCHASE;
        break;
      }
    }
  }

  /**
   * change isManual value
   * @param isManual
   */
  chargeManualForm(isManual: boolean) {
    if (isManual === true) this.searchCriteria.isManual = true;
    else this.searchCriteria.isManual = false;
  }

  //determine of change the value of isSpoticar
  setIsSpoticar(isSpoticar: string) {
    if (isSpoticar === "true") {
      this.searchCriteria.isspoticar = "true";
      this.isSpoticar = true;
    } else if (isSpoticar === "false") {
      this.searchCriteria.isspoticar = "false";
      this.isSpoticar = false;
    }
  }

  //filter vehicule based on professionel criteria
  getLevDataByPro(isPro: string) {
    if (isPro == "false") {
      this.vehs = this.vehs.filter((ele: { [x: string]: string }) => {
        const LEV_PRO: string =
          "LEV_" + this.checkWidget().toLocaleUpperCase() + "_Pro";
        return ele[LEV_PRO] != "true";
      });
    } else {
      if ("undefined" !== typeof this.searchCriteria.marque)
        this.marqueChanged();
    }
  }

  /**
   * recharge automatically 4x4 & 4x2 values correspondingly to
   * their values on levData associated file
   *
   * @param vehicleid
   */
  engineChange(vehicleid: any) {
    let countryCode = this.searchCriteria.countryCode.toLowerCase();
    let languageCode = this.searchCriteria.langageCode.toLowerCase();
    if (languageCode === "no") {
      this.searchCriteria.langageCode = "nb";
      languageCode = "nb";
    }
    var veh;
    if (this.asBloc) {
      veh = this.vehs.filter(
        (ele: { LEV_AS_LCDV: any }) => ele.LEV_AS_LCDV === vehicleid
      )[0];
    } else {
      const nameFile =
        "levdata/levdata-as/levdata_" + countryCode + "_" + languageCode;
      this.levservice.getData(nameFile).subscribe((data) => {
        veh = this.vehs.filter(
          (ele: { LEV_AS_LCDV: any }) => ele.LEV_AS_LCDV === vehicleid
        )[0];
      });
    }
    this.engineChangeAction(vehicleid);
    // if(!this.savBloc && !this.ecoBloc)this.check4x4Or4x2(veh);
  }

  engineChangeAction(vehicleid: string | undefined) {
    this.vehicleId = null;
    this.vehicleId = vehicleid;
    this.psuhLcdv10WithLabel();
  }

  selectLangage() {
    let data = this.listCountry;
    this.languages = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element.countryCode == this.searchCriteria.countryCode) {
        this.languages = element.languages;
        this.languages.sort((a, b) =>
          a.languageLabel
            .toLowerCase()
            .localeCompare(b.languageLabel.toLowerCase())
        );
        this.updateVehicleIdList(
          this.searchCriteria.countryCode,
          this.languages[0].languageCode
        );
        this.searchCriteria.langageCode = this.languages[0].languageCode;
        break;
      }
    }
    this.psuhLcdv10WithLabel();
  }

  btnClick() {
    this.data.storage = this.searchCriteria;
    this.router.navigate(["/usage-simulator"]);
  }

  btnClick2() {
    this.data.storage = this.searchCriteria;
    this.router.navigate(["/compatibility-test"]);
  }

  btnClick3() {
    this.data.storage = this.searchCriteria;
    this.router.navigate(["/charge-simulator"]);
  }

  btnClick4() {
    this.data.storage = this.searchCriteria;
    this.router.navigate(["/autonomy-simulator"]);
  }

  btnClick6() {
    this.data.storage = this.searchCriteria;
    console.log("hhhhhhh", this.data.storage, this.searchCriteria);
    this.router.navigate(["/saving-calculator"]);
  }

  btnClick7() {
    this.data.storage = this.searchCriteria;
    this.router.navigate(["/eco-advisor"]);
  }

  btnClick8() {
    this.data.storage = this.searchCriteria;
    this.router.navigate(["/free2move"]);
  }

  updateVehicleIdList(country = "", langue = "") {
    let languageCode =
      langue !== ""
        ? langue.toLocaleLowerCase()
        : this.searchCriteria.langageCode.toLocaleLowerCase();
    const countryCode =
      country !== ""
        ? country.toLocaleLowerCase()
        : this.searchCriteria.countryCode.toLocaleLowerCase();
    if (languageCode === "no") {
      this.searchCriteria.langageCode = "nb";
      languageCode = "nb";
    }
    var marqueCode = null;
    if (
      null !== this.searchCriteria.marque &&
      typeof this.searchCriteria.marque !== "undefined"
    ) {
      marqueCode = this.searchCriteria.marque.toLocaleLowerCase();
    }
    this.updateVehsList(marqueCode, countryCode, languageCode);
  }
  updateVehsList(
    marqueCode: string | null,
    countryCode: string,
    languageCode: string
  ) {
    if (this.validInputs(marqueCode, countryCode, languageCode)) {
      var nameFile =
        "levdata/levdata-" +
        this.checkWidget() +
        "/levdata_" +
        countryCode +
        "_" +
        languageCode;
      this.levservice.getData(nameFile).subscribe(
        (data) => {
          this.vehs = data["vehs"];
          this.filterVehsByBrand(marqueCode);
        },
        (error) => {
          this.levservice
            .getData(
              "levdata/levdata-us/levdata_" + countryCode + "_" + languageCode
            )
            .subscribe(
              (data) => {
                this.vehs = this.vehs.filter(
                  (ele: { LEV_US_BRAND: string | null }) =>
                    ele.LEV_US_BRAND === marqueCode
                );
              },
              (error) => {
                console.log(error);
              }
            );
        }
      );
      if (!["uc", "sav", "eco"].includes(this.checkWidget()))
        this.addCoupledVehs(marqueCode, countryCode, languageCode);
    }
  }
  filterVehsByBrand(marqueCode: string | null) {
    if (this.usBloc) {
      this.vehs = this.vehs.filter(
        (ele: { LEV_US_BRAND: string | null }) =>
          ele.LEV_US_BRAND === marqueCode
      );
    } else if (this.tecBloc) {
      this.vehs = this.vehs.filter(
        (ele: { LEV_TEC_BRAND: string | null }) =>
          ele.LEV_TEC_BRAND === marqueCode
      );
    } else if (this.ecoBloc) {
      this.vehs = this.vehs.filter(
        (ele: { LEV_ECO_BRAND: string | null }) =>
          ele.LEV_ECO_BRAND === marqueCode
      );
    } else if (this.savBloc) {
      this.vehs = this.vehs.filter(
        (ele: { LEV_SAV_BRAND: string | null }) =>
          ele.LEV_SAV_BRAND === marqueCode
      );
    } else {
      this.vehs = this.vehs.filter((ele: { [x: string]: string | null }) => {
        var LEV_BRAND = Object.keys(ele);
        return ele[LEV_BRAND[0]] === marqueCode;
      });
    }
    this.psuhLcdv10WithLabel();
  }

  validInputs(marqueCode: any, countryCode: any, languageCode: any) {
    const validLanguageCode =
      null !== languageCode &&
      "undefined" !== typeof languageCode &&
      "" !== languageCode;
    const validCountryCode =
      null !== countryCode &&
      "undefined" !== typeof countryCode &&
      "" !== countryCode;
    const validMarqueCode =
      null !== marqueCode &&
      "undefined" !== typeof marqueCode &&
      "" !== marqueCode;
    return validLanguageCode && validCountryCode && validMarqueCode;
  }

  /**
   * push coupled vehs to the existant list of VU & VP vehs
   * @param marque
   * @param country
   * @param langue
   *
   */
  addCoupledVehs(marque: string | null, country: string, langue: string) {
    const nameFile =
      "coupled_levdata/coupled_data_" +
      this.checkWidget() +
      "/coupled_data_" +
      this.checkWidget() +
      "_" +
      marque +
      "/levdata_coupled_" +
      country +
      "_" +
      langue;
    this.levservice.getData(nameFile).subscribe(
      (data) => {
        if ("undefined" !== typeof data["LEV_COUPLES"]) {
          data["LEV_COUPLES"].forEach((couple: any) => {
            this.pushCouples(couple);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  pushCouples(couple: Record<string, any>) {
     var obj: Record<string, any> = {};
    let LEV_COUPLE = Object.keys(couple);
    const widgetUpperCase: string = this.checkWidget().toLocaleUpperCase();
    const LCDV_KEY = "LEV_" + widgetUpperCase + "_LCDV";
    const LABEL_KEY = "LEV_" + widgetUpperCase + "_label";
    if (this.checkWidget() === "as") {
      obj[LCDV_KEY] = couple[LEV_COUPLE[0]];
      obj[LABEL_KEY] = couple[LEV_COUPLE[2]];
      obj["LEV_AS_4x4"] = true;
      obj["LEV_AS_4x2"] = true;
      this.vehs.push(obj);
    } else if (["cs", "us"].includes(this.checkWidget())) {
      obj[LCDV_KEY] = couple[LEV_COUPLE[0]];
      obj[LABEL_KEY] = couple[LEV_COUPLE[2]];
      this.vehs.push(obj);
    }
  }
  initBrands() {
    this.marques = [];
    this.pushBrands();
    this.marqueChanged();
  }
  selectUS() {
    this.radioValue = 1;
    this.usBloc = true;
    this.tecBloc = false;
    this.csBloc = false;
    this.asBloc = false;
    this.savBloc = false;
    this.ecoBloc = false;
    this.sideBarOpen = !this.sideBarOpen;
    this.MainOpen = !this.MainOpen;
    this.initBrands();
  }

  selectQueva() {
    this.radioValue = 1;
    this.usBloc = false;
    this.tecBloc = true;
    this.csBloc = false;
    this.asBloc = false;
    this.savBloc = false;
    this.ecoBloc = false;
    this.sideBarOpen = !this.sideBarOpen;
    this.MainOpen = !this.MainOpen;
    this.initBrands();
  }

  selectCS() {
    this.radioValue = 1;
    this.usBloc = false;
    this.tecBloc = false;
    this.csBloc = true;
    this.asBloc = false;
    this.savBloc = false;
    this.ecoBloc = false;
    this.sideBarOpen = !this.sideBarOpen;
    this.MainOpen = !this.MainOpen;
    this.initBrands();
  }

  selectAS() {
    this.radioValue = 1;
    this.usBloc = false;
    this.tecBloc = false;
    this.csBloc = false;
    this.asBloc = true;
    this.savBloc = false;
    this.ecoBloc = false;
    this.sideBarOpen = !this.sideBarOpen;
    this.MainOpen = !this.MainOpen;
    this.initBrands();
  }

  selectSAV() {
    this.radioValue = 1;
    this.usBloc = false;
    this.tecBloc = false;
    this.csBloc = false;
    this.asBloc = false;
    this.savBloc = true;
    this.ecoBloc = false;
    this.sideBarOpen = !this.sideBarOpen;
    this.MainOpen = !this.MainOpen;
    this.initBrands();
  }

  selectECO() {
    this.radioValue = 1;
    this.usBloc = false;
    this.tecBloc = false;
    this.csBloc = false;
    this.asBloc = false;
    this.savBloc = false;
    this.ecoBloc = true;
    this.sideBarOpen = !this.sideBarOpen;
    this.MainOpen = !this.MainOpen;
    this.initBrands();
  }

  selectUC() {
    this.radioValue = 1;
    this.usBloc = false;
    this.tecBloc = false;
    this.csBloc = false;
    this.asBloc = false;
    this.savBloc = false;
    this.ecoBloc = false;
    this.sideBarOpen = !this.sideBarOpen;
    this.MainOpen = !this.MainOpen;
    this.initBrands();
  }
}
