import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { API_CONFIG } from 'src/assets/plugSurfing_params/api_url_config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{ 
}


