import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Brand } from "../../services/vehicle-data.service";

@Component({
  selector: "app-brand-selector",
  templateUrl: "./brand-selector.component.html",
  styleUrls: ["./brand-selector.component.scss"],
})
export class BrandSelectorComponent implements OnInit, OnChanges {
  @Input() brands: Brand[] = [];
  @Input() selectedBrand: string = "";
  @Input() labelCulture: any;
  @Input() readOnly: boolean = false;
  @Input() tabType: string = "";

  @Output() brandSelected = new EventEmitter<string>();

  // Use a local property to track the selected brand
  localSelectedBrand: string = "";
  isBrandSelected: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.localSelectedBrand = this.selectedBrand;

    if (this.selectedBrand) {
      this.isBrandSelected = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectedBrand"]) {
      // Update local selected brand when input changes
      this.localSelectedBrand = changes["selectedBrand"].currentValue;

      if (changes["selectedBrand"].currentValue) {
        this.isBrandSelected = true;
      }
    }

    if (changes["tabType"]) {
    }
  }

  onBrandChange(selectedBrand: string): void {
    // Always emit the selected brand, even if it seems the same
    this.localSelectedBrand = selectedBrand;
    this.isBrandSelected = true;

    console.log("BrandSelector - Emitting brand:", selectedBrand);
    this.brandSelected.emit(selectedBrand);
  }
}
