<main>
  <!-- Loader will be displayed while loading is true -->
  <div
    *ngIf="loading"
    class="loading-container"
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
  ></div>
  <div
    [class.AP]="cs_peugeot"
    [class.AC]="cs_citroen"
    [class.FT]="cs_fiat"
    [class.FO]="cs_fiatPro"
    [class.AR]="cs_alfaRomeo"
    [class.DS]="cs_ds"
    [class.JE]="cs_jeep"
    class="container-cs"
    *ngIf="!loading"
  >
    <div
      class="row-cs"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    >
      <div class="cs-header">
        <div class="block-slider-autonomy-wrapper" *ngIf="vehicule">
          <div class="block-slider-wrapper">
            <p
              role="status"
              class="block-slider-autonomy-text"
              [class.AP]="cs_peugeot"
              [class.AC]="cs_citroen"
              [class.FT]="cs_fiat"
              [class.FO]="cs_fiatPro"
              [class.AR]="cs_alfaRomeo"
              [class.DS]="cs_ds"
              [class.JE]="cs_jeep"
              [attr.aria-label]="
                labelCulture.indicate_initial_and_desired_charge
              "
            >
              {{ labelCulture.indicate_initial_and_desired_charge }}
            </p>
            <div
              class="lev-cs-slider"
              [class.FT]="cs_fiat"
              [class.FO]="cs_fiatPro"
              [class.AR]="cs_alfaRomeo"
              [class.DS]="cs_ds"
              [class.JE]="cs_jeep"
              [class.AP]="cs_peugeot"
            >
              <span
                class="floor"
                role="status"
                aria-label="0%"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
                [class.AP]="cs_peugeot"
                >0%</span
              >
              <ngx-slider
                [(value)]="socMini"
                [(highValue)]="socMaxi"
                [attr.role]="'slider'"
                [attr.aria-valuemax]="socMaxi"
                [attr.aria-valuemin]="socMini"
                [attr.aria-valuenow]="socMini"
                [attr.aria-labelledby]="'batterySliderId'"
                [attr.aria-orientation]="'horizontal'"
                [attr.id]="'batterySliderId'"
                #batterySliderIdRef
                [options]="options"
                (userChange)="getLoadingTime()"
              ></ngx-slider>
              <span
                class="ceil"
                role="status"
                aria-label="100%"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
                [class.AP]="cs_peugeot"
                >100%</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="WLTP" [class.hidden]="true">
        <div
          class="autonomy-value"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <span>{{ labelCulture.estimated_recouvered_range }}</span>
          {{ calculateAutonomy() }}
          {{ labelCulture.distance_unit }}
        </div>
        <div class="Legal-mention">({{ labelCulture.legale_mentions }})</div>
      </div>
    </div>

    <!-- Charging Time in Full Size -->
    <div class="charging-wrapper" *ngIf="charges.length > 0 && !isMobile">
      <!-- Loop through the first element of charges -->
      <div class="charging-labels-wrapper">
        <div
          class="labels-table"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <div></div>
          <div>{{ labelCulture.charging_power }}</div>
          <div class="estimated-time-label">
            {{ labelCulture.estimated_time }}
          </div>
          <div *ngIf="renderCostAndFuelComponents">
            {{ labelCulture.charging_cost }}*
          </div>
          <div *ngIf="renderCostAndFuelComponents">
            {{ labelCulture.fuel_cost }}*
          </div>
        </div>
        <div class="charging-table">
          <div
            class="charging-label"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
          >
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.2002 9.70039H12.3002V19.9004H3.2002V9.70039ZM4.4002 1.90039H11.1002C12.0002 1.90039 12.3002 2.30039 12.3002 3.10039V8.70039H3.2002V3.10039C3.2002 2.30039 3.5002 1.90039 4.4002 1.90039ZM20.9002 3.30039V0.900391H19.9002V3.30039H18.1002V0.900391H17.1002V3.30039H16.2002V5.40039C16.2002 6.70039 17.2002 7.90039 18.5002 8.10039V14.8004C18.5002 15.4004 18.0002 15.8004 17.5002 15.8004C16.9002 15.8004 16.5002 15.3004 16.5002 14.8004V9.70039C16.5002 9.10039 16.1002 8.70039 15.5002 8.70039H13.4002V3.10039C13.4002 2.50039 13.2002 1.90039 12.8002 1.50039C12.4002 1.10039 11.8002 0.900391 11.2002 0.900391H4.5002C3.8002 0.900391 3.2002 1.10039 2.8002 1.60039C2.4002 2.00039 2.2002 2.60039 2.2002 3.10039V19.9004H0.700195V20.9004H14.8002V19.9004H13.3002V9.70039H15.5002V14.8004C15.5002 15.9004 16.4002 16.8004 17.5002 16.8004C18.6002 16.8004 19.5002 15.9004 19.5002 14.8004V8.10039C20.8002 7.90039 21.7002 6.70039 21.7002 5.40039V3.30039H20.9002Z"
                fill="#17234D"
              />
            </svg>
            {{ labelCulture.public_charging }}
          </div>
          <div class="vertical-line"></div>
          <div class="charging-element">
            <div
              class="groupe-charging-element"
              *ngFor="let charge of charges[0]; let i = index"
            >
              <div
                class="charging-station"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[0] }}
              </div>
              <div
                class="charging-power"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[1] }}
              </div>
              <div class="desktop-progress-bar">
                <app-progress-bar
                  *ngIf="lastCharge > 0"
                  [value]="charge[2]"
                  [lastStepValue]="lastCharge"
                  [cs_peugeot]="cs_peugeot"
                  [cs_fiat]="cs_fiat"
                  [cs_fiatPro]="cs_fiatPro"
                  [cs_alfaRomeo]="cs_alfaRomeo"
                  [cs_citroen]="cs_citroen"
                  [cs_ds]="cs_ds"
                  [cs_jeep]="cs_jeep"
                ></app-progress-bar>
              </div>
              <div
                class="estimated-time"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[3] }}
              </div>
              <div
                class="charging-cost"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
                *ngIf="renderCostAndFuelComponents"
              >
                {{ chargingCost[0][i]?.[4] || 'N/A' }}€
              </div>
            </div>
          </div>
          <div class="vertical-line" *ngIf="renderCostAndFuelComponents"></div>
          <div
            class="fuel-cost"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
            *ngIf="renderCostAndFuelComponents"
          >
            {{ FuelCostDisplay }}€*
          </div>
        </div>
        <app-button-discover
          style="align-self: center"
          [buttonText]="labelCulture.discover_charging_on_the_go"
          [cs_peugeot]="cs_peugeot"
          [cs_fiat]="cs_fiat"
          [cs_fiatPro]="cs_fiatPro"
          [cs_alfaRomeo]="cs_alfaRomeo"
          [cs_citroen]="cs_citroen"
          [cs_ds]="cs_ds"
          [cs_jeep]="cs_jeep"
          [isStyled]="true"
          [buttonUrl]="getLocalizedUrl('charge-go.html')"
        ></app-button-discover>
      </div>

      <!-- Loop through the second element of charges -->

      <div class="charging-labels-wrapper">
        <div
          class="labels-table"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <div></div>
          <div>{{ labelCulture.charging_power }}</div>
          <div class="estimated-time-label">
            {{ labelCulture.estimated_time }}
          </div>
          <div *ngIf="renderCostAndFuelComponents">
            {{ labelCulture.charging_cost }}*
          </div>
          <div *ngIf="renderCostAndFuelComponents">
            {{ labelCulture.fuel_cost }}*
          </div>
        </div>
        <div class="charging-table">
          <div
            class="charging-label"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5001 1.70039L19.5001 9.20039V19.4004H1.5001V9.20039L10.5001 1.70039ZM10.5001 0.400391L0.600098 8.70039V20.3004H20.4001V8.70039L10.5001 0.400391ZM9.0001 11.2004L11.0001 8.00039H9.5001L6.7001 12.5004H11.6001L9.3001 16.4004H10.8001L13.8001 11.2004H9.0001Z"
                fill="#17234D"
              />
            </svg>
            {{ labelCulture.private_charging }}
          </div>
          <div class="vertical-line"></div>
          <div class="charging-element">
            <div
              class="groupe-charging-element"
              *ngFor="let charge of charges[1]; let i = index"
            >
              <div
                class="charging-station"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[0] }}
              </div>
              <div
                class="charging-power"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[1] }}
              </div>
              <div class="desktop-progress-bar">
                <app-progress-bar
                  *ngIf="lastCharge > 0"
                  [value]="charge[2]"
                  [lastStepValue]="lastCharge"
                  [cs_peugeot]="cs_peugeot"
                  [cs_fiat]="cs_fiat"
                  [cs_fiatPro]="cs_fiatPro"
                  [cs_alfaRomeo]="cs_alfaRomeo"
                  [cs_citroen]="cs_citroen"
                  [cs_ds]="cs_ds"
                  [cs_jeep]="cs_jeep"
                ></app-progress-bar>
              </div>
              <div
                class="estimated-time"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[3] }}
              </div>
              <div
                class="charging-cost"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
                *ngIf="renderCostAndFuelComponents"
              >
                {{ chargingCost[1][i]?.[4] || 'N/A' }}€
              </div>
            </div>
          </div>
          <div class="vertical-line" *ngIf="renderCostAndFuelComponents"></div>
          <div
            class="fuel-cost"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
            *ngIf="renderCostAndFuelComponents"
          >
            {{ FuelCostDisplay }}€*
          </div>
        </div>
        <app-button-discover
          style="align-self: center"
          [buttonText]="labelCulture.discover_home_charging"
          [cs_peugeot]="cs_peugeot"
          [cs_fiat]="cs_fiat"
          [cs_fiatPro]="cs_fiatPro"
          [cs_alfaRomeo]="cs_alfaRomeo"
          [cs_citroen]="cs_citroen"
          [cs_ds]="cs_ds"
          [cs_jeep]="cs_jeep"
          [isStyled]="true"
          [buttonUrl]="getLocalizedUrl('charge-go.html')"
        ></app-button-discover>
      </div>
    </div>

    <!-- Charging Time in Mobile -->
    <div class="charging-wrapper" *ngIf="charges.length > 0 && isMobile">
      <!-- Loop through the first element of charges [100,50,22,20] -->
      <div class="charging-labels-wrapper">
        <div class="charging-table">
          <div
            class="charging-label"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
          >
            {{ labelCulture.public_charging }}
          </div>
          <div class="charging-element">
            <div
              class="groupe-charging-element"
              *ngFor="let charge of charges[0]; let i = index"
            >
              <div class="power-icon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2749 4.94961L5.84985 9.37461H10.1999L6.52485 13.0496H7.64985L12.0749 8.62461H7.72485L11.3999 4.94961H10.2749ZM8.99985 1.34961C4.72485 1.34961 1.34985 4.72461 1.34985 8.99961C1.34985 13.2746 4.72485 16.6496 8.99985 16.6496C13.2749 16.6496 16.6499 13.2746 16.6499 8.99961C16.6499 4.72461 13.2749 1.34961 8.99985 1.34961ZM8.99985 17.3996C4.34985 17.3996 0.599854 13.6496 0.599854 8.99961C0.599854 4.34961 4.34985 0.599609 8.99985 0.599609C13.6499 0.599609 17.3999 4.34961 17.3999 8.99961C17.3999 13.6496 13.6499 17.3996 8.99985 17.3996Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div
                class="charging-station"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[0] }}
              </div>
              <div class="battery-icon">
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.39971 7.37578H8.04971V8.12578H3.39971V7.37578ZM14.4247 7.37578H16.6747V8.12578H14.4247V10.3758H13.6747V8.12578H11.4247V7.37578H13.6747V5.12578H14.4247V7.37578ZM18.5497 12.7758H1.44971V3.17578H4.59971V1.22578H7.22471V3.10078H12.8497V1.22578H15.4747V3.10078H18.6247V12.7758H18.5497ZM16.1497 2.42578V0.550781H12.0997V2.42578H7.89971V0.550781H3.84971V2.42578H0.699707V13.4508H19.2997V2.42578H16.1497Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div
                class="charging-power"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[1] }}
              </div>
              <div class="money-icon">
                <svg
                  width="10"
                  height="18"
                  view
                  Box="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.08998 14.7746C5.95501 14.8288 6.81254 14.5854 7.51998 14.0846C7.80545 13.8461 8.03137 13.5443 8.17979 13.2032C8.32821 12.8621 8.39504 12.4911 8.37498 12.1196C8.39163 11.785 8.33668 11.4507 8.21381 11.139C8.09095 10.8273 7.90298 10.5454 7.66248 10.3121C7.01301 9.79857 6.23445 9.47416 5.41248 9.37461L4.48248 9.20211C3.38224 9.07371 2.34148 8.63399 1.48248 7.93461C1.16119 7.63363 0.908988 7.26656 0.743254 6.8587C0.577521 6.45084 0.502199 6.01189 0.52248 5.57211C0.505422 5.12332 0.584988 4.67611 0.755791 4.26075C0.926595 3.84539 1.18465 3.47157 1.51248 3.16461C2.27383 2.50759 3.24435 2.14331 4.24998 2.13711V0.412109H5.30748V2.18211C6.24163 2.25873 7.12213 2.65006 7.80498 3.29211C8.48629 4.0036 8.94056 4.90169 9.10998 5.87211H7.99998C7.85364 5.05364 7.43943 4.30701 6.82248 3.74961C6.17772 3.26068 5.38049 3.01619 4.57248 3.05961C3.77077 3.01084 2.97659 3.23851 2.32248 3.70461C2.05693 3.92909 1.84844 4.21338 1.71415 4.53412C1.57985 4.85486 1.52359 5.20289 1.54998 5.54961C1.53584 5.86975 1.59623 6.1888 1.72638 6.48163C1.85652 6.77446 2.05288 7.03308 2.29998 7.23711C3.01698 7.74227 3.84555 8.06649 4.71498 8.18211L5.64498 8.36211C6.6869 8.49032 7.66518 8.93238 8.44998 9.62961C8.7631 9.9525 9.00706 10.3359 9.16697 10.7563C9.32687 11.1767 9.39935 11.6252 9.37998 12.0746C9.40011 12.5663 9.31177 13.0563 9.12123 13.51C8.93069 13.9636 8.64263 14.3698 8.27748 14.6996C7.43528 15.3948 6.36903 15.76 5.27748 15.7271V17.4521H4.24998V15.6596C3.22941 15.558 2.27417 15.1108 1.54248 14.3921C0.81022 13.6377 0.348657 12.6618 0.22998 11.6171H1.33248C1.3992 12.5165 1.81998 13.3526 2.50248 13.9421C3.24415 14.5243 4.1707 14.8198 5.11248 14.7746"
                    fill="black"
                  />
                </svg>
              </div>

              <div
                class="charging-cost"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ chargingCost[0][i]?.[4] || 'N/A' }}€
              </div>
              <div class="estimatedTime-Progress">
                <app-circular-progress
                  *ngIf="lastCharge > 0"
                  [value]="charge[2]"
                  [lastStepValue]="lastCharge"
                  [cs_peugeot]="cs_peugeot"
                  [cs_fiat]="cs_fiat"
                  [cs_fiatPro]="cs_fiatPro"
                  [cs_alfaRomeo]="cs_alfaRomeo"
                  [cs_citroen]="cs_citroen"
                  [cs_ds]="cs_ds"
                  [cs_jeep]="cs_jeep"
                  timeString="{{ charge[3] }}"
                ></app-circular-progress>
              </div>
            </div>
          </div>
          <div
            class="fuel-cost"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
          >
            {{ labelCulture.fuel_cost_will_be }} {{ FuelCostDisplay }}€*
          </div>
          <app-button-discover
            [buttonText]="labelCulture.discover_charging_on_the_go"
            [cs_peugeot]="cs_peugeot"
            [cs_fiat]="cs_fiat"
            [cs_fiatPro]="cs_fiatPro"
            [cs_alfaRomeo]="cs_alfaRomeo"
            [cs_citroen]="cs_citroen"
            [cs_ds]="cs_ds"
            [cs_jeep]="cs_jeep"
            [cs_je]="cs_je"
            [isStyled]="true"
            [buttonUrl]="getLocalizedUrl('charge-go.html')"
          ></app-button-discover>
        </div>
      </div>

      <!-- Loop through the second element of charges [11,7.4,3.7,2.3] -->
      <div class="charging-labels-wrapper">
        <div class="charging-table">
          <div
            class="charging-label"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
          >
            {{ labelCulture.private_charging }}
          </div>
          <div class="charging-element">
            <div
              class="groupe-charging-element"
              *ngFor="let charge of charges[1]; let i = index"
            >
              <div class="power-icon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2749 4.94961L5.84985 9.37461H10.1999L6.52485 13.0496H7.64985L12.0749 8.62461H7.72485L11.3999 4.94961H10.2749ZM8.99985 1.34961C4.72485 1.34961 1.34985 4.72461 1.34985 8.99961C1.34985 13.2746 4.72485 16.6496 8.99985 16.6496C13.2749 16.6496 16.6499 13.2746 16.6499 8.99961C16.6499 4.72461 13.2749 1.34961 8.99985 1.34961ZM8.99985 17.3996C4.34985 17.3996 0.599854 13.6496 0.599854 8.99961C0.599854 4.34961 4.34985 0.599609 8.99985 0.599609C13.6499 0.599609 17.3999 4.34961 17.3999 8.99961C17.3999 13.6496 13.6499 17.3996 8.99985 17.3996Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div
                class="charging-station"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[0] }}
              </div>
              <div class="battery-icon">
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.39971 7.37578H8.04971V8.12578H3.39971V7.37578ZM14.4247 7.37578H16.6747V8.12578H14.4247V10.3758H13.6747V8.12578H11.4247V7.37578H13.6747V5.12578H14.4247V7.37578ZM18.5497 12.7758H1.44971V3.17578H4.59971V1.22578H7.22471V3.10078H12.8497V1.22578H15.4747V3.10078H18.6247V12.7758H18.5497ZM16.1497 2.42578V0.550781H12.0997V2.42578H7.89971V0.550781H3.84971V2.42578H0.699707V13.4508H19.2997V2.42578H16.1497Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div
                class="charging-power"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ charge[1] }}
              </div>
              <div class="money-icon">
                <svg
                  width="10"
                  height="18"
                  view
                  Box="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.08998 14.7746C5.95501 14.8288 6.81254 14.5854 7.51998 14.0846C7.80545 13.8461 8.03137 13.5443 8.17979 13.2032C8.32821 12.8621 8.39504 12.4911 8.37498 12.1196C8.39163 11.785 8.33668 11.4507 8.21381 11.139C8.09095 10.8273 7.90298 10.5454 7.66248 10.3121C7.01301 9.79857 6.23445 9.47416 5.41248 9.37461L4.48248 9.20211C3.38224 9.07371 2.34148 8.63399 1.48248 7.93461C1.16119 7.63363 0.908988 7.26656 0.743254 6.8587C0.577521 6.45084 0.502199 6.01189 0.52248 5.57211C0.505422 5.12332 0.584988 4.67611 0.755791 4.26075C0.926595 3.84539 1.18465 3.47157 1.51248 3.16461C2.27383 2.50759 3.24435 2.14331 4.24998 2.13711V0.412109H5.30748V2.18211C6.24163 2.25873 7.12213 2.65006 7.80498 3.29211C8.48629 4.0036 8.94056 4.90169 9.10998 5.87211H7.99998C7.85364 5.05364 7.43943 4.30701 6.82248 3.74961C6.17772 3.26068 5.38049 3.01619 4.57248 3.05961C3.77077 3.01084 2.97659 3.23851 2.32248 3.70461C2.05693 3.92909 1.84844 4.21338 1.71415 4.53412C1.57985 4.85486 1.52359 5.20289 1.54998 5.54961C1.53584 5.86975 1.59623 6.1888 1.72638 6.48163C1.85652 6.77446 2.05288 7.03308 2.29998 7.23711C3.01698 7.74227 3.84555 8.06649 4.71498 8.18211L5.64498 8.36211C6.6869 8.49032 7.66518 8.93238 8.44998 9.62961C8.7631 9.9525 9.00706 10.3359 9.16697 10.7563C9.32687 11.1767 9.39935 11.6252 9.37998 12.0746C9.40011 12.5663 9.31177 13.0563 9.12123 13.51C8.93069 13.9636 8.64263 14.3698 8.27748 14.6996C7.43528 15.3948 6.36903 15.76 5.27748 15.7271V17.4521H4.24998V15.6596C3.22941 15.558 2.27417 15.1108 1.54248 14.3921C0.81022 13.6377 0.348657 12.6618 0.22998 11.6171H1.33248C1.3992 12.5165 1.81998 13.3526 2.50248 13.9421C3.24415 14.5243 4.1707 14.8198 5.11248 14.7746"
                    fill="black"
                  />
                </svg>
              </div>

              <div
                class="charging-cost"
                [class.AP]="cs_peugeot"
                [class.AC]="cs_citroen"
                [class.FT]="cs_fiat"
                [class.FO]="cs_fiatPro"
                [class.AR]="cs_alfaRomeo"
                [class.DS]="cs_ds"
                [class.JE]="cs_jeep"
              >
                {{ chargingCost[1][i]?.[4] || 'N/A' }}€
              </div>
              <div class="estimatedTime-Progress">
                <app-circular-progress
                  *ngIf="lastCharge > 0"
                  [value]="charge[2]"
                  [cs_peugeot]="cs_peugeot"
                  [cs_fiat]="cs_fiat"
                  [cs_fiatPro]="cs_fiatPro"
                  [cs_alfaRomeo]="cs_alfaRomeo"
                  [cs_citroen]="cs_citroen"
                  [cs_ds]="cs_ds"
                  [cs_jeep]="cs_jeep"
                  [lastStepValue]="lastCharge"
                  timeString="{{ charge[3] }}"
                ></app-circular-progress>
              </div>
            </div>
          </div>
          <div
            class="fuel-cost"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
          >
            {{ labelCulture.fuel_cost_will_be }} {{ FuelCostDisplay }}€*
          </div>
          <app-button-discover
            [buttonText]="labelCulture.discover_home_charging"
            [cs_peugeot]="cs_peugeot"
            [cs_fiat]="cs_fiat"
            [cs_fiatPro]="cs_fiatPro"
            [cs_alfaRomeo]="cs_alfaRomeo"
            [cs_citroen]="cs_citroen"
            [cs_ds]="cs_ds"
            [cs_jeep]="cs_jeep"
            [isStyled]="true"
            [buttonUrl]="getLocalizedUrl('charge-go.html')"
          ></app-button-discover>
        </div>
      </div>
    </div>

    <div class="container-cost-fuel" *ngIf="renderCostAndFuelComponents">
      <p
        class="electricity-fuel-cost-text"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        {{ labelCulture.title_customize }}
      </p>
      <div class="electricity-fuel-cost">
        <div class="electricity-cost">
          <div
            class="electricity-cost-title"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
          >
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.93582 0.25L0.5 8.78125H9.20968L1.19117 16H2.67742L11.75 7.79688H3.04032L11.3871 0.25H9.93582Z"
                fill="#034FA0"
              />
            </svg>
            <span
              >{{ labelCulture.energy_cost }} ({{
                labelCulture.cs_energy_cost_unit
              }})</span
            >
          </div>
          <div class="electricity-cost-components">
            <app-chargercost
              [step]="0.01"
              [label]="labelCulture.cs_home_charging"
              [startingValue]="0.25"
              [cs_peugeot]="cs_peugeot"
              [cs_fiat]="cs_fiat"
              [cs_fiatPro]="cs_fiatPro"
              [cs_alfaRomeo]="cs_alfaRomeo"
              [cs_citroen]="cs_citroen"
              [cs_ds]="cs_ds"
              [cs_jeep]="cs_jeep"
              (valueChange)="onValueChange($event, 'Private-charge')"
            ></app-chargercost>
            <app-chargercost
              [step]="0.01"
              [label]="labelCulture.cs_public_chargers"
              [startingValue]="0.4"
              [cs_peugeot]="cs_peugeot"
              [cs_fiat]="cs_fiat"
              [cs_fiatPro]="cs_fiatPro"
              [cs_alfaRomeo]="cs_alfaRomeo"
              [cs_citroen]="cs_citroen"
              [cs_ds]="cs_ds"
              [cs_jeep]="cs_jeep"
              (valueChange)="onValueChange($event, 'Public-standard-charge')"
            ></app-chargercost>
            <app-chargercost
              [step]="0.01"
              [label]="labelCulture.cs_fast_chargers"
              [startingValue]="0.65"
              [cs_peugeot]="cs_peugeot"
              [cs_fiat]="cs_fiat"
              [cs_fiatPro]="cs_fiatPro"
              [cs_alfaRomeo]="cs_alfaRomeo"
              [cs_citroen]="cs_citroen"
              [cs_ds]="cs_ds"
              [cs_jeep]="cs_jeep"
              (valueChange)="onValueChange($event, 'Public-fast-charge')"
            ></app-chargercost>
          </div>
        </div>

        <div class="fuel-cost">
          <div
            class="fuel-cost-title"
            [class.AP]="cs_peugeot"
            [class.AC]="cs_citroen"
            [class.FT]="cs_fiat"
            [class.FO]="cs_fiatPro"
            [class.AR]="cs_alfaRomeo"
            [class.DS]="cs_ds"
            [class.JE]="cs_jeep"
          >
            <svg
              width="13"
              height="18"
              viewBox="0 0 13 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12.125C12 7.25 6.75 1.25 6.75 1.25C6.75 1.25 1.5 7.25 1.5 12.125C1.5 17 6.75 17 6.75 17C6.75 17 12 17 12 12.125Z"
                stroke="#034FA0"
                stroke-width="1.5"
              />
            </svg>
            <span
              >{{ labelCulture.fuel_cost }} ({{
                labelCulture.cs_fuel_cost_unit
              }})</span
            >
          </div>
          <div class="fuel-cost-components">
            <app-chargercost
              [step]="0.05"
              [label]="labelCulture.cs_cost_per_liter"
              [startingValue]="1.8"
              [cs_peugeot]="cs_peugeot"
              [cs_fiat]="cs_fiat"
              [cs_fiatPro]="cs_fiatPro"
              [cs_alfaRomeo]="cs_alfaRomeo"
              [cs_citroen]="cs_citroen"
              [cs_ds]="cs_ds"
              [cs_jeep]="cs_jeep"
              (valueChange)="onValueChangeFuel($event, 'Cost-per-liter')"
            ></app-chargercost>
            <app-chargercost
              [step]="0.1"
              [label]="labelCulture.cs_consumption_100"
              [typeOfCost]="'Fuel'"
              [startingValue]="6"
              [cs_peugeot]="cs_peugeot"
              [cs_fiat]="cs_fiat"
              [cs_fiatPro]="cs_fiatPro"
              [cs_alfaRomeo]="cs_alfaRomeo"
              [cs_citroen]="cs_citroen"
              [cs_ds]="cs_ds"
              [cs_jeep]="cs_jeep"
              (valueChange)="onValueChangeFuel($event, 'Consomation')"
            ></app-chargercost>
          </div>
        </div>
      </div>
    </div>

    <div class="disclaimer">
      <div class="horizontal-ligne"></div>
      <p
        class="disclaimer-text"
        [class.AP]="cs_peugeot"
        [class.AC]="cs_citroen"
        [class.FT]="cs_fiat"
        [class.FO]="cs_fiatPro"
        [class.AR]="cs_alfaRomeo"
        [class.DS]="cs_ds"
        [class.JE]="cs_jeep"
      >
        {{ labelCulture.disclaimer_charging_time }}
      </p>
    </div>
  </div>
</main>
