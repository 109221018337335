<div
  *ngIf="
    resultsConfirmation === 'goodResults';
    then content;
    else other_content
  "
></div>
<ng-template #content>
  <div
    class="eco-advisor-back-section"
    [ngClass]="[brand, country]"
    (click)="backToStepOne($event, 'return')"
  >
    <div
      class="eco-advisor-list-results-back-img"
      [ngClass]="[brand, country]"
    ></div>
    <div
      class="eco-advisor-list-results-back-to-step-one"
      [ngClass]="[brand, country]"
    >
      {{ eco_advisor_back_label }}
    </div>
  </div>
  <div class="eco-advisor-list-results-header" [ngClass]="[brand, country]">
    <div
      class="eco-advisor-list-results-header-first-section"
      [ngClass]="[brand, country]"
    >
      <div
        class="eco-advisor-list-postal-code-label"
        [ngClass]="[brand, country]"
      >
        {{ eco_advisor_list_postal_code_label }}
      </div>
      <div class="eco-advisor-list-postal-code" [ngClass]="[brand, country]">
        <div
          class="eco-advisor-list-postal-code-value"
          [ngClass]="[brand, country]"
        >
          {{ zipCode }}
        </div>
        <div
          class="eco-advisor-list-postal-code-modify"
          (click)="backToStepOne($event, 'modify')"
          [ngClass]="[brand, country]"
        >
          {{ eco_advisor_list_postal_code_modify }}
        </div>
      </div>
    </div>
    <div
      class="eco-advisor-list-results-header-second-section"
      [ngClass]="[brand, country]"
    >
      <div class="eco-advisor-economy-label" [ngClass]="[brand, country]">
        {{ eco_advisor_list_economy_label }}
      </div>
      <div class="eco-advisor-economy" [ngClass]="[brand, country]">
        <div class="eco-advisor-economy-value" [ngClass]="[brand, country]">
          {{
            results
              ? results[0]
                ? results[0].value
                : 0
              : calculateTotalIncentives()
          }}
        </div>
        <div class="eco-advisor-economy-currency" [ngClass]="[brand, country]">
          {{ eco_advisor_list_economy_currency }}
        </div>
      </div>
      <div
        class="eco-advisor-economy-centered-arrow"
        [ngClass]="[brand, country]"
      ></div>
    </div>
  </div>
  <div class="eco-advisor-incentive-section" [ngClass]="[brand, country]">
    <div
      class="eco-advisor-list-incentives"
      [ngClass]="[brand, country]"
      *ngFor="
        let incentive of results;
        let lastIncentive = last;
        let firstIncentive = first;
        let i = index
      "
    >
      <app-incentive
        *ngIf="incentive.value"
        [title]="incentive.title"
        [description]="incentive.description"
        [value]="incentive.value"
        [currency]="incentive.currency"
        [target]="incentive.target"
        [linkLabel]="eco_more_link_label"
        [isMobile]="isMobileResults"
        [isFirst]="firstIncentive"
        [index]="i"
        [hideContent]="true"
        [link]="incentive.link"
        [brand]="brand"
        [country]="country"
      ></app-incentive>
      <div
        *ngIf="!lastIncentive"
        class="eco-advisor-list-separator"
        [ngClass]="[brand, country]"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #other_content>
  <div
    *ngIf="resultsConfirmation === 'noResults'"
    class="eco-advisor-no-results-container"
  >
    <div class="eco-advisor-no-results" [ngClass]="[brand, country]">
      {{ error_msg_no_incentives_for_actual_zip_code }}
      <span class="eco-advisor-current-zipcode" [ngClass]="[brand, country]">{{
        zipCode
      }}</span>
      <input
        class="eco-advisor-submit"
        [ngClass]="[brand, country]"
        [value]="use_other_zip_code"
        type="button"
        (click)="backToStepOneFromError('return')"
      />
      <div
        class="eco-advisor-rect-horizontal"
        [ngClass]="[brand, country]"
      ></div>
    </div>
  </div>
</ng-template>
