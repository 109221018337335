import { Options } from "@angular-slider/ngx-slider";

export let ngxSliderOptionsConfig: Options = {
  floor: 0,
  ceil: 100,
  step: 10,
  minRange: 10,
  hideLimitLabels: true,
  showOuterSelectionBars: true,
  translate: (value: number): string => {
    return value + "%";
  },
};
