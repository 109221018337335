import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, shareReplay } from 'rxjs/operators';

import { API_CONFIG } from '@assets/plugSurfing_params/api_url_config';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CostAPIService {

  headers: HttpHeaders;
  value: any;
  financialType: any;
  localBring: number = 20
  criteriaData: any;
  defaultMileage!: number;
  defaultDuration!: number;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set('x-ibm-client-id', API_CONFIG.clientId);
  }

  getOffers(body: any): Observable<any> {
    return this.http.post(API_CONFIG.offersURL, body, { headers: this.headers })
      .pipe(
        shareReplay(1)
      );

  }

  getCriteria(body: any = API_CONFIG.criteriaBody) {
    return this.getOffers(API_CONFIG.offersBody).pipe(
      switchMap((data: any) => {
        this.value = {
          ...body,
          parameters: {
            productKey: this.financialType = data.financialOffers[0]["financialProductId"]["productKey"]
          }
        };
        return this.http.post(API_CONFIG.criteriasURL, this.value, { headers: this.headers });
      }),
      shareReplay(1)
    )
  }

  getDetails(body: any) {

    return this.getCriteria(API_CONFIG.criteriaBody).pipe(
      switchMap((data: any) => {
        this.criteriaData = data;
        this.localBring = data.deposits.defaultValue;
        this.defaultMileage = data.mileages.defaultValue;
        this.defaultDuration = data.durations.defaultValue;
        if (API_CONFIG.criteriaBody.context.distributionBrand.toLowerCase() == 'ap' && API_CONFIG.criteriaBody.context.countryCode.toLowerCase() == 'it') {
          this.defaultMileage = 10000;
          this.defaultDuration = 36;
          this.criteriaData.durations =
          {
            "min": 36,
            "max": 36,
            "step": 12,
            "defaultValue": 36
          };


        this.criteriaData.mileages =
        {
          "min": 10000,
          "max": 10000,
          "step": 5000,
          "defaultValue": 10000,
        };
      }


        let  value: any = {
          ...body,
          parameters: {
            productKey: this.value.parameters.productKey,
            depositAmountKind: data.deposits.amountKind,
            deposit: body["parameters"]["deposit"],
            duration: this.defaultDuration,
            annualMileage: this.defaultMileage,
            services: [{
              packageCode: body["parameters"]["services"]["packageCode"],
              serviceCode: body["parameters"]["services"]["serviceCode"],
              "included": true
            }]
          }
        };
        return this.http.post(API_CONFIG.detailsURL, value, { headers: this.headers });
    }),
  );
}


getRsults(body: any) {
  let value: any = {
    ...body,
    parameters: {
      productKey: this.value.parameters.productKey,
      depositAmountKind: this.criteriaData.deposits.amountKind,
      deposit: body["parameters"]["deposit"],
      duration: body["parameters"]["duration"],
      annualMileage: body["parameters"]["annualMileage"],
      services: [{
        packageCode: body["parameters"]["services"]["packageCode"],
        serviceCode: body["parameters"]["services"]["serviceCode"],
        "included": true
      }]
    }
  };
  return this.http.post(API_CONFIG.detailsURL, value, { headers: this.headers })
}

}
