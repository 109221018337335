import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class SliderComponent implements OnInit {
  @Input("defaultValue") defaultValue: number = 0;
  @Input("step") step: number = 0;
  @Input("min") min: number = 0;
  @Input("max") max: number = 0;
  @Input("unit") unit: string = "";
  @Input("label") label: string = "";
  @Input("sliderText") sliderText: string = "";
  @Input("brand") brand: string = "";
  @Input("country") country: string = "";
  @Output("onChangeSliderValue") onChangeSliderValue: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(private changeDetector: ChangeDetectorRef) {}
  brandClass?: string;
  countryClass?: string;
  ngOnInit() {
    this.brandClass = this.brand;
    this.countryClass = this.country;
  }
  onChangeValue(event:any): void {
    this.onChangeSliderValue.emit(event.value);
  }
}
