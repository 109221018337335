export class Vehicule {

    LEV_CS_BRAND: string = "";
    LEV_CS_LCDV: string = "";
    LEV_CS_label: string = "";
    LEV_CS_autonomie: string = "";
    LEV_CS_OBC:any =[];
    LEV_CS_Installed_capacity:string="";
    LEV_CS_DOD:string="";
    LEV_CS_Useful_capacity: string="";
    LEV_CS_Load_efficiency: string="";
    LEV_CS_DCDC: string="";
    LEV_CS_WLTP!: number;
    LEV_CS_TYPE: string="";
    LEV_CS_Pro: string="";
    LEV_CS_Picture: string="";
}