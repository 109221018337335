<!-- help-me-choose.component.html -->
<div class="help-me-choose-wrapper">
  <!-- Loading indicator -->
  <div *ngIf="isScriptLoading" class="loading-container"></div>

  <!-- Help Me Choose container where the script will inject content -->
  <div
    id="helpmechoose-container"
    class="help-me-choose-container"
    [class.hidden]="isScriptLoading"
  ></div>
</div>