// tab-container.component.ts
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ContentChildren,
} from "@angular/core";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";

export enum TabType {
  CHARGING_SIMULATOR = "cs",
  FUEL_SAVING = "sav",
  RANGE_CALCULATOR = "as",
  HELP_ME_CHOOSE = "hmc",
}

export interface TabState {
  index: number;
  tabType: TabType;
}

@Component({
  selector: "app-tab-container",
  templateUrl: "./tab-container.component.html",
  styleUrls: ["./tab-container.component.scss"],
})
export class TabContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren("childTabs") childTabs: QueryList<MatTabGroup>;

  @Input() labelCulture: any;
  @Input() canRenderChargingSimulator: boolean = true;
  @Input() canRenderRangeCalculator: boolean = true;
  @Input() canRenderFuelSaving: boolean = true;
  @Input() canRenderHelpMeChoose: boolean = true;
  @Input() activeIndex: number = 0;

  // New brand input
  @Input() brand: string = "";

  // Helper properties to determine which brand class to apply
  get isPeugeot(): boolean {
    return this.brand.toLowerCase() === "ap";
  }
  get isCitroen(): boolean {
    return this.brand.toLowerCase() === "ac";
  }
  get isFiat(): boolean {
    return this.brand.toLowerCase() === "ft";
  }
  get isFiatPro(): boolean {
    return this.brand.toLowerCase() === "fo";
  }
  get isAlfaRomeo(): boolean {
    return this.brand.toLowerCase() === "ar";
  }
  get isDS(): boolean {
    return this.brand.toLowerCase() === "ds";
  }
  get isJeep(): boolean {
    return this.brand.toLowerCase() === "je";
  }

  @Output() tabChanged = new EventEmitter<TabState>();

  currentTabState: TabState = {
    index: 0,
    tabType: TabType.CHARGING_SIMULATOR,
  };

  private realignTimeout?: ReturnType<typeof setTimeout>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    // Initially set the first available tab as active
    this.determineInitialActiveTab();
  }

  ngAfterViewInit(): void {
    // Ensure initial alignment
    this.realignTabIndicators();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.realignTimeout) {
      clearTimeout(this.realignTimeout);
    }
  }

  /**
   * Determine which tab should be initially active based on what's enabled
   */
  private determineInitialActiveTab(): void {
    const tabConfig = [
      {
        type: TabType.CHARGING_SIMULATOR,
        canRender: this.canRenderChargingSimulator,
      },
      { type: TabType.FUEL_SAVING, canRender: this.canRenderFuelSaving },
      {
        type: TabType.RANGE_CALCULATOR,
        canRender: this.canRenderRangeCalculator,
      },
      { type: TabType.HELP_ME_CHOOSE, canRender: this.canRenderHelpMeChoose },
    ];

    const enabledTabs = tabConfig.filter((tab) => tab.canRender);
    this.currentTabState.tabType = enabledTabs[0].type;
  }

  /**
   * Handle tab change events from the Material tab group
   */
  onTabChange(event: MatTabChangeEvent): void {
    try {
      // Update active index
      this.activeIndex = event.index;
      this.currentTabState.index = event.index;

      // Map the index to the correct tab type
      this.mapIndexToTabType(event.index);

      // Emit the tab change event
      this.tabChanged.emit(this.currentTabState);

      // Handle tab indicator realignment
      this.realignTabIndicators();
    } catch (error) {
      console.error("Error in tab change handler:", error);
    }
  }

  /**
   * Map tab index to tab type enum
   */
  private mapIndexToTabType(index: number): void {
    // Build an array of enabled tabs in order
    const enabledTabs = [];

    if (this.canRenderChargingSimulator) {
      enabledTabs.push(TabType.CHARGING_SIMULATOR);
    }

    if (this.canRenderFuelSaving) {
      enabledTabs.push(TabType.FUEL_SAVING);
    }

    if (this.canRenderRangeCalculator) {
      enabledTabs.push(TabType.RANGE_CALCULATOR);
    }

    if (this.canRenderHelpMeChoose) {
      enabledTabs.push(TabType.HELP_ME_CHOOSE);
    }

    // Set the tab type based on the actual index in enabled tabs
    if (index >= 0 && index < enabledTabs.length) {
      this.currentTabState.tabType = enabledTabs[index];
    } else {
      // Fallback
      this.currentTabState.tabType = TabType.CHARGING_SIMULATOR;
    }
  }

  /**
   * Manually realign the tab indicators for material tabs
   * This fixes a common issue with dynamically rendered tabs
   */
  private realignTabIndicators(): void {
    if (!this.childTabs || this.childTabs.length === 0) {
      return;
    }

    const childTabsArray = this.childTabs.toArray();

    // Clear existing timeout
    if (this.realignTimeout) {
      clearTimeout(this.realignTimeout);
    }

    // Set new timeout with increased delay
    this.realignTimeout = setTimeout(() => {
      try {
        requestAnimationFrame(() => {
          childTabsArray.forEach((childTab: MatTabGroup) => {
            if (childTab) {
              childTab.realignInkBar();
            }
          });
        });
      } catch (error) {
        console.error("Error realigning tab indicators:", error);
      }
    }, 150); // Increased timeout for better reliability
  }
}
