<main>
  <div class="small-padding-eco">
    <div class="sav-container-multiple-models" [ngClass]="[brandClass, countryClass]"
      [class.vx]="brandClass == 'ov' && countryClass == 'gb'" *ngIf="isMultipleVehicleDisplay">
      <mat-form-field appearance="fill" [ngClass]="[brandClass, countryClass]"
        [class.vx]="brandClass == 'ov' && countryClass == 'gb'" [class.rotate]="rotate">
        <mat-label [ngClass]="[brandClass, countryClass]">
          <img src="{{ vehicle?.LEV_SAV_PICTURE }}" alt="" class="sav-option-img" />
          <span class="sav-option-label" role="status" [attr.aria-label]="vehicleLabel">{{ vehicleLabel }}</span>
        </mat-label>
        <mat-select [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
          [(value)]="choosenVehicle" class="sav-select" (click)="rotateFunction()" (selectionChange)="changeVehicle()"
          role="button" tabindex="0">
          <!-- <mat-option>{{ vehicle.LEV_SAV_LABEL }}</mat-option> -->
          <div *ngIf="rotate" class="options-div" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
            <mat-option [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
              class="sav-option"></mat-option>
            <mat-option *ngFor="
                let ivehicle of filterListOfVehicles();
                let index = index;
                let last = last
              " [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'"
              [class.close]="!rotate" (click)="rotate = !rotate" class="sav-option" [value]="ivehicle" role="option"
              tabindex="0">
              <img src="{{ ivehicle.LEV_SAV_PICTURE }}" alt="" class="sav-option-img"
                [ngClass]="[brandClass, countryClass]" />
              <span class="sav-option-label" role="status" [attr.aria-label]="ivehicle.LEV_SAV_LABEL">{{
                ivehicle.LEV_SAV_LABEL }}</span>
              <div class="divider" *ngIf="!last && !['ov', 'ds'].includes(brand)"></div>
            </mat-option>
          </div>
        </mat-select>
        <!-- <mat-error *ngIf="animalControl.hasError('required')"
      >Please choose an animal</mat-error
    > -->
        <!-- <mat-hint>{{ animalControl.value?.sound }}</mat-hint> -->
      </mat-form-field>
    </div>
    <!--version mobile third section-->
    <div *ngIf="deviceIsMobile()" class="sav-container-mobile" [ngClass]="[brandClass, countryClass]"
      [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
      <div *ngIf="!isPHEV" class="sub-sav-container-mobile-one" [ngClass]="[brandClass, countryClass]"
        [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div class="first-section-mobile" [class.eco-advisor-no-results]="saving4ByYear <= 0"
          [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <div class="sva-title-section economy-section-result" [class.eco-advisor-no-results]="saving4ByYear <= 0"
            [ngClass]="[brandClass, countryClass]" role="status" [attr.aria-label]="
              saving4ByYear > 0 ? lev_sav_results_section : lev_sav_no_economies
            ">
            {{
            saving4ByYear > 0 ? lev_sav_results_section : lev_sav_no_economies
            }}
          </div>
          <div *ngIf="saving4ByYear > 0" class="sav-sub-title-or-small-results-mobile"
            [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
            <div class="sav-big-value" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
                saving4ByYear > 0
                  ? saving4ByYear + ' ' + lev_sav_currency_big_value
                  : ''
              ">
              {{
              saving4ByYear > 0
              ? saving4ByYear + " " + lev_sav_currency_big_value
              : ""
              }}
            </div>
            <div class="sav-big-value-text" role="status"
              [attr.aria-label]="saving4ByYear > 0 ? lev_sav_results_text : ''">
              {{ saving4ByYear > 0 ? lev_sav_results_text : "" }}
            </div>
          </div>
        </div>
        <!--      <div class="second-section-mobile" [ngClass]="[brandClass, countryClass]">-->
        <!--        <div class="sav-second-section-title" [ngClass]="[brandClass, countryClass]">-->
        <!--          {{sav_second_section_title}}-->
        <!--        </div>-->
        <!--      </div>-->
      </div>
      <div *ngIf="!isPHEV && savingByYear > 0" class="sub-sav-container-mobile-two"
        [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div class="third-section-mobile" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <div class="optimized-container">
            <div class="sav-sub-title-or-small-results-mobile sav-clarification" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
              [attr.aria-label]="savingByYear > 0 ? lev_sav_results_annee : ''">
              {{ savingByYear > 0 ? lev_sav_results_annee : "" }}
            </div>
            <div class="sav-small-value" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
                savingByYear > 0
                  ? savingByYear + ' ' + lev_sav_currency_big_value
                  : ''
              ">
              {{
              savingByYear > 0
              ? savingByYear + " " + lev_sav_currency_big_value
              : ""
              }}
            </div>
          </div>
          <div class="optimized-container">
            <div class="sav-sub-title-or-small-results-mobile sav-clarification" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
              [attr.aria-label]="savingByMonth > 0 ? lev_sav_results_mois : ''">
              {{ savingByMonth > 0 ? lev_sav_results_mois : "" }}
            </div>
            <div class="sav-small-value" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
                savingByMonth > 0
                  ? savingByMonth + ' ' + lev_sav_currency_big_value
                  : ''
              ">
              {{
              savingByMonth > 0
              ? savingByMonth + " " + lev_sav_currency_big_value
              : ""
              }}
            </div>
          </div>
        </div>
        <!--      <div class="fourth-section-mobile" [ngClass]="[brandClass, countryClass]">-->
        <!--        <div class="sav-second-section-value" [ngClass]="[brandClass, countryClass]">-->
        <!--          {{chargesByWeek}}-->
        <!--          <img *ngIf="icon_img" [src]="icon_img" class="img-responsive sav-icon" [ngClass]="[brandClass, countryClass]" alt="charge icon"/>-->
        <!--        </div>-->
        <!--        <div class="sav-description-mobile" [ngClass]="[brandClass, countryClass]">{{sav_second_description_mobile}}</div>-->
        <!--      </div>-->
      </div>

      <div *ngIf="isPHEV" class="sub-sav-container-mobile-one" [class.phev]="isPHEV"
        [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div class="first-section-mobile" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <div class="sva-title-section economy-section-result" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              saving4ByYear > 0 ? lev_sav_results_section : lev_sav_no_economies
            ">
            {{
            saving4ByYear > 0 ? lev_sav_results_section : lev_sav_no_economies
            }}
          </div>
          <div class="sav-sub-title-or-small-results-mobile" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
            [attr.aria-label]="lev_sav_results_text">
            {{ lev_sav_results_text }}
            <div class="sav-big-value" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
                saving4ByYear > 0
                  ? saving4ByYear + ' ' + lev_sav_currency_big_value
                  : ''
              ">
              {{
              saving4ByYear > 0
              ? saving4ByYear + " " + lev_sav_currency_big_value
              : ""
              }}
            </div>
          </div>
          <div class="first-section-mobile-savings">
            <div class="sav-small-value left" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
                savingByYear > 0
                  ? savingByYear +
                    ' ' +
                    lev_sav_currency_big_value +
                    ' ' +
                    lev_sav_results_annee
                  : ' '
              ">
              {{
              savingByYear > 0
              ? savingByYear +
              " " +
              lev_sav_currency_big_value +
              "" +
              lev_sav_results_annee
              : ""
              }}
            </div>
            <div class="sav-small-value right" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
                savingByMonth > 0
                  ? savingByMonth +
                    ' ' +
                    lev_sav_currency_big_value +
                    ' ' +
                    lev_sav_results_month
                  : ' '
              ">
              {{
              savingByMonth > 0
              ? savingByMonth +
              " " +
              lev_sav_currency_big_value +
              "" +
              lev_sav_results_month
              : ""
              }}
            </div>
          </div>
          <div class="first-section-mobile-mileage">
            <div class="sav-small-value left" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
                annualMileage + ' ' + lev_sav_results_distance_annee
              ">
              {{ annualMileage + " " + lev_sav_results_distance_annee }}
            </div>
            <div class="sav-small-value right" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
                monthMileage + ' ' + lev_sav_results_distance_month
              ">
              {{ monthMileage + " " + lev_sav_results_distance_month }}
            </div>
          </div>
        </div>
        <div class="second-section-mobile" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <div class="sav-description-mobile" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="vehicleLabel">
            {{ vehicleLabel }}
          </div>
          <div class="sav-second-section-title" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
            [attr.aria-label]="electricDrivePercent + '%'">
            {{ electricDrivePercent + "%" }}
          </div>
          <div class="sav-description-mobile" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
            [attr.aria-label]="sav_second_section_title">
            {{ sav_second_section_title }}
          </div>
        </div>
      </div>
    </div>
    <div class="sav-container-top-side">
      <div class="sav-container col-md-4 col-sm-12" [class.sav-first-mobile]="deviceIsMobile()" [class.phev]="isPHEV"
        [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div class="row sva-title-section" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
          [attr.aria-label]="lev_sav_deplacement_section">
          {{ lev_sav_deplacement_section }}
        </div>
        <div class="row sav-sub-container" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <div *ngIf="lev_sav_annuel" class="sav-sub-container-title-section" (click)="showOrHideContent()">
            <div class="sva-sub-title" [ngClass]="[brandClass, countryClass]"
              [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="lev_sav_annuel">
              {{ lev_sav_annuel }}
            </div>
            <div *ngIf="isPHEV" class="sav-arrow" [class.up]="!this.isDown"></div>
          </div>
          <app-slider *ngIf="!this.isDown || !isPHEV" class="sav-slider" [label]="distance"
            [sliderText]="lev_sav_annual_slider_title" [brand]="brandClass" [country]="countryClass"
            (onChangeSliderValue)="changeAnnualMileage($event)" [defaultValue]="annualMileage" [min]="annualMileageMin"
            [max]="annualMileageMax" [step]="annualMileageStep" [unit]="lev_sav_weekday_slider_unit"></app-slider>
          <app-slider *ngIf="!this.isDown && isPHEV" class="sav-slider"
            [sliderText]="lev_sav_weekday_number_of_daily_charge_slider_title" [brand]="brandClass"
            [country]="countryClass" (onChangeSliderValue)="changeWeekDayNumberDailyCharge($event)"
            [defaultValue]="weekDayNumberDailyCharge" [min]="weekDayNumberDailyChargeMin"
            [max]="weekDayNumberDailyChargeMax" [step]="weekDayNumberDailyChargeStep"></app-slider>
          <!--      <app-switcher *ngIf="!this.isDown || !isPHEV" class="sav-switcher"-->
          <!--                    [toggleText]="lev_sav_weekday_switcher_title"-->
          <!--                    [brand]="brandClass"-->
          <!--                    [country]="countryClass"-->
          <!--                    [checked]="isUrbanOrMixedWeekDay"-->
          <!--                    (onCheck)="toggleUrbanMixedWeekDay($event)"-->
          <!--                    [active]="lev_sav_weekday_switcher_active"-->
          <!--                    [inactive]="lev_sav_weekday_switcher_inactive"></app-switcher>-->
        </div>
        <!--    <div class="row sav-sub-container" [ngClass]="[brandClass, countryClass]">-->
        <!--      <div class="sav-sub-container-title-section" (click)="showOrHideContent()">-->
        <!--        <div class="sva-sub-title" [ngClass]="[brandClass, countryClass]">-->
        <!--          {{lev_sav_weekend}}-->
        <!--        </div>-->
        <!--        <div *ngIf="isPHEV" class="sav-arrow" [class.up]="this.isDown"></div>-->
        <!--      </div>-->
        <!--      <app-slider *ngIf="this.isDown || !isPHEV" class="sav-slider"-->
        <!--                  [sliderText]="lev_sav_weekend_slider_title"-->
        <!--                  [brand]="brandClass"-->
        <!--                  [country]="countryClass"-->
        <!--                  (onChangeSliderValue) = "changeWeekEndMileage($event)"-->
        <!--                  [defaultValue]="weekEndMileage"-->
        <!--                  [min]="weekEndMileageMin"-->
        <!--                  [max]="weekEndMileageMax"-->
        <!--                  [step]="weekEndMileageStep"-->
        <!--                  [unit]="lev_sav_weekend_slider_unit"></app-slider>-->
        <!--      <app-slider *ngIf="this.isDown && isPHEV" class="sav-slider"-->
        <!--                  [sliderText]="lev_sav_weekend_number_of_daily_charge_slider_title"-->
        <!--                  [brand]="brandClass"-->
        <!--                  [country]="countryClass"-->
        <!--                  (onChangeSliderValue) = "changeWeekEndNumberDailyCharge($event)"-->
        <!--                  [defaultValue]="weekEndNumberDailyCharge"-->
        <!--                  [min]="weekEndNumberDailyChargeMin"-->
        <!--                  [max]="weekEndNumberDailyChargeMax"-->
        <!--                  [step]="weekEndNumberDailyChargeStep"></app-slider>-->
        <!--      <app-switcher *ngIf="this.isDown || !isPHEV" class="sav-switcher"-->
        <!--                    [brand]="brandClass"-->
        <!--                    [country]="countryClass"-->
        <!--                    [toggleText]="lev_sav_weekend_switcher_title"-->
        <!--                    [checked]="isUrbanOrMixedWeekEnd"-->
        <!--                    [active]="lev_sav_weekend_switcher_active"-->
        <!--                    (onCheck)="toggleUrbanMixedWeekEnd($event)"-->
        <!--                    [inactive]="lev_sav_weekend_switcher_inactive"></app-switcher>-->
        <!--    </div>-->
      </div>
      <div class="sav-container col-md-4 col-sm-12" [ngClass]="[brandClass, countryClass]"
        [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div class="row sva-title-section" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
          [attr.aria-label]="lev_sav_your_vehicle">
          {{ lev_sav_your_vehicle }}
        </div>
        <div class="row sav-sub-container" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <app-slider class="sav-slider" [label]="fuelCost" [sliderText]="lev_sav_slider_fuel_cost_title"
            (onChangeSliderValue)="changeFuelCost($event)" [brand]="brandClass" [country]="countryClass"
            [defaultValue]="fuelCost" [min]="fuelCostMin" [max]="fuelCostMax" [step]="fuelCostStep"
            [unit]="lev_sav_slider_fuel_cost_unit"></app-slider>
          <app-slider class="sav-slider" [label]="fuelEfficiency" [sliderText]="lev_sav_slider_fuel_efficiency_title"
            [brand]="brandClass" [country]="countryClass" (onChangeSliderValue)="changeFuelEfficiencyCost($event)"
            [defaultValue]="fuelEfficiency" [min]="fuelEfficiencyMin" [max]="fuelEfficiencyMax"
            [step]="fuelEfficiencyStep" [unit]="lev_sav_slider_fuel_efficiency_unit"></app-slider>
        </div>
      </div>
      <div class="sav-container sav-container-second-section col-md-4 col-sm-12" [ngClass]="[brandClass, countryClass]"
        [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div class="row sva-title-section sav-top-white-space sub-section-title" [class.phev]="isPHEV"
          [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
          [attr.aria-label]="
            lev_sav_couts_vehicule_section +
            ' ' +
            (vehicle ? vehicle.LEV_SAV_LABEL : '')
          ">
          {{
          lev_sav_couts_vehicule_section +
          " " +
          (vehicle ? vehicle.LEV_SAV_LABEL : "")
          }}
        </div>
        <div class="row sav-sub-container" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <app-slider class="sav-slider" [label]="electricityCost" [sliderText]="lev_sav_slider_electricity_cost_title"
            [brand]="brandClass" [country]="countryClass" (onChangeSliderValue)="changeElectricityCost($event)"
            [defaultValue]="electricityCost" [min]="electricityCostMin" [max]="electricityCostMax"
            [step]="electricityCostStep" [unit]="lev_sav_slider_electricity_cost_unit"></app-slider>
        </div>
      </div>
    </div>
    <div *ngIf="!deviceIsMobile()" class="sav-container sav-container-third-section col-md-12"
      [class.eco-advisor-no-results]="saving4ByYear <= 0" [ngClass]="[brandClass, countryClass]"
      [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
      <div class="sva-title-section economy-section-result col-md-4" [class.eco-advisor-no-results]="saving4ByYear <= 0"
        [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
        [attr.aria-label]="
          saving4ByYear > 0 ? lev_sav_results_section : lev_sav_no_economies
        ">
        {{ saving4ByYear > 0 ? lev_sav_results_section : lev_sav_no_economies }}
      </div>
      <div *ngIf="saving4ByYear > 0" class="first-section col-md-4" [ngClass]="[brandClass, countryClass]"
        [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div class="sav-sub-title-or-small-results" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <div class="sav-big-value" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              saving4ByYear > 0
                ? saving4ByYear + ' ' + lev_sav_currency_big_value
                : ''
            ">
            {{
            saving4ByYear > 0
            ? saving4ByYear + " " + lev_sav_currency_big_value
            : ""
            }}
          </div>
          <div class="sav-big-value-text" role="status"
            [attr.aria-label]="saving4ByYear > 0 ? lev_sav_results_text : ''">
            {{ saving4ByYear > 0 ? lev_sav_results_text : "" }}
          </div>
        </div>
        <div *ngIf="isPHEV && savingByYear > 0" class="sav-sub-title-or-small-results sav-clarification"
          [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <div class="sav-small-value left" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              savingByYear > 0
                ? savingByYear +
                  ' ' +
                  lev_sav_currency_big_value +
                  ' ' +
                  lev_sav_results_annee
                : ''
            ">
            {{
            savingByYear > 0
            ? savingByYear +
            " " +
            lev_sav_currency_big_value +
            " " +
            lev_sav_results_annee
            : ""
            }}
          </div>
          <div class="sav-small-value right" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              savingByMonth > 0
                ? savingByMonth +
                  ' ' +
                  lev_sav_currency_big_value +
                  ' ' +
                  lev_sav_results_month
                : ''
            ">
            {{
            savingByMonth > 0
            ? savingByMonth +
            " " +
            lev_sav_currency_big_value +
            " " +
            lev_sav_results_month
            : ""
            }}
          </div>
        </div>
        <div *ngIf="isPHEV && annualMileage" class="sav-sub-title-or-small-results sav-clarification"
          [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
          <div class="sav-small-value left" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              annualMileage + ' ' + lev_sav_results_distance_annee
            ">
            {{ annualMileage + " " + lev_sav_results_distance_annee }}
          </div>
          <div class="sav-small-value right" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              monthMileage + ' ' + lev_sav_results_distance_month
            ">
            {{ monthMileage + " " + lev_sav_results_distance_month }}
          </div>
        </div>
      </div>
      <div *ngIf="savingByYear > 0" class="section col-md-4" [ngClass]="[brandClass, countryClass]"
        [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div *ngIf="!isPHEV" class="sav-sub-title-or-small-results sav-clarification"
          [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
          [attr.aria-label]="savingByYear > 0 ? lev_sav_results_annee : ''">
          {{ savingByYear > 0 ? lev_sav_results_annee : "" }}
          <div class="sav-small-value" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              savingByYear > 0
                ? savingByYear + ' ' + lev_sav_currency_big_value
                : ''
            ">
            {{
            savingByYear > 0
            ? savingByYear + " " + lev_sav_currency_big_value
            : ""
            }}
          </div>
        </div>
        <div *ngIf="!isPHEV && savingByMonth > 0" class="sav-sub-title-or-small-results sav-clarification"
          [ngClass]="[brandClass, countryClass]" [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status"
          [attr.aria-label]="savingByMonth > 0 ? lev_sav_results_mois : ''">
          {{ savingByMonth > 0 ? lev_sav_results_mois : "" }}
          <div class="sav-small-value" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              savingByMonth > 0
                ? savingByMonth + ' ' + lev_sav_currency_big_value
                : ''
            ">
            {{
            savingByMonth > 0
            ? savingByMonth + " " + lev_sav_currency_big_value
            : ""
            }}
          </div>
        </div>
      </div>
      <!--    <div *ngIf="!isPHEV" class="second-section" [ngClass]="[brandClass, countryClass]">-->
      <!--      <div class="sav-second-section-title" [ngClass]="[brandClass, countryClass]">-->
      <!--        {{sav_second_section_title}}-->
      <!--        <div class="sav-second-section-value" [ngClass]="[brandClass, countryClass]">-->
      <!--          {{chargesByWeek}}-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <img *ngIf="icon_img" [src]="icon_img"-->
      <!--           class="img-responsive sav-icon"-->
      <!--           [ngClass]="[brandClass, countryClass]"-->
      <!--           alt="charge icon"/>-->
      <!--    </div>-->
      <div *ngIf="isPHEV" class="second-section" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
        [class.vx]="brandClass == 'ov' && countryClass == 'gb'">
        <div class="sav-second-section-title" [ngClass]="[brandClass, countryClass]"
          [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="vehicleLabel">
          {{ vehicleLabel }}
          <div class="sav-second-section-value" [class.phev]="isPHEV" [ngClass]="[brandClass, countryClass]"
            [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="
              electricDrivePercent + '%' + ' ' + sav_second_section_title
            ">
            {{ electricDrivePercent + "%" }}
          </div>
          {{ sav_second_section_title }}
        </div>
      </div>
    </div>

    <div *ngIf="lev_sav_legal_mentions" class="sav-legal-mentions" [ngClass]="[brandClass, countryClass]"
      [class.vx]="brandClass == 'ov' && countryClass == 'gb'" role="status" [attr.aria-label]="lev_sav_legal_mentions"
      [innerHTML]="lev_sav_legal_mentions"></div>
  </div>
</main>