<main>
  <div
    class="lev-qeva"
    [class.d1]="isd1 === 'true'"
    [class.ac]="tec_citroen"
    [class.ap]="tec_peugeot"
    [class.ds]="tec_ds"
    [class.ov]="tec_opel"
  >
    <!-- Start: Main Title Header -->
    <div
      *ngIf="!tec_ds"
      class="lev-qeva-header"
      role="status"
      [attr.aria-label]="labelCulture.tec_title"
      [class.ac]="tec_citroen"
      [class.ap]="tec_peugeot"
      [class.ov]="tec_opel"
    >
      {{ labelCulture.tec_title }}
    </div>
    <div *ngIf="tec_ds" class="lev-qeva-header ds"></div>
    <!-- End:   Main Title Header -->

    <form
      method="POST"
      #tecForm="ngForm"
      [class.ac]="tec_citroen"
      [class.ap]="tec_peugeot"
      [class.ds]="tec_ds"
      [class.ov]="tec_opel"
    >
      <!-- Start:  Image -->
      <ng-container
        *ngIf="!isIE; then thenTemplate; else elseTemplate"
      ></ng-container>
      <ng-template #thenTemplate>
        <div
          class="lev-qeva-image"
          [class.ac]="tec_citroen"
          [class.ap]="tec_peugeot"
          [class.ds]="tec_ds"
          [class.ov]="tec_opel"
        >
          <img
            alt=""
            *ngIf="cssData.img_top_xs && cssData.img_top_md"
            srcset="{{ cssData.img_top_xs }} 320w, {{ cssData.img_top_xs }} 640w, {{ cssData.img_top_md }} 2x"
          />
        </div>
      </ng-template>
      <ng-template #elseTemplate>
        <div
          class="lev-qeva-image"
          role="img"
          aria-label="Decorative image"
          [class.ac]="tec_citroen"
          [class.ap]="tec_peugeot"
          [class.ds]="tec_ds"
          [class.ov]="tec_opel"
          [ngStyle]="{
            'background-image': 'url(&quot;' + cssData.img_top_xs + '&quot;)',
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position-x': 'center',
            'background-position-y': 'center'
          }"
        ></div>
      </ng-template>
      <!-- End:   Image -->

      <div
        class="lev-qeva-questions"
        [class.ac]="tec_citroen"
        [class.ap]="tec_peugeot"
        [class.ds]="tec_ds"
        [class.ov]="tec_opel"
      >
        <!-- Start: Question -->
        <div
          class="lev-qeva-title"
          role="status"
          [attr.aria-label]="
            (tec_blk_q1_show && labelCulture.tec_blk_q1) ||
            (tec_blk_q2_show && labelCulture.tec_blk_q2) ||
            (tec_blk_q3_show && labelCulture.tec_blk_q3) ||
            (tec_res_1 && labelCulture.tec_result_title1) ||
            (tec_res_2 && labelCulture.tec_result_title2) ||
            (tec_res_3 && labelCulture.tec_result_title3) ||
            (tec_res_4 && labelCulture.tec_result_title4) ||
            ''
          "
          [class.final]="tec_results_ok"
          [class.ac]="tec_citroen"
          [class.ap]="tec_peugeot"
          [class.ds]="tec_ds"
          [class.ov]="tec_opel"
        >
          {{
            (tec_blk_q1_show && labelCulture.tec_blk_q1) ||
              (tec_blk_q2_show && labelCulture.tec_blk_q2) ||
              (tec_blk_q3_show && labelCulture.tec_blk_q3) ||
              (tec_res_1 && labelCulture.tec_result_title1) ||
              (tec_res_2 && labelCulture.tec_result_title2) ||
              (tec_res_3 && labelCulture.tec_result_title3) ||
              (tec_res_4 && labelCulture.tec_result_title4) ||
              ""
          }}
        </div>
        <!-- End:   Question -->

        <!-- Start: Text result -->
        <div
          class="lev-qeva-statment"
          *ngIf="tec_results_ok"
          role="status"
          [attr.aria-label]="
            (tec_res_1 && labelCulture.tec_result_text1) ||
            (tec_res_2 && labelCulture.tec_result_text2) ||
            (tec_res_3 && labelCulture.tec_result_text3) ||
            (tec_res_4 && labelCulture.tec_result_text4) ||
            ''
          "
          [class.ac]="tec_citroen"
          [class.ap]="tec_peugeot"
          [class.ds]="tec_ds"
          [class.ov]="tec_opel"
        >
          {{
            (tec_res_1 && labelCulture.tec_result_text1) ||
              (tec_res_2 && labelCulture.tec_result_text2) ||
              (tec_res_3 && labelCulture.tec_result_text3) ||
              (tec_res_4 && labelCulture.tec_result_text4) ||
              ""
          }}
        </div>
        <!-- End:   Text result -->

        <!-- Start: Anwser for Question 1 -->
        <mat-radio-group
          *ngIf="tec_blk_q1_show"
          class="lev-qeva-answers of-question-1"
          role="radiogroup"
          [class.ac]="tec_citroen"
          [class.ap]="tec_peugeot"
          [class.ds]="tec_ds"
          [class.ov]="tec_opel"
          [(ngModel)]="tec_blk_q1"
          name="tec_blk_q1_choice"
        >
          <mat-radio-button
            class="lev-qeva-answer"
            [class.ac]="tec_citroen"
            role="radio"
            [attr.aria-label]="labelCulture.tec_blk_q1_choice_urban"
            (click)="radioTag(1)"
            value="1"
            >{{ labelCulture.tec_blk_q1_choice_urban }}</mat-radio-button
          >
          <mat-radio-button
            class="lev-qeva-answer"
            [class.ac]="tec_citroen"
            role="radio"
            [attr.aria-label]="labelCulture.tec_blk_q1_choice_highway"
            (click)="radioTag(3)"
            value="3"
            >{{ labelCulture.tec_blk_q1_choice_highway }}</mat-radio-button
          >
          <mat-radio-button
            class="lev-qeva-answer"
            [class.ac]="tec_citroen"
            role="radio"
            [attr.aria-label]="labelCulture.tec_blk_q1_choice_rural"
            (click)="radioTag(2)"
            value="2"
            >{{ labelCulture.tec_blk_q1_choice_rural }}</mat-radio-button
          >
          <mat-radio-button
            class="lev-qeva-answer"
            [class.ac]="tec_citroen"
            role="radio"
            [attr.aria-label]="labelCulture.tec_blk_q1_choice_mixed"
            (click)="radioTag(4)"
            value="4"
            >{{ labelCulture.tec_blk_q1_choice_mixed }}</mat-radio-button
          >
        </mat-radio-group>
        <!-- End:   Anwser for Question 1 -->

        <!-- Start: Anwser for Question 2 -->
        <mat-form-field
          *ngIf="tec_blk_q2_show"
          class="lev-qeva-answers of-question-2"
          [attr.label]="labelCulture.tec_blk_q2_distance_unit"
          [class.ac]="tec_citroen"
          [class.ap]="tec_peugeot"
          [class.ds]="tec_ds"
          [class.ov]="tec_opel"
        >
          <input
            class="lev-qeva-answer"
            role="textbox"
            [attr.aria-label]="
              tec_q2_r1 +
              (country.toLocaleLowerCase() === 'vx' ? 'miles' : 'km')
            "
            matInput
            (blur)="kmTag()"
            (focus)="focusKm()"
            myNumberOnly
            pattern="[0-9]{1,4}"
            maxlength="4"
            name="tec_q2_r1"
            [(ngModel)]="tec_q2_r1"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            customMin="1"
          />
          <mat-placeholder
            class="lev-qeva-answer"
            *ngIf="tec_opel || tec_citroen"
            [hidden]="kmClicked"
            >{{ labelCulture.tec_blk_q2_distance_unit }}</mat-placeholder
          >
        </mat-form-field>
        <!-- End:   Anwser for Question 2 -->

        <!-- Start: Anwser for Question 3 -->
        <mat-radio-group
          *ngIf="tec_blk_q3_show"
          class="lev-qeva-answers of-question-3"
          [class.ac]="tec_citroen"
          [class.ap]="tec_peugeot"
          [class.ds]="tec_ds"
          [class.ov]="tec_opel"
          [(ngModel)]="tec_blk_q3"
          role="radiogroup"
          name="tec_blk_q3_choice"
        >
          <mat-radio-button
            class="lev-qeva-answer"
            [class.ac]="tec_citroen"
            role="radio"
            [attr.aria-label]="labelCulture.tec_blk_q3_stage_1"
            value="5"
            (click)="radioTag(5)"
            >{{ labelCulture.tec_blk_q3_stage_1 }}</mat-radio-button
          >
          <mat-radio-button
            class="lev-qeva-answer"
            [class.ac]="tec_citroen"
            role="radio"
            [attr.aria-label]="labelCulture.tec_blk_q3_stage_2"
            value="6"
            (click)="radioTag(6)"
            >{{ labelCulture.tec_blk_q3_stage_2 }}</mat-radio-button
          >
          <mat-radio-button
            class="lev-qeva-answer"
            [class.ac]="tec_citroen"
            role="radio"
            [attr.aria-label]="labelCulture.tec_blk_q3_stage_3"
            value="7"
            (click)="radioTag(7)"
            >{{ labelCulture.tec_blk_q3_stage_3 }}</mat-radio-button
          >
        </mat-radio-group>
        <!-- End:   Anwser for Question 3 -->
        <div
          class="lev-qeva-footer"
          [class.final]="tec_results_ok"
          [class.ds]="tec_ds"
          *ngIf="tec_ds"
        >
          <!-- Start: Previous Button -->
          <button
            class="lev-qeva-button previous"
            *ngIf="!tec_blk_q1_show && !tec_results_ok"
            role="button"
            [attr.aria-label]="labelCulture.tec_blk_cta_back"
            [class.ds]="tec_ds"
            (click)="back()"
          >
            <span>{{ labelCulture.tec_blk_cta_back }}</span>
          </button>
          <!-- End:   Previous Button -->

          <!-- Start: Restart Button -->
          <button
            class="lev-qeva-button restart"
            *ngIf="tec_results_ok"
            role="button"
            [attr.aria-label]="labelCulture.tec_blk_cta_restart"
            [class.final]="tec_results_ok"
            [class.ds]="tec_ds"
            (click)="restart()"
          >
            <span>{{ labelCulture.tec_blk_cta_restart }}</span>
          </button>
          <!-- End:   Restart Button -->

          <!-- Start: Next Button -->
          <button
            class="lev-qeva-button next next-lev"
            *ngIf="!tec_results_ok"
            role="button"
            [attr.aria-label]="
              (!tec_results_ok &&
                (tec_blk_q1_show || tec_blk_q2_show) &&
                labelCulture.tec_blk_cta_continue) ||
              (!tec_results_ok &&
                tec_blk_q3_show &&
                labelCulture.tec_blk_cta_validate)
            "
            [class.ds]="tec_ds"
            [class.disabled]="tec_blk_q2_show && !tec_q2_r1"
            (click)="!tec_results_ok && !tec_blk_q2_show && save()"
            (click)="(tec_blk_q1_show || tec_blk_q2_show) && continue()"
          >
            <span>
              {{
                (!tec_results_ok &&
                  (tec_blk_q1_show || tec_blk_q2_show) &&
                  labelCulture.tec_blk_cta_continue) ||
                  (!tec_results_ok &&
                    tec_blk_q3_show &&
                    labelCulture.tec_blk_cta_validate)
              }}
            </span>
          </button>
          <!-- End:   Next Button -->

          <!-- Start: Other Buttons -->
          <ng-container
            *ngFor="let result of tec_results_ok ? results : []; let i = index"
          >
            <button
              class="lev-qeva-button"
              *ngIf="i % 2 == 0"
              role="button"
              [attr.aria-label]="results[i]"
              [class.final]="tec_results_ok"
              [class.ds]="tec_ds"
              (click)="ctaTag(results[i + 1])"
            >
              <span>{{ results[i] }}</span>
            </button>
          </ng-container>
          <!-- End:   Others Buttons -->
        </div>
      </div>
    </form>

    <div
      class="lev-qeva-footer"
      [class.final]="tec_results_ok"
      [class.ac]="tec_citroen"
      [class.ap]="tec_peugeot"
      [class.ds]="tec_ds"
      [class.ov]="tec_opel"
      *ngIf="!tec_ds"
    >
      <!-- Start: Previous Button -->
      <button
        class="lev-qeva-button previous"
        *ngIf="!tec_blk_q1_show && !tec_results_ok"
        role="button"
        [attr.aria-label]="labelCulture.tec_blk_cta_back"
        [class.ac]="tec_citroen"
        [class.ap]="tec_peugeot"
        [class.ds]="tec_ds"
        [class.ov]="tec_opel"
        (click)="back()"
      >
        <img
          alt=""
          class="citroen-img"
          *ngIf="tec_citroen"
          src="{{ cssData.arrow_actif_left }}"
        />
        <span>{{ labelCulture.tec_blk_cta_back }}</span>
      </button>
      <!-- End:   Previous Button -->

      <!-- Start: Restart Button -->
      <button
        class="lev-qeva-button restart"
        *ngIf="tec_results_ok"
        role="button"
        [attr.aria-label]="labelCulture.tec_blk_cta_restart"
        [class.final]="tec_results_ok"
        [class.ac]="tec_citroen"
        [class.ap]="tec_peugeot"
        [class.ds]="tec_ds"
        [class.ov]="tec_opel"
        (click)="restart()"
      >
        <ng-container [ngSwitch]="true">
          <img
            alt=""
            class="citroen-img"
            *ngSwitchCase="tec_citroen && tecForm.valid"
            src="{{ cssData.arrow_actif_left }}"
          />
          <img
            alt=""
            class="citroen-img"
            *ngSwitchCase="tec_citroen && !tecForm.valid"
            src="{{ cssData.arrow_disabled_left }}"
          />
        </ng-container>
        <span>{{ labelCulture.tec_blk_cta_restart }}</span>
      </button>
      <!-- End:   Restart Button -->

      <!-- Start: Progress Dot -->
      <div
        class="lev-qeva-progress"
        role="status"
        aria-label="Progress Dot"
        [class.ac]="tec_citroen"
        [class.ap]="tec_peugeot"
        [class.ds]="tec_ds"
        [class.ov]="tec_opel"
        [class.invisible]="tec_results_ok"
      >
        <div
          class="lev-qeva-progress-dot"
          role="status"
          [attr.aria-label]="
            'first step ' + (tec_blk_q1_show ? 'selected' : '')
          "
          [class.selected]="tec_blk_q1_show"
        ></div>
        <div
          class="lev-qeva-progress-dot"
          role="status"
          [attr.aria-label]="
            'second step ' + (tec_blk_q2_show ? 'selected' : '')
          "
          [class.selected]="tec_blk_q2_show"
        ></div>
        <div
          class="lev-qeva-progress-dot"
          role="status"
          [attr.aria-label]="'last step ' + (tec_blk_q3_show ? 'selected' : '')"
          [class.selected]="tec_blk_q3_show"
        ></div>
      </div>
      <!-- End:   Progress Dot -->

      <!-- Start: Next Button -->
      <button
        class="lev-qeva-button next next-lev"
        *ngIf="!tec_results_ok"
        role="button"
        [attr.aria-label]="
          (!tec_results_ok &&
            (tec_blk_q1_show || tec_blk_q2_show) &&
            labelCulture.tec_blk_cta_continue) ||
          (!tec_results_ok &&
            tec_blk_q3_show &&
            labelCulture.tec_blk_cta_validate)
        "
        [class.ac]="tec_citroen"
        [class.ap]="tec_peugeot"
        [class.ds]="tec_ds"
        [class.ov]="tec_opel"
        [class.disabled]="tec_blk_q2_show && !tec_q2_r1"
        (click)="(tec_blk_q1_show || tec_blk_q2_show) && continue()"
        (click)="!tec_results_ok && !tec_blk_q2_show && save()"

      >
        <ng-container [ngSwitch]="true">
          <img
            alt=""
            class="citroen-img"
            *ngSwitchCase="
              tec_citroen &&
              !(
                (tec_blk_q2_show && !tec_q2_r1) ||
                !tecForm.touched ||
                !tecForm.valid
              )
            "
            src="{{ cssData.arrow_actif_right }}"
          />
          <img
            alt=""
            class="citroen-img"
            *ngSwitchCase="
              tec_citroen &&
              ((tec_blk_q2_show && !tec_q2_r1) ||
                !tecForm.touched ||
                !tecForm.valid)
            "
            src="{{ cssData.arrow_disabled_right }}"
          />
        </ng-container>
        <span>
          {{
            (!tec_results_ok &&
              (tec_blk_q1_show || tec_blk_q2_show) &&
              labelCulture.tec_blk_cta_continue) ||
              (!tec_results_ok &&
                tec_blk_q3_show &&
                labelCulture.tec_blk_cta_validate)
          }}
        </span>
      </button>
      <!-- End:   Next Button -->

      <!-- Start: Other Buttons -->
      <ng-container
        *ngFor="let result of tec_results_ok ? results : []; let i = index"
      >
        <button
          class="lev-qeva-button"
          *ngIf="i % 2 == 0"
          role="button"
          [attr.aria-label]="results[i]"
          [class.final]="tec_results_ok"
          [class.ac]="tec_citroen"
          [class.ap]="tec_peugeot"
          [class.ds]="tec_ds"
          [class.ov]="tec_opel"
          (click)="ctaTag(results[i + 1])"
        >
          <span>{{ results[i] }}</span>
        </button>
      </ng-container>
      <!-- End:   Others Buttons -->
    </div>

    <!-- Start: MENTIONS LEGALS -->
    <div
      class="lev-qeva-mention-legal"
      role="status"
      [attr.aria-label]="
        labelCulture.tec_mentions_legales +
        '\n' +
        (labelCulture.tec_mentions_legales_sub1 || '')
      "
      [class.ac]="tec_citroen"
      [class.ap]="tec_peugeot"
      [class.ds]="tec_ds"
      [class.ov]="tec_opel"
    >
      {{
        labelCulture.tec_mentions_legales +
          "\n" +
          (labelCulture.tec_mentions_legales_sub1 || "")
      }}
    </div>
    <!-- End:   MENTIONS LEGALS -->
  </div>
</main>
