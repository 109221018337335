<!-- brand-selector.component.html -->
<div class="selection">
  <h5>
    {{ labelCulture?.brand || "Brand" }}
  </h5>
  <mat-form-field appearance="outline" floatLabel="never">
    <mat-label>{{ labelCulture?.select || "Select" }}</mat-label>
    <mat-select
      [(ngModel)]="localSelectedBrand"
      name="brand"
      (ngModelChange)="onBrandChange($event)"
      [disabled]="readOnly"
    >
      <mat-option *ngFor="let brand of brands" [value]="brand.value">
        {{ brand.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
