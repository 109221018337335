import { API_CONFIG } from "@assets/plugSurfing_params/api_url_config";

export function addDynamicStyles() {
    let styles = `
    @font-face {
      font-family: 'DS_title_regular';
      src: url("`+API_CONFIG.server_url+`fonts/ds/DSTitleWebSmall_Regular.ttf");
    }

    @font-face {
      font-family: 'DS_title_italic';
      src: url("`+API_CONFIG.server_url+`fonts/ds/DSTitleWebSmall_Italic.ttf");
    }

    @font-face {
      font-family: 'DS_regular';
      src: url('`+API_CONFIG.server_url+`fonts/ds/DSAutomobiles_Regular.eot') format('eot'), url('`+API_CONFIG.server_url+`fonts/ds/DSAutomobiles_Regular.otf') format('otf'), url('`+API_CONFIG.server_url+`fonts/ds/DSAutomobiles_Regular.ttf') format('truetype');
    }

    @font-face {
      font-family: 'DS_Bold';
      src: url('`+API_CONFIG.server_url+`fonts/ds/DSAutomobiles_Bold.eot') format('eot'), url('`+API_CONFIG.server_url+`fonts/ds/DSAutomobiles-Bold.woff') format('woff'), url('`+API_CONFIG.server_url+`fonts/ds/DSAutomobiles_Bold.ttf') format('truetype');
    }`;
      const node = document.createElement('style');
      node.innerHTML = styles;
      document.body.appendChild(node);
    }
