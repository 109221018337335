<main>
  <div
    class="container"
    [class.d1]="isd1 === 'true'"
    [class.access-lev-widget]="us_citroen && isAccess"
    [class.container-ov]="us_opel"
    [class.ds_font]="us_ds"
    [class.ap_font]="us_peugeot"
    [ngClass]="
      cs_spoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
    "
    [class.spoticar_font]="cs_spoticar"
    [class.citroen_font]="us_citroen"
  >
    <div
      class="row"
      [class.row-cs-spoticar]="cs_spoticar"
      [class.row-cs-ds]="us_ds"
    >
      <div
        class="cs-header"
        [class.cs-header-stndl-ds]="us_ds && SearcheCriteria.countryCode !== ''"
        [class.cs-header-ac]="us_citroen"
        [class.cs-header-ap]="us_peugeot"
        [class.cs-header-spoticar]="cs_spoticar"
        [class.cs-header-ds]="us_ds"
        [class.cs-header-ov]="us_opel"
        [class.vauxhall]="cs_vauxhall"
      >
        <div
          class="block-select-vehicule"
          [class.access-lev-selector]="us_citroen && isAccess"
          [class.block-select-vehicule-ov]="us_opel"
          [class.vauxhall]="cs_vauxhall"
        >
          <span
            class="csTitles"
            role="status"
            [attr.aria-label]="labelCulture.cs_choose_vehicle"
            [class.csTitles-ac]="us_citroen"
            [class.csTitles-ap]="us_peugeot"
            [class.csTitles-spoticar]="cs_spoticar"
            [class.csTitles-ds]="us_ds"
            [class.csTitles-ov]="us_opel"
            [class.vauxhall]="cs_vauxhall"
            [class.ap_font]="us_peugeot"
            [class.is-right-to-left]="isRightToLeft"
            [class.spoticar_bold_font]="cs_spoticar"
            [class.peugeot_bold_font]="isd1 === 'true'"
          >
            {{ labelCulture.cs_choose_vehicle }}
          </span>
          <div
            class="block-vehicule-wrapper"
            [class.block-vehicule-wrapper-ds]="us_ds"
            [class.block-vehicule-wrapper-spoticar]="cs_spoticar"
            [class.block-vehicule-wrapper-ov]="us_opel"
            [class.vauxhall]="cs_vauxhall"
          >
            <button
              class="zone-accordion"
              [class.accordion]="vehs.length > 1"
              [class.zone-accordion-ds]="us_ds"
              [class.zone-accordion-ap]="us_peugeot"
              [class.zone-accordion-spoticar]="cs_spoticar"
              [class.zone-accordion-ac]="us_citroen"
              [class.zone-accordion-ov]="us_opel"
              role="button"
              tabindex="0"
              (click)="toggle()"
            >
              <img
                src="{{ vehicule.LEV_CS_Picture }}"
                class="image-vehicule"
                alt=""
                [class.image-vehicule-spoticar]="cs_spoticar"
                [class.image-vehicule-ap]="us_peugeot"
                [class.ac]="us_citroen"
                [class.cs-selector-vehicule-ds]="us_ds"
              />
              <span
                class="label-vehicule"
                role="status"
                [attr.aria-label]="vehicule.LEV_CS_label"
                [class.bold]="!us_citroen"
                [class.label-vehicule-ap]="us_peugeot"
                [class.label-vehicule-spoticar]="cs_spoticar"
                [class.label-vehicule-ac]="us_citroen"
              >
                {{ vehicule.LEV_CS_label }}</span
              >
              <div
                *ngIf="cs_spoticar && vehs.length > 1"
                [class.chevron-spoticar-1]="cs_spoticar"
              ></div>
            </button>
            <div
              class="cs-accordion-panel"
              style="display: none"
              role="radiogroup"
              [attr.aria-label]="'select : ' + labelCulture.cs_choose_vehicle"
              [class.hide-scroll-bar]="vehs.length - 1 < 4"
              [class.accordion-panel-ds]="us_ds"
              [class.ap]="us_peugeot"
              [class.spoticar]="cs_spoticar"
              [class.ds]="us_ds"
              [class.ov]="us_opel"
              [class.ac]="us_citroen"
            >
              <div
                *ngFor="
                  let v of filterItemsOfVehicules(vehicule);
                  let i = index
                "
                class="vehicules-to-select"
                [class.vehicules-to-select-ds]="us_ds"
                [class.vehicules-to-select-ap]="us_peugeot"
                [class.vehicules-to-select-spoticar]="cs_spoticar"
                [class.spoticar]="cs_spoticar"
                [class.vehicules-to-select-ac]="us_citroen"
                [class.vauxhall]="cs_vauxhall"
                role="radiogroup"
                tabindex="0"
                (keyup.enter)="vehiculeChanged(v)"
                (keyup.space)="vehiculeChanged(v)"
                (click)="vehiculeChanged(v)"
              >
                <img
                  src="{{ v.LEV_CS_Picture }}"
                  class="image-vehicule toBeSelected"
                  alt=""
                  [class.image-vehicule-toBeSelected-ap]="us_peugeot"
                  [class.image-vehicule-toBeSelected-spoticar]="cs_spoticar"
                  [class.cs-selector-vehicule-ds]="us_ds"
                />
                <span
                  class="label-vehicule"
                  role="status"
                  [attr.aria-label]="v.LEV_CS_label"
                  [class.label-vehicule-ap]="us_peugeot"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.label-vehicule-spoticar]="cs_spoticar"
                  [class.vehicules-to-select-ds]="us_ds"
                  [class.label-vehicule-ac]="us_citroen"
                  >{{ v.LEV_CS_label }}</span
                >
                <input
                  type="radio"
                  name="vehicule"
                  [(ngModel)]="vehicule"
                  [attr.aria-label]="v.LEV_CS_label"
                  (mousedown)="check($event, v)"
                  [value]="v"
                  [class.radio-ds]="us_ds"
                  [class.radio-ap]="us_peugeot"
                  [class.radio-ap-id]="us_peugeot"
                  [class.radio-spoticar]="cs_spoticar"
                  [class.radio-ac]="us_citroen"
                />
              </div>
            </div>
          </div>
          <div
            class="block-obc-wrapper"
            *ngIf="defaultobc == 'false'"
            [class.block-obc-wrapper-ap]="us_peugeot"
            [class.block-obc-wrapper-spoticar]="cs_spoticar"
            [class.block-obc-wrapper-ds]="us_ds"
            [class.block-obc-wrapper-ac]="us_citroen"
            [class.block-obc-wrapper-ov]="us_opel"
            [class.vauxhall]="cs_vauxhall"
          >
            <button
              role="button"
              tabindex="0"
              *ngIf="defaultobc == 'false'"
              class="accordion-obc zone-accordion-obc"
              [class.zone-accordion-obc-ds]="us_ds"
              [class.zone-accordion-obc-ap]="us_peugeot"
              [class.zone-accordion-obc-spoticar]="cs_spoticar"
              [class.zone-accordion-obc-ac]="us_citroen"
              [class.zone-accordion-obc-ov]="us_opel"
              [class.vauxhall]="cs_vauxhall"
              (click)="toggleOBC()"
            >
              <span
                class="label-obc"
                role="status"
                [attr.aria-label]="labelCulture.cs_obc"
                [class.inline-label-ap-d1]="isd1 === 'true'"
                [class.label-obc-ds]="us_ds"
                [class.is-right-to-left]="isRightToLeft"
                [class.label-obc-spoticar]="cs_spoticar"
                [class.label-obc-ac]="us_citroen"
              >
                {{ labelCulture.cs_obc }}
              </span>
              <span
                class="label-obc"
                role="status"
                [attr.aria-label]="obcValue + labelCulture.cs_kw"
                [class.bold]="!us_peugeot"
                [class.label-obc-ap]="us_peugeot"
                [class.label-obc-spoticar]="cs_spoticar"
                [class.is-right-to-left]="isRightToLeft"
                [class.label-obc-ap-d1]="isd1 === 'true'"
                [class.label-obc-ac]="us_citroen"
              >
                {{ obcValue }} {{ labelCulture.cs_kw }}
              </span>
              <div
                *ngIf="cs_spoticar"
                [class.chevron-spoticar-2]="cs_spoticar"
              ></div>
            </button>
            <div
              class="accordion-panel-obc"
              style="display: none"
              [class.accordion-panel-obc-ds]="us_ds"
              [class.accordion-panel-obc-spoticar]="cs_spoticar"
              [class.vauxhall]="cs_vauxhall"
              [class.accordion-panel-obc-ac]="us_citroen"
            >
              <div
                class="line-obc"
                [class.ac]="us_citroen"
                *ngFor="let o of vehicule.LEV_CS_OBC; let i = index"
                (click)="obcChanged(o)"
                (keyup.enter)="obcChanged(o)"
                (keyup.space)="obcChanged(o)"
                [ngStyle]="{ display: o == obcValue ? 'none' : 'block' }"
                role="list"
                tabindex="0"
              >
                <span
                  *ngIf="o && o !== obcValue"
                  [ngClass]="'label-obc label-obc' + i"
                  role="status"
                  [attr.aria-label]="labelCulture.cs_obc + labelCulture.cs_kw"
                  [class.label-obc-ds]="us_ds"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.label-obc-spoticar]="cs_spoticar"
                  [class.label-obc-ac]="us_citroen"
                >
                  {{ labelCulture.cs_obc }}
                </span>
                <span
                  *ngIf="o && o !== obcValue"
                  [ngClass]="'label-obc label-obc' + i"
                  [class.label-obc-ap-d1]="isd1 === 'true'"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.label-obc-spoticar]="cs_spoticar"
                  [class.label-obc-ac]="us_citroen"
                  >{{ o }} {{ labelCulture.cs_kw }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="link-obc"
            *ngIf="linkobc"
            [class.link-obc-ac]="us_citroen"
            [class.link-obc-ap]="us_peugeot"
            [class.link-obc-spoticar]="cs_spoticar"
            [class.link-obc-ds]="us_ds"
            [class.link-obc-ov]="us_opel"
            [class.link-obc-without-obc-value]="defaultobc"
          >
            <a
              href="{{ linkobc }}"
              target="_blank"
              role="link"
              [class.is-right-to-left]="isRightToLeft"
              [attr.aria-label]="labelCulture.cs_obc_link"
            >
              {{ labelCulture.cs_obc_link }}
              <img
                class="right-chevron"
                alt=""
                src="{{ cssData.chevron_right }}"
              />
            </a>
          </div>
        </div>
        <div
          class="block-slider-autonomy-wrapper"
          *ngIf="vehicule.LEV_CS_TYPE == 'BEV'"
          [class.block-slider-autonomy-wrapper-ac]="us_citroen"
          [class.block-slider-autonomy-wrapper-spoticar]="cs_spoticar"
          [class.block-slider-autonomy-wrapper-ov]="us_opel"
        >
          <div
            class="block-slider-wrapper"
            [class.block-slider-wrapper-ap]="us_peugeot"
            [class.block-slider-wrapper-spoticar]="cs_spoticar"
            [class.block-slider-wrapper-ac]="us_citroen"
            [class.is-right-to-left]="isRightToLeft"
            [class.block-slider-wrapper-ac-access]="us_citroen && isAccess"
            [class.block-slider-wrapper-ds]="us_ds"
            [class.block-slider-wrapper-ov]="us_opel"
            [class.vauxhall]="cs_vauxhall"
          >
            <p
              [class.ap_font]="us_peugeot"
              role="status"
              [attr.aria-label]="
                labelCulture.cs_indicate_initial_and_desired_charge
              "
              [class.spoticar_bold_font]="cs_spoticar"
              [class.is-right-to-left]="isRightToLeft"
              [class.peugeot_bold_font]="isd1 === 'true'"
              [class.csTitles]="us_peugeot"
            >
              {{ labelCulture.cs_indicate_initial_and_desired_charge }}
            </p>
            <div
              class="lev-cs-slider"
              [class.ac]="us_citroen"
              [class.ds]="us_ds"
              [class.spoticar]="cs_spoticar"
              [class.ov]="us_opel"
              [class.vauxhall]="cs_vauxhall"
            >
              <span
                class="floor"
                role="status"
                aria-label="0%"
                [class.spoticar]="cs_spoticar"
                [class.ac]="us_citroen"
                >0%</span
              >
              <ngx-slider
                [(value)]="socMini"
                [class.ds]="us_ds"
                [class.spoticar]="cs_spoticar"
                [(highValue)]="socMaxi"
                [attr.role]="'slider'"
                [attr.aria-valuemax]="socMaxi"
                [attr.aria-valuemin]="socMini"
                [attr.aria-valuenow]="socMini"
                [attr.aria-labelledby]="'batterySliderId'"
                [attr.aria-orientation]="'horizontal'"
                [attr.id]="'batterySliderId'"
                #batterySliderIdRef
                [options]="options"
                (userChange)="getLoadingTime()"
                (mouseup)="sliderPourcent()"
              ></ngx-slider>
              <span
                class="ceil"
                role="status"
                aria-label="100%"
                [class.spoticar]="cs_spoticar"
                [class.ac]="us_citroen"
                >100%</span
              >
            </div>
            <!-- <p>% SOC mini: <input type="number" [(ngModel)]="socMini"></p>
            <p>% SOC maxi: <input type="number" [(ngModel)]="socMaxi"></p> -->
          </div>
          <img
            class="right-chevron-autonomy"
            *ngIf="!us_citroen"
            src="{{ cssData.chevron_right_autonomy }}"
            alt=""
            [class.right-chevron-autonomy-ap]="us_peugeot"
            [class.right-chevron-autonomy-spoticar]="cs_spoticar"
            [class.right-chevron-autonomy-ac]="us_citroen"
            [class.right-chevron-autonomy-ds]="us_ds"
            [class.right-chevron-autonomy-ov]="us_opel"
            [class.access-lev-chevron-right]="us_citroen && isAccess"
            [class.vauxhall]="cs_vauxhall"
          />
          <!-- <div
            class="block-autonomy-wrapper"
            role="status"
            [attr.aria-label]="
              labelCulture.cs_autonomy_found +
              ' : ' +
              calculateAutonomy() +
              labelCulture.cs_distance_unit
            "
            [class.tablette]="isTablette()"
            [class.block-autonomy-wrapper-ac]="us_citroen"
            [class.block-autonomy-wrapper-ap]="us_peugeot"
            [class.block-autonomy-wrapper-spoticar]="cs_spoticar"
            [class.block-autonomy-wrapper-ds]="us_ds"
            [class.block-autonomy-wrapper-ov]="us_opel"
            [class.access-lev-bloc-autonomy-access]="us_citroen && isAccess"
            [class.vauxhall]="cs_vauxhall"
          >
            <p
              class="autonomy-title"
              [class.cs-header-autonomy-title-ap]="us_peugeot"
              [class.cs-header-autonomy-title-spoticar]="cs_spoticar"
              [class.ap_bold_font]="us_peugeot"
              [class.is-right-to-left]="isRightToLeft"
              [class.bold]="!us_citroen"
              [class.spoticar_bold_font]="cs_spoticar"
              [class.peugeot_bold_font]="isd1 === 'true'"
            >
              {{ labelCulture.cs_autonomy_found }}
            </p>
            <p
              class="autonomy-value"
              [class.autonomy-value-ap]="us_peugeot"
              [class.is-right-to-left]="isRightToLeft"
              [class.autonomy-value-spoticar]="cs_spoticar"
              [class.autonomy-value-ac]="us_citroen"
              [class.autonomy-value-ds]="us_ds"
              [class.autonomy-value-ov]="us_opel"
              [class.phev]="vehicule.LEV_CS_TYPE.toLowerCase() === 'phev'"
              [class.ap_font]="us_peugeot"
            >
              {{ calculateAutonomy() }} {{ labelCulture.cs_distance_unit }}
            </p>
          </div> -->
        </div>
        <div
          class="text-phev"
          [class.ds]="us_ds"
          [class.ap]="us_peugeot"
          [class.ac]="us_citroen"
          [class.ov]="us_opel"
          *ngIf="vehicule.LEV_CS_TYPE == 'PHEV'"
        >
          <p
            class="autonomy-value"
            role="status"
            [attr.aria-label]="labelCulture.cs_textdata_PHEV"
            [class.tablette]="isTablette()"
            [class.autonomy-value-ap]="us_peugeot"
            [class.is-right-to-left]="isRightToLeft"
            [class.autonomy-value-spoticar]="cs_spoticar"
            [class.autonomy-value-ac]="us_citroen"
            [class.autonomy-value-ds]="us_ds"
            [class.autonomy-value-ov]="us_opel"
            [class.phev]="vehicule.LEV_CS_TYPE.toLowerCase() === 'phev'"
            [class.vx]="cs_vauxhall"
          >
            {{ labelCulture.cs_textdata_PHEV }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="row row-cs-body-mobile"
      [class.ap]="us_peugeot"
      [class.spoticar]="cs_spoticar"
      [class.ac]="us_citroen"
      [class.ds]="us_ds"
      [class.ov]="us_opel"
    >
      <div
        class="cs-body"
        [class.cs-body-ac]="us_citroen"
        [class.cs-body-ds]="us_ds"
        [class.cs-body-spoticar]="cs_spoticar"
        [class.cs-body-ov]="us_opel"
        [class.vauxhall]="cs_vauxhall"
      >
        <div
          class="lev-row lev-row-header"
          [class.ac]="us_citroen"
          [class.ap]="us_peugeot"
          [class.spoticar]="cs_spoticar"
          [class.ds]="us_ds"
          [class.ov]="us_opel"
          [class.vauxhall]="cs_vauxhall"
        >
          <div class="lev-cell empty"></div>
          <div class="lev-cell-group">
            <div class="lev-cell-group-item">
              <div
                class="lev-cell-group-item-value csTitles"
                role="status"
                [attr.aria-label]="labelCulture.cs_loading_power"
                [class.ap_font]="us_peugeot"
                [class.spoticar_bold_font]="cs_spoticar"
                [class.peugeot_bold_font]="isd1 === 'true'"
                [class.is-right-to-left]="isRightToLeft"
                [class.ds_font]="us_ds"
                [class.lev-cell-group-item-value-ov]="us_opel"
                [class.lev-cell-group-item-value-ac]="us_citroen"
                [class.vauxhall]="cs_vauxhall"
                [class.lev-cell-group-item-value-ds]="us_ds"
              >
                {{ labelCulture.cs_loading_power }}
              </div>
              <div class="lev-cell-group-item-progress">
                <div
                  class="time csTitles"
                  role="status"
                  [attr.aria-label]="labelCulture.cs_charging_time"
                  [class.ap_font]="us_peugeot"
                  [class.spoticar_bold_font]="cs_spoticar"
                  [class.time-ov]="us_opel"
                  [class.time-ac]="us_citroen"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.vauxhall]="cs_vauxhall"
                  [class.peugeot_bold_font]="isd1 === 'true'"
                  [class.d1-style]="isd1 === 'true'"
                  [class.ds_font]="us_ds"
                >
                  {{ labelCulture.cs_charging_time }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="lev-row"
          *ngFor="let c of charges; let i = index"
          [class.hideFirst]="i == 0 && vehicule.LEV_CS_TYPE == 'PHEV'"
          [class.tablette]="isTablette()"
          [class.ac]="us_citroen"
          [class.ap]="us_peugeot"
          [class.spoticar]="cs_spoticar"
          [class.ds]="us_ds"
          [class.ov]="us_opel"
          [class.vauxhall]="cs_vauxhall"
        >
          <div *ngIf="c && c.length" class="lev-cell label">
            <div
              class="lev-cell-label"
              role="status"
              [attr.aria-label]="displayPictoLabelCharge(i)[1]"
              [class.ap_font]="us_peugeot"
              [class.spoticar_bold_font]="cs_spoticar"
              [class.lev-cell-label-ap]="us_peugeot"
              [class.peugeot_bold_font]="isd1 === 'true'"
              [appBreakLine]="displayPictoLabelCharge(i)[1]"
            ></div>
          </div>
          <div *ngIf="c && c.length" class="lev-cell-group">
            <div
              class="lev-cell-group-item"
              *ngFor="let ch of charges[i]; let j = index"
            >
              <div
                class="lev-cell-group-item-value"
                role="status"
                [attr.aria-label]="ch[0]"
              >
                {{ ch[0] }}
              </div>
              <div
                [class]="
                  'lev-cell-group-item-progress percent-' +
                  (ch[1] | shiftPercent : maxArray)
                "
                role="status"
                [attr.aria-label]="
                  displayPictoLabelCharge(i)[1] +
                  ' ' +
                  labelCulture.cs_loading_power +
                  ' : ' +
                  ch[0] +
                  ' ,percent-' +
                  (ch[1] | shiftPercent : maxArray) +
                  ' , ' +
                  labelCulture.cs_charging_time +
                  ' : ' +
                  ch[2]
                "
                [class.even]="labels.indexOf(ch[0]) % 2 == 0 && !us_ds"
                [class.ac]="us_citroen"
                [class.even-spoticar]="cs_spoticar"
                [class.even-ds]="labels.indexOf(ch[0]) % 2 == 0 && us_ds"
                [class.odd]="labels.indexOf(ch[0]) % 2 != 0"
              >
                <div class="greenUp">
                  <p *ngIf="langage == 'fr' && ch[0] == '3,2 kW'">Green'Up</p>
                </div>
                <div class="time">{{ ch[2] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="cs-body-mobile"
        [class.tablette]="isTablette()"
        [class.ap]="us_peugeot"
        [class.spoticar]="cs_spoticar"
        [class.ds]="us_ds"
        [class.ac]="us_citroen"
        [class.ov]="us_opel"
      >
        <span
          class="csTitles"
          role="status"
          [attr.aria-label]="labelCulture.cs_charging_time"
          [class.ac]="us_citroen"
          [class.ds]="us_ds"
          [class.ov]="us_opel"
          [class.ap]="us_peugeot"
          [class.spoticar]="cs_spoticar"
          [class.spoticar_bold_font]="cs_spoticar"
          [class.is-right-to-left]="isRightToLeft"
          [class.d1-style]="us_peugeot"
          [class.peugeot_bold_font]="isd1 === 'true'"
          [class.vauxhall]="cs_vauxhall"
          >{{ labelCulture.cs_charging_time }}</span
        >
        <!-- Swiper -->
        <swiper-container
          class="swiper-container"
          [pagination]="{ clickable: true }"
          [navigation]="true"
          [class.tablette]="isTablette()"
          [class.swiper-pagination-ac]="us_citroen"
          [class.swiper-pagination-ap]="us_peugeot"
          [class.swiper-pagination-spoticar]="cs_spoticar"
          [class.swiper-pagination-ds]="us_ds"
          [class.swiper-pagination-ov]="us_opel"
          [class.swiper-pagination-vx]="cs_vauxhall"
        >
          <swiper-slide *ngIf="vehicule.LEV_CS_TYPE != 'PHEV'">
            <div
              class="swiper-wrapper"
              [class.ov]="us_opel"
              [class.spoticar]="cs_spoticar"
              [class.ds]="us_ds"
              [class.vauxhall]="cs_vauxhall"
            >
              <div
                class="swiper-slide"
                [class.ap]="us_peugeot"
                [class.spoticar]="cs_spoticar"
                [class.ac]="us_citroen"
                [class.ds]="us_ds"
                [class.tablette]="isTablette()"
                [class.spoticar_bold_font]="cs_spoticar"
                [class.peugeot_bold_font]="isd1 === 'true'"
              >
                <div
                  class="mode-mobile mode4-mobile"
                  *ngIf="vehicule.LEV_CS_TYPE != 'PHEV'"
                  role="cell"
                  tabindex="0"
                  [attr.aria-label]="labelCulture.cs_mode4"
                  [class.ap]="us_peugeot"
                  [class.spoticar]="cs_spoticar"
                  [class.ac]="us_citroen"
                  [class.ds]="us_ds"
                  [class.ov]="us_opel"
                  (keyup.space)="changeBorne('rapide')"
                  (keyup.enter)="changeBorne('rapide')"
                  (click)="changeBorne('rapide')"
                >
                  <span
                    class="rapide selected"
                    [class.tablette]="isTablette()"
                    [class.rapideAP]="us_peugeot"
                    [class.rapideSpoticar]="cs_spoticar"
                    [class.rapideAC]="us_citroen"
                    [class.rapideDS]="us_ds"
                    [class.is-right-to-left]="isRightToLeft"
                    [class.rapideOV]="us_opel"
                    [class.selectedAP]="us_peugeot"
                    [class.selectedAC]="us_citroen"
                    [class.selectedOV]="us_opel"
                    >{{ labelCulture.cs_mode4 }}</span
                  >
                </div>
              </div>
            </div>
            <div
              class="charging-time-results-wrapper"
              [class.tablette]="isTablette()"
            >
              <div
                [hidden]="c.type != 'rapide'"
                *ngFor="let c of chargeTimeMobileRapid; let i = index"
                class="charging-time-results"
                [class.tablette]="isTablette()"
                [class.charging-time-results-unique]="
                  countCTByPrise(c.type) == 1
                "
                [class.charging-time-results-ov]="us_opel"
                [class.charging-time-results-ap]="us_peugeot"
                [class.charging-time-results-spoticar]="cs_spoticar"
                [class.charging-time-results-ds]="us_ds"
                [class.charging-time-results-ac]="us_citroen"
                [class.vauxhall]="cs_vauxhall"
              >
                <div
                  class="charging-time-results-head"
                  role="cell"
                  [attr.aria-label]="c.key + labelCulture.cs_kw"
                  [class.tablette-ap]="isTablette() && us_peugeot"
                  [class.charging-time-results-head-ap]="us_peugeot"
                  [class.charging-time-results-head-spoticar]="cs_spoticar"
                  [class.charging-time-results-head-ds]="us_ds"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.charging-time-results-head-ac]="us_citroen"
                  [class.charging-time-results-head-ov]="us_opel"
                  [class.vauxhall]="cs_vauxhall"
                >
                  {{ c.key }}&nbsp;{{ labelCulture.cs_kw }}&nbsp;{{
                    c.key == "3,2" ? "Green-up" : ""
                  }}
                </div>
                <div
                  class="charging-time-results-body"
                  role="cell"
                  [attr.aria-label]="c.value"
                  [class.tablette]="isTablette()"
                  [class.tablette-ap]="isTablette() && us_peugeot"
                  [class.charging-time-results-body-ap]="us_peugeot"
                  [class.charging-time-results-body-spoticar]="cs_spoticar"
                  [class.charging-time-results-body-ds]="us_ds"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.charging-time-results-body-ac]="us_citroen"
                  [class.charging-time-results-body-ov]="us_opel"
                  [class.vauxhall]="cs_vauxhall"
                >
                  {{ c.value }}
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="swiper-wrapper"
              [class.ov]="us_opel"
              [class.spoticar]="cs_spoticar"
              [class.ds]="us_ds"
              [class.vauxhall]="cs_vauxhall"
            >
              <div
                class="swiper-slide"
                [class.ap]="us_peugeot"
                [class.spoticar]="cs_spoticar"
                [class.ac]="us_citroen"
                [class.ds]="us_ds"
                [class.tablette]="isTablette()"
                [class.spoticar_bold_font]="cs_spoticar"
                [class.peugeot_bold_font]="isd1 === 'true'"
              >
                <div
                  class="mode-mobile mode3-mobile"
                  role="cell"
                  tabindex="0"
                  [attr.aria-label]="labelCulture.cs_mode3"
                  [class.ap]="us_peugeot"
                  [class.spoticar]="cs_spoticar"
                  [class.ac]="us_citroen"
                  [class.ds]="us_ds"
                  [class.ov]="us_opel"
                  [class.mode3_vh_mobile]="vehicule.LEV_CS_TYPE == 'PHEV'"
                  (keyup.space)="changeBorne('accelerated')"
                  (keyup.enter)="changeBorne('accelerated')"
                  (click)="changeBorne('accelerated')"
                >
                  <span
                    class="accelerated selected"
                    [class.tablette]="isTablette()"
                    [class.acceleratedAP]="us_peugeot"
                    [class.acceleratedSpoticar]="cs_spoticar"
                    [class.acceleratedDS]="us_ds"
                    [class.acceleratedAC]="us_citroen"
                    [class.is-right-to-left]="isRightToLeft"
                    [class.selectedAP]="us_peugeot"
                    [class.selectedAC]="us_citroen"
                    [class.selectedOV]="us_opel"
                    >{{ labelCulture.cs_mode3 }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="charging-time-results-wrapper"
              [class.tablette]="isTablette()"
            >
              <div
                [hidden]="c.type != 'accelerated'"
                *ngFor="let c of chargeTimeMobileAccelerated; let i = index"
                class="charging-time-results"
                [class.tablette]="isTablette()"
                [class.charging-time-results-unique]="
                  countCTByPrise(c.type) == 1
                "
                [class.charging-time-results-ov]="us_opel"
                [class.charging-time-results-ap]="us_peugeot"
                [class.charging-time-results-spoticar]="cs_spoticar"
                [class.charging-time-results-ds]="us_ds"
                [class.charging-time-results-ac]="us_citroen"
                [class.vauxhall]="cs_vauxhall"
              >
                <div
                  class="charging-time-results-head"
                  role="cell"
                  [attr.aria-label]="c.key + labelCulture.cs_kw"
                  [class.tablette-ap]="isTablette() && us_peugeot"
                  [class.charging-time-results-head-ap]="us_peugeot"
                  [class.charging-time-results-head-spoticar]="cs_spoticar"
                  [class.charging-time-results-head-ds]="us_ds"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.charging-time-results-head-ac]="us_citroen"
                  [class.charging-time-results-head-ov]="us_opel"
                  [class.vauxhall]="cs_vauxhall"
                >
                  {{ c.key }}&nbsp;{{ labelCulture.cs_kw }}&nbsp;{{
                    c.key == "3,2" ? "Green-up" : ""
                  }}
                </div>
                <div
                  class="charging-time-results-body"
                  role="cell"
                  [attr.aria-label]="c.value"
                  [class.tablette]="isTablette()"
                  [class.tablette-ap]="isTablette() && us_peugeot"
                  [class.charging-time-results-body-ap]="us_peugeot"
                  [class.charging-time-results-body-spoticar]="cs_spoticar"
                  [class.charging-time-results-body-ds]="us_ds"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.charging-time-results-body-ac]="us_citroen"
                  [class.charging-time-results-body-ov]="us_opel"
                  [class.vauxhall]="cs_vauxhall"
                >
                  {{ c.value }}
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="swiper-wrapper"
              [class.ov]="us_opel"
              [class.spoticar]="cs_spoticar"
              [class.ds]="us_ds"
              [class.vauxhall]="cs_vauxhall"
            >
              <div
                class="swiper-slide"
                [class.ap]="us_peugeot"
                [class.spoticar]="cs_spoticar"
                [class.ac]="us_citroen"
                [class.ds]="us_ds"
                [class.ov]="us_opel"
                [class.tablette]="isTablette()"
                [class.spoticar_bold_font]="cs_spoticar"
                [class.peugeot_bold_font]="isd1 === 'true'"
              >
                <!-- Mode 2 : Prise domestique-->
                <div
                  class="mode-mobile mode2-mobile"
                  role="cell"
                  tabindex="0"
                  [attr.aria-label]="labelCulture.cs_mode2"
                  [class.ap]="us_peugeot"
                  [class.spoticar]="cs_spoticar"
                  [class.ac]="us_citroen"
                  [class.ov]="us_opel"
                  [class.ds]="us_ds"
                  [class.mode2_vh_mobile]="vehicule.LEV_CS_TYPE == 'PHEV'"
                  (keyup.space)="changeBorne('standard')"
                  (keyup.enter)="changeBorne('standard')"
                  (click)="changeBorne('standard')"
                >
                  <span
                    class="standard selected"
                    [class.tablette]="isTablette()"
                    [class.standardAP]="us_peugeot"
                    [class.standardDS]="us_ds"
                    [class.standardSpoticar]="cs_spoticar"
                    [class.standardAC]="us_citroen"
                    [class.is-right-to-left]="isRightToLeft"
                    [class.selectedAP]="us_peugeot"
                    [class.selectedAC]="us_citroen"
                    [class.selectedOV]="us_opel"
                    >{{ labelCulture.cs_mode2 }}</span
                  >
                </div>
              </div>
            </div>
            <div
              class="charging-time-results-wrapper"
              [class.tablette]="isTablette()"
            >
              <div
                [hidden]="c.type != 'standard'"
                *ngFor="let c of chargeTimeMobileStandard; let i = index"
                class="charging-time-results"
                [class.tablette]="isTablette()"
                [class.charging-time-results-unique]="
                  countCTByPrise(c.type) == 1
                "
                [class.charging-time-results-ov]="us_opel"
                [class.charging-time-results-ap]="us_peugeot"
                [class.charging-time-results-spoticar]="cs_spoticar"
                [class.charging-time-results-ds]="us_ds"
                [class.charging-time-results-ac]="us_citroen"
                [class.vauxhall]="cs_vauxhall"
              >
                <div
                  class="charging-time-results-head"
                  role="cell"
                  [attr.aria-label]="c.key + labelCulture.cs_kw"
                  [class.tablette-ap]="isTablette() && us_peugeot"
                  [class.charging-time-results-head-ap]="us_peugeot"
                  [class.charging-time-results-head-spoticar]="cs_spoticar"
                  [class.charging-time-results-head-ds]="us_ds"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.charging-time-results-head-ac]="us_citroen"
                  [class.charging-time-results-head-ov]="us_opel"
                  [class.vauxhall]="cs_vauxhall"
                >
                  {{ c.key }}&nbsp;{{ labelCulture.cs_kw }}&nbsp;{{
                    c.key == "3,2" ? "Green-up" : ""
                  }}
                </div>
                <div
                  class="charging-time-results-body"
                  role="cell"
                  [attr.aria-label]="c.value"
                  [class.tablette]="isTablette()"
                  [class.tablette-ap]="isTablette() && us_peugeot"
                  [class.charging-time-results-body-ap]="us_peugeot"
                  [class.charging-time-results-body-spoticar]="cs_spoticar"
                  [class.charging-time-results-body-ds]="us_ds"
                  [class.is-right-to-left]="isRightToLeft"
                  [class.charging-time-results-body-ac]="us_citroen"
                  [class.charging-time-results-body-ov]="us_opel"
                  [class.vauxhall]="cs_vauxhall"
                >
                  {{ c.value }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
    <div class="row" *ngIf="displayML()">
      <div
        class="mentions-legales"
        role="status"
        [attr.aria-label]="labelCulture.cs_legale_mentions"
        [class.is-right-to-left]="isRightToLeft"
        [class.ov]="us_opel"
        [class.spoticar]="cs_spoticar"
        [class.vauxhall]="cs_vauxhall"
      >
        {{ labelCulture.cs_legale_mentions }}
      </div>
    </div>
  </div>
</main>
