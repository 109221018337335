<div
  class="slider-container"
  [class.FT]="cs_fiat"
  [class.AR]="cs_alfaRomeo"
  [class.JE]="cs_jeep"
  [class.AP]="cs_peugeot"
>
  <ngx-slider
    [(value)]="value"
    [options]="options"
    (valueChange)="onSliderChange($event)"
  >
  </ngx-slider>
</div>
