export class tec_labelCulture {
  [key: string]: string;
  tec_title: string = "";
  tec_blk_q1: string = "";
  tec_blk_q1_choice_urban: string = "";
  tec_blk_q1_choice_rural: string = "";
  tec_blk_q1_choice_highway: string = "";
  tec_blk_q1_choice_mixed: string = "";
  tec_blk_q2: string = "";
  tec_blk_q2_distance_unit: string = "";
  tec_blk_q3: string = "";
  tec_blk_q3_stage_1: string = "";
  tec_blk_q3_stage_2: string = "";
  tec_blk_q3_stage_3: string = "";
  tec_blk_cta_continue: string = "";
  tec_blk_cta_back: string = "";
  tec_blk_cta_validate: string = "";
  tec_blk_cta_redirection: string = "";
  tec_blk_cta_discover: string = "";
  tec_blk_cta_restart: string = "";
  tec_result_title1: string = "";
  tec_result_title2: string = "";
  tec_result_title3: string = "";
  tec_result_title4: string = "";
  tec_result_text1: string = "";
  tec_result_text2: string = "";
  tec_result_text3: string = "";
  tec_result_text4: string = "";
  tec_mentions_legales: string = "";
  tec_mentions_legales_sub1: string = "";
}