///<reference path="../../../../../node_modules/@types/swiper/index.d.ts"/>

import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { SearcheCriteria } from "src/app/models/searchCriteria";
import { Vehicule } from "src/app/models/vehicule";
import { Data } from "src/app/models/data";
import { Options } from "@angular-slider/ngx-slider";
import { LevServiceService } from "src/app/services/lev-service.service";
import { API_CONFIG } from "src/assets/plugSurfing_params/api_url_config";
import { cs_labelCulture } from "../models/label_culture";
import { CssData } from "src/app/models/css-data";
import { Observable } from "rxjs";
import { environment } from "@env/as/environment";
import { ChargingTime } from "../models/charging_time";
//import {forEach} from "@angular/router/src/utils/collection"; Unused

import { addDynamicStyles } from "../utility/charge-simulator.d-styles";
import {
  isDefaultOBC,
  isPro,
  timeConvert,
  validURL,
  tagCsLabel,
  tagCsvalue,
  isIEOrEdge,
  cleanDisplay,
} from "../utility/charge-simulator.utility";
import { ngxSliderOptionsConfig } from "../utility/ngx-slider-options-config";
import { TranslationsService } from "@lev/services/translations.service";
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();

@Component({
  selector: "app-charge-simulator",
  templateUrl: "./charge-simulator.component.html",
  styleUrls: ["./charge-simulator.component.scss"],
})
export class ChargeSimulatorComponent implements OnInit, AfterViewInit {
  @Input("country")
  country: string;

  @Input("langage")
  langage: string;

  @Input("brand")
  brand: string;

  @Input("ispro")
  ispro: string;

  @Input("isd1")
  isd1: string;

  @Input("isspoticar")
  isspoticar: string;

  @Input("vehicleid")
  vehicleid: string;

  @Input("defaultobc")
  defaultobc: string;

  @Input("linkobc")
  linkobc: string;

  @Input("type")
  type: string;

  @Input("lcdv10")
  lcdv10: string;

  mySwiper: Swiper;
  SearcheCriteria: SearcheCriteria = new SearcheCriteria();
  socMini: number = 0;
  socMaxi: number;

  /* Refactored or Exported */
  options: Options = ngxSliderOptionsConfig;

  vehs: any = [];
  obc: any = [];
  maxArray: number;
  obcValue: any = "";
  isCoupledVehicleId: boolean = false;
  coupledData: any = [];
  loadingTime: any = [];
  chargeTime: Observable<[Array<any>, Array<any>]> = null;
  chargeTimeMobile: ChargingTime[] = [];
  chargeTimeMobileRapid: ChargingTime[] = [];
  chargeTimeMobileStandard: ChargingTime[] = [];
  chargeTimeMobileAccelerated: ChargingTime[] = [];
  charges: Array<any> = [];
  selectedType: string = "rapide";
  // charteTimeGraph: any;
  result: any = [];
  mins: number;
  applyStyle: boolean;
  vehicule: Vehicule = new Vehicule();
  selectedVehicule: Vehicule = new Vehicule();
  labelCulture: cs_labelCulture = new cs_labelCulture();
  us_peugeot: boolean = false;
  us_citroen: boolean = false;
  cs_spoticar: boolean = false;
  us_ds: boolean = false;
  us_opel: boolean = false;
  cs_vauxhall: boolean = false;
  isAccess: boolean = false;
  chart = [];
  cssData: CssData;
  barBackgroundColor: string;
  eventCategory = "Content";
  eventAction = "Widget::LEV::ChargingTime";
  eventcarSelector = "CarSelector";
  eventReglette = "Reglette";
  eventChargingType = "ChargingType";
  eventOBC = "OBC";
  eventSelected = "";
  labels: string[] = [];
  displayMLFor: string[] = [
    "AT",
    "ES",
    "FR",
    "IT",
    "NL",
    "NO",
    "SE",
    "PL",
    "PT",
  ];
  screenWidth: number;
  isRightToLeft: boolean = false;

  @ViewChild("batterySliderIdRef") batterySliderIdRef: ElementRef;
  @ViewChild("swiperContainer") swiperContainer: ElementRef;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }
  constructor(
    private data: Data,
    private levservice: LevServiceService,
    private changeDetector: ChangeDetectorRef,
    private translationsService: TranslationsService
  ) {
    this.cssData = new CssData();
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    this.vehicule.LEV_CS_OBC = [];
    this.applyStyle =
      window.location.origin == "https://www-rec.lev.awsmpsa.com"
        ? true
        : false;
    //init input Params
    this.survey();
    this.someChecks();
    this.checkEnv();
    this.loadCoupledData();
    //init lables &&  data && css
    this.getLabelsByBrandAndLang();
    this.showBrand();
    this.getCssByBrand();
    if (!["false", "true"].includes(this.defaultobc)) this.defaultobc = "false";
    this.checkNavAndOV();
    this.refreshUI();
  }

  isRightToLeftChecker() {
    this.isRightToLeft = ["ar", "he"].includes(this.langage.toLowerCase());
  }

  getLevByBrandAndLcdv10() {
    const nameFile =
      "levdata/levdata-cs/levdata_" +
      this.country.toLowerCase() +
      "_" +
      this.langage.toLowerCase();
    this.isRightToLeftChecker();
    this.loadCoupledData();
    this.levservice.getData(nameFile).subscribe((data) => {
      if (!this.vehicleid)
        data["vehs"].forEach((element) => {
          if (element.LEV_CS_BRAND === this.brand.toLowerCase()) {
            if (
              element.LEV_CS_LCDV10 !== undefined &&
              element.LEV_CS_LCDV10 !== null
            ) {
              if (element.LEV_CS_LCDV10.includes(this.lcdv10)) {
                this.vehicleid = element.LEV_CS_LCDV;
              }
            }
          }
        });
      this.getLevByBrandAndLang();
    });
  }
  someChecks() {
    if (!["false", "true"].includes(this.ispro)) this.ispro = "false";
    if (!["false", "true"].includes(this.isspoticar)) this.isspoticar = "false";
    if (this.brand.toLocaleLowerCase() === "ap") this.isd1 = "true";
    if (this.langage.toLowerCase() === "no") this.langage = "nb";
  }
  checkNavAndOV() {
    let isMozillaFirefox: boolean = /(Mozilla|Firefox)/g.test(
      window.navigator.userAgent
    );
    let opelOrVauxhall: boolean = this.us_opel || this.cs_vauxhall;

    switch (true) {
      // For Firefox
      case isMozillaFirefox && opelOrVauxhall:
        this.socMaxi = 100;
        setTimeout(() => (this.socMaxi = 80), 300 * 2);
        break;
      default:
        // for IE
        this.socMaxi = 80;
        // this.cleanDisplay();
        cleanDisplay();
    }
    if (opelOrVauxhall) this.socMaxi = 100;
    this.type == "BEV" ? (this.socMaxi = 80) : (this.socMaxi = 100);
    //else if(this.type == "PHEV")this.socMaxi = 100;
    this.refreshUI();
  }

  checkEnv() {
    /* Default values for testing purposes*/
    if (!environment.production) {
      this.brand = this.brand || "AP";
      this.country = this.country || "FR";
      this.langage = this.langage || "fr";
    }
  }
  //display ML for some countries
  displayML() {
    let found = false;
    if (this.us_opel) {
      this.displayMLFor.forEach((e) => {
        if (e.toLowerCase() == this.country.toLowerCase()) {
          found = true;
        }
      });
    }
    return found;
  }

  /**
   * this.fonction set swiper option depending
   * on some conditions
   *
   * @param cs_spoticar
   *
   * @return Swiper
   */
  // setSwiper(cs_spoticar: boolean) {
  //   let that: this = this;
  //   // return new Swiper(".swiper-container", {
  //   //   pagination: ".swiper-pagination",
  //   //   paginationClickable: true,
  //   //   nextButton: cs_spoticar
  //   //     ? ".swiper-button-next-spoticar"
  //   //     : ".swiper-button-next",
  //   //   prevButton: cs_spoticar
  //   //     ? ".swiper-button-prev-spoticar"
  //   //     : ".swiper-button-prev",
  //   //   spaceBetween: 30,
  //   //   onReachBeginning() {
  //   //     that.vehicule.LEV_CS_TYPE.toLowerCase() == "bev"
  //   //       ? (that.selectedType = "rapide")
  //   //       : (that.selectedType = "accelerated");
  //   //   },
  //   //   onReachEnd() {
  //   //     that.selectedType = "standard";
  //   //   },
  //   // });
  // }

  ngAfterViewInit() {
    // this.mySwiper = this.setSwiper(this.cs_spoticar);
    this.changeDetector.detectChanges();
  }

  getCssByBrand() {
    let nameFile = "css/css_us_" + this.brand.toLowerCase();
    this.levservice.getData(nameFile).subscribe((data) => {
      const cssProperties = [
        "chevron_right",
        "chevron_right_autonomy",
        "chargeur_accelere",
        "chargeur_rapide",
        "chargeur_standard",
        "chevron",
        "chevron_off",
        "chevron_hover",
        "chevron_off_hover",
        "chevron_swiper_left",
        "chevron_swiper_right",
      ];
      this.cssData = data;
      cssProperties.forEach((property) => {
        this.cssData[property] = API_CONFIG.server_url + this.cssData[property];
      });

      addDynamicStyles(this.cssData, this.isd1);
    });
  }

  survey() {
    if (
      this.brand == undefined &&
      this.langage == undefined &&
      this.country == undefined
    ) {
      this.SearcheCriteria = this.data.storage;
      this.initFromSearchCriteria();
    }
    if (["gp", "gf", "mq", "re"].indexOf(this.country.toLowerCase()) > -1) {
      this.isAccess = true;
    }
  }
  initFromSearchCriteria() {
    if (this.SearcheCriteria) {
      this.country = this.SearcheCriteria.countryCode;
      this.langage = this.SearcheCriteria.langageCode;
      this.brand = this.SearcheCriteria.marque;
      this.defaultobc = isDefaultOBC(this.SearcheCriteria);
      this.vehicleid = this.SearcheCriteria.vehicleid;
      this.lcdv10 = this.SearcheCriteria.lcdv10;
      this.ispro = isPro(this.SearcheCriteria);
      this.isspoticar = this.SearcheCriteria.isspoticar;
      // this.linkobc = this.validURL(this.SearcheCriteria.linkOBC) ? this.SearcheCriteria.linkOBC : "";
      this.linkobc = validURL(this.SearcheCriteria.linkOBC)
        ? this.SearcheCriteria.linkOBC
        : "";
      this.type = this.SearcheCriteria.typeVehicle;
    }
  }

  showBrand() {
    let brand: string = this.brand.toLowerCase();
    let country: string = this.country.toLocaleLowerCase();
    this.setBrandAndStyle(brand, country);
  }
  setBrandAndStyle(brand: string, country: string) {
    if (brand === "ap") {
      this.us_peugeot = true;
      this.barBackgroundColor = "#143d5f";
    } else if (brand === "ac") {
      this.us_citroen = true;
      this.barBackgroundColor = "#007fa4";
    } else if (brand === "ds") {
      this.us_ds = true;
      this.barBackgroundColor = "#2f2727";
    } else if (brand === "ov") {
      this.us_opel = true;
      this.barBackgroundColor = "#2f2727";
      if (country === "gb") {
        this.cs_vauxhall = this.us_opel;
      }
    } else if (brand === "vx") {
      this.us_opel = true;
      this.cs_vauxhall = this.us_opel;
      this.barBackgroundColor = "#2f2727";
    }
    if (this.isspoticar === "true") this.cs_spoticar = true;
  }

  /* Refactored or Exported */
  toggle() {
    if (document.getElementsByClassName("accordion-obc")[0]) {
      document
        .getElementsByClassName("accordion-obc")[0]
        .classList.remove("lev-cs-opened");
      const accordion = <HTMLElement>(
        document.getElementsByClassName("accordion-obc")[0].nextElementSibling
      );
      accordion.style.maxHeight = null;
      accordion.style.display = "none";
    }
    if (this.vehs.length > 1) {
      document
        .getElementsByClassName("accordion")[0]
        .classList.toggle("lev-cs-opened");
      var panel = <HTMLElement>(
        document.getElementsByClassName("accordion")[0].nextElementSibling
      );
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
        panel.style.display = "none";
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
        panel.style.display = "table";
      }
    }
  }

  /* Refactored or Exported */
  toggleOBC() {
    if (document.getElementsByClassName("accordion")[0]) {
      document
        .getElementsByClassName("accordion")[0]
        .classList.remove("lev-cs-opened");
      const accordion = <HTMLElement>(
        document.getElementsByClassName("accordion")[0].nextElementSibling
      );
      accordion.style.maxHeight = null;
      accordion.style.display = "none";
    }
    if (document.getElementsByClassName("accordion-obc")[0]) {
      document
        .getElementsByClassName("accordion-obc")[0]
        .classList.toggle("lev-cs-opened");
      var panel = <HTMLElement>(
        document.getElementsByClassName("accordion-obc")[0].nextElementSibling
      );
    }
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      panel.style.display = "none";
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.style.display = "table";
    }
  }

  /* Refactored or Exported */
  changeBorne(borne) {
    this.selectedType = borne;
    //this.tagCsLabel(this.eventAction, this.eventChargingType);
    tagCsLabel(this.eventAction, this.eventChargingType);
  }

  isHybridVehicle(): boolean {
    const hybridLCDVValues = [
      "1CCE",
      "1CWK",
      "1PP82",
      "1PP8",
      "1PR8",
      "1PR8SW",
      "1PP67",
      "1PP68",
      "1PP5A",
      "1PP5C",
      "1SXC2",
      "1SXC1",
      "1SXC",
      "1SX8",
      "1SX8S",
      "1SX8S2",
      "1SD4",
      "1GO5",
      "1GUO",
      "1GUO2",
    ];
    return hybridLCDVValues.includes(this.vehicule.LEV_CS_LCDV);
  }
  refreshUI() {
    this.socMini = this.isHybridVehicle() ? 0 : 20;
    this.socMaxi = this.isHybridVehicle() ? 100 : 80;
  }
  getLevByBrandAndLang() {
    let brand: string = this.brand.toLowerCase();
    let country: string = this.country.toLowerCase();
    let langage: string = this.langage.toLowerCase();
    // if (("ap" == brand) && ("no" == country)) this.langage = "nb";
    let nameFile = "levdata/levdata-cs/levdata_" + country + "_" + langage;
    this.levservice.getData(nameFile).subscribe((data) => {
      this.isProVehicleId(this.vehicleid, data["vehs"]);
      data = this.justCoupledVehicles(
        this.vehicleid,
        this.getLevDataByPro(data["vehs"], this.ispro)
      ).filter((element) => element.LEV_CS_BRAND.toLowerCase() == brand);
      this.pushDataToVehs(data);
      this.vehicule = this.vehs[0];
      this.selectedVehicule = this.vehicule;
      this.vehicule.LEV_CS_OBC =
        this.defaultobc === "true"
          ? this.vehicule.LEV_CS_OBC[0]
          : this.vehicule.LEV_CS_OBC.reverse().map(
              (value) => value["0"] || value["1"]
            );
      this.obcValue = this.vehicule.LEV_CS_OBC[0];
      this.selectedType =
        this.vehicule.LEV_CS_TYPE == "PHEV" ? "accelerated" : "rapide";
      this.refreshUI();
      this.getLoadingTime();
    });
  }
  pushDataToVehs(data) {
    data.forEach((element) => {
      if (!this.vehicleid || !this.isVehiculeIdValid(data)) {
        // if (!this.type) this.vehs.push(element);
        // else if(element.LEV_CS_TYPE.toLowerCase() === this.type.toLowerCase()) this.vehs.push(element);
        if (
          !this.type ||
          element.LEV_CS_TYPE.toLowerCase() === this.type.toLowerCase()
        )
          this.vehs.push(element);
      } else if (
        element.LEV_CS_LCDV.toLowerCase() === this.vehicleid.toLowerCase()
      )
        this.vehs.push(element);
      this.vehs = this.vehs.filter((ele) => ele.LEV_CS_LCDV !== "1PP67");
      element.LEV_CS_Picture = API_CONFIG.server_url + element.LEV_CS_Picture;
    });
  }
  /**
   * load coupled Data
   * @param marque
   * @param country
   * @param langue
   *
   */
  loadCoupledData() {
    let languageCode = this.langage.toLocaleLowerCase();
    let countryCode = this.country.toLocaleLowerCase();
    const marqueCode = this.brand.toLocaleLowerCase();
    this.coupledData = [];
    var nameFile =
      "coupled_levdata/coupled_data_cs/coupled_data_cs_" +
      marqueCode +
      "/levdata_coupled_" +
      countryCode +
      "_" +
      languageCode;
    this.levservice.getData(nameFile).subscribe(
      (data) => {
        if ("undefined" !== typeof data["LEV_COUPLES"]) {
          this.coupledData = data["LEV_COUPLES"];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * lets just couples of couple lcdv
   *
   * @param vehicleid
   * @param datavehs
   */
  justCoupledVehicles(vehicleid, datavehs) {
    var vehsCouples = [];
    var dataToReturn = [];
    this.coupledData.forEach((couple) => {
      if (vehicleid === couple.LEV_COUPLE_CS_LCDV) {
        couple.vehs.forEach((veh) => {
          vehsCouples.push(veh);
        });
      }
    });
    if (vehsCouples.length >= 2) {
      this.isCoupledVehicleId = true;
      vehsCouples.forEach((element) => {
        datavehs.forEach((veh) => {
          if (
            veh.LEV_CS_LCDV.toLocaleLowerCase() ===
            element.toString().toLocaleLowerCase()
          ) {
            dataToReturn.push(veh);
          }
        });
      });
    }
    return dataToReturn.length > 0 ? dataToReturn : datavehs;
  }

  // if pro vehicle id is selected without giving isPro flag
  // in scrip or it is equals to false then change isPro to true
  isProVehicleId(vehicleid, vehicles) {
    if (vehicleid) {
      vehicles.forEach((vehicle) => {
        if (
          vehicle.LEV_CS_LCDV === vehicleid &&
          vehicle.LEV_CS_Pro === "true"
        ) {
          this.ispro = "true";
        }
      });
    }
  }

  // DS 9 E-TENSE 225 dispo in FR, ES, IT,  NL , BE , LU
  filterDS9(proData) {
    let country: string = this.country.toUpperCase();
    const isCountryToShow = [
      "FR",
      "BE",
      "IT",
      "DE",
      "NL",
      "ES",
      "PL",
      "PT",
      "AT",
      "MQ",
      "GF",
      "GP",
      "CH",
      "NO",
      "GB",
      "TR",
      "AR",
      "LU",
      "SE",
    ].includes(country);

    if (!isCountryToShow) {
      let brand: string = this.brand.toLowerCase();
      proData = proData.filter((ele) => {
        if (ele.LEV_CS_BRAND.toLowerCase() === brand)
          return ele.LEV_CS_LCDV !== "1SXC";
      });
    }
    return proData;
  }

  // filter data and give list of pro vehicles
  getLevDataByPro(proData, pro) {
    if (pro === "false") {
      let brand: string = this.brand.toLowerCase();
      proData = proData.filter((ele) => {
        if (ele.LEV_CS_BRAND.toLocaleLowerCase() === brand) {
          return ele.LEV_CS_Pro !== "true";
        }
      });
    }
    //filter DS 9 E-TENSE 225
    proData = this.filterDS9(proData);
    return proData;
  }

  getLabelsByBrandAndLang() {
    let nameFile = "labels_cs/labels_cs_" + this.brand.toLowerCase();
    try {
      this.translationsService
        .getTranslationsByWidgetAndBrandAndCulture(
          "CS",
          this.brand.toUpperCase(),
          this.country.toUpperCase(),
          this.langage.toLowerCase()
        )
        .then((data) => {
          if (!data.message) this.labelCulture = data.values;
          else
            this.levservice
              .getData(nameFile)
              .subscribe(
                (data) =>
                  (this.labelCulture =
                    data[
                      this.langage.toLowerCase() +
                        "-" +
                        this.country.toLowerCase()
                    ])
              );
        })
        .then(() => {
          this.getLevByBrandAndLcdv10();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  isTablette() {
    const MOBILE_MAX_WIDTH = 425; //Adjust as needed
    const TABLET_MAX_WIDTH = 1024;
    this.screenWidth = window.innerWidth;
    const tablet =
      this.screenWidth <= TABLET_MAX_WIDTH &&
      this.screenWidth > MOBILE_MAX_WIDTH;
    return tablet;
  }

  pushChargeTimeMobile(element: string, value: string) {
    let type = "";
    if (["100,0", "50,0"].includes(element)) type = "rapide";
    if (["22,0", "11,0", "7,4", "4,6", "3,7"].includes(element))
      type = "accelerated";
    if (["3,2", "2,3", "1,8", "1,4"].includes(element)) type = "standard";

    //  this.chargeTimeMobile.push({
    //    type,
    //    key: element === "100,0" ? "≥ 100" : element,
    //    value,
    //  });

    // Push to specific arrays based on the type
    if (type === "rapide") {
      this.chargeTimeMobileRapid.push({
        type,
        key: element === "100,0" ? "≥ 100" : element,
        value,
      });
    } else if (type === "accelerated") {
      this.chargeTimeMobileAccelerated.push({
        type,
        key: element === "100,0" ? "≥ 100" : element,
        value,
      });
    } else if (type === "standard") {
      this.chargeTimeMobileStandard.push({
        type,
        key: element === "100,0" ? "≥ 100" : element,
        value,
      });
    }
  }

  getLoadingTime() {
    this.changeDetector.detectChanges();
    let objectLoadingTime = [];
    let country = this.country.toLowerCase();
    let nameFile = "LoadingTimeData/Ltdata-" + this.brand.toLowerCase();
    // this.getLabels();
    this.getOptimizedLabels();
    this.chargeTimeMobile = [];
    this.chargeTimeMobileRapid = [];
    this.chargeTimeMobileAccelerated = [];
    this.chargeTimeMobileStandard = [];
    this.levservice.getData(nameFile).subscribe((data) => {
      let chargeTime = [],
        charteTimeGraph = [];
      this.loadingTime = data;
      this.loadObjectTime(
        objectLoadingTime,
        country,
        charteTimeGraph,
        chargeTime
      );
      this.rangeCharges(chargeTime, charteTimeGraph);
      this.applyAriaAttributeToGeneratedSpanSlider();
      return [chargeTime, charteTimeGraph] as [any, any];
    });
    return this.chargeTime;
  }
  getOptimizedLabels() {
    // this.labels = this.vehicule["LEV_DEFAULT_VALUES"]["LEV_DEFAULT_CHARGES"];
    this.labels = this.vehicule?.["LEV_DEFAULT_VALUES"]?.["LEV_DEFAULT_CHARGES"]
      ? this.vehicule["LEV_DEFAULT_VALUES"]["LEV_DEFAULT_CHARGES"]
      : [
          "≥ 100 kW",
          "50 kW",
          "22 kW",
          "11 kW",
          "7,4 kW",
          "3,7 kW",
          "3,2 kW",
          "2,3 kW",
        ];
  }

  loadObjectTime(objectLoadingTime, country, charteTimeGraph, chargeTime) {
    const corespondingCharges = this.loadingTime.LoadingCharges.filter((ele) =>
      ele.vehs.includes(this.vehicule.LEV_CS_LCDV)
    )[0];

    var chargeResults = [];
    chargeResults = Object.keys(corespondingCharges.values)[0].includes("OBC")
      ? corespondingCharges.values["OBC_" + this.obcValue]
      : corespondingCharges.values;

    objectLoadingTime = Object.keys(chargeResults);
    // objectLoadingTime = this.loadingSpecialObjectTimeByCountry(
    //   country,
    //   objectLoadingTime
    // );

    objectLoadingTime.forEach((element) => {
      if (
        this.labels.includes(
          (Number.isInteger(+element.toString().replace(",", "."))
            ? parseInt(element) === 100
              ? "≥ " + parseInt(element)
              : parseInt(element)
            : element) + " kW"
        )
      ) {
        this.pushLoadingTimeObject(
          chargeResults,
          charteTimeGraph,
          chargeTime,
          element
        );
      }
    });
  }
  pushLoadingTimeObject(
    objectLoadingTime,
    charteTimeGraph,
    chargeTime,
    identifier
  ) {
    // if (this.vehicule.LEV_CS_TYPE.toLowerCase() == "bev")
    //   this.applyOBCBEV(element, index);
    // else this.applyOBCPHEV(element, index);
    this.applyGeneralObc(objectLoadingTime[identifier]);
    charteTimeGraph.push(this.result);
    // chargeTime.push(this.timeConvert(this.result));
    // this.pushChargeTimeMobile(element, this.timeConvert(this.result));

    chargeTime.push(
      timeConvert(this.result, this.labelCulture, this.cs_vauxhall)
    );
    this.pushChargeTimeMobile(
      identifier,
      timeConvert(this.result, this.labelCulture, this.cs_vauxhall)
    );
  }
  rangeCharges(chargeTime, charteTimeGraph): void {
    this.charges = [];
    let chargesRap = [];
    let chargesAcc = [];
    let chargesDom = [];
    //to be tested
    this.labels.forEach((label, index) => {
      if (["≥ 100 kW", "50 kW", "20 KW"].includes(label))
        chargesRap.push({
          0: label,
          1: charteTimeGraph[index],
          2: chargeTime[index],
        });
      if (["22 kW", "11 kW", "7,4 kW", "4,6 kW", "3,7 kW"].includes(label))
        chargesAcc.push({
          0: label,
          1: charteTimeGraph[index],
          2: chargeTime[index],
        });
      else if (["3,2 kW", "2,3 kW", "1,8 kW", "1,4 kW"].includes(label))
        chargesDom.push({
          0: label,
          1: charteTimeGraph[index],
          2: chargeTime[index],
        });
    });
    this.charges = [chargesRap, chargesAcc, chargesDom];
    this.maxArray = Math.max(...charteTimeGraph);
  }

  applyGeneralObc(element) {
    if (this.vehicule.LEV_CS_TYPE.toLowerCase() == "phev") this.socMaxi = 100;
    this.result = element[this.socMaxi] - element[this.socMini];
  }

  displayPictoLabelCharge(i) {
    let picto: string = "";
    let label: string = "";
    if (i === 0) {
      picto = this.cssData.chargeur_rapide;
      label = this.labelCulture.cs_mode4;
    } else if (i === 1) {
      picto = this.cssData.chargeur_accelere;
      label = this.labelCulture.cs_mode3;
    } else if (i === 2) {
      picto = this.cssData.chargeur_standard;
      label = this.labelCulture.cs_mode2;
    }
    return [picto, label];
  }
  calculateLoadingTimeChagesByOBCValue(loadingTimeCharges, i) {
    if (
      Object.keys(this.loadingTime.LoadingCharges[0].values)[0].includes("OBC")
    )
      loadingTimeCharges =
        this.loadingTime.LoadingCharges[i].values["OBC_" + this.obcValue];
    else loadingTimeCharges = loadingTimeCharges.values;
    return loadingTimeCharges;
  }

  /* Refactored or Exported */
  obcChanged(value) {
    this.toggleOBC();
    // this.toggleOBC();
    this.obcValue = value;
    this.getLoadingTime();
    //this.tagCsLabel(this.eventAction, this.eventOBC);
    tagCsLabel(this.eventAction, this.eventOBC);
  }

  check(evt, v) {
    evt.target.checked = true;
    if (isIEOrEdge()) this.vehiculeChanged(v);
    setTimeout(function () {
      evt.target.checked = false;
    }, 10);
  }
  calculateSocMaxi() {
    let calculatedSocMaxi = 100; // Valeur par défaut pour le type BEV

    if (this.vehicule.LEV_CS_TYPE.toLowerCase() == "phev") {
      calculatedSocMaxi = 100;
    } else if (this.vehicule.LEV_CS_TYPE.toLowerCase() == "bev") {
      calculatedSocMaxi = 80;
    }

    return calculatedSocMaxi;
  }
  /* Refactored or Exported */
  vehiculeChanged(v) {
    this.vehicule = v;
    this.toggle();
    console.log("this.vehicule changed", this.vehicule);
    if (typeof this.vehicule.LEV_CS_OBC[0] != "string")
      this.vehicule.LEV_CS_OBC =
        this.defaultobc == "true"
          ? this.vehicule.LEV_CS_OBC[0]
          : this.vehicule.LEV_CS_OBC.reverse().map(
              (value) => value["0"] || value["1"]
            );
    this.selectedVehicule = this.vehicule;
    this.selectedType =
      this.vehicule.LEV_CS_TYPE == "PHEV" ? "accelerated" : "rapide";

    this.socMaxi = this.calculateSocMaxi();

    this.refreshUI();
    this.obcValue = this.vehicule.LEV_CS_OBC[0];
    this.getLoadingTime();
    //this.tagCsLabel(this.eventAction, this.eventcarSelector);
    tagCsLabel(this.eventAction, this.eventcarSelector);
  }

  isVehiculeIdValid(list) {
    let found = false;
    list.forEach((element) => {
      if (
        element.LEV_CS_LCDV.toLowerCase() == this.vehicleid.toLowerCase() &&
        element.LEV_CS_BRAND.toLowerCase() == this.brand.toLowerCase()
      )
        found = true;
    });
    return found;
  }

  // calculateAutonomy() {
  //   var km: any;
  //   const country = this.country.toLowerCase();
  //   const brand = this.brand.toLowerCase();
  //   let initialAutonomy = (this.vehicule.LEV_CS_WLTP * this.socMaxi) / 100;
  //   km = this.preaparingAutonomy(brand, initialAutonomy);
  //   if (["uk", "gb"].includes(country)) km = this.levservice.toMiles(km);
  //   return km;
  // }

  // /* Refactored or Exported */
  // preaparingAutonomy(brand, initialAutonomy) {
  //   var km = initialAutonomy;
  //   if (this.vehicule.LEV_CS_TYPE.toLowerCase() == "phev")
  //     km = ~~initialAutonomy;
  //   if (brand == "ov" && this.socMaxi == 100) km = initialAutonomy;
  //   // else if (this.checkIsFloatNumber(initialAutonomy)) km = this.round10(initialAutonomy);
  //   if (checkIsFloatNumber(initialAutonomy)) km = round10(initialAutonomy);
  //   // else km = initialAutonomy;
  //   return km;
  // }

  sliderPourcent() {
    tagCsvalue(
      this.eventAction,
      this.eventReglette,
      this.socMini,
      this.socMaxi
    );
  }

  filterItemsOfVehicules(v) {
    return this.vehs.filter((x) => x.LEV_CS_label !== v.LEV_CS_label);
  }

  countCTByPrise(prise) {
    if (prise === "rapide") {
      return this.chargeTimeMobileRapid.filter((obj) => obj.type === "rapide")
        .length;
    } else if (prise === "accelerated") {
      return this.chargeTimeMobileAccelerated.filter(
        (obj) => obj.type === "accelerated"
      ).length;
    } else {
      return this.chargeTimeMobileStandard.filter((obj) => obj.type === prise)
        .length;
    }
  }

  /**
   * this function is created to handle WCAG 2.1
   * requirement for this date there no option that can point on
   * spans inside slider Angular material component
   *
   * Please to feel free to enhance this code
   *
   * return void
   */
  private applyAriaAttributeToGeneratedSpanSlider() {
    //pointing on span children of slider component
    // @ts-ignore
    let batterySliderChildren: HTMLElement = this.batterySliderIdRef
      ? this.batterySliderIdRef["elementRef"].nativeElement.children
      : null;
    let swiperChildren: HTMLElement = this.swiperContainer
      ? this.swiperContainer.nativeElement.children
      : null;
    if (batterySliderChildren !== null) {
      //setting attributes and ids for required span children
      batterySliderChildren[4].attributes["aria-labelledby"].value =
        "minSlider";
      batterySliderChildren[4].attributes["aria-orientation"].value =
        "horizontal";
      batterySliderChildren[4].id = "minSlider";
      batterySliderChildren[5].attributes["aria-labelledby"].value =
        "maxSlider";
      batterySliderChildren[5].attributes["aria-orientation"].value =
        "horizontal";
      batterySliderChildren[5].id = "maxSlider";
    }

    if (swiperChildren !== null) {
      swiperChildren[3].ariaLabel = "next";
      swiperChildren[4].ariaLabel = "previous";
    }
  }

  nextSection() {
    this.mySwiper.slideTo(0);
  }

  prevSection() {
    this.mySwiper.slideTo(1);
  }
}
