import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { LabelType, Options } from "@angular-slider/ngx-slider";

@Component({
  selector: "app-slider-box",
  templateUrl: "./slider-box.component.html",
  styleUrls: ["./slider-box.component.scss"],
})
export class SliderBoxComponent implements OnInit, AfterViewInit {
  @Input() label: string; // Label to identify this slider
  @Input() value: number = 80; // The current value you want to center
  @Input() minValue: number = 0; // Minimum value
  @Input() maxValue: number = 130; // Maximum value
  @Input() step: number = 10; // Step size
  @Input("labelCulture") labelCulture: any = {};
  @Input("cs_alfaRomeo") cs_alfaRomeo: boolean = false;
  @Input("cs_fiat") cs_fiat: boolean = false;
  @Input("cs_jeep") cs_jeep: boolean = false;
  @Input("cs_peugeot") cs_peugeot: boolean = false;

  @Output() valueChange: EventEmitter<{ label: string; value: number }> =
    new EventEmitter(); // Output both label and value

  options!: Options;
  formattedValue: string = ""; // Holds the value with the unit for display purposes

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.updateSliderOptions();
    this.updateFormattedValue(); // Format the initial value
  }

  ngAfterViewInit(): void {
    this.updateGradient(); // Apply initial gradient after the view is initialized
  }

  // Update the slider options
  updateSliderOptions(): void {
    const centerValue = this.value;

    this.options = {
      floor: this.minValue,
      ceil: this.maxValue,
      step: this.step,
      showTicksValues: false,

      // Custom function to map value to position (to center the value)
      customValueToPosition: (
        val: number,
        minVal: number,
        maxVal: number
      ): number => {
        if (val < centerValue) {
          return (0.5 * (val - minVal)) / (centerValue - minVal);
        } else {
          return 0.5 + (0.5 * (val - centerValue)) / (maxVal - centerValue);
        }
      },

      // Custom function to map position back to value (reverse the compression)
      customPositionToValue: (
        percent: number,
        minVal: number,
        maxVal: number
      ): number => {
        if (percent < 0.5) {
          return minVal + (percent / 0.5) * (centerValue - minVal);
        } else {
          return centerValue + ((percent - 0.5) / 0.5) * (maxVal - centerValue);
        }
      },
      translate: (value: number, labelType: LabelType): string => {
        if (this.label === this.labelCulture.TEMPERATURE) {
          return `${value} °C`; // Custom translation for temperature
        } else if (this.label === this.labelCulture.VITESSE) {
          return `${value} Km/h`; // Custom translation for speed
        } else {
          return `${value}KG`;
        }
      },
    };
  }

  // Function to update the gradient dynamically
  updateGradient(): void {
    const sliderBars =
      this.elRef.nativeElement.querySelectorAll(".ngx-slider-bar");
    if (sliderBars) {
      const minVal = this.minValue;
      const maxVal = this.maxValue;
      const centerValue = this.value;

      // Use the customValueToPosition function to calculate the percentage for centering
      const percentage =
        this.options.customValueToPosition(this.value, minVal, maxVal) * 100;

      // Reduce the spread of the gradient by making it smaller than 50% (e.g., 25%)
      const spread = 25; // Adjust this value to change the size of the gradient
      const gradientPositionStart = Math.max(percentage - spread, 0); // Start the gradient
      const gradientPositionEnd = Math.min(percentage + spread, 100); // End the gradient

      if (
        this.cs_alfaRomeo === true ||
        this.cs_fiat === true ||
        this.cs_jeep === true ||
        this.cs_peugeot === true
      ) {
        sliderBars.forEach((bar: HTMLElement) => {
          bar.style.background = `linear-gradient(90deg, rgba(255, 255, 255, 0) ${gradientPositionStart}%,rgb(56 56 56) ${percentage}%, rgba(255, 255, 255, 0) ${gradientPositionEnd}%)`;
        });
      } else {
        sliderBars.forEach((bar: HTMLElement) => {
          bar.style.background = `linear-gradient(90deg, rgba(255, 255, 255, 0) ${gradientPositionStart}%, #FFFFFF ${percentage}%, rgba(255, 255, 255, 0) ${gradientPositionEnd}%)`;
        });
      }
    }
  }

  // Triggered whenever the slider value changes
  onSliderChange(newValue: number): void {
    this.value = newValue;
    this.updateGradient(); // Update gradient based on the new value
    this.updateFormattedValue(); // Update the formatted value

    // Emit the value along with the label
    this.valueChange.emit({ label: this.label, value: this.value });
  }

  // Function to update the formatted value with units
  updateFormattedValue(): void {
    let unit = "";

    if (this.label === "TEMPERATURE") {
      unit = "°C";
    } else if (this.label === "VITESSE") {
      unit = "Km/h";
    }

    this.formattedValue = `${this.value} ${unit}`;
  }
}
