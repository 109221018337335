import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-speed-box",
  templateUrl: "./speed-box.component.html",
  styleUrls: ["./speed-box.component.scss"],
})
export class SpeedBoxComponent implements OnInit {
  @Input() speed!: number;
  @Input() speedS!: number;
  @Input() speedXl!: number;
  @Input() consumption!: number;
  @Input() consumptionS!: number;
  @Input() consumptionXl!: number;
  @Input() unit: string = "";
  @Input() label: string = "";
  @Input() modeElectricLabel: string = "";
  @Input() levAsPicture: string = "";
  @Input() labelVehicle: string = "";
  @Input() labelParoursEurope: string = "";
  @Input() mentionsLegalForConsumption: string = "";
  @Input("as_consoUnits") as_consoUnits: string = "";
  @Input("as_hybridModeConsumption") as_hybridModeConsumption: string = "";
  @Input() batteryS: string = "";
  @Input() batteryXl: string = "";
  @Input() consoS: string = this.batteryS;
  @Input() consoXl: string = this.batteryXl;
  @Input() as_citroen: boolean = false;
  @Input("isd1") isd1: boolean = false;
  @Input("isspoticar") isspoticar: boolean = false;
  @Input("isRightToLeft") isRightToLeft: boolean = false;
  @Input("asHasConso") asHasConso: boolean = false;
  @Input("isNewOpelStyle") isNewOpelStyle: boolean = false;
  @Input() as_peugeot: boolean = false;
  @Input() as_fiat: boolean = false;
  @Input() as_ds: boolean = false;
  @Input() as_opel: boolean = false;
  @Input() as_vauxhall: boolean = false;
  @Input() mobile: boolean = false;

  constructor() {}

  ngOnInit() {}
}
