<!-- Box Icons -->
<link
  rel="stylesheet"
  href="https://unpkg.com/boxicons@latest/css/boxicons.min.css"
/>
<!--The content below is only a placeholder and can be replaced.-->
<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css"
/>
<body>
  <!-- =============== Navbar ================ -->
  <div class="containers">
    <div class="navigation" [class.active]="!sideBarOpen">
      <ul id="nav">
        <li>
          <div class="logo-name">
            <div class="logo-image">
              <img class="citroen-img" src="{{ logo_image }}" />
            </div>
            <span class="title">LEV Dashboard </span>
          </div>
        </li>
        <ul class="scrollable-content">
          <li>
            <a (click)="selectUS()" class="btnn" [class.activee]="usBloc">
              <span class="icon"><i class="bx bxs-map"></i></span>
              <span class="text"> Usage Simulator</span>
            </a>
          </li>
          <li>
            <a (click)="selectQueva()" class="btnn" [class.activee]="tecBloc">
              <span class="icon"><i class="bx bx-test-tube"></i></span>
              <span class="text"> Compatibility Test</span>
            </a>
          </li>
          <li>
            <a (click)="selectCS()" class="btnn" [class.activee]="csBloc">
              <span class="icon"> <i class="bx bxs-ev-station"></i></span>
              <span class="text">{{ "Charge Simulator" }}</span>
            </a>
          </li>
          <li>
            <a (click)="selectAS()" class="btnn" [class.activee]="asBloc">
              <span class="icon"><i class="bx bxs-chart"></i></span>
              <span class="text"> {{ "Automony Simulator" }}</span>
            </a>
          </li>
          <li>
            <a (click)="selectSAV()" class="btnn" [class.activee]="savBloc">
              <span class="icon"> <i class="bx bxs-calculator"></i></span>
              <span class="text">{{ "Saving Calculator" }}</span>
            </a>
          </li>
          <li>
            <a (click)="selectECO()" class="btnn" [class.activee]="ecoBloc">
              <span class="icon"><i class="bx bxs-bar-chart-square"></i></span>
              <span class="text">{{ "Eco Advisor" }}</span>
            </a>
          </li>
          <li>
            <a (click)="btnClick8()" class="btnn">
              <span class="icon"><i class="bx bxs-bar-chart-square"></i></span>
              <span class="text">{{ "F2MC" }}</span>
            </a>
          </li>
        </ul>
      </ul>
    </div>
    <!-- ========================= Main ==================== -->
    <div class="main" [class.active]="MainOpen">
      <div class="topbar">
        <div class="toggle" [class.active]="TogeleOpen">
          <button class="btn-close" (click)="openMenu()">
            <span class="icon"><i class="bx bx-menu"></i></span>
          </button>
        </div>
      </div>

      <div class="top-head-section">
        <h2>{{ title }}</h2>
        <p class="textinput">{{ subtitle }}</p>
      </div>
      <!-- BLOC USAGE SIMULATOR -->
      <div class="forms" *ngIf="usBloc">
        <form class="formulaires">
          <div class="inputfield">
            <label for="brand-us">
              <b>Brand*</b>
            </label>
            <select
              id="brand-us"
              name="brand-us"
              [(ngModel)]="searchCriteria.marque"
              class="form-control"
              (change)="marqueChanged()"
            >
              <option *ngFor="let marque of marques" [ngValue]="marque">
                {{ marque }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="country-us">
              <b> Country*</b>
            </label>
            <select
              id="country-us"
              class="form-control"
              name="country-us"
              [(ngModel)]="searchCriteria.countryCode"
              (change)="selectLangage()"
              required
            >
              <option
                *ngFor="let country of countries"
                [ngValue]="country.code"
              >
                {{ country.labelle + "(" + country.code + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="language-us">
              <b>Language*</b>
            </label>
            <select
              id="language-us"
              class="form-control"
              name="language-us"
              [(ngModel)]="searchCriteria.langageCode"
              (ngModelChange)="updateVehicleIdList()"
              required
            >
              <option
                *ngFor="let language of languages"
                [ngValue]="language.languageCode"
              >
                {{ language.languageLabel + "(" + language.languageCode + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="language-us">
              <p>Is Spoticar*</p>
            </label>
            <input
              class="form-control"
              class="checkmark"
              id="ispro-us"
              type="checkbox"
              [(ngModel)]="searchCriteria.isPro"
              [ngModelOptions]="{ standalone: true }"
              (change)="
                getLevDataByPro(searchCriteria.isPro ? 'true' : 'false')
              "
            />
          </div>
          <div class="inputfield">
            <label for="vehicleid-us">
              <b>Véhicule id *</b>
            </label>
            <select
              id="vehicleid-us"
              class="form-control"
              name="vehicleid-us"
              [(ngModel)]="searchCriteria.vehicleid"
              (change)="engineChangeAction(searchCriteria.vehicleid)"
              required
            >
              <option *ngFor="let v of vehs" [ngValue]="v.LEV_US_LCDV">
                {{ v.LEV_US_LCDV }} - {{ v.LEV_US_label }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="lcdv10-us">
              <b>LCDV10 *</b>
            </label>
            <select
              id="lcdv10-us"
              class="form-control"
              name="lcdv10-us"
              [(ngModel)]="searchCriteria.lcdv10"
              (change)="lcdv10Change(searchCriteria.lcdv10)"
              require
            >
              <option value=""></option>
              <option *ngFor="let v of lcdv10WithLabel" [ngValue]="v.lcdv10">
                {{ v.lcdv10 }} - {{ v.lcdv10Label }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="displaymode-us">
              <b>Display mode *</b>
            </label>
            <select
              id="displaymode-us"
              class="form-control"
              name="displaymode-us"
              [(ngModel)]="searchCriteria.displayMode"
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <div class="inputfield">
            <label for="type-us">
              <b>Type de vehicule</b>
            </label>
            <select
              id="type-us"
              class="form-control"
              name="type-us"
              [(ngModel)]="searchCriteria.typeVehicle"
            >
              <option value="BEV">BEV</option>
              <option value="PHEV">PHEV</option>
            </select>
          </div>
          <div class="button-us">
            <button *ngIf="usBloc" (click)="btnClick()">
              {{ "Usage Simulator" | titlecase }}
            </button>
          </div>
        </form>
      </div>

      <!-- BLOC QUEVA -->

      <div *ngIf="tecBloc" class="forms">
        <form class="formulaires-qeva">
          <div class="inputfield">
            <label for="brand-qeva">
              <b>Brand*</b>
            </label>
            <select
              id="brand-qeva"
              name="brand-qeva"
              [(ngModel)]="searchCriteria.marque"
              (change)="marqueChanged()"
              required
            >
              <option value=""></option>
              <option *ngFor="let marque of marques" [ngValue]="marque">
                {{ marque }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="country-qeva">
              <b> Country*</b>
            </label>
            <select
              id="country-qeva"
              class="form-control"
              name="country"
              [(ngModel)]="searchCriteria.countryCode"
              (change)="selectLangage()"
              required
            >
              <option value=""></option>
              <option
                *ngFor="let country of countries"
                [ngValue]="country.code"
              >
                {{ country.labelle + "(" + country.code + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="language-qeva">
              <b>Language*</b>
            </label>
            <select
              id="language-qeva"
              class="form-control"
              name="language"
              [(ngModel)]="searchCriteria.langageCode"
              (ngModelChange)="updateVehicleIdList()"
            >
              <option></option>
              <option
                *ngFor="let language of languages"
                [ngValue]="language.languageCode"
              >
                {{ language.languageLabel + "(" + language.languageCode + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="ispro-qeva">
              <label>Is Pro Vehicule *</label>
            </label>
            <input
              name="checkqueva"
              id="ispro-qeva"
              type="checkbox"
              class="checkmark"
              [(ngModel)]="searchCriteria.isPro"
              (change)="
                getLevDataByPro(searchCriteria.isPro ? 'true' : 'false')
              "
            />
          </div>
          <div class="inputfield">
            <label for="vehicleid-qeva">
              <b>Véhicule id</b>
            </label>
            <select
              id="vehicleid-qeva"
              class="form-control"
              name="vehicleid-qeva"
              [(ngModel)]="searchCriteria.vehicleid"
              (change)="engineChange(searchCriteria.vehicleid)"
              require
            >
              <option value=""></option>
              <ng-container *ngFor="let v of vehs">
                <option [ngValue]="v.LEV_TEC_LCDV">
                  {{ v.LEV_TEC_LCDV }} - {{ v.LEV_TEC_label }}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="inputfield">
            <label for="lcdv10-tec">
              <b>*LCDV10</b>
            </label>
            <select
              id="lcdv10-tec"
              class="form-control"
              name="lcdv10"
              [(ngModel)]="searchCriteria.lcdv10"
              (change)="lcdv10Change(searchCriteria.lcdv10)"
              require
            >
              <option value=""></option>
              <option *ngFor="let v of lcdv10WithLabel" [ngValue]="v.lcdv10">
                {{ v.lcdv10 }} - {{ v.lcdv10Label }}
              </option>
            </select>
          </div>
          <div class="button-queva">
            <button *ngIf="tecBloc" (click)="btnClick2()">
              {{ "Compatibility Test" | titlecase }}
            </button>
          </div>
        </form>
      </div>

      <!-- BLOC CHARGE SIMULATOR -->

      <div class="forms" *ngIf="csBloc">
        <form class="formulaires">
          <div class="inputfield">
            <label for="brand-cs">
              <b>Brand*</b>
            </label>
            <select
              id="brand-cs"
              class="form-control"
              name="brand-cs"
              [(ngModel)]="searchCriteria.marque"
              (change)="marqueChanged()"
            >
              <option *ngFor="let marque of marques" [ngValue]="marque">
                {{ marque }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="country-qeva">
              <b> Country*</b>
            </label>
            <select
              id="country-cs"
              class="form-control"
              name="country"
              [(ngModel)]="searchCriteria.countryCode"
              (change)="selectLangage()"
            >
              <option
                *ngFor="let country of countries"
                [ngValue]="country.code"
              >
                {{ country.labelle + "(" + country.code + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="language-cs">
              <b>Language*</b>
            </label>
            <select
              id="language-cs"
              class="form-control"
              name="language-cs"
              [(ngModel)]="searchCriteria.langageCode"
              (ngModelChange)="updateVehicleIdList()"
            >
              <option
                *ngFor="let language of languages"
                [ngValue]="language.languageCode"
              >
                {{ language.languageLabel + "(" + language.languageCode + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="ispro-cs">
              <b>Is Pro Vehicule*</b>
            </label>
            <input
              id="ispro-cs"
              class="form-control"
              class="checkmark"
              type="checkbox"
              [(ngModel)]="searchCriteria.isPro"
              [ngModelOptions]="{ standalone: true }"
              (change)="
                getLevDataByPro(searchCriteria.isPro ? 'true' : 'false')
              "
            />
          </div>
          <div class="inputfield">
            <label for="isspoticar-cs">
              <b>Is spoticar*</b>
            </label>
            <input
              id="isspoticar-cs"
              type="checkbox"
              [(ngModel)]="isSpoticar"
              class="checkmark"
              [ngModelOptions]="{ standalone: true }"
              (change)="setIsSpoticar(isSpoticar ? 'true' : 'false')"
            />
          </div>
          <div class="inputfield">
            <label for="vehicleid-cs">
              <b>Véhicule id*</b>
            </label>
            <select
              id="vehicleid-cs"
              class="form-control"
              name="vehicleid-cs"
              [(ngModel)]="searchCriteria.vehicleid"
              (change)="engineChangeAction(searchCriteria.vehicleid)"
            >
              <option value=""></option>
              <option *ngFor="let v of vehs" [ngValue]="v.LEV_CS_LCDV">
                {{ v.LEV_CS_LCDV }} - {{ v.LEV_CS_label }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="lcdv10-cs">
              <b>LCDV10*</b>
            </label>
            <select
              id="lcdv10-cs"
              class="form-control"
              name="lcdv10"
              [(ngModel)]="searchCriteria.lcdv10"
              (change)="lcdv10Change(searchCriteria.lcdv10)"
              require
            >
              <option value=""></option>
              <option *ngFor="let v of lcdv10WithLabel" [ngValue]="v.lcdv10">
                {{ v.lcdv10 }} - {{ v.lcdv10Label }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="defaultobc-cs">
              <b>Default OBC:</b>
            </label>
            <input
              id="defaultobc-cs"
              class="checkmark"
              type="checkbox"
              name="tchek"
              [(ngModel)]="searchCriteria.defaultOBC"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="inputfield">
            <label>
              <b>Type de vehicule:</b>
            </label>
            <select
              class="form-control"
              name="typeVehicle"
              [(ngModel)]="searchCriteria.typeVehicle"
            >
              <option value="BEV">BEV</option>
              <option value="PHEV">PHEV</option>
            </select>
          </div>
          <div class="button-cs">
            <button *ngIf="csBloc" (click)="btnClick3()">
              {{ "Charge Simulator" | titlecase }}
            </button>
          </div>
        </form>
      </div>
      <!-- BLOC AUTONOMY SIMULATOR -->

      <div *ngIf="asBloc" class="forms">
        <form class="formulaires-as">
          <div class="inputfield">
            <label for="brand-as">
              <b>Brand*</b>
            </label>
            <select
              id="brand-as"
              class="form-control"
              name="brand-as"
              [(ngModel)]="searchCriteria.marque"
              (change)="marqueChanged()"
            >
              <option value=""></option>
              <option *ngFor="let marque of marques" [ngValue]="marque">
                {{ marque }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="country-as">
              <b> Country*</b>
            </label>
            <select
              id="country-as"
              class="form-control"
              name="country-as"
              [(ngModel)]="searchCriteria.countryCode"
              (change)="selectLangage()"
            >
              <option
                *ngFor="let country of countries"
                [ngValue]="country.code"
              >
                {{ country.labelle + "(" + country.code + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="language-as">
              <b>Language*</b>
            </label>
            <select
              id="language-as"
              class="form-control"
              name="language-as"
              [(ngModel)]="searchCriteria.langageCode"
              (ngModelChange)="updateVehicleIdList()"
            >
              <option value=""></option>
              <option
                *ngFor="let language of languages"
                [ngValue]="language.languageCode"
              >
                {{ language.languageLabel + "(" + language.languageCode + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="ispro-as">
              <b>Is Pro Vehicule*</b>
            </label>
            <input
              id="ispro-as"
              class="form-control"
              class="checkmark"
              type="checkbox"
              [(ngModel)]="searchCriteria.isPro"
              [ngModelOptions]="{ standalone: true }"
              (change)="
                getLevDataByPro(searchCriteria.isPro ? 'true' : 'false')
              "
            />
          </div>
          <div class="inputfield">
            <label for="isspoticar-as">
              <b>Is spoticar</b>
            </label>
            <input
              id="isspoticar-as"
              class="checkmark"
              type="checkbox"
              [(ngModel)]="isSpoticar"
              name="tchkeas"
              (change)="setIsSpoticar(isSpoticar ? 'true' : 'false')"
            />
          </div>
          <div class="inputfield">
            <label for="vehicleid-as">
              <b>Véhicule id</b>
            </label>
            <select
              id="vehicleid-as"
              class="form-control"
              name="vehicleid-as"
              [(ngModel)]="searchCriteria.vehicleid"
              (change)="engineChange(searchCriteria.vehicleid)"
              require
            >
              <option value=""></option>
              <option *ngFor="let v of vehs" [ngValue]="v.LEV_AS_LCDV">
                {{ v.LEV_AS_LCDV }} - {{ v.LEV_AS_label }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="lcdv10-as">
              <b>*LCDV10</b>
            </label>
            <select
              id="lcdv10-as"
              class="form-control"
              name="lcdv10"
              [(ngModel)]="searchCriteria.lcdv10"
              (change)="lcdv10Change(searchCriteria.lcdv10)"
              require
            >
              <option value=""></option>
              <option *ngFor="let v of lcdv10WithLabel" [ngValue]="v.lcdv10">
                {{ v.lcdv10 }} - {{ v.lcdv10Label }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="lcdv10-as">
              <b>*Multiple vehicles display</b>
            </label>
            <input
              id="multiple-vehicle-ids-as"
              class="form-control"
              name="mlcdv"
              placeholder="separate iputs using pip (ex : 1PP1|1PP2|1PP8)"
              [(ngModel)]="searchCriteria.mlcdv"
              (change)="mlcdvChange(searchCriteria.mlcdv)"
              require
            />
          </div>
          <div class="button-as">
            <button *ngIf="asBloc" (click)="btnClick4()">
              {{ "Autonomy Simulator" | titlecase }}
            </button>
          </div>
        </form>
      </div>

      <!-- BLOC SAVING CALCULATOR -->
      <div *ngIf="savBloc" class="forms">
        <form class="formulaires-sav">
          <div class="inputfield">
            <label for="brand-sav">
              <b>Brand*</b>
            </label>
            <select
              id="brand-sav"
              class="form-control"
              name="brand-sav"
              [(ngModel)]="searchCriteria.marque"
              (change)="marqueChanged()"
            >
              <option *ngFor="let marque of marques" [ngValue]="marque">
                {{ marque }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="country-sav">
              <b> Country*</b>
            </label>
            <select
              id="country-sav"
              class="form-control"
              name="country-sav"
              [(ngModel)]="searchCriteria.countryCode"
              (change)="selectLangage()"
            >
              <option
                *ngFor="let country of countries"
                [ngValue]="country.code"
              >
                {{ country.labelle + "(" + country.code + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="language-sav">
              <b>language*</b>
            </label>
            <select
              id="language-sav"
              class="form-control"
              name="language"
              [(ngModel)]="searchCriteria.langageCode"
              (ngModelChange)="updateVehicleIdList()"
            >
              <option value=""></option>
              <option
                *ngFor="let language of languages"
                [ngValue]="language.languageCode"
              >
                {{ language.languageLabel + "(" + language.languageCode + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="vehicleid-sav">
              <b>*Véhicule id</b>
            </label>
            <select
              id="vehicleid-sav"
              class="form-control"
              name="vehicleid-sav"
              [(ngModel)]="searchCriteria.vehicleid"
              (change)="engineChange(searchCriteria.vehicleid)"
              require
            >
              <option value=""></option>
              <option *ngFor="let v of vehs" [ngValue]="v.LEV_SAV_LCDV">
                {{ v.LEV_SAV_LCDV }} - {{ v.LEV_SAV_LABEL }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="multiple-lcdv-sav">
              <b>*Multiple vehicles display</b>
            </label>
            <input
              id="multiple-vehicle-ids-sav"
              class="form-control"
              name="mlcdv"
              placeholder="separate iputs using pip (ex : 1PP1|1PP2|1PP8)"
              [(ngModel)]="searchCriteria.mlcdv"
              (change)="mlcdvChange(searchCriteria.mlcdv)"
              require
            />
          </div>
          <div class="button-sav">
            <button *ngIf="savBloc" (click)="btnClick6()">
              {{ "Saving Calculator" | titlecase }}
            </button>
          </div>
        </form>
      </div>
      <!-- BLOC ECO ADVISOR -->
      <div *ngIf="ecoBloc" class="forms">
        <form class="formulaires-eco">
          <div class="inputfield">
            <label for="brand-eco">
              <b>Brand*</b>
            </label>
            <select
              id="brand-eco"
              class="form-control"
              name="brand-eco"
              [(ngModel)]="searchCriteria.marque"
              (change)="marqueChanged()"
            >
              <option *ngFor="let marque of marques" [ngValue]="marque">
                {{ marque }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="country-eco">
              <b> Country*</b>
            </label>
            <select
              id="country-eco"
              class="form-control"
              name="country-eco"
              [(ngModel)]="searchCriteria.countryCode"
              (change)="selectLangage()"
            >
              <option
                *ngFor="let country of countries"
                [ngValue]="country.code"
              >
                {{ country.labelle + "(" + country.code + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="language-eco">
              <b>Language*</b>
            </label>
            <select
              id="language-eco"
              class="form-control"
              name="language-eco"
              [(ngModel)]="searchCriteria.langageCode"
              (ngModelChange)="updateVehicleIdList()"
            >
              <option value=""></option>
              <option
                *ngFor="let language of languages"
                [ngValue]="language.languageCode"
              >
                {{ language.languageLabel + "(" + language.languageCode + ")" }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="brandmvsspe-eco">
              <b>Brandmvsspe*</b>
            </label>
            <select
              id="brandmvsspe-eco"
              class="form-control"
              name="brandmvsspe-eco"
              [(ngModel)]="searchCriteria.brandmvsspe"
              (change)="brandmvsspeChange(searchCriteria.brandmvsspe)"
              required
            >
              <option value=""></option>
              <option *ngFor="let b of brandmvsspe" [ngValue]="b.code">
                {{ b.label }}
              </option>
            </select>
          </div>
          <div class="inputfield">
            <label for="vehicleid-eco">
              <b>Véhicule id*</b>
            </label>
            <select
              id="vehicleid-eco"
              class="form-control"
              name="vehicleid-eco"
              [(ngModel)]="searchCriteria.vehicleid"
              (change)="engineChange(searchCriteria.vehicleid)"
              require
            >
              <option value=""></option>
              <option *ngFor="let v of vehs" [ngValue]="v.LEV_ECO_LCDV">
                {{ v.LEV_ECO_LCDV }} - {{ v.LEV_ECO_LABEL }}
              </option>
            </select>
          </div>
          <div class="button-eco">
            <button *ngIf="ecoBloc" (click)="btnClick7()">
              {{ "Eco Advisor" | titlecase }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</body>
