import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit {
  @Input() value: number = 0;
  @Input() lastStepValue: number = 100; // This represents 100% in width
  @Input() cs_peugeot: boolean = false; // This represents 100% in width
  @Input() cs_citroen: boolean = false; // This represents 100% in width
  @Input() cs_fiat: boolean = false; // This represents 100% in width
  @Input() cs_fiatPro: boolean = false; // This represents 100% in width
  @Input() cs_alfaRomeo: boolean = false; // This represents 100% in width
  @Input() cs_ds: boolean = false; // This represents 100% in width
  @Input() cs_jeep: boolean = false; // This represents 100% in width
  fillerRelativePercentage: number = 0;
  fillerFlex: number = 0;

  ngOnInit(): void {
    this.animateProgressBar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      this.animateProgressBar();
    }
  }

  private animateProgressBar(): void {
    const targetValue = this.transform(this.value, this.lastStepValue);
    let startValue = this.fillerRelativePercentage; // Start from the current percentage
    const duration = 700; // Duration in milliseconds
    const increment = (targetValue - startValue) / (duration / 50); // 50ms interval

    const interval = setInterval(() => {
      startValue += increment;
      if (
        (increment > 0 && startValue >= targetValue) ||
        (increment < 0 && startValue <= targetValue)
      ) {
        startValue = targetValue;
        clearInterval(interval);
      }
      this.fillerRelativePercentage = Math.floor(startValue);
      this.updateValues();
    }, 50);
  }

  private updateValues(): void {
    // Update the filler relative percentage
    if (this.lastStepValue > 0) {
      this.fillerRelativePercentage = this.transform(
        this.value,
        this.lastStepValue
      );
    } else {
      this.fillerRelativePercentage = 0;
    }
  }

  private transform(value: number, max: number): number {
    // Apply logarithmic scaling for better distribution
    const scaledValue = value > 0 ? Math.log10(value + 1) : 0; // Adding 1 to avoid log(0) which is undefined
    const scaledMax = max > 0 ? Math.log10(max + 1) : 1;

    // Calculate percentage based on logarithmic scale
    return max > 0 ? Math.min(100, (scaledValue / scaledMax) * 100) : 0;
  }
}
