import {
  Directive,
  Input,
  Renderer2,
  ElementRef,
  SecurityContext,
  AfterViewChecked,
} from "@angular/core";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";

@Directive({
  selector: "[appBreakLine]",
})
export class BreakLineDirective implements AfterViewChecked {
  @Input()
  set appBreakLine(value: string | null) {
    this.updateView(value);
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private _domSanitizer: DomSanitizer
  ) {}

  ngAfterViewChecked(): void {
    // If needed, you can add additional logic here
  }

  private updateView(value: string | null): void {
    if (value !== null && value !== undefined) {
      const hasNewLine = value.includes("\n");

      if (hasNewLine) {
        const breakedValue: string[] = value.split("\n");
        const safeValue: SafeValue = this._domSanitizer.bypassSecurityTrustHtml(
          breakedValue.join("<br />")
        );
        this.renderer.setProperty(
          this.el.nativeElement,
          "innerHTML",
          this._domSanitizer.sanitize(SecurityContext.HTML, safeValue)
        );
      } else {
        this.renderer.setProperty(this.el.nativeElement, "innerText", value);
      }
    }
  }
}
