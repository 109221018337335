// Define the shape of the dataLayer object
interface DataLayerEvent {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

// Initialize the dataLayer array
declare global {
  interface Window {
    dataLayer: DataLayerEvent[];
  }
}

export function tagInWeekUse() {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Saving Calculator::Filter::In Week Use",
    "In Week Average Daily Use"
  );
}

export function tagInWeekEndUse() {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Saving Calculator::Filter::Weekend Use",
    "Week End Average Daily Use"
  );
}

export function tagFuelCost() {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Saving Calculator::Filter::Fuel Cost",
    "Fuel Cost"
  );
}

export function tagEnergiticEfficiency() {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Saving Calculator::Filter::Energetic Efficiency",
    "Energetic Efficiency"
  );
}

export function tagEnergiticElectricCost() {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Saving Calculator::Filter::Electricity Cost",
    "Electricity Cost"
  );
}

export function tagWeekdaySwitcher(urbanOrMixt: string) {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Saving Calculator::Select::Toggle::In Week Use",
    urbanOrMixt
  );
}

export function tagWeekendSwitcher(urbanOrMixt: string) {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Saving Calculator::Select::Toggle::Weekend Use",
    urbanOrMixt
  );
}

function pushDetailsToDataLayer(eventAction: string, eventLabel: string) {
  window.dataLayer.push({
    event: "uaevent",
    eventCategory: "Widget",
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
}
