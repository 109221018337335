export class UsParams{
    country: string="";
    language: string="";
    culture: string="";
    brand: string="";
    form: string="";
    derivedModel: string="";
    grbodystyle:string="";
    idwrapper: string="";
    datalayer :string="";
    urlreferrer:string="";
    maxwidth:string="";
    mode:string="";
    };