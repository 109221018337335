// Define the shape of the dataLayer object
interface DataLayerEvent {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

// Initialize the dataLayer array
declare global {
  interface Window {
    dataLayer: DataLayerEvent[];
  }
}

export function tagGeolocation() {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Eco Advisor::Select::Geolocation",
    "Geolocation"
  );
}

export function tagSearchIncentives(buttonName: string = "discover") {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Eco Advisor::Redirection::Internal",
    buttonName
  );
}

export function tagReturn(buttonName: string = "return") {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Eco Advisor::Redirection::Internal",
    buttonName
  );
}

export function tagModifyZipCode(buttonName: string = "modify") {
  window.dataLayer = window.dataLayer || [];
  pushDetailsToDataLayer(
    "Widget::LEV::Eco Advisor::Redirection::Internal",
    buttonName
  );
}

function pushDetailsToDataLayer(eventAction: string, eventLabel: string) {
  window.dataLayer.push({
    event: "uaevent",
    eventCategory: "Widget",
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
}
