import { tec_labelCulture } from "../models/label_culture";

type TecLabelCulture = {
  [key: string]: string;
};

export function split(context: any, cta: string) {
  var result: any[] = [];
  var result2: any[] = [];
  var result3 = [];
  var result4 = [];
  var result5 = [];
  if (cta) result = cta.split("|");
  if (result) {
    result.forEach((element, index) => {
      let elementLength = element.length - 1;
      if (element[elementLength] == ";")
        element = element.slice(0, elementLength);
      result2[index] = element.split(";");
    });
    if (result.length >= 4) {
      context.cta1 = result[0];
      context.cta2 = result[1];
      context.cta3 = result[2];
      context.cta4 = result[3];
    }
  }
  if (result2) result3 = pushElementToArray(result2);
  if (result3) result4 = pushElementToArray(result3, ",");

  if (result4) result5 = pushElementToArray(result4);
  context.results = result5;
}
export function pushElementToArray(array: any[], split?: string) {
  var results: any[] = [];
  array.forEach(
    (element: {
      forEach: (arg0: (values: any) => void) => void;
      split: (arg0: string) => any;
    }) => {
      if (Array.isArray(element))
        element.forEach((values) => {
          if (split !== undefined) results.push(values.split(split));
          else results.push(values);
        });
      else {
        if (split !== undefined) results.push(element.split(split));
        else results.push(element);
      }
    }
  );
  return results;
}
export function tagPlanEvent(eventLabel: string) {
  window["dataLayer"] = window["dataLayer"] || [];
  window["dataLayer"].push({
    event: "uaevent",
    eventCategory: "Content",
    eventAction: "Widget::LEV::TestCompatibilité",
    eventLabel: eventLabel,
  });
}
export function tagPlanKm(this: any) {
  window["dataLayer"] = window["dataLayer"] || [];
  window["dataLayer"].push({
    event: "uaevent",
    eventCategory: "Content",
    eventAction: "Widget::LEV::TestCompatibilité",
    eventLabel: this.tec_q2_r1,
  });
}
export function isIE(): boolean {
  let userAgent: string = window.navigator.userAgent;
  return /msie\s|trident\/|edge\//i.test(userAgent);
}

export function checkNavView(viewPort: { matches: any }) {
  if (viewPort.matches) return "mobile";
  else return "desktop";
}
export function getLevDataByPro(proData: any[], pro: string) {
  if (pro === "false") {
    proData = proData.filter((ele: { isPro: string }) => ele.isPro !== "true");
  }
  return proData;
}

export function filterIt(arr: TecLabelCulture, searchKey: string) {
  return Object.keys(arr).filter((key) => arr[key].includes(searchKey));
}
