import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  Input,
  SimpleChanges,
} from "@angular/core";
import { ChargercostComponent } from "../../components/chargercost/chargercost.component";
import { LevServiceService } from "@lev/services";

@Component({
  selector: "app-fuel-saving",
  templateUrl: "./fuel-saving.component.html",
  styleUrls: ["./fuel-saving.component.scss"],
})
export class FuelSavingComponent implements OnInit {
  @ViewChildren(ChargercostComponent)
  chargerCosts!: QueryList<ChargercostComponent>;

  @Input() vehicleid: string = "";
  @Input() brand: string = "";
  @Input() selectedBrand: string = "";
  @Input() vehicules: Array<any> = [];
  @Input() country: string = "FR";
  @Input() language: string = "EN";
  @Input("labelCulture") labelCulture: any = {};

  cs_peugeot: boolean = false;
  cs_citroen: boolean = false;
  cs_fiat: boolean = false;
  cs_fiatPro: boolean = false;
  cs_alfaRomeo: boolean = false;
  cs_ds: boolean = false;
  cs_jeep: boolean = false;

  // Initial values
  private readonly initialValues = {
    fuelCost: 1.8,
    fuelConsumption: 6,
    privateCharge: 0.25,
    publicStandardCharge: 0.4,
    publicFastCharge: 0.65,
  };

  // Current values
  currentFuelValues: number[] = [
    this.initialValues.fuelCost,
    this.initialValues.fuelConsumption,
  ];

  currentElectricityPrices = {
    private: this.initialValues.privateCharge,
    publicStandard: this.initialValues.publicStandardCharge,
    publicFast: this.initialValues.publicFastCharge,
  };

  isInitialized: boolean;
  loadUI: boolean;
  vehiclesListBeforeMapping: any;
  vehiclesList: any;
  fuelSavingData: any[] = [];

  constructor(private levservice: LevServiceService) {}

  ngOnInit() {
    // Set initialized to false initially
    this.isInitialized = false;
    // First load the data
    this.getLevByBrand().then(() => {
      // Then do the rest of initialization only after data is loaded
      this.setBrandAndStyle(this.brand);
      this.sendInitialValues();
      this.isInitialized = true; // Set to true after everything is ready
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["vehicleid"] || changes["brand"] || changes["vehicules"] || changes["selectedBrand"]) {
      this.getLevByBrand().then(() => {
        this.sendInitialValues();
        this.setBrandAndStyle(this.brand);
      });
    }
  }

  setBrandAndStyle(brand: string) {
    // Reset both values first
    this.cs_peugeot = false;
    this.cs_citroen = false;
    this.cs_fiat = false;
    this.cs_fiatPro = false;
    this.cs_alfaRomeo = false;
    this.cs_ds = false;
    this.cs_jeep = false;

    if (brand.toLocaleLowerCase() === "f2mc") {
      this.cs_peugeot = false;
      this.cs_citroen = false;
      this.cs_fiat = false;
      this.cs_fiatPro = false;
      this.cs_alfaRomeo = false;
      this.cs_ds = false;
      this.cs_jeep = false;
    }
    // Then set the appropriate one based on the brand
    if (brand.toLowerCase() === "ap") {
      this.cs_peugeot = true;
    } else if (brand.toLowerCase() === "ac") {
      this.cs_citroen = true;
    } else if (brand.toLowerCase() === "ft") {
      this.cs_fiat = true;
    } else if (brand.toLowerCase() === "ar") {
      this.cs_alfaRomeo = true;
    } else if (brand.toLowerCase() === "fo") {
      this.cs_fiatPro = true;
    } else if (brand.toLowerCase() === "ds") {
      this.cs_ds = true;
    } else if (brand.toLowerCase() === "je") {
      this.cs_jeep = true;
    }
  }

  getLevByBrand(): Promise<void> {
    return new Promise((resolve) => {
      const nameFile = "levdata/levdata-fuel/levdata_fr_fr";
      this.loadUI = false; // Start with UI hidden while loading
      this.levservice.getData(nameFile).subscribe({
        next: (data) => {
          try {
            if (!data || !data.vehs) {
              console.error("Invalid data structure received:", data);
              this.loadUI = false; // Hide UI on invalid data
              resolve(); // Resolve even on error, just with no data
              return;
            }

            this.vehiclesListBeforeMapping = data.vehs;
            this.vehiclesList = this.vehiclesListBeforeMapping.filter(
              (item) => {
                if (!item?.LEV_SAV_BRAND || !item?.LEV_SAV_LCDV) {
                  return false;
                }
                return (
                  item.LEV_SAV_BRAND.toLowerCase() ===
                    this.selectedBrand.toLowerCase() &&
                  item.LEV_SAV_LCDV.toLowerCase() ===
                    this.vehicleid.toLowerCase()
                );
              }
            );

            if (this.vehiclesList.length > 0) {
              this.fuelSavingData = this.vehiclesList.map((vehicle) => {
                return {
                  brand: vehicle.LEV_SAV_BRAND,
                  consumption: vehicle.LEV_SAV_CONSUMPTION,
                  // Include both camel case and Pascal case versions to avoid errors
                  Consumption: vehicle.LEV_SAV_CONSUMPTION,
                  Brand: vehicle.LEV_SAV_BRAND,
                };
              });
              this.loadUI = true; // Show UI when we have valid data
            } else {
              this.loadUI = false; // Hide UI when no matching vehicles found
              console.warn(
                "No matching vehicles found for brand:",
                this.brand,
                "and vehicle ID:",
                this.vehicleid
              );
            }

            resolve(); // Resolve the promise when data processing is complete
          } catch (error) {
            console.error("Error processing vehicle data:", error);
            this.loadUI = false; // Hide UI on error
            resolve(); // Resolve even on error
          }
        },
        error: (error) => {
          console.error("Error fetching LEV data:", error);
          this.loadUI = false; // Hide UI on error
          resolve(); // Resolve even on error
        },
      });
    });
  }

  private sendInitialValues() {
    // Initialize both fuel and electricity values
    this.currentFuelValues = [
      this.initialValues.fuelCost,
      this.initialValues.fuelConsumption,
    ];

    this.currentElectricityPrices = {
      private: this.initialValues.privateCharge,
      publicStandard: this.initialValues.publicStandardCharge,
      publicFast: this.initialValues.publicFastCharge,
    };
  }

  onValueChangeFuel(value: number, type: string) {
    if (type === "Cost-per-liter") {
      this.currentFuelValues[0] = value;
    } else if (type === "Consomation") {
      this.currentFuelValues[1] = value;
    }

    // Create a new array to trigger change detection
    this.currentFuelValues = [...this.currentFuelValues];
  }

  onValueChange(value: number, type: string) {
    const newPrices = { ...this.currentElectricityPrices };

    switch (type) {
      case "Private-charge":
        newPrices.private = value;
        break;
      case "Public-standard-charge":
        newPrices.publicStandard = value;
        break;
      case "Public-fast-charge":
        newPrices.publicFast = value;
        break;
    }

    // Update prices object to trigger change detection
    this.currentElectricityPrices = newPrices;
  }
}
