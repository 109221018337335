import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-chargercost",
  templateUrl: "./chargercost.component.html",
  styleUrls: ["./chargercost.component.scss"],
})
export class ChargercostComponent implements OnInit {
  @Input() label: string;
  @Input() step: number = 0.05;
  @Input() typeOfCost: string;
  @Input() startingValue?: number = 0;
  @Input() cs_peugeot: boolean = false;
  @Input() cs_citroen: boolean = false;
  @Input() cs_fiat: boolean = false;
  @Input() cs_fiatPro: boolean = false;
  @Input() cs_alfaRomeo: boolean = false;
  @Input() cs_ds: boolean = false;
  @Input() cs_jeep: boolean = false;
  @Output() valueChange = new EventEmitter<number>();

  value: number = 0;
  currency: string = "€";

  ngOnInit(): void {
    this.value = this.startingValue;
    this.checktypeOfCost();
    // Emit initial value
    this.valueChange.emit(this.value);
  }

  increaseValue(): void {
    this.value = parseFloat((this.value + this.step).toFixed(2));
    // Ensure we're emitting the new value
    this.valueChange.emit(this.value);
  }

  decreaseValue(): void {
    if (this.value > 0) {
      this.value = parseFloat((this.value - this.step).toFixed(2));
      // Ensure we're emitting the new value
      this.valueChange.emit(this.value);
    }
  }

  checktypeOfCost(): void {
    if (this.typeOfCost === "Fuel") {
      this.currency = "lt";
    }
  }
}
