import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-button-discover",
  templateUrl: "./button-discover.component.html",
  styleUrls: ["./button-discover.component.scss"],
})
export class ButtonDiscoverComponent implements OnInit {
  @Input() buttonText: string = "DISCOVER PUBLIC CHARGING";
  @Input() isStyled: boolean = false;
  @Input() cs_peugeot: boolean = false;
  @Input() cs_citroen: boolean = false;
  @Input() cs_fiat: boolean = false;
  @Input() cs_fiatPro: boolean = false;
  @Input() cs_alfaRomeo: boolean = false;
  @Input() cs_ds: boolean = false;
  @Input() cs_jeep: boolean = false;
  @Input() buttonUrl: string = "dzdz";

  constructor() {}

  ngOnInit(): void {}

  navigateToUrl() {
    if (this.buttonUrl) {
      if (this.isExternalUrl(this.buttonUrl)) {
        // Open external URL safely
        const newWindow = window.open(
          this.buttonUrl,
          "_blank",
          "noopener,noreferrer"
        );
        if (newWindow) newWindow.opener = null;
      } else {
        // Navigate to internal route using window.location.href
        window.location.href = this.buttonUrl;
      }
    }
  }

  // Check if the URL is external
  isExternalUrl(url: string): boolean {
    return /^https?:\/\//.test(url);
  }
}
