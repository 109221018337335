// vehicle-selector.component.ts
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Vehicle, OBC } from "../../services/vehicle-data.service";

export interface VehicleSelectionData {
  vehicleId: string;
  obcValue?: string;
}

@Component({
  selector: "app-vehicle-selector",
  templateUrl: "./vehicle-selector.component.html",
  styleUrls: ["./vehicle-selector.component.scss"],
})
export class VehicleSelectorComponent implements OnInit, OnChanges {
  @Input() vehicles: Vehicle[] = [];
  @Input() obcs: OBC[] = [];
  @Input() selectedVehicleId: string = "";
  @Input() selectedOBC: string = "";
  @Input() labelCulture: any;
  @Input() tabType: string = "";

  @Output() vehicleSelected = new EventEmitter<VehicleSelectionData>();

  isVehicleSelected: boolean = false;
  isObcRequired: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.isObcRequired = this.tabType === "cs";

    if (this.selectedVehicleId) {
      this.isVehicleSelected = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["vehicles"]) {
    }

    if (changes["tabType"]) {
      this.isObcRequired = this.tabType === "cs";
    }

    if (changes["selectedVehicleId"] && this.selectedVehicleId) {
      this.isVehicleSelected = true;
    }

    // When OBCs array changes and vehicle is selected, auto-select first OBC
    if (
      changes["obcs"] &&
      this.isVehicleSelected &&
      this.isObcRequired &&
      this.obcs &&
      this.obcs.length > 0 &&
      !this.selectedOBC
    ) {
      this.selectedOBC = this.obcs[0].value;
      this.emitSelectionData();
    }
  }

  onVehicleChange(vehicleId: string): void {
    this.selectedVehicleId = vehicleId;
    this.isVehicleSelected = true;

    // Auto-select the first OBC if obcs are available and required
    if (this.isObcRequired && this.obcs && this.obcs.length > 0) {
      // Set the selectedOBC to the first value in the array
      this.selectedOBC = this.obcs[0].value;
    }

    // Emit the selection event
    this.emitSelectionData();
  }

  onOBCChange(obcValue: string): void {
    this.selectedOBC = obcValue;

    // Emit the selection event
    this.emitSelectionData();
  }

  // Helper to emit selection data with all required fields
  private emitSelectionData(): void {
    const data: VehicleSelectionData = {
      vehicleId: this.selectedVehicleId,
    };

    if (this.isObcRequired && this.selectedOBC) {
      data.obcValue = this.selectedOBC;
    }

    this.vehicleSelected.emit(data);
  }

  // Format the OBC value for display
  formatOBCValue(value: string): string {
    return value ? parseFloat(value.replace(",", ".").trim()).toString() : "";
  }
}
