import { SearcheCriteria } from "@lev/models";
import { cs_labelCulture } from "../models/label_culture";
import { Vehicule } from "src/app/models/vehicule";

export function timeConvert(
  num: number,
  label_culture: cs_labelCulture,
  cs_vauxhall: boolean
): string {
  let hours: number = Math.floor(num / 60);
  let minutes: number = num % 60;
  let value: string = `${("0" + hours).slice(-2)}@@@@${
    label_culture.cs_houre
  }@@@@${("0" + minutes).slice(-2)}@@@@${label_culture.cs_minute}`;
  return value.replace(/@@@@/gi, cs_vauxhall ? " " : "");
}

export function validURL(str: string): boolean {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function filterMultipleObjects(object: any[], list: any[]) {
  list.forEach((listElement: any) => {
    object = object.filter(
      (objectElement: any) => objectElement !== listElement
    );
  });
  return object;
}

export function setSwiper(
  vehicule: Vehicule,
  selectedType: string,
  cs_spoticar: boolean
): Swiper {
  return;
}

export function round10(x: number): number {
  return Math.round(x / 10) * 10;
}

export function tagCsLabel(eventAction: string, eventLabel: string) {
  window["dataLayer"] = window["dataLayer"] || [];
  window["dataLayer"].push({
    event: "uaevent",
    eventCategory: "Content",
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
}

type DataLayerEvent = {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: string; // Make eventValue optional
};
export function tagCsvalue(
  eventAction: string,
  eventLabel: string,
  minSelected: number,
  maxSelected: number
) {
  window["dataLayer"] = window["dataLayer"] || [];
  window["dataLayer"].push({
    event: "uaevent",
    eventCategory: "Content",
    eventAction: eventAction,
    eventLabel: eventLabel,
    eventValue: "Pourcentage selected(" + minSelected + ":" + maxSelected + ")",
  } as DataLayerEvent); // Explicitly cast to DataLayerEvent
}

export function checkIsFloatNumber(number: number): boolean {
  return Number(number) === number && number % 1 !== 0;
}

export function isIEOrEdge(): boolean {
  // Internet Explorer 6-11
  return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
}

export function isCorrectCountry(param: string, country: string): boolean {
  return param == country.toLowerCase();
}

export function cleanDisplay() {
  window.setTimeout(function () {
    let resizeEvent = window.document.createEvent("UIEvents");
    resizeEvent.initEvent("resize", true, false);
    window.dispatchEvent(resizeEvent);
  }, 10000);
}

export function isDefaultOBC(searcheCriteria: SearcheCriteria) {
  return searcheCriteria.defaultOBC ? "true" : "false";
}

export function isPro(searcheCriteria: SearcheCriteria) {
  return searcheCriteria.isPro ? "true" : "false";
}

export function isSpoticar(searcheCriteria: SearcheCriteria) {
  return searcheCriteria.isspoticar ? "true" : "false";
}
