import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { LevServiceService } from "@lev/services";
import { API_CONFIG } from "@assets/plugSurfing_params/api_url_config";

@Component({
  selector: "app-range-calculator",
  templateUrl: "./range-calculator.component.html",
  styleUrls: ["./range-calculator.component.scss"],
})
export class RangeCalculatorComponent implements OnInit {
  @Input() country: string = "FR";
  @Input() language: string = "EN";
  @Input() brand: string = "F2MC";
  @Input() selectedBrand: string = "F2MC";
  @Input() vehicleid: string = "i3";
  @Input("labelCulture") labelCulture: any = {};

  selectedDrivingStyle: string = "NORMAL";
  sliderValue: number = 0;
  sliderLabel: string = "Speed";

  vehiclesListBeforeMapping: any;
  vehiclesList: any = [];
  vehiculeData: any = [];
  autonomyData: any = [];
  slidersData: any = []; // Array to hold dynamic slider data
  checkBoxData: any = []; // Array to hold dynamic checkbox data
  vehicules_VS_list: any = [
    "1CK0",
    "1KC7",
    "1ACS",
    "1GK0",
    "1KG7",
    "1OVZ",
    "1PK0",
    "1K07",
    "1APT",
  ];

  dataReady: boolean = false; // Flag to ensure data is fetched before rendering
  loadUI: boolean = false;
  defaultValueForslider: any[] = [];

  private isInitialized = false;
  checkboxLabel: string;
  checkboxValue: number;
  imageSource: string;
  carName: string;
  cs_peugeot: boolean = false;
  cs_citroen: boolean = false;
  cs_fiat: boolean = false;
  cs_fiatPro: boolean = false;
  cs_alfaRomeo: boolean = false;
  cs_ds: boolean = false;
  cs_jeep: boolean = false;
  imageExist: boolean;

  constructor(
    private levservice: LevServiceService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isInitialized = true;
    this.getLevByBrand();
    this.setBrandAndStyle(this.brand);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isInitialized && (changes["brand"] || changes["vehicleid"])) {
      // Call getLevByBrand() if any of these inputs change
      this.getLevByBrand();
      this.setBrandAndStyle(this.brand);
    }
  }

  setBrandAndStyle(brand: string) {
    // Reset both values first
    this.cs_peugeot = false;
    this.cs_citroen = false;
    this.cs_fiat = false;
    this.cs_fiatPro = false;
    this.cs_alfaRomeo = false;
    this.cs_ds = false;
    this.cs_jeep = false;

    if (brand.toLocaleLowerCase() === "f2mc") {
      this.cs_peugeot = false;
      this.cs_citroen = false;
      this.cs_fiat = false;
      this.cs_fiatPro = false;
      this.cs_alfaRomeo = false;
      this.cs_ds = false;
      this.cs_jeep = false;
    }
    // Then set the appropriate one based on the brand
    if (brand.toLowerCase() === "ap") {
      this.cs_peugeot = true;
    } else if (brand.toLowerCase() === "ac") {
      this.cs_citroen = true;
    } else if (brand.toLowerCase() === "ft") {
      this.cs_fiat = true;
    } else if (brand.toLowerCase() === "ar") {
      this.cs_alfaRomeo = true;
    } else if (brand.toLowerCase() === "fo") {
      this.cs_fiatPro = true;
    } else if (brand.toLowerCase() === "ds") {
      this.cs_ds = true;
    } else if (brand.toLowerCase() === "je") {
      this.cs_jeep = true;
    }
  }

  getLevByBrand() {
    const nameFile = "levdata/levdata-as/levdata_fr_fr";

    this.loadUI = false; // Start with UI hidden while loading

    this.levservice.getData(nameFile).subscribe({
      next: (data) => {
        try {
          if (!data || !data.vehs) {
            console.error("Invalid data structure received:", data);
            this.loadUI = false; // Hide UI on invalid data
            return;
          }

          this.vehiclesListBeforeMapping = data.vehs;
          this.vehiclesList = this.vehiclesListBeforeMapping.filter((item) => {
            if (
              !item?.LEV_AS_BRAND ||
              !item?.LEV_AS_LCDV ||
              !item?.LEV_DEFAULT_VALUES?.DRIVING_STYLE
            ) {
              return false;
            }
            return (
              item.LEV_AS_BRAND.toLowerCase() ===
                this.selectedBrand.toLowerCase() &&
              item.LEV_AS_LCDV.toLowerCase() === this.vehicleid.toLowerCase() &&
              item.LEV_DEFAULT_VALUES.DRIVING_STYLE !== "as_tempere"
            );
          });

          if (this.vehiclesList.length > 0) {
            this.extractVehiculeData(this.vehiclesList[0]);
            this.getResult(this.vehiclesList[0].LEV_AS_LCDV);
            this.carName = this.vehiclesList[0].LEV_AS_label || "";
            if (this.vehiclesList[0].LEV_AS_BRAND === "fo") {
              this.imageSource = `${API_CONFIG.server_url}${this.vehiclesList[0].LEV_AS_Picture}`;
              this.imageExist = true;
            } else {
              this.imageExist = false;
            }
            this.loadUI = true; // Show UI when we have valid data
          } else {
            this.loadUI = false; // Hide UI when no matching vehicles found
            console.warn(
              "No matching vehicles found for brand:",
              this.brand,
              "and vehicle ID:",
              this.vehicleid
            );
          }
        } catch (error) {
          console.error("Error processing vehicle data:", error);
          this.loadUI = false; // Hide UI on error
        }
      },
      error: (error) => {
        console.error("Error fetching LEV data:", error);
        this.loadUI = false; // Hide UI on error
      },
    });
  }

  // Extracts relevant data for sliders and checkboxes
  extractVehiculeData(vehicleData: any): void {
    this.slidersData = [];
    this.defaultValueForslider = [];
    const defaultValues = vehicleData.LEV_DEFAULT_VALUES;

    // Get the language-country key for labelCulture
    const labelKey = `${this.language.toLowerCase()}-${this.country.toLowerCase()}`;

    // Define slider mappings
    const sliderFields = [
      {
        key: "TEMPERATURE",
        defaultKey: "TEMPERATURE",
      },
      {
        key: "VITESSE",
        defaultKey: "VITESSE",
      },
      {
        key: "MASSE",
        defaultKey: "MASSE",
      },
      {
        key: "PAYLOAD",
        defaultKey: "PAYLOAD",
      },
    ];

    // Process sliders
    sliderFields.forEach((field) => {
      const fieldData = defaultValues[field.key];
      if (fieldData && (fieldData.MIN !== 0 || fieldData.MAX !== 0)) {
        // Get label from labelCulture, fallback to default key if not found
        const label = this.labelCulture[field.key] || field.defaultKey;

        this.slidersData.push({
          label: label,
          key: field.key,
          minValue: fieldData.MIN,
          maxValue: fieldData.MAX,
          value: fieldData.VALUE,
          step: fieldData.STEP,
        });

        this.defaultValueForslider.push({
          label: label,
          key: field.key,
          value: fieldData.VALUE,
        });
      }
    });

    // Process checkboxes
    this.checkBoxData = [];

    // Checkbox mappings
    const checkboxFields = [
      {
        key: "CLIMATISATION",
        defaultKey: "CLIMATISATION",
      },
      {
        key: "POMPE_CHALEUR",
        defaultKey: "POMPE_CHALEUR",
      },
    ];

    checkboxFields.forEach((field) => {
      if (defaultValues[field.key] !== undefined) {
        const label = this.labelCulture[field.key] || field.defaultKey;

        this.checkBoxData.push({
          label: label,
          key: field.key,
          value:
            defaultValues[field.key] === 1
              ? this.labelCulture["ON"] || "ON"
              : this.labelCulture["OFF"] || "OFF",
        });
      }
    });
    this.cdr.detectChanges();
  }

  getResult(vehicleid: string) {
    let srcFile = "autonomiesData/adata-" + this.selectedBrand.toLowerCase();
    let nameFile = srcFile;
    try {
      this.levservice
        .getDataWithPromise(nameFile)
        .then(async (data) => {
          this.vehiculeData = await data;
          let values0 = this.vehiculeData.autonomies[0].values[0];
          if (this.getTypeOfVehicle().toLowerCase() === "vfou")
            values0 = this.vehiculeData.autonomies[0].values[0][0]["false"];

          // Corrected filtering logic
          this.autonomyData = this.vehiculeData.autonomies.filter(
            (autonomy) => {
              return autonomy.vehs.includes(vehicleid); // 'autonomy' instead of 'this.vehiculeData'
            }
          );

          this.dataReady = true; // Set dataReady to true once data fetching is done
          this.cdr.detectChanges();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  getTypeOfVehicle() {
    const substrVehiculeId = this.vehicleid
      ? this.vehicleid.substring(0, 1)
      : "";
    const isVS = this.vehicules_VS_list.indexOf(this.vehicleid) > -1;
    var strPrefix = "V";
    if (isVS) {
      strPrefix = strPrefix + "S";
    } else if (this.brand.toLocaleLowerCase() === "fo") {
      strPrefix = strPrefix + "FOU";
    } else if (substrVehiculeId === "1") {
      strPrefix = strPrefix + "P";
    } else if (substrVehiculeId === "2") {
      strPrefix = strPrefix + "U";
    } else if (substrVehiculeId === "D") {
      strPrefix = strPrefix + "PD";
    }
    return strPrefix;
  }

  onDrivingStyleChange(newStyle: string): void {
    this.selectedDrivingStyle = newStyle;
  }

  onSliderValueChange(event: { label: string; value: number }): void {
    this.sliderLabel = event.label;
    this.sliderValue = event.value;
    this.slidersData.forEach((slider) => {
      if (slider.label === event.label) {
        slider.value = event.value;
      }
    });

    // You can now use event.label to know which slider is being updated and event.value for the new value.
    // You can also update any data based on this value.
  }

  handleCheckBoxChange(event: { label: string; value: number }): void {
    this.checkboxLabel = event.label;
    this.checkboxValue = event.value;
    this.checkBoxData.forEach((checkbox) => {
      if (checkbox.label === event.label) {
        checkbox.value = event.value;
      }
    });
  }

  shouldHideComponent(): boolean {
    return (
      this.vehicleid &&
      [
        "juni",
        "ypsi",
        "aven",
        "2CXE5O",
        "2CXE5L",
        "2CXE7O",
        "2CXE7L",
        "2CXE5P",
        "2CXE5M",
        "2CXE7P",
        "2CXE7M",
        "2IXE7M",
        "2IXE7P",
        "2IXE5M",
        "2IXE5P",
        "2IXE7L",
        "2PXE7O",
        "2IXE5L",
        "2IXE5O",
        "2GXE7M",
        "2GXE7P",
        "2GXE5M",
        "2GXE5P",
        "2GXE7L",
        "2IXE7O",
        "2GXE5L",
        "2GXE5O",
        "2PXE7M",
        "2PXE7P",
        "2PXE5M",
        "2PXE5P",
        "2PXE7L",
        "2GXE7O",
        "2PXE5L",
        "2PXE5O",
      ].includes(this.vehicleid)
    );
  }
}
