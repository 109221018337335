import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { Options, LabelType } from "@angular-slider/ngx-slider";

@Component({
  selector: "app-driving-style",
  templateUrl: "./driving-style.component.html",
  styleUrls: ["./driving-style.component.scss"],
})
export class DrivingStyleComponent implements OnInit, AfterViewInit {
  @Input() words: string[] = ["Calm", "Normal", "Dynamic"]; // Input array of words
  @Output() drivingStyleChange: EventEmitter<string> = new EventEmitter(); // Output event for driving style
  @Input("labelCulture") labelCulture: any = {};
  @Input("cs_alfaRomeo") cs_alfaRomeo: boolean = false;
  @Input("cs_fiat") cs_fiat: boolean = false;
  @Input("cs_jeep") cs_jeep: boolean = false;
  @Input("cs_peugeot") cs_peugeot: boolean = false;

  public value: number = 1; // Initial slider value, starting with "Normal"
  options!: Options; // Declare options but initialize later in ngOnInit()

  // Mapping for new values to be emitted
  private drivingStyleMap: { [key: string]: string } = {
    Calm: "as_calm",
    Normal: "as_normal",
    Dynamic: "as_dynamic",
  };

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.options = {
      floor: 0,
      ceil: this.words.length - 1, // Use the length of the words array to set the range
      step: 1, // Slider moves in steps of 1 (since we're mapping to indices)
      showTicksValues: false,
      translate: (value: number, label: LabelType): string => {
        return this.words[value]; // Display the corresponding word based on the value (index)
      },
      customValueToPosition: (
        val: number,
        minVal: number,
        maxVal: number
      ): number => {
        // Normalize the values based on the array indices (0, 1, 2 for "Calm", "Normal", "Dynamic")
        return (val - minVal) / (maxVal - minVal);
      },
      customPositionToValue: (
        percent: number,
        minVal: number,
        maxVal: number
      ): number => {
        // Convert the slider's position back into an index
        const value = percent * (maxVal - minVal) + minVal;
        return Math.round(value); // Return a rounded index to map it back to the word array
      },
    };

    // Emit the default driving style (mapped value) on initialization
    const mappedValue = this.drivingStyleMap[this.words[this.value]];
    this.drivingStyleChange.emit(mappedValue); // Emit the default driving style (as_normal)
  }

  ngAfterViewInit(): void {
    this.updateGradient(); // Apply initial gradient after the view is initialized
  }

  // Function to update the gradient dynamically
  updateGradient(): void {
    const sliderBar =
      this.elRef.nativeElement.querySelectorAll(".ngx-slider-bar");
    if (sliderBar) {
      const percentage =
        ((this.value - this.options.floor!) /
          (this.options.ceil! - this.options.floor!)) *
        100;

      // Reduce the spread of the gradient by making it smaller than 50% (e.g., 25%)
      const spread = 25; // Adjust this value to change the size of the gradient (lower value = narrower gradient)
      const gradientPositionStart = Math.max(percentage - spread, 0); // Start the gradient
      const gradientPositionEnd = Math.min(percentage + spread, 100); // End the gradient

      if (
        this.cs_alfaRomeo === true ||
        this.cs_fiat === true ||
        this.cs_jeep === true ||
        this.cs_peugeot
      ) {
        sliderBar[2].style.background = `linear-gradient(90deg, rgba(255, 255, 255, 0) ${gradientPositionStart}%,rgb(56 56 56) ${percentage}%, rgba(255, 255, 255, 0) ${gradientPositionEnd}%)`;
      } else {
        sliderBar[2].style.background = `linear-gradient(90deg, rgba(255, 255, 255, 0) ${gradientPositionStart}%, #FFFFFF ${percentage}%, rgba(255, 255, 255, 0) ${gradientPositionEnd}%)`;
      }
    }
  }

  // Triggered whenever the slider value changes
  onSliderChange(newValue: number): void {
    this.value = newValue;
    this.updateGradient(); // Update gradient based on the new value

    // Emit the mapped driving style (as_calm, as_normal, as_dynamic)
    const mappedValue = this.drivingStyleMap[this.words[newValue]];
    this.drivingStyleChange.emit(mappedValue);
  }
}
