import { Component, Input, OnInit } from "@angular/core";
import { API_CONFIG } from "../../../../../src/assets/plugSurfing_params/api_url_config";

@Component({
  selector: "app-incentive",
  templateUrl: "./incentive.component.html",
  styleUrls: ["./incentive.component.scss"],
})
export class IncentiveComponent implements OnInit {
  @Input("title") title: string="";
  @Input("description") description: string="";
  @Input("linkLabel") linkLabel: string="";
  @Input("value") value: string="";
  @Input("currency") currency: string="";
  @Input("target") target: string="";
  @Input("link") link: string="";
  @Input("index") index!: number;
  @Input("isMobile") isMobile: boolean = false;
  @Input("isFirst") isFirst: boolean=false;
  @Input("hideContent") hideContent: boolean=false;
  @Input("brand") brand: string="";
  @Input("country") country: string="";
  @Input() eco_price_purchasing_assistance_with_regional_return_label: string="";
  @Input() eco_price_conversion_bonus_national_label: string="";
  @Input() eco_preferential_parking_rate_label: string="";
  @Input() eco_gray_card_certificate_of_registration_label: string="";
  @Input() eco_help_with_the_installation_of_a_charging_station_label: string="";
  @Input() eco_avantage_fiscaux_label: string="";
  @Input()
  eco_contibution_nationale_aux_infrastructures_de_charge_label: string="";
  @Input() eco_cite_credit_d_impot_transition_d_energetique_label: string="";

  eco_arrow_icon = API_CONFIG.server_url + "shared/eco-arrow.png";
  eco_checked_icon = API_CONFIG.server_url + "shared/eco-checked.png";
  isChecked: boolean=false;

  ngOnInit() {
    this.hideContentInMobileVersion();
    this.isCheckValue();
  }

  isCheckValue() {
    this.isChecked = [this.value].includes("Y");
  }

  /**
   * check if not first incentive
   * and hide content
   *
   * @return void
   *
   */
  hideContentInMobileVersion() {
    if (this.index === 0 && !this.isFirst) this.hideContent = true;
  }

  /**
   * function to toggle content
   * in mobile version to make application
   * more ergonomic and easy for better user experience
   *
   * the first incentive is shown initially
   * and can be changed later by user
   *
   * @param index : number
   *
   * @return void
   */
  showOrHideContent(index:number) {
    if (index === 0 && this.isFirst) {
      this.isFirst = false;
      this.hideContent = true;
    } else if (index === 0 && this.hideContent) {
      this.isFirst = true;
      this.hideContent = false;
    } else this.hideContent = !this.hideContent;
  }
}
