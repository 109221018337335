import { Options } from "@angular-slider/ngx-slider";

export let ngxSliderOptionsConfig: Options = {
  floor: 0,
  ceil: 100,
  step: 10,
  minRange: 10,
  hideLimitLabels: true,
  showOuterSelectionBars: true,
  combineLabels: (minValue: string, maxValue: string): string => {
    if (minValue != maxValue) {
      setTimeout(function () {
        (<HTMLElement>(
          document.getElementsByClassName("ngx-slider-combined")[0]
        )).style.opacity = "0";
        (<HTMLElement>(
          document.getElementsByClassName("ngx-slider-model-value")[0]
        )).style.opacity = "1";
        (<HTMLElement>(
          document.getElementsByClassName("ngx-slider-model-high")[0]
        )).style.opacity = "1";
        (<HTMLElement>(
          document.getElementsByClassName("ngx-slider-combined")[0]
        )).style.opacity = "0";
      }, 1);
    } else {
      (<HTMLElement>(
        document.getElementsByClassName("ngx-slider-model-value")[0]
      )).style.opacity = "0";
      (<HTMLElement>(
        document.getElementsByClassName("ngx-slider-model-high")[0]
      )).style.opacity = "0";
      (<HTMLElement>(
        document.getElementsByClassName("ngx-slider-combined")[0]
      )).style.opacity = "1";
    }
    return minValue;
  },
  translate: (value: number): string => {
    return value + "%";
  },
};
