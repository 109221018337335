import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { SearcheCriteria } from "src/app/models/searchCriteria";
import { Vehicule } from "src/app/models/vehicule";
import { Options } from "@angular-slider/ngx-slider";
import { LevServiceService } from "src/app/services/lev-service.service";
import { API_CONFIG } from "src/assets/plugSurfing_params/api_url_config";
import { cs_labelCulture } from "../../../../../charge-simulator/src/app/models/label_culture";
import { CssData } from "src/app/models/css-data";
import { BehaviorSubject, Observable } from "rxjs";
import { ChargingTime } from "../../../../../charge-simulator/src/app/models/charging_time";
import { ngxSliderOptionsConfig } from "../../utilities/ngx-slider-options-config";

/**
 * Component for displaying charging time information for a specific vehicle.
 * This component accepts various input properties to customize the display.
 * It also includes methods for initializing the component, handling changes, and refreshing the UI.
 */
@Component({
  selector: "app-charging-time-f2mc",
  templateUrl: "./charging-time-f2mc.component.html",
  styleUrls: ["./charging-time-f2mc.component.scss"],
})
export class ChargingTimeF2mcComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input("country")
  country: string = "FR";

  @Input("displaycost")
  displaycost: string;

  progressValue = 80;

  @Input("language")
  language: string = "FR";

  @Input()
  brand: string = "";

  @Input("selectedBrand") selectedBrand: string = "";

  @Input("ispro")
  ispro: string = "false";

  @Input("isd1")
  isd1: string;

  @Input("isspoticar")
  isspoticar: string = "false";

  @Input()
  vehicleid: string = "";

  @Input("defaultobc")
  defaultobc: string = "false";

  @Input("linkobc")
  linkobc: string;

  @Input("type")
  type: string;

  @Input("lcdv10")
  lcdv10: string;

  @Input("obc") obcValue: string;

  @Input() labelCulture: cs_labelCulture = new cs_labelCulture();

  SearcheCriteria: SearcheCriteria = new SearcheCriteria();
  socMini: number = 0;
  socMaxi: number;

  /* Refactored or Exported */
  options: Options = ngxSliderOptionsConfig;

  vehs: any = [];
  obc: any = [];
  maxArray: number;
  isCoupledVehicleId: boolean = false;
  coupledData: any = [];
  loadingTime: any = [];
  chargeTime: Observable<[Array<any>, Array<any>]> = null;
  chargeTimeMobile: ChargingTime[] = [];
  chargeTimeMobileRapid: ChargingTime[] = [];
  chargeTimeMobileStandard: ChargingTime[] = [];
  chargeTimeMobileAccelerated: ChargingTime[] = [];
  charges: Array<any> = [];
  selectedType: string = "rapide";
  // charteTimeGraph: any;
  result: any = [];
  mins: number;
  applyStyle: boolean;
  renderCostAndFuelComponents: boolean = true;
  vehicule: Vehicule = new Vehicule();
  selectedVehicule: Vehicule = new Vehicule();

  cs_peugeot: boolean = false;
  cs_citroen: boolean = false;
  cs_fiat: boolean = false;
  cs_fiatPro: boolean = false;
  cs_vauxhall: boolean = false;
  cs_alfaRomeo: boolean = false;
  cs_ds: boolean = false;
  cs_jeep: boolean;

  isAccess: boolean = false;
  chart = [];
  cssData: CssData;
  barBackgroundColor: string;
  eventCategory = "Content";
  eventAction = "Widget::LEV::ChargingTime";
  eventcarSelector = "CarSelector";
  eventReglette = "Reglette";
  eventChargingType = "ChargingType";
  eventOBC = "OBC";
  eventSelected = "";
  labels: string[] = [];
  screenWidth: number;
  isRightToLeft: boolean = false;
  powerStations = [];

  @ViewChild("batterySliderIdRef") batterySliderIdRef: ElementRef;
  lastGroup: any;
  lastCharge: number;
  chargingCost: any;
  fuelCost: any = [];
  isMobile = false;
  km: any;

  loading: boolean = true; // Add this flag to control the loader
  FuelCostDisplay: number;

  browserLanguage: string = "en";
  browserCountry: string = "US";
  baseUrl: string = "https://www.free2movecharge";

  constructor(
    private levservice: LevServiceService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.cssData = new CssData();
    this.checkScreenWidth();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    this.isMobile = window.innerWidth <= 764;
  }

  private isInitialized = false;
  ngOnInit() {
    // console.log(navigator);
    this.detectBrowserLanguage();
    this.initializeComponent();
    this.setBrandAndStyle(this.brand.toLowerCase(), this.country.toLowerCase());
    this.isInitialized = true;
    if (this.displaycost === "true") {
      this.renderCostAndFuelComponents = true;
    } else if (this.displaycost === "false") {
      this.renderCostAndFuelComponents = false;
    }

    this.loading = true; // Set loading to true while data is loading
    this.loadComponentData().then(() => {
      // Set loading to false once the data is fully loaded
      this.loading = false;
    });
  }

  detectBrowserLanguage() {
    // Get browser language (e.g., "en-US")
    if (navigator && navigator.language) {
      const [lang, country] = navigator.language.split("-");
      this.browserLanguage = lang.toLowerCase();
      this.browserCountry = country ? country.toLowerCase() : "";

      // If no country is input or it's empty, use the browser's country
      if (!this.country || this.country === "") {
        this.country = this.browserCountry.toUpperCase();
      }
    }
  }

  getLocalizedUrl(path: string): string {
    // First priority: use the component's country input if available
    const localeToUse = this.country
      ? this.country.toLowerCase()
      : this.browserLanguage;
    return `${this.baseUrl}.${localeToUse}/${path}`;
  }

  loadComponentData(): Promise<void> {
    return new Promise<void>((resolve) => {
      // Simulate async loading here (replace this with your real data loading logic)
      setTimeout(() => {
        resolve(); // Resolve when data is loaded
      }, 2000); // Example delay, replace with actual logic
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.isInitialized &&
      (changes["brand"] ||
        changes["vehicleid"] ||
        changes["obcValue"] ||
        changes["labelCulture"])
    ) {
      this.vehs = [];
      this.initializeComponent();
      this.setBrandAndStyle(
        this.brand.toLowerCase(),
        this.country.toLowerCase()
      );
    }
    if (
      changes["brand"] ||
      changes["vehicleid"] ||
      changes["obcValue"] ||
      changes["labelCulture"]
    ) {
      if (!this.brand || !this.vehicleid) {
        console.error(
          "Missing required input properties:",
          this.brand,
          this.vehicleid
        );
        return; // Prevent further execution if essential properties are missing.
      }
      this.initializeComponent();
      this.setBrandAndStyle(
        this.brand.toLowerCase(),
        this.country.toLowerCase()
      );
    }
  }

  initializeComponent() {
    this.vehicule.LEV_CS_OBC = [];
    this.applyStyle =
      window.location.origin == "https://www-rec.lev.awsmpsa.com"
        ? true
        : false;
    if (!this.labelCulture || Object.keys(this.labelCulture).length === 0) {
      this.getLabelsByBrandAndLang();
    } else {
      // If labelCulture is provided, proceed with getting vehicle data
      this.getLevByBrandAndLang();
    }
    if (!["false", "true"].includes(this.defaultobc)) this.defaultobc = "false";
    this.refreshUI();
  }

  ngAfterViewInit() {
    // this.changeDetector.detectChanges();
  }

  // UPDATE THIS FUNCTION WITH THE NEW STYLING FOR THE FREE2MOVE CHARGING TIME WIDGET
  setBrandAndStyle(brand: string, country: string) {
    if (brand.toLocaleLowerCase() === "f2mc") {
      this.cs_peugeot = false;
      this.cs_citroen = false;
      this.cs_fiat = false;
      this.cs_fiatPro = false;
      this.cs_alfaRomeo = false;
      this.cs_ds = false;
    }
    if (brand.toLowerCase() === "ap") {
      this.cs_peugeot = true;
    } else if (brand.toLowerCase() === "ac") {
      this.cs_citroen = true;
    } else if (brand.toLowerCase() === "ft") {
      this.cs_fiat = true;
    } else if (brand.toLowerCase() === "ar") {
      this.cs_alfaRomeo = true;
    } else if (brand.toLowerCase() === "fo") {
      this.cs_fiatPro = true;
    } else if (brand.toLowerCase() === "ds") {
      this.cs_ds = true;
    } else if (brand.toLowerCase() === "je") {
      this.cs_jeep = true;
    }
  }

  // UPDATE THIS FUNCTION WITH THE NEW STYLING FOR THE FREE2MOVE CHARGING TIME WIDGET
  changeBorne(borne) {
    this.selectedType = borne;
    //this.tagCsLabel(this.eventAction, this.eventChargingType);
    this.tagCsLabel(this.eventAction, this.eventChargingType);
  }

  // UPDATE THIS FUNCTION BASED ON HYBRIED VEHICLE
  refreshUI() {
    this.socMini = 20;
    this.socMaxi = 80;
  }
  getLevByBrandAndLang() {
    let brand: string = this.selectedBrand.toLowerCase();
    let country: string = this.country.toLowerCase();
    let language: string = this.language.toLowerCase();
    let nameFile = "levdata/levdata-cs/levdata_" + "fr" + "_" + "fr";

    const loadData = (file: string) => {
      this.levservice.getData(file).subscribe({
        next: (data) => {
          if (data && data["vehs"]) {
            this.isProVehicleId(this.vehicleid, data["vehs"]);
            const FilteredData = data["vehs"].filter(
              (element) => element.LEV_CS_BRAND.toLowerCase() == brand
            );
            this.pushDataToVehs(FilteredData);

            if (this.vehs.length > 0) {
              this.vehicule = this.vehs[0];
              this.selectedVehicule = this.vehicule;
            } else {
              console.error(
                "No vehicles found for the given brand and language."
              );
            }

            this.selectedVehicule = this.vehicule;
            this.vehicule.LEV_CS_OBC =
              this.defaultobc === "true"
                ? this.vehicule.LEV_CS_OBC[0]
                : this.vehicule.LEV_CS_OBC.reverse().map(
                    (value) => value["0"] || value["1"]
                  );
            this.selectedType =
              this.vehicule.LEV_CS_TYPE == "PHEV" ? "accelerated" : "rapide";

            this.refreshUI();
            this.getLoadingTime();
          } else {
            console.error("Invalid data structure", data);
          }
        },
        error: (error) => {
          console.error(`Error loading data for ${file}:`, error);
          if (file !== "levdata/levdata-cs/levdata_fr_fr") {
            loadData("levdata/levdata-cs/levdata_fr_fr");
          }
        },
      });
    };

    loadData(nameFile);
  }

  pushDataToVehs(data) {
    data.forEach((element) => {
      if (!this.vehicleid || !this.isVehiculeIdValid(data)) {
        if (
          !this.type ||
          element.LEV_CS_TYPE.toLowerCase() === this.type.toLowerCase()
        )
          this.vehs.push(element);
      } else if (
        element.LEV_CS_LCDV.toLowerCase() === this.vehicleid.toLowerCase()
      )
        this.vehs.push(element);
      this.vehs = this.vehs.filter((ele) => ele.LEV_CS_LCDV !== "1PP67");
      element.LEV_CS_Picture = API_CONFIG.server_url + element.LEV_CS_Picture;
    });
  }

  loadCoupledData() {
    let languageCode = this.language.toLocaleLowerCase();
    let countryCode = this.country.toLocaleLowerCase();
    const marqueCode = this.brand.toLocaleLowerCase();
    this.coupledData = [];
    var nameFile =
      "coupled_levdata/coupled_data_cs/coupled_data_cs_" +
      marqueCode +
      "/levdata_coupled_" +
      countryCode +
      "_" +
      languageCode;
    this.levservice.getData(nameFile).subscribe(
      (data) => {
        if ("undefined" !== typeof data["LEV_COUPLES"]) {
          this.coupledData = data["LEV_COUPLES"];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  justCoupledVehicles(vehicleid, datavehs) {
    var vehsCouples = [];
    var dataToReturn = [];
    this.coupledData.forEach((couple) => {
      if (vehicleid === couple.LEV_COUPLE_CS_LCDV) {
        couple.vehs.forEach((veh) => {
          vehsCouples.push(veh);
        });
      }
    });
    if (vehsCouples.length >= 2) {
      this.isCoupledVehicleId = true;
      vehsCouples.forEach((element) => {
        datavehs.forEach((veh) => {
          if (
            veh.LEV_CS_LCDV.toLocaleLowerCase() ===
            element.toString().toLocaleLowerCase()
          ) {
            dataToReturn.push(veh);
          }
        });
      });
    }
    return dataToReturn.length > 0 ? dataToReturn : datavehs;
  }

  // if pro vehicle id is selected without giving isPro flag
  // in scrip or it is equals to false then change isPro to true
  isProVehicleId(vehicleid, vehicles) {
    if (vehicleid) {
      vehicles.forEach((vehicle) => {
        if (
          vehicle.LEV_CS_LCDV === vehicleid &&
          vehicle.LEV_CS_Pro === "true"
        ) {
          this.ispro = "true";
        }
      });
    }
  }

  // DS 9 E-TENSE 225 dispo in FR, ES, IT,  NL , BE , LU
  filterDS9(proData) {
    let country: string = this.country.toUpperCase();
    const isCountryToShow = [
      "FR",
      "BE",
      "IT",
      "DE",
      "NL",
      "ES",
      "PL",
      "PT",
      "AT",
      "MQ",
      "GF",
      "GP",
      "CH",
      "NO",
      "GB",
      "TR",
      "AR",
      "LU",
      "SE",
    ].includes(country);

    if (!isCountryToShow) {
      let brand: string = this.brand.toLowerCase();
      proData = proData.filter((ele) => {
        if (ele.LEV_CS_BRAND.toLowerCase() === brand)
          return ele.LEV_CS_LCDV !== "1SXC";
      });
    }
    return proData;
  }

  // filter data and give list of pro vehicles
  getLevDataByPro(proData, pro) {
    if (pro === "false") {
      let brand: string = this.selectedBrand.toLowerCase();
      proData = proData.filter((ele) => {
        if (ele.LEV_CS_BRAND.toLocaleLowerCase() === brand) {
          return ele.LEV_CS_Pro !== "true";
        }
      });
    }
    //filter DS 9 E-TENSE 225
    proData = this.filterDS9(proData);
    return proData;
  }

  // Labels and Translations from the backoffice
  getLabelsByBrandAndLang() {
    if (!this.labelCulture || Object.keys(this.labelCulture).length === 0) {
      let nameFile =
        "labels_f2mc/labels_f2mc_" + this.selectedBrand.toLowerCase();
      this.levservice.getData(nameFile).subscribe((data) => {
        this.labelCulture =
          data[this.language.toLowerCase() + "-" + this.country.toLowerCase()];
        this.getLevByBrandAndLang();
      });
    } else {
      this.getLevByBrandAndLang();
    }
  }

  pushChargeTimeMobile(element: string, value: string) {
    let type = "";
    if (["100,0", "50,0"].includes(element)) type = "rapide";
    if (["22,0", "11,0", "7,4", "4,6", "3,7"].includes(element))
      type = "accelerated";
    if (["3,2", "2,3", "1,8", "1,4"].includes(element)) type = "standard";

    // Push to specific arrays based on the type
    if (type === "rapide") {
      this.chargeTimeMobileRapid.push({
        type,
        key: element === "100,0" ? "≥ 100" : element,
        value,
      });
    } else if (type === "accelerated") {
      this.chargeTimeMobileAccelerated.push({
        type,
        key: element === "100,0" ? "≥ 100" : element,
        value,
      });
    } else if (type === "standard") {
      this.chargeTimeMobileStandard.push({
        type,
        key: element === "100,0" ? "≥ 100" : element,
        value,
      });
    }
  }

  getLoadingTime() {
    this.currentAutonomy = 0;
    this.changeDetector.detectChanges();
    let objectLoadingTime = [];
    let country = this.country.toLowerCase();
    let nameFile = "LoadingTimeData/Ltdata-" + this.selectedBrand.toLowerCase();
    this.getOptimizedLabels();
    this.levservice.getData(nameFile).subscribe((data) => {
      let chargeTime = [],
        charteTimeGraph = [];
      this.loadingTime = data;
      this.loadObjectTime(
        objectLoadingTime,
        country,
        charteTimeGraph,
        chargeTime
      );
      this.rangeCharges(chargeTime, charteTimeGraph);
      return [chargeTime, charteTimeGraph] as [any, any];
    });
    return this.chargeTime;
  }
  getOptimizedLabels() {
    this.labels = this.vehicule["LEV_DEFAULT_VALUES"]["LEV_DEFAULT_CHARGES"];
  }

  loadObjectTime(objectLoadingTime, country, charteTimeGraph, chargeTime) {
    const corespondingCharges = this.loadingTime.LoadingCharges.filter((ele) =>
      ele.vehs.includes(this.vehicule.LEV_CS_LCDV)
    )[0];
    var chargeResults = [];
    chargeResults = Object.keys(corespondingCharges.values)[0].includes("OBC")
      ? corespondingCharges.values["OBC_" + this.obcValue]
      : corespondingCharges.values;

    if (!corespondingCharges) {
      console.warn("No corresponding charges found for the vehicle.");
      return;
    }
    if (!chargeResults) {
      console.warn("Charge results are undefined or invalid.");
      return;
    }
    objectLoadingTime = Object.keys(chargeResults);

    objectLoadingTime.forEach((element) => {
      if (
        this.labels.includes(
          (Number.isInteger(+element.toString().replace(",", "."))
            ? parseInt(element) === 100
              ? "≥ " + parseInt(element)
              : parseInt(element)
            : element) + " kW"
        )
      ) {
        this.pushLoadingTimeObject(
          chargeResults,
          charteTimeGraph,
          chargeTime,
          element
        );
      }
    });
  }

  // This function is used to push the loading time object to the charteTimeGraph and chargeTime arrays
  // for Example : Charte Time Graph inside pushLoadingTimeObject: [26]
  //Charge Time inside pushLoadingTimeObject: ['00h26min']
  pushLoadingTimeObject(
    objectLoadingTime,
    charteTimeGraph,
    chargeTime,
    identifier
  ) {
    this.applyGeneralObc(objectLoadingTime[identifier]);

    this.powerStations.push({ identifier });
    charteTimeGraph.push(this.result);

    chargeTime.push(
      this.timeConvert(this.result, this.labelCulture, this.cs_vauxhall)
    );
  }

  rangeCharges(chargeTime, charteTimeGraph): void {
    this.charges = [];
    let chargesRap = [];
    let chargesDom = [];

    const labelMappings = {
      "≥ 100 kW": "cs_dc_fast",
      "50 kW": "cs_dc_fast", // Update this if a different labelCulture key is required
      "22 kW": "cs_ac_normal", // Update this if a different labelCulture key is required
      "20 kW": "cs_dc_fast", // Update this if a different labelCulture key is required
      "11 kW": "cs_eproWallbox",
      "7,4 kW": "cs_easyWallbox",
      "4,6 kW": "cs_standard_socket",
      "3,7 kW": "cs_standard_socket",
      "3,2 kW": "cs_renforced_socket",
      "2,3 kW": "cs_standard_socket",
      "1,8 kW": "cs_standard_socket",
      "1,4 kW": "cs_standard_socket",
    };

    this.labels.forEach((label, index) => {
      if (labelMappings[label]) {
        const mappedKey = this.labelCulture[labelMappings[label]];
        if (["≥ 100 kW", "50 kW", "22 kW"].includes(label)) {
          chargesRap.push({
            0: mappedKey,
            1: label,
            2: charteTimeGraph[index],
            3: chargeTime[index],
          });
        } else if (
          ["11 kW", "7,4 kW", "3,7 kW", "3,2 kW", "2,3 kW", "1,8 kW"].includes(
            label
          )
        ) {
          chargesDom.push({
            0: mappedKey,
            1: label,
            2: charteTimeGraph[index],
            3: chargeTime[index],
          });
        }
      }
    });
    this.charges = [chargesRap, chargesDom];
    this.calculateElectricityCost(this.charges);
    this.applyChangesonChargesTableBasedOnCountry(this.charges);
    this.lastGroup = this.charges[this.charges.length - 1]; // Get the last group
    const lastChargeString = this.lastGroup[this.lastGroup.length - 1][2]; // Get the last charge value as a string
    this.lastCharge = Number(lastChargeString); // Convert to number
    this.maxArray = Math.max(...charteTimeGraph);
  }

  applyChangesonChargesTableBasedOnCountry(charges) {
    if (this.country.toLowerCase() === "fr") {
      charges[1] = charges[1].filter((item) => item[1] !== "1,4 kW");
      charges[1] = charges[1].filter((item) => item[1] !== "1,8 kW");
      charges[1] = charges[1].filter((item) => item[1] !== "3,2 kW");
    } else {
      charges[1] = charges[1].filter((item) => item[1] !== "1,8 kW");
      charges[1] = charges[1].filter((item) => item[1] !== "3,2 kW");
    }
  }

  applyGeneralObc(element) {
    // SocMINI = 20 diayl Charger and socMax = 80 for BEV
    if (this.vehicule.LEV_CS_TYPE.toLowerCase() == "phev") this.socMaxi = 100;
    this.result = element[this.socMaxi] - element[this.socMini];
  }

  calculateLoadingTimeChagesByOBCValue(loadingTimeCharges, i) {
    if (
      Object.keys(this.loadingTime.LoadingCharges[0].values)[0].includes("OBC")
    )
      loadingTimeCharges =
        this.loadingTime.LoadingCharges[i].values["OBC_" + this.obcValue];
    else loadingTimeCharges = loadingTimeCharges.values;
    return loadingTimeCharges;
  }

  isVehiculeIdValid(list) {
    let found = false;
    list.forEach((element) => {
      if (
        element.LEV_CS_LCDV.toLowerCase() == this.vehicleid.toLowerCase() &&
        element.LEV_CS_BRAND.toLowerCase() == this.selectedBrand.toLowerCase()
      )
        found = true;
    });
    return found;
  }

  filterItemsOfVehicules(v) {
    return this.vehs.filter((x) => x.LEV_CS_label !== v.LEV_CS_label);
  }

  calculateElectricityCost(charges) {
    const defaultElectricCostMapping = {
      "100": 0.65,
      "50": 0.65,
      "22": 0.4,
      "20": 0.65,
      "11": 0.25,
      "7,4": 0.25,
      "4,6": 0.25,
      "3,7": 0.25,
      "3,2": 0.25,
      "2,3": 0.25,
      "1,8": 0.25,
      "1,4": 0.25,
    };

    const defaultElectricCoefficientMapping = {
      "100": 1,
      "50": 1,
      "22": 1.15,
      "20": 1,
      "11": 1.15,
      "7,4": 1.15,
      "4,6": 1.15,
      "3,7": 1.15,
      "3,2": 1.15,
      "2,3": 1.15,
      "1,8": 1.15,
      "1,4": 1.15,
    };

    this.chargingCost = charges.map((subArray) =>
      subArray.map((item) => {
        let powerStr = item[1].replace(/[^\d,]/g, ""); // Extract the number from the 1st property (kW)
        let power = parseFloat(powerStr.replace(",", ".")); // Convert to number, handling decimal commas
        let cost = defaultElectricCostMapping[powerStr]; // Get the corresponding cost from the mapping
        let coefficient = defaultElectricCoefficientMapping[powerStr]; // Get the corresponding coefficient from the mapping
        let substractionOfMaxAndMin = (this.socMaxi - this.socMini) / 100;
        let usefulCapacity = parseFloat(this.vehicule.LEV_CS_Useful_capacity);
        // Calculate the final product and round it to 2 decimal places
        let totalCost = Math.round(
          usefulCapacity * substractionOfMaxAndMin * coefficient * cost
        ).toFixed(2);

        return {
          0: usefulCapacity,
          1: substractionOfMaxAndMin,
          2: coefficient,
          3: cost,
          4: parseFloat(totalCost), // Convert back to number
          5: power,
        };
      })
    );
  }

  private currentCostPerLiter: number = 1.9;
  private currentConsumption: number = 5.0;
  private currentAutonomy: number = 0;

  calculateFuelCost(WLTP: number) {
    const wltp = WLTP / 100;
    const totalCost = Math.floor(
      this.currentCostPerLiter * this.currentConsumption * wltp
    ).toFixed(2);

    this.fuelCost = [
      {
        0: wltp,
        1: this.currentCostPerLiter,
        2: this.currentConsumption,
        3: parseFloat(totalCost),
      },
    ];
    this.FuelCostDisplay = this.fuelCost[0][3];
    this.changeDetector.detectChanges();
  }

  onValueChange(newValue: number, typeOfValue: string) {
    const powerMappings = {
      "Public-fast-charge": [100, 50, 20],
      "Public-standard-charge": [22],
      "Private-charge": [11, 7.4, 3.7, 4.6, 3.2, 2.3, 1.8],
    };

    const powerValues = powerMappings[typeOfValue] || [];

    this.chargingCost = this.chargingCost.map((subArray) =>
      subArray.map((item) => {
        if (powerValues.includes(item[5])) {
          let usefulCapacity = item[0];
          let substractionOfMaxAndMin = item[1];
          let coefficient = item[2];
          let power = item[5];
          // Recalculate the cost and floor it to 2 decimal places
          let totalCost = Math.round(
            usefulCapacity * substractionOfMaxAndMin * coefficient * newValue
          ).toFixed(2);

          return {
            0: usefulCapacity,
            1: substractionOfMaxAndMin,
            2: coefficient,
            3: newValue,
            4: parseFloat(totalCost), // Convert back to number
            5: power,
          };
        } else {
          return item; // Leave other items unchanged
        }
      })
    );
  }

  onValueChangeFuel(newValue: number, typeOfValue: string) {
    if (typeOfValue === "Cost-per-liter") {
      this.currentCostPerLiter = newValue;
    } else if (typeOfValue === "Consomation") {
      this.currentConsumption = newValue;
    }

    // Only update fuel cost without recalculating autonomy
    this.updateFuelCost();
  }

  private updateFuelCost() {
    const wltp = this.currentAutonomy / 100;
    const totalCost = Math.floor(
      this.currentCostPerLiter * this.currentConsumption * wltp
    ).toFixed(2);

    this.fuelCost = [
      {
        0: wltp,
        1: this.currentCostPerLiter,
        2: this.currentConsumption,
        3: parseFloat(totalCost),
      },
    ];

    this.FuelCostDisplay = parseFloat(totalCost);
  }

  calculateAutonomy(): number {
    if (this.currentAutonomy === 0) {
      const country = this.country.toLowerCase();
      const brand = this.brand.toLowerCase();
      let initialAutonomy = (this.vehicule.LEV_CS_WLTP * this.socMaxi) / 100;
      this.currentAutonomy = this.preaparingAutonomy(brand, initialAutonomy);

      if (["uk", "gb"].includes(country)) {
        this.currentAutonomy = this.levservice.toMiles(this.currentAutonomy);
      }

      // Calculate fuel cost once autonomy is set
      this.updateFuelCost();
    }

    return this.currentAutonomy;
  }

  // /* Refactored or Exported */
  preaparingAutonomy(brand, initialAutonomy) {
    var km = initialAutonomy;
    if (this.vehicule.LEV_CS_TYPE.toLowerCase() == "phev")
      km = ~~initialAutonomy;
    if (brand == "ov" && this.socMaxi == 100) km = initialAutonomy;
    // else if (this.checkIsFloatNumber(initialAutonomy)) km = this.round10(initialAutonomy);
    if (this.checkIsFloatNumber(initialAutonomy))
      km = Math.round(initialAutonomy);
    // else km = initialAutonomy;
    return km;
  }

  checkIsFloatNumber(number: number): boolean {
    return Number(number) === number && number % 1 !== 0;
  }

  timeConvert(
    num: number,
    label_culture: cs_labelCulture,
    cs_vauxhall: boolean
  ): string {
    let hours: number = Math.floor(num / 60);
    let minutes: number = num % 60;
    let value: string = `${("0" + hours).slice(-2)}@@@@${
      label_culture.cs_houre
    }@@@@${("0" + minutes).slice(-2)}@@@@${label_culture.cs_minute}`;
    return value.replace(/@@@@/gi, cs_vauxhall ? " " : "");
  }

  tagCsLabel(eventAction: string, eventLabel: string) {
    window["dataLayer"] = window["dataLayer"] || [];
    window["dataLayer"].push({
      event: "uaevent",
      eventCategory: "Content",
      eventAction: eventAction,
      eventLabel: eventLabel,
    });
  }
}
