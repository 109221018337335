<hr
  *ngIf="mobile === true && as_peugeot && !isspoticar"
  class="lev-hl"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
/>
<div
  class="lev-as-result-title"
  [class.d1]="isd1"
  [class.ac]="as_citroen"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
  [ngClass]="
    isspoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
  "
  [class.ds]="as_ds"
  [class.vx]="as_vauxhall"
  [class.ov]="as_opel"
  *ngIf="!speedS && !speedXl && speed"
>
  <div
    *ngIf="!isNewOpelStyle"
    role="status"
    [class.is-right-to-left]="isRightToLeft"
    [attr.aria-label]="
      (asHasConso ? modeElectricLabel : label) + ' : ' + speed + unit
    "
    class="lev-as-result-title-label"
  >
    {{ asHasConso ? modeElectricLabel : label }}
  </div>
  <div
    *ngIf="!isNewOpelStyle"
    [class.is-right-to-left]="isRightToLeft"
    class="lev-as-result-title-value"
  >
    {{ speed }}
  </div>
  <div
    *ngIf="!isNewOpelStyle"
    [class.is-right-to-left]="isRightToLeft"
    class="lev-as-result-title-unit"
  >
    {{ unit }}
  </div>
  <div
    *ngIf="isNewOpelStyle"
    role="status"
    [attr.aria-label]="
      (asHasConso ? modeElectricLabel : label) + ' : ' + speed + unit
    "
    class="lev-as-result-newStyleOpel"
  >
    <div
      class="lev-as-result-newStyleOpel-value"
      [class.is-right-to-left]="isRightToLeft"
    >
      {{ speed }}
    </div>
    <div
      class="lev-as-result-newStyleOpel-unit"
      [class.is-right-to-left]="isRightToLeft"
    >
      {{ unit }}
    </div>
    <div
      class="lev-as-result-newStyleOpel-label"
      [class.is-right-to-left]="isRightToLeft"
    >
      {{ asHasConso ? modeElectricLabel : label }}
    </div>
  </div>
  <div
    *ngIf="!isNewOpelStyle"
    [class.is-right-to-left]="isRightToLeft"
    class="lev-as-result-title-info hide"
  >
    i
  </div>
</div>
<img
  *ngIf="
    !consumptionS && !consumptionXl && speed && asHasConso && !isNewOpelStyle
  "
  class="lev-as-result-image"
  [src]="levAsPicture"
  alt=""
  [class.ac]="as_citroen"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
  [class.spoticar]="isspoticar"
  [class.ds]="as_ds"
  [class.vx]="as_vauxhall"
  [class.ov]="as_opel"
/>
<div
  *ngIf="
    !consumptionS && !consumptionXl && speed && asHasConso && !isNewOpelStyle
  "
  class="lev-as-result-name"
  role="status"
  [attr.aria-label]="labelVehicle"
  [class.ac]="as_citroen"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
  [ngClass]="
    isspoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
  "
  [class.ds]="as_ds"
  [class.vx]="as_vauxhall"
  [class.is-right-to-left]="isRightToLeft"
  [class.ov]="as_opel"
>
  {{ labelVehicle }}
</div>
<div
  class="lev-as-result-title margin-conso"
  [class.d1]="isd1"
  [class.ac]="as_citroen"
  [class.spoticar]="isspoticar"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
  [class.ds]="as_ds"
  [class.vx]="as_vauxhall"
  [class.ov]="as_opel"
  *ngIf="!consumptionS && !consumptionXl && speed && asHasConso"
>
  <div
    class="lev-as-result-title-label"
    role="status"
    [class.is-right-to-left]="isRightToLeft"
    [attr.aria-label]="as_hybridModeConsumption"
  >
    {{ as_hybridModeConsumption }}
  </div>
  <div
    class="lev-as-result-title-value"
    role="status"
    [class.is-right-to-left]="isRightToLeft"
    [attr.aria-label]="consumption + as_consoUnits"
  >
    {{ consumption }}
  </div>
  <div
    class="lev-as-result-title-unit"
    [class.is-right-to-left]="isRightToLeft"
  >
    {{ as_consoUnits }}
  </div>
  <div
    class="lev-as-result-title-info hide"
    [class.is-right-to-left]="isRightToLeft"
  >
    i
  </div>
</div>
<div
  *ngIf="!consumptionS && !consumptionXl && speed && asHasConso"
  class="lev-as-result-consumption-info"
  [class.ac]="as_citroen"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
  [class.spoticar]="isspoticar"
  [class.ds]="as_ds"
  [class.vx]="as_vauxhall"
  [class.ov]="as_opel"
  [class.is-right-to-left]="isRightToLeft"
  role="status"
  [attr.aria-label]="labelParoursEurope"
>
  {{ labelParoursEurope }}
</div>
<div
  *ngIf="!consumptionS && !consumptionXl && speed && asHasConso"
  class="lev-as-result-consumption-legal-mensions"
  [class.ac]="as_citroen"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
  [class.spoticar]="isspoticar"
  [class.ds]="as_ds"
  [class.vx]="as_vauxhall"
  role="status"
  [class.is-right-to-left]="isRightToLeft"
  [attr.aria-label]="mentionsLegalForConsumption"
  [class.ov]="as_opel"
>
  {{ mentionsLegalForConsumption }}
</div>

<div
  id="lev-row-batteries"
  [class.d1]="isd1"
  [class.ac]="as_citroen"
  [class.ap]="as_peugeot"
  [class.fo]="as_fiat"
  [ngClass]="
    isspoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
  "
  [class.ds]="as_ds"
  [class.vx]="as_vauxhall"
  [class.ov]="as_opel"
  *ngIf="(speedS || speedXl) && !speed"
>
  <div class="row">
    <div
      id="lev-label-s-xl"
      class="col-md-12 lev-as-result-title-couple"
      [class.ac]="as_citroen"
      [class.ap]="as_peugeot"
      [class.fo]="as_fiat"
      [class.spoticar]="isspoticar"
      [class.ds]="as_ds"
      [class.vx]="as_vauxhall"
      [class.ov]="as_opel"
      *ngIf="batteryS && batteryXl && !speed"
    >
      <div
        class="lev-as-result-title-label"
        role="status"
        [class.is-right-to-left]="isRightToLeft"
        [attr.aria-label]="asHasConso ? modeElectricLabel : label"
      >
        {{ asHasConso ? modeElectricLabel : label }}
      </div>
    </div>
  </div>
  <div
    class="{{
      null !== mobile && mobile === true ? 'col-md-12 col-sm-12' : 'row pxp-40'
    }}"
    [class.vx]="as_vauxhall"
    [class.spoticar]="isspoticar"
    [class.ac]="as_citroen"
  >
    <div class="row">
      <div
        class="col-md-6 col-sm-6 lev-as-result-title to-left {{
          null !== mobile && mobile === true ? 'small-battery-to-left' : ''
        }}"
        [class.ac]="as_citroen"
        [class.ap]="as_peugeot"
        [class.fo]="as_fiat"
        [class.spoticar]="isspoticar"
        [class.ds]="as_ds"
        [class.vx]="as_vauxhall"
        [class.ov]="as_opel"
        *ngIf="speedS"
        role="status"
        [attr.aria-label]="batteryS + ' : ' + label + ' ' + speedS + unit"
      >
        <div class="lev-as-result-title-label">{{ batteryS }}</div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-value {{
            null !== mobile && mobile === true ? 'medium-value' : ''
          }}"
        >
          {{ speedS }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-unit {{
            null !== mobile && mobile === true ? 'medium-km adapte-margin' : ''
          }}"
        >
          {{ unit }}
        </div>
        <div
          class="lev-as-result-title-info hide"
          [class.is-right-to-left]="isRightToLeft"
        >
          i
        </div>
        <div
          class="lev-as-result-title-label"
          [class.is-right-to-left]="isRightToLeft"
          *ngIf="speedS && !speedXl"
        >
          {{ label }}
        </div>
      </div>
      <div
        class="lev-vl"
        [class.ac]="as_citroen"
        [class.ap]="as_peugeot"
        [class.fo]="as_fiat"
        [class.spoticar]="isspoticar"
        [class.ds]="as_ds"
        [class.vx]="as_vauxhall"
        [class.ov]="as_opel"
        *ngIf="batteryS && batteryXl"
      ></div>
      <div
        class="col-md-6 col-sm-6 lev-as-result-title {{
          null !== mobile && mobile === true ? 'large-battery-to-right' : ''
        }}"
        [class.ac]="as_citroen"
        [class.ap]="as_peugeot"
        [class.fo]="as_fiat"
        [class.spoticar]="isspoticar"
        [class.ds]="as_ds"
        [class.vx]="as_vauxhall"
        role="status"
        [attr.aria-label]="batteryXl + ' : ' + label + ' ' + speedXl + unit"
        [class.ov]="as_opel"
        *ngIf="speedXl"
      >
        <div
          class="lev-as-result-title-label"
          [class.is-right-to-left]="isRightToLeft"
        >
          {{ batteryXl }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-value {{
            null !== mobile && mobile === true ? 'medium-value' : ''
          }}"
        >
          {{ speedXl }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-unit {{
            null !== mobile && mobile === true ? 'medium-km' : ''
          }}"
        >
          {{ unit }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-info hide"
        >
          i
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-label"
          *ngIf="!speedS && speedXl"
        >
          {{ label }}
        </div>
      </div>
    </div>
  </div>
  <img
    *ngIf="asHasConso && !isNewOpelStyle"
    class="lev-as-result-image"
    [src]="levAsPicture"
    alt=""
    [class.ac]="as_citroen"
    [class.ap]="as_peugeot"
    [class.fo]="as_fiat"
    [class.spoticar]="isspoticar"
    [class.ds]="as_ds"
    [class.vx]="as_vauxhall"
    [class.ov]="as_opel"
    alt=""
  />
  <div
    *ngIf="asHasConso"
    class="lev-as-result-name"
    [class.ac]="as_citroen"
    [class.ap]="as_peugeot"
    [class.fo]="as_fiat"
    [ngClass]="
      isspoticar ? ['spoticar', 'spoticar-style', 'spoticar-lev-style'] : ''
    "
    [class.ds]="as_ds"
    [class.vx]="as_vauxhall"
    role="status"
    [class.is-right-to-left]="isRightToLeft"
    [attr.aria-label]="labelVehicle"
    [class.ov]="as_opel"
  >
    {{ labelVehicle }}
  </div>
  <div class="row margin-conso" *ngIf="asHasConso">
    <div
      id="lev-label-s-xl2"
      class="col-md-12 lev-as-result-title-couple"
      [class.ac]="as_citroen"
      [class.ap]="as_peugeot"
      [class.fo]="as_fiat"
      [class.spoticar]="isspoticar"
      [class.ds]="as_ds"
      [class.vx]="as_vauxhall"
      [class.ov]="as_opel"
      *ngIf="consumptionS && consumptionXl && !consumption"
    >
      <div
        class="lev-as-result-title-label"
        role="status"
        [class.is-right-to-left]="isRightToLeft"
        [attr.aria-label]="as_hybridModeConsumption"
      >
        {{ as_hybridModeConsumption }}
      </div>
    </div>
  </div>
  <div
    class="{{
      null !== mobile && mobile === true ? 'col-md-12 col-sm-12' : 'row pxp-40'
    }}"
    [class.vx]="as_vauxhall"
    [class.spoticar]="isspoticar"
    [class.ac]="as_citroen"
    *ngIf="asHasConso"
  >
    <div class="row">
      <div
        class="col-md-6 col-sm-6 lev-as-result-title to-left {{
          null !== mobile && mobile === true ? 'small-battery-to-left' : ''
        }}"
        [class.ac]="as_citroen"
        [class.ap]="as_peugeot"
        [class.fo]="as_fiat"
        [class.spoticar]="isspoticar"
        [class.ds]="as_ds"
        [class.vx]="as_vauxhall"
        role="status"
        [attr.aria-label]="
          batteryS +
          ' : ' +
          as_hybridModeConsumption +
          ' ' +
          consumptionS +
          as_consoUnits
        "
        [class.ov]="as_opel"
        *ngIf="consumptionS"
      >
        <div
          class="lev-as-result-title-label"
          [class.is-right-to-left]="isRightToLeft"
        >
          {{ batteryS }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-value {{
            null !== mobile && mobile === true ? 'medium-value' : ''
          }}"
        >
          {{ consumptionS }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-unit {{
            null !== mobile && mobile === true ? 'medium-km adapte-margin' : ''
          }}"
        >
          {{ as_consoUnits }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-info hide"
        >
          i
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-label"
          *ngIf="consumptionS && !consumptionXl"
        >
          {{ as_hybridModeConsumption }}
        </div>
      </div>
      <div
        class="lev-vl"
        [class.ac]="as_citroen"
        [class.ap]="as_peugeot"
        [class.fo]="as_fiat"
        [class.spoticar]="isspoticar"
        [class.ds]="as_ds"
        [class.vx]="as_vauxhall"
        [class.ov]="as_opel"
        *ngIf="consumptionS && consumptionXl"
      ></div>
      <div
        class="col-md-6 col-sm-6 lev-as-result-title {{
          null !== mobile && mobile === true ? 'large-battery-to-right' : ''
        }}"
        [class.ac]="as_citroen"
        [class.ap]="as_peugeot"
        [class.fo]="as_fiat"
        [class.spoticar]="isspoticar"
        [class.ds]="as_ds"
        [class.vx]="as_vauxhall"
        role="status"
        [attr.aria-label]="
          batteryXl +
          ' : ' +
          as_hybridModeConsumption +
          ' ' +
          consumptionXl +
          as_consoUnits
        "
        [class.ov]="as_opel"
        *ngIf="consumptionXl"
      >
        <div
          class="lev-as-result-title-label"
          [class.is-right-to-left]="isRightToLeft"
        >
          {{ batteryXl }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-value {{
            null !== mobile && mobile === true ? 'medium-value' : ''
          }}"
        >
          {{ consumptionXl }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-unit {{
            null !== mobile && mobile === true ? 'medium-km' : ''
          }}"
        >
          {{ as_consoUnits }}
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-info hide"
        >
          i
        </div>
        <div
          [class.is-right-to-left]="isRightToLeft"
          class="lev-as-result-title-label"
          *ngIf="!consumptionS && consumptionXl"
        >
          {{ as_hybridModeConsumption }}
        </div>
      </div>
    </div>
    <div
      class="lev-as-result-consumption-info"
      [class.ac]="as_citroen"
      [class.ap]="as_peugeot"
      [class.fo]="as_fiat"
      [class.spoticar]="isspoticar"
      [class.ds]="as_ds"
      [class.vx]="as_vauxhall"
      role="status"
      [class.is-right-to-left]="isRightToLeft"
      [attr.aria-label]="labelParoursEurope"
      [class.ov]="as_opel"
    >
      {{ labelParoursEurope }}
    </div>
    <div
      class="lev-as-result-consumption-legal-mensions"
      [class.ac]="as_citroen"
      [class.ap]="as_peugeot"
      [class.fo]="as_fiat"
      [class.spoticar]="isspoticar"
      [class.ds]="as_ds"
      [class.vx]="as_vauxhall"
      role="status"
      [class.is-right-to-left]="isRightToLeft"
      [attr.aria-label]="mentionsLegalForConsumption"
      [class.coupled-consumption]="consumptionS && consumptionXl"
      [class.ov]="as_opel"
    >
      {{ mentionsLegalForConsumption }}
    </div>
  </div>
</div>
