import { BrowserModule } from "node_modules/@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { AppComponent } from "./app.component";
import { UsageSimulatorComponent } from "projects/usage-simulator/src/app/usage-simulator/usage-simulator.component";
import { CompatibilityTestComponent } from "projects/compatibility-test/src/app/compatibility-test/compatibility-test.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSliderModule } from "@angular/material/slider";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SurveyComponent } from "./survey/survey.component";
import { LevServiceService } from "./services/lev-service.service";
import { TecMinInputValueDirective } from "projects/compatibility-test/src/app/directives/tec-min-input-value.directive";
import { Data } from "./models/data";
import { NumberOnlyDirective } from "./directives/number.directive";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ChargeSimulatorComponent } from "projects/charge-simulator/src/app/charge-simulator/charge-simulator.component";
import { ShiftPipe } from "projects/charge-simulator/src/app/pipes/shift.pipe";
import { ShiftPercentPipe } from "projects/charge-simulator/src/app/pipes/shift-percent.pipe";
import { AutonomySimulatorComponent } from "projects/autonomy-simulator/src/app/autonomy-simulator/autonomy-simulator.component";
import { SpeedBoxComponent } from "projects/autonomy-simulator/src/app/autonomy-simulator/speed-box/speed-box.component";
import { SliderToggleComponent } from "projects/autonomy-simulator/src/app/autonomy-simulator/slider-toggle/slider-toggle.component";
import { BreakLineDirective } from "@lev/cs/directives/break-line.directive";
import { AppRoutingModule } from "./app-routing.module";
import { CostAPIService } from "./services/cost-api.service";
import { EcoAdvisorComponent } from "@lev/eco/eco-advisor/eco-advisor.component";
import { SavingCalculatorComponent } from "@lev/sav/saving-calculator/saving-calculator.component";
import { SwitcherComponent } from "@lev/sav/switcher/switcher.component";
import { SliderComponent } from "@lev/sav/slider/slider.component";
import { ResultsComponent } from "@lev/eco/results/results.component";
import { IncentiveComponent } from "@lev/eco/incentive/incentive.component";
import { EsolutionFetchService } from "@lev/services/esolution.service";
import { EcoAdvisorService } from "@lev/services/eco-advisor.service";
import { TranslationsService } from "@lev/services/translations.service";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { LoaderInterceptor } from "@lev/us/services/loading.interceptor";
import { MatTabsModule } from "@angular/material/tabs";

import { Free2moveComponent } from "projects/free2move/src/app/free2move/free2move.component";
import { ChargercostComponent } from "projects/free2move/src/app/components/chargercost/chargercost.component";
import { ChargingTimeF2mcComponent } from "projects/free2move/src/app/widgets/charging-time-f2mc/charging-time-f2mc.component";
import { Free2moveService } from "projects/free2move/src/app/services/free2move.service";
// import { FuelcostComponent } from './components/fuelcost/fuelcost.component';
import { CircularProgressComponent } from "projects/free2move/src/app/widgets/charging-time-f2mc/components/circular-progress/circular-progress.component";
import { ProgressBarComponent } from "projects/free2move/src/app/widgets/charging-time-f2mc/components/progress-bar/progress-bar.component";
import { ButtonDiscoverComponent } from "projects/free2move/src/app/widgets/charging-time-f2mc/components/button-discover/button-discover.component";
import { RouterModule } from "@angular/router";
import { DrivingStyleComponent } from "projects/free2move/src/app/widgets/range-calculator/components/driving-style/driving-style.component";
import { SliderBoxComponent } from "projects/free2move/src/app/widgets/range-calculator/components/slider-box/slider-box.component";
import { RangeCalculatorComponent } from "projects/free2move/src/app/widgets/range-calculator/range-calculator.component";
import { SpeedComponent } from "projects/free2move/src/app/widgets/range-calculator/components/speed-box/speed-box.component";
import { CheckBoxComponent } from "projects/free2move/src/app/widgets/range-calculator/components/check-box/check-box.component";
import { FuelSavingComponent } from "projects/free2move/src/app/widgets/fuel-saving/fuel-saving.component";
import { SpeedSliderComponent } from "projects/free2move/src/app/widgets/fuel-saving/components/speed-slider/speed-slider.component";
import { FuelSavingCardsComponent } from "projects/free2move/src/app/widgets/fuel-saving/components/fuel-saving-cards/fuel-saving-cards.component";
import { HammerModule } from "@angular/platform-browser";

import { VehicleDataService } from "projects/free2move/src/app/services/vehicle-data.service";

// Subcomponents
import { TabContainerComponent } from "projects/free2move/src/app/components/tab-container/tab-container.component";
import { BrandSelectorComponent } from "projects/free2move/src/app/components/brand-selector/brand-selector.component";
import { VehicleSelectorComponent } from "projects/free2move/src/app/components/vehicle-selector/vehicle-selector.component";
import { HelpMeChooseComponent } from "projects/free2move/src/app/components//help-me-choose/help-me-choose.component";
@NgModule({
  declarations: [
    AppComponent,
    UsageSimulatorComponent,
    CompatibilityTestComponent,
    ChargeSimulatorComponent,
    AutonomySimulatorComponent,
    SavingCalculatorComponent,
    EcoAdvisorComponent,
    Free2moveComponent,
    SpeedBoxComponent,
    SliderToggleComponent,
    SurveyComponent,
    TecMinInputValueDirective,
    BreakLineDirective,
    NumberOnlyDirective,
    ShiftPercentPipe,
    ShiftPipe,
    SwitcherComponent,
    SliderComponent,
    ResultsComponent,
    IncentiveComponent,
    ChargercostComponent,
    ChargingTimeF2mcComponent,
    ProgressBarComponent,
    CircularProgressComponent,
    ButtonDiscoverComponent,
    DrivingStyleComponent,
    SpeedBoxComponent,
    SliderBoxComponent,
    RangeCalculatorComponent,
    SpeedComponent,
    CheckBoxComponent,
    FuelSavingComponent,
    SpeedSliderComponent,
    FuelSavingCardsComponent,
    TabContainerComponent,
    BrandSelectorComponent,
    VehicleSelectorComponent,
    HelpMeChooseComponent,
  ],
  imports: [
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    NgbModule,
    NgxSliderModule,
    MatTabsModule,
    HammerModule,
  ],
  providers: [
    Data,
    LevServiceService,
    CostAPIService,
    EsolutionFetchService,
    EcoAdvisorService,
    Free2moveService,
    TranslationsService,
      VehicleDataService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}

  ngDoBootstrap() {}
}
