
export class Vehicule {
    LEV_AS_BRAND:   string = "";
    LEV_AS_LCDV:    string = "";
    LEV_AS_label:   string = "";
    LEV_AS_TYPE:    string = "";
    LEV_AS_Pro:     string = "";
    LEV_AS_Picture: string = "";
  LEV_DEFAULT_VALUES: any;
}
