export class CssData {
    img_top_md: string = "";
    img_top_xs: string = "";
    img_picto: string = "";
    marker_icon_plugsurfing: string = "";
    marker_icon_plugsurfing_inactif_d1: string = "";
    marker_icon_plugsurfing_actif_d1: string = "";
    marker_icon_tomtom: string = "";
    loader: string = "";
    loaderd1: string = "";
    picto_point: string = "";
    colorRoute: string = "";
    picto_depart: string = "";
    picto_arrive: string = "";
    tooltip: string = "";
    distance: string = "";
    recharge_necessaire: string = "";
    sans_recharge: string = "";
    close_popin: string = "";
    geolocalisation: string = "";
    geolocalisation_hover: string = "";
    inversion: string = "";
    chevron: string = "";
    chevron_off: string = "";
    validate_outline: string = "";
    chevron_hover: string = "";
    chevron_off_hover: string = "";
    prise_off:string = "";
    prise_on:string = "";
    en_savoir_plus:string = "";
    prise_cambo_off:string = "";
    prise_cambo_on:string = "";
    prise_domestique_off:string = "";
    prise_domestique_on:string = "";
    prise_type2_off:string = "";
    prise_type2_on:string = "";
    prise_type3_off:string = "";
    prise_type3_on:string = "";
    arrow_white:string = "";
    arrow_white_opposite:string = "";
    arrow_grey:string = "";
    arrow_black:string = "";
    chevron_right:string = "";
    chevron_right_autonomy: string = "";
    chargeur_accelere:string = "";
    chargeur_rapide:string = "";
    chargeur_standard:string = "";
    prise_renforcee:string = "";
    refresh: string = "";
    chevron_swiper_right: string = "";
    chevron_swiper_left: string = "";
    vehicule1:  string = "";
    vehicule2:  string = "";
    [key: string ]:  string ;
}