<div
  class="speed-wrapper"
  [class.FT]="cs_fiat"
  [class.AR]="cs_alfaRomeo"
  [class.JE]="cs_jeep"
  [class.AP]="cs_peugeot"
>
  <div
    class="estimated-autonomy"
    [class.FT]="cs_fiat"
    [class.AR]="cs_alfaRomeo"
    [class.JE]="cs_jeep"
    [class.AP]="cs_peugeot"
  >
    {{ labelCulture.as_estimated_autonomy }}
  </div>
  <div
    class="displayed-value"
    [class.FT]="cs_fiat"
    [class.AR]="cs_alfaRomeo"
    [class.JE]="cs_jeep"
    [class.AP]="cs_peugeot"
  >
    {{ displayedValue }}
  </div>
  <div
    class="km"
    [class.FT]="cs_fiat"
    [class.AR]="cs_alfaRomeo"
    [class.JE]="cs_jeep"
    [class.AP]="cs_peugeot"
  >
    {{ labelCulture.as_estimated_autonomy_unit }}
  </div>
  <!-- <div class="based-on">
    {{ labelCulture.as_estimated_autonomy_value }}
  </div> -->
</div>
