// free2move.component.ts
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
} from "@angular/core";
import { Free2moveService } from "../services/free2move.service";
import { LevServiceService } from "@lev/services/lev-service.service";
import { VehicleDataService } from "../services/vehicle-data.service";

@Component({
  selector: "app-free2move",
  templateUrl: "./free2move.component.html",
  styleUrls: ["./free2move.component.scss"],
})
export class Free2moveComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() displaycost: string = "true";
  @Input() country: string = "GB";
  @Input() brand: string = "";
  @Input() language: string = "EN";
  @Input() vehicleid: string = "";
  @Input() chargingsimulator: string = "true";
  @Input() rangecalculator: string = "true";
  @Input() fuelsaving: string = "true";
  @Input() helpmechoose: string = "true";
  @Input() defaultwidget: string = "cs"; // Default to charging simulator

  // Tab visibility flags
  canRenderChargingSimulator: boolean = true;
  canRenderRangeCalculator: boolean = true;
  canRenderFuelSaving: boolean = true;
  canRenderHelpMeChoose: boolean = true;

  // Brand styling flags
  cs_peugeot: boolean = false;
  cs_citroen: boolean = false;
  cs_fiat: boolean = false;
  cs_fiatPro: boolean = false;
  cs_alfaRomeo: boolean = false;
  cs_ds: boolean = false;
  cs_jeep: boolean = false;

  // Labels and data
  labelCulture: any;
  isLabelsLoaded: boolean = false;
  activeIndex: number = 0;
  isScriptLoading: boolean = false;

  // Vehicle selection state
  selectedBrand: string = "";
  selectedCar: string = "";
  selectedOBC: string = "";
  currentWidgetValue: string = "cs";
  currentTabState = {
    index: 0,
    tabType: "cs",
  };

  // UI state
  isBrandSelected: boolean = false;
  isVehicleSelected: boolean = false;
  canRenderComponent: boolean = false;
  brandExists: boolean = false;

  // Data
  vehicleIds = [];
  OBCs = [];
  brands = [];
  fuelSavingData = [];
  vehs = [];

  private _mappedDefaultWidget: string = "cs";

  constructor(
    private free2moveService: Free2moveService,
    private cdr: ChangeDetectorRef,
    private levservice: LevServiceService,
    private vehicleDataService: VehicleDataService
  ) {}

  ngOnInit(): void {
    // Process URL parameters first
    this.processUrlParameters();

    // Map the default widget during initialization
    this._mappedDefaultWidget = this.vehicleDataService.mapDefaultWidgetParam(
      this.defaultwidget
    );

    // Check if brand exists in predefined list
    this.checkBrandExists();

    // Set brand styling
    this.setBrandAndStyle(this.brand, this.country);

    // Load labels and initialize component
    this.getLabelsByBrandAndLang().then(() => {
      this.initializeComponent();

      // Set up data subscriptions after initialization
      this.setupDataSubscriptions();

      // Load initial data - this will trigger the data loaded event
      this.loadVehicleData(this.currentWidgetValue, true);
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Handle input changes
    if (changes["chargingsimulator"]) {
      this.chargingsimulator = this.validateInput(
        changes["chargingsimulator"].currentValue,
        "true"
      );
      this.canRenderChargingSimulator = this.convertToBoolean(
        this.chargingsimulator
      );
    }

    if (changes["rangecalculator"]) {
      this.rangecalculator = this.validateInput(
        changes["rangecalculator"].currentValue,
        "true"
      );
      this.canRenderRangeCalculator = this.convertToBoolean(
        this.rangecalculator
      );
    }

    if (changes["fuelsaving"]) {
      this.fuelsaving = this.validateInput(
        changes["fuelsaving"].currentValue,
        "true"
      );
      this.canRenderFuelSaving = this.convertToBoolean(this.fuelsaving);
    }

    if (changes["helpmechoose"]) {
      this.helpmechoose = this.validateInput(
        changes["helpmechoose"].currentValue,
        "true"
      );
      this.canRenderHelpMeChoose = this.convertToBoolean(this.helpmechoose);
    }
  }

  private setupDataSubscriptions(): void {
    // Subscribe to brands data
    this.vehicleDataService.brands$.subscribe((brands) => {
      this.brands = brands;

      // If brand is pre-selected, try to find it in the list
      if (this.brandExists && this.selectedBrand) {
        const matchingBrand = this.vehicleDataService.findBrandByValue(
          this.selectedBrand
        );
        if (matchingBrand) {
          this.onBrandChange(matchingBrand.value);
        }
      }
    });

    // Subscribe to data loaded events
    this.vehicleDataService.dataLoaded$.subscribe((tabType) => {
      if (tabType && tabType === this.currentWidgetValue) {
        if (this.vehicleid) {
          // If vehicle ID is provided, first find the correct brand
          if (!this.brandExists || this.brand.toLowerCase() === "f2mc") {
            const correctBrand = this.vehicleDataService.findBrandForVehicleId(
              this.vehicleid,
              tabType
            );
            console.log("correctBrand for vehicleid:", correctBrand);

            if (correctBrand) {
              this.brand = correctBrand;
              this.brandExists = true;
              this.selectedBrand = correctBrand;

              // Now trigger brand change with the correct brand
              this.onBrandChange(correctBrand);

              // Set a small delay to ensure vehicles are loaded before attempting vehicle selection
              setTimeout(() => {
                if (this.vehicleid) {
                  this.selectedCar = this.vehicleid;
                  this.onVehiculeChange(this.vehicleid);
                }
              }, 300);
            }
          } else if (this.brandExists && this.selectedBrand) {
            // Brand is already known, just load vehicles for this brand and then select vehicle
            this.vehicleDataService.loadVehiclesForBrand(
              tabType,
              this.selectedBrand
            );

            // Set a small delay to ensure vehicles are loaded before attempting vehicle selection
            setTimeout(() => {
              if (this.vehicleid) {
                this.selectedCar = this.vehicleid;
                this.onVehiculeChange(this.vehicleid);
              }
            }, 300);
          }
        }
      }
    });

    // Subscribe to vehicles data
    this.vehicleDataService.vehicles$.subscribe((vehicles) => {
      this.vehicleIds = vehicles;

      // If vehicle ID is pre-selected, select it
      if (this.vehicleid && vehicles.some((v) => v.LCDV === this.vehicleid)) {
        this.selectedCar = this.vehicleid;
        this.isVehicleSelected = true;
        this.onVehiculeChange(this.vehicleid);
      }
    });

    // Subscribe to OBC data
    this.vehicleDataService.obcs$.subscribe((obcs) => {
      this.OBCs = obcs;

      // Select first OBC by default if needed
      if (this.selectedCar && this.OBCs.length > 0 && !this.selectedOBC) {
        this.selectedOBC = this.OBCs[0].value;
        this.updateCanRenderComponent();
      }
    });
  }

  private processUrlParameters(): void {
    const url = new URL(window.location.href);
    const previewParam = url.searchParams.get("preview");

    if (previewParam && ["true", "1"].includes(previewParam.toLowerCase())) {
      // Process each URL parameter
      this.brand = url.searchParams.get("brand") || this.brand;
      this.country = url.searchParams.get("country") || this.country;
      this.language = url.searchParams.get("language") || this.language;
      this.chargingsimulator =
        url.searchParams.get("chargingsimulator") || this.chargingsimulator;
      this.vehicleid = url.searchParams.get("vehicleid") || this.vehicleid;
      this.rangecalculator =
        url.searchParams.get("rangecalculator") || this.rangecalculator;
      this.fuelsaving = url.searchParams.get("fuelsaving") || this.fuelsaving;
      this.helpmechoose =
        url.searchParams.get("helpmechoose") || this.helpmechoose;

      // Add handling for the defaultwidget parameter
      this.defaultwidget =
        url.searchParams.get("defaultwidget") || this.defaultwidget;

      // Log all parameters for debugging
      console.log("URL Parameters:", {
        brand: this.brand,
        country: this.country,
        language: this.language,
        vehicleid: this.vehicleid,
        defaultwidget: this.defaultwidget,
        chargingsimulator: this.chargingsimulator,
        rangecalculator: this.rangecalculator,
        fuelsaving: this.fuelsaving,
        helpmechoose: this.helpmechoose,
      });
    }
  }

  private checkBrandExists(): void {
    const matchingBrand = this.vehicleDataService.findBrandByValue(this.brand);
    if (matchingBrand) {
      this.brandExists = true;
      this.selectedBrand = this.brand;
    }
  }

  private initializeComponent(): void {
    // Validate string inputs and convert to booleans
    this.chargingsimulator = this.validateInput(this.chargingsimulator, "true");
    this.rangecalculator = this.validateInput(this.rangecalculator, "true");
    this.fuelsaving = this.validateInput(this.fuelsaving, "true");
    this.helpmechoose = this.validateInput(this.helpmechoose, "true");

    this.canRenderChargingSimulator = this.convertToBoolean(
      this.chargingsimulator
    );
    this.canRenderRangeCalculator = this.convertToBoolean(this.rangecalculator);
    this.canRenderFuelSaving = this.convertToBoolean(this.fuelsaving);
    this.canRenderHelpMeChoose = this.convertToBoolean(this.helpmechoose);

    // Set initial active tab based on defaultwidget
    this.setInitialActiveTab();

    // Load initial data
    this.loadVehicleData(this.currentWidgetValue);
  }

  private loadVehicleData(
    tabType: string = "cs",
    forceFresh: boolean = false
  ): void {
    this.vehicleDataService.loadVehicleData(tabType, forceFresh);
    this.currentWidgetValue = tabType;
  }

  onTabChange(event: any): void {
    this.activeIndex = event.index;
    this.currentTabState = {
      index: event.index,
      tabType: event.tabType,
    };

    const previousTabType = this.currentWidgetValue;
    this.currentWidgetValue = event.tabType;

    // Reset any retry counters in the service
    this.vehicleDataService.resetRetryCounters();

    // Load data for the new tab if it's not the help-me-choose tab
    if (event.tabType !== "hmc") {
      // Check if we already have data for this tab type
      const hasTabData = this.vehicleDataService.hasDataForTabType(
        event.tabType
      );

      // Only force fresh data load if we don't have data already
      this.loadVehicleData(event.tabType, !hasTabData);

      // If a car is already selected and we're in a tab that uses OBCs, reload OBCs
      if (
        this.selectedCar &&
        (event.tabType === "cs" || event.tabType === "as")
      ) {
        this.vehicleDataService.loadOBCsForVehicle(
          event.tabType,
          this.selectedBrand,
          this.selectedCar
        );
      }

      // If we have a selected brand, reload vehicles for the new tab
      // but only if we already have data for this tab
      if (this.selectedBrand && hasTabData) {
        // Short delay to ensure data is available
        setTimeout(() => {
          this.vehicleDataService.loadVehiclesForBrand(
            event.tabType,
            this.selectedBrand
          );
        }, 300);
      }
    }

    // Reset canRenderComponent for proper re-evaluation
    this.canRenderComponent = false;
    this.updateCanRenderComponent();
  }

  onBrandChange(brandValue: string): void {
    // If brand is changing, reset vehicle and OBC selections
    if (this.selectedBrand !== brandValue) {
      // Only reset selections if not coming from URL parameters
      if (!this.vehicleid || this.isVehicleSelected) {
        this.selectedCar = "";
        this.selectedOBC = "";
        this.isVehicleSelected = false;
      }
    }

    // Set the new brand
    this.selectedBrand = brandValue;
    this.isBrandSelected = true;

    // Load vehicles for the selected brand
    this.vehicleDataService.loadVehiclesForBrand(
      this.currentWidgetValue,
      brandValue
    );

    // Update render flag
    this.updateCanRenderComponent();
  }

  onVehiculeChange(vehicleId: string): void {
    this.selectedCar = vehicleId;
    this.isVehicleSelected = true;

    // Load OBCs for the selected vehicle if needed
    if (this.currentWidgetValue === "cs" || this.currentWidgetValue === "as") {
      this.vehicleDataService.loadOBCsForVehicle(
        this.currentWidgetValue,
        this.selectedBrand,
        vehicleId
      );
    }

    this.updateCanRenderComponent();
  }

  onOBCChange(obcValue: string): void {
    this.selectedOBC = obcValue;
    this.updateCanRenderComponent();
  }

  // Update the flag to render child components
  private updateCanRenderComponent(): void {
    switch (this.currentWidgetValue) {
      case "cs":
        this.canRenderComponent =
          !!this.selectedBrand && !!this.selectedCar && !!this.selectedOBC;
        break;
      case "sav":
        this.canRenderComponent = !!this.selectedBrand && !!this.selectedCar;
        break;
      case "as":
        this.canRenderComponent = !!this.selectedBrand && !!this.selectedCar;
        break;
      default:
        this.canRenderComponent = true;
    }
  }

  setBrandAndStyle(brand: string, country: string): void {
    // Reset all styling flags
    this.cs_peugeot = false;
    this.cs_citroen = false;
    this.cs_fiat = false;
    this.cs_fiatPro = false;
    this.cs_alfaRomeo = false;
    this.cs_ds = false;
    this.cs_jeep = false;

    // Set appropriate flag based on brand
    switch (brand.toLowerCase()) {
      case "ap":
        this.cs_peugeot = true;
        break;
      case "ac":
        this.cs_citroen = true;
        break;
      case "ft":
        this.cs_fiat = true;
        break;
      case "ar":
        this.cs_alfaRomeo = true;
        break;
      case "fo":
        this.cs_fiatPro = true;
        break;
      case "ds":
        this.cs_ds = true;
        break;
      case "je":
        this.cs_jeep = true;
        break;
    }
  }

  // Utility method to convert string to boolean
  convertToBoolean(value: string): boolean {
    return value === "true";
  }

  // Utility method to validate input with default
  validateInput(
    input: string | undefined | null,
    defaultValue: string
  ): string {
    return input === undefined || input === null || input === ""
      ? defaultValue
      : input;
  }

  // Load labels based on brand and language
  getLabelsByBrandAndLang(): Promise<void> {
    return new Promise((resolve) => {
      const nameFile = "labels_f2mc/labels_f2mc_" + this.brand.toLowerCase();
      this.levservice.getData(nameFile).subscribe({
        next: (data) => {
          const requestedKey =
            this.language.toLowerCase() + "-" + this.country.toLowerCase();

          // Check if the requested language-country combination exists
          if (data[requestedKey]) {
            this.labelCulture = data[requestedKey];
          } else {
            // If not, fallback to en-gb
            this.labelCulture = data["en-gb"];
          }

          this.isLabelsLoaded = true;
          resolve();
        },
        error: (error) => {
          console.error("Error loading labels:", error);

          // Try loading AP labels as fallback
          this.levservice.getData("labels_f2mc/labels_f2mc_ap").subscribe({
            next: (fallbackData) => {
              this.labelCulture = fallbackData["en-gb"];
              this.isLabelsLoaded = true;
              resolve();
            },
            error: (fallbackError) => {
              console.error("Error loading fallback labels:", fallbackError);
              this.isLabelsLoaded = true;
              resolve();
            },
          });
        },
      });
    });
  }

  /**
   * Sets the initial active tab based on defaultwidget parameter and tab availability
   */
  private setInitialActiveTab(): void {
    const mappedDefaultWidget = this.vehicleDataService.mapDefaultWidgetParam(
      this.defaultwidget
    );

    const tabConfig = [
      { type: "cs", canRender: this.canRenderChargingSimulator },
      { type: "sav", canRender: this.canRenderFuelSaving },
      { type: "as", canRender: this.canRenderRangeCalculator },
      { type: "hmc", canRender: this.canRenderHelpMeChoose },
    ];

    const enabledTabs = tabConfig.filter((tab) => tab.canRender);

    let targetIndex = enabledTabs.findIndex(
      (tab) => tab.type === mappedDefaultWidget
    );

    if (targetIndex === -1) {
      targetIndex = 0;
    }

    this.activeIndex = targetIndex;
    this.currentWidgetValue = enabledTabs[targetIndex].type;

    // Force change detection to ensure currentWidgetValue is updated
    this.cdr.detectChanges();

    this.currentTabState = {
      index: this.activeIndex,
      tabType: this.currentWidgetValue,
    };

    this.loadVehicleData(this.currentWidgetValue, true);
  }
}
