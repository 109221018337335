<div class="eco-advisor-incentive" *ngIf="index !== 0">
  <div class="eco-advisor-incentive-section">
    <div class="eco-advisor-incentive-inline-element">
      <div class="eco-advisor-incentive-title" [ngClass]="[brand, country]">
        {{ title }}
      </div>
      <div
        *ngIf="isMobile"
        class="eco-advisor-incentive-arrow-mobile"
        (click)="showOrHideContent(index)"
        [ngClass]="[brand, country]"
      >
        <img
          class="eco-advisor-incentive-icon-mobile"
          [class.rotate]="hideContent && !isFirst"
          [src]="eco_arrow_icon"
          alt="eco-advisor-incentive-icon-mobile"
        />
      </div>
    </div>
    <div *ngIf="isMobile" class="eco-advisor-incentive-data">
      <div class="eco-advisor-incentive-value" [ngClass]="[brand, country]">
        {{ !isChecked ? value + " " + currency : "" }}
        <div
          *ngIf="isMobile && isChecked"
          class="eco-advisor-incentive-checked"
          [ngClass]="[brand, country]"
        ></div>
      </div>
    </div>
    <div
      *ngIf="description"
      class="eco-advisor-incentive-description"
      [class.hide]="isMobile && hideContent && !isFirst"
      [ngClass]="[brand, country]"
    >
      {{ description }}
    </div>
    <a
      *ngIf="link"
      class="eco-advisor-incentive-link"
      [class.hide]="isMobile && hideContent && !isFirst"
      [target]="target"
      [href]="link"
      [ngClass]="[brand, country]"
    >
      {{ linkLabel }}
    </a>
  </div>
  <div class="eco-advisor-incentive-data">
    <div
      *ngIf="!isMobile"
      class="eco-advisor-incentive-value"
      [ngClass]="[brand, country]"
    >
      {{ !isChecked ? value + " " + currency : "" }}
      <div
        *ngIf="!isMobile && isChecked"
        class="eco-advisor-incentive-checked"
        [ngClass]="[brand, country]"
      ></div>
    </div>
  </div>
</div>
