import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SurveyComponent }            from './survey/survey.component';
import { AutonomySimulatorComponent } from '@lev/as/autonomy-simulator/autonomy-simulator.component';
import { ChargeSimulatorComponent }   from '@lev/cs/charge-simulator/charge-simulator.component';
import { CompatibilityTestComponent } from '@lev/qeva/compatibility-test/compatibility-test.component';
import { UsageSimulatorComponent }    from '@lev/us/usage-simulator/usage-simulator.component';
import {EcoAdvisorComponent}          from "@lev/eco/eco-advisor/eco-advisor.component";
import {SavingCalculatorComponent}    from "@lev/sav/saving-calculator/saving-calculator.component";
import {Free2moveComponent}           from '../../projects/free2move/src/app/free2move/free2move.component';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: SurveyComponent },
  { path: 'usage-simulator',    component: UsageSimulatorComponent },
  { path: 'compatibility-test', component: CompatibilityTestComponent },
  { path: 'charge-simulator',   component: ChargeSimulatorComponent },
  { path: 'saving-calculator',  component: SavingCalculatorComponent },
  { path: 'eco-advisor',        component: EcoAdvisorComponent },
  { path: 'free2move',        component: Free2moveComponent },
  { path: 'autonomy-simulator', component: AutonomySimulatorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
