// help-me-choose.component.ts
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";

@Component({
  selector: "app-help-me-choose",
  templateUrl: "./help-me-choose.component.html",
  styleUrls: ["./help-me-choose.component.scss"],
})
export class HelpMeChooseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() brand: string = "";
  @Input() country: string = "GB";
  @Input() language: string = "EN";

  private scriptUrl =
    "https://www.free2movecharge.it/content/dam/stellantis/free2movecharge/2024/ee/hmc-library/main.bundle.js";
  private scriptLoaded = false;
  isScriptLoading: boolean = false;

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    // No initialization needed here - we'll load the script in AfterViewInit
  }

  ngAfterViewInit(): void {
    // Load the script after the view is initialized
    this.cleanupHelpMeChoose();
    this.loadHelpMeChooseScript().catch((error) => {
      console.error("Error loading Help Me Choose script:", error);
    });
  }

  ngOnDestroy(): void {
    this.cleanupHelpMeChoose();
  }

  /**
   * Public method to reload the component
   */
  public refresh(): void {
    this.cleanupHelpMeChoose();
    this.scriptLoaded = false;

    setTimeout(() => {
      this.loadHelpMeChooseScript().catch((error) => {
        console.error("Error refreshing Help Me Choose:", error);
      });
    }, 0);
  }

  /**
   * Clean up existing scripts and DOM elements
   */
  private cleanupHelpMeChoose(): void {
    // Remove existing script
    const existingScript = document.querySelector(
      `script[src="${this.scriptUrl}"]`
    );
    if (existingScript) {
      existingScript.remove();
    }

    // Clear container
    const container = this.elementRef.nativeElement.querySelector(
      "#helpmechoose-container"
    );
    if (container) {
      container.innerHTML = "";
    }
  }

  /**
   * Load the external Help Me Choose script
   */
  private async loadHelpMeChooseScript(): Promise<void> {
    if (this.scriptLoaded) return;
    this.isScriptLoading = true;

    try {
      const container = this.elementRef.nativeElement.querySelector(
        "#helpmechoose-container"
      );
      if (!container) {
        throw new Error("Container element not found");
      }

      // Create the main div structure
      const mainDiv = document.createElement("div");
      mainDiv.id = "mainDiv";

      const firstChildDiv = document.createElement("div");
      firstChildDiv.id = "firstChildDiv";
      mainDiv.appendChild(firstChildDiv);

      const secondDiv = document.createElement("div");
      secondDiv.id = "secondDiv";
      mainDiv.appendChild(secondDiv);

      container.appendChild(mainDiv);

      return new Promise<void>((resolve, reject) => {
        try {
          // Create and configure the script
          const script = document.createElement("script");
          script.src = this.scriptUrl;
          script.setAttribute("data-token", "helpMeChoose-free2move-1234");
          script.setAttribute("data-target", "#secondDiv");
          script.setAttribute("data-url", window.location.href);

          // Add brand, country, language attributes if available
          if (this.brand) {
            script.setAttribute("data-brand", this.brand);
          }

          if (this.country) {
            script.setAttribute("data-country", this.country);
          }

          if (this.language) {
            script.setAttribute("data-language", this.language);
          }

          // Handle script loading events
          script.onload = () => {
            this.scriptLoaded = true;
            this.isScriptLoading = false;
            this.cdr.detectChanges();
            resolve();
          };

          script.onerror = (error) => {
            console.error("Error loading Help Me Choose script:", error);
            this.isScriptLoading = false;
            this.cdr.detectChanges();
            reject(error);
          };

          // Add the script to the DOM
          mainDiv.appendChild(script);
        } catch (error) {
          this.isScriptLoading = false;
          this.cdr.detectChanges();
          reject(error);
        }
      });
    } catch (error) {
      this.isScriptLoading = false;
      this.cdr.detectChanges();
      throw error;
    }
  }
}
