// infoWindowTemplateCar.ts
interface Connector {
  standard: string;
  format: string;
  max_electric_power: number;
}

interface Address {
  address: string;
  postal_code: string;
  city: string;
  country: string;
}

interface StationData {
  connectors: Connector[];
  address: Address | null;
}

export const infoWindowTemplateAP = (stationData: StationData): string => {
  return `
        <div class='info-content' style="min-width: max-content;">
            <h3 style="margin: 10px 0;">${
              stationData.connectors?.length || 0
            } <b>Charger(s)</b></h3>
            ${stationData.connectors
              ?.map((connector, index) => {
                let standard = connector.standard;
                switch (standard) {
                  case "IEC_62196_T2_COMBO":
                    standard = "Type 2 C";
                    break;
                  case "IEC_62196_T2":
                    standard = "Type 2";
                    break;
                  case "CHADEMO":
                    standard = "Chademo";
                    break;
                  case "IEC_62196_T1":
                    standard = "Type 1";
                    break;
                  case "Type 1 - Combo":
                    standard = "Type 1 C";
                    break;
                  case "TESLA":
                    standard = "Tesla";
                    break;
                  // Add more cases if needed
                }

                return `
                            <div style="color: white;background-color: #00a3e0;padding: 4px 10px;margin: 10px 0;box-shadow: 0 0 4px 1px #0000004d;">
                                <h4><b>Plug</b> - ${standard}</h4>
                                <ul>
                                    <li>${stationData.address?.address}</li>
                                    <li>${stationData.address?.postal_code}, ${stationData.address?.city}, ${stationData.address?.country}</li>
                                    <li><b>Format</b> ${connector.format}</li>
                                    <li><b>Power</b> ${connector.max_electric_power} W</li>
                                </ul>
                            </div>`;
              })
              .join("")}
        </div>`;
};

export const infoWindowTemplateAC = (stationData: StationData): string => {
  return `
        <div class='info-content' style="min-width: max-content;" >
            <h3 style="margin: 10px 0;">${
              stationData.connectors?.length || 0
            } <b>Charger(s)</b></h3>
            ${stationData.connectors
              ?.map((connector, index) => {
                let standard = connector.standard;
                switch (standard) {
                  case "IEC_62196_T2_COMBO":
                    standard = "Type 2 C";
                    break;
                  case "IEC_62196_T2":
                    standard = "Type 2";
                    break;
                  case "CHADEMO":
                    standard = "Chademo";
                    break;
                  case "IEC_62196_T1":
                    standard = "Type 1";
                    break;
                  case "Type 1 - Combo":
                    standard = "Type 1 C";
                    break;
                  case "TESLA":
                    standard = "Tesla";
                    break;
                  // Add more cases if needed
                }

                return `
                            <div style="color: white;background-color: #2885a1;padding: 4px 10px;margin: 10px 0;box-shadow: 0 0 4px 1px #0000004d;">
                                <h4><b>Plug</b> - ${standard}</h4>
                                <ul>
                                    <li>${stationData.address?.address}</li>
                                    <li>${stationData.address?.postal_code}, ${stationData.address?.city}, ${stationData.address?.country}</li>
                                    <li><b>Format</b> ${connector.format}</li>
                                    <li><b>Power</b> ${connector.max_electric_power} W</li>
                                </ul>
                            </div>`;
              })
              .join("")}
        </div>`;
};
