import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { API_CONFIG } from "src/assets/plugSurfing_params/api_url_config";
import { catchError, map } from "rxjs/operators";

declare var google: any;

@Injectable({
  providedIn: "root",
})
export class LevServiceService {
  brand: string = "ds";
  apiKey!: string;

  constructor(private http: HttpClient) {}

  getAll(): Observable<any> {
    return this.http.get<any>(
      API_CONFIG.server_url + "levdata/levdata-us/levdata.json"
    );
  }

  getCountriesLongitudeAndLatitude(
    country: string
  ): Observable<{ lat: number; lng: number }> {
    return this.http
      .get<{ ref_country_codes: any[] }>(
        API_CONFIG.server_url + "countriesLatAndLongitude/data.json"
      )
      .pipe(
        map((response) => {
          const countryCode = country.toUpperCase();
          const countryData = response.ref_country_codes.find(
            (item) => item.alpha2.toUpperCase() === countryCode
          );
          if (countryData) {
            return {
              lat: countryData.latitude,
              lng: countryData.longitude,
            };
          } else {
            throw new Error("Country not found in the data.");
          }
        })
      );
  }
  getCountryinformation(): Observable<any> {
    return this.http.get<any>(API_CONFIG.server_url + "countryJsonData.json");
  }

  getData(
    nameFile: string = "levdata/levdata-uc/levdata_fr_fr"
  ): Observable<any> {
    const url = API_CONFIG.server_url + nameFile + ".json";
    return this.http.get<any>(url).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        throw error; // Rethrow the error to handle it downstream
      })
    );
  }

  getDataWithPromise(nameFile: string = "levdata/levdata-uc/levdata_fr_fr") {
    return new Promise((resolve, reject) => {
      this.http
        .get(API_CONFIG.server_url + nameFile + ".json")
        .toPromise()
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  }

  /**
   * Get zipCode "postal" for a given lng lat
   *
   * @param {number} latitude Latitude
   * @param {number} longitude Longitude
   *
   * @return {Observable<any>}
   */
  getZipCode(
    latitude: number,
    longitude: number,
    apiKeyAp: string
  ): Observable<any> {
    return this.http.get<any>(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKeyAp}`
    );
  }

  toMiles(km: number) {
    return Math.round(km * 0.62137119223733);
  }
}
