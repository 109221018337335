import { Component, HostListener, Input, OnInit } from "@angular/core";
import { CssData, SearcheCriteria, Data } from "../../../../../src/app/models";
import { LevServiceService } from "../../../../../src/app/services";
import { EcoAdvisorService } from "../../../../../src/app/services/eco-advisor.service";
import { TranslationsService } from "../../../../../src/app/services/translations.service";
import { API_CONFIG } from "../../../../../src/assets/plugSurfing_params/api_url_config";
import { Incentives } from "../interfaces/incentives.interface";
import { tagGeolocation, tagSearchIncentives } from "../utility/gtm.utility";

@Component({
  selector: "app-eco-advisor",
  templateUrl: "./eco-advisor.component.html",
  styleUrls: ["./eco-advisor.component.scss"],
})
export class EcoAdvisorComponent implements OnInit {
  @Input("country") country: string;

  @Input("langage") langage: string;

  @Input("brand") brand: string;

  @Input("brandmvsspe") brandmvsspe: string;

  @Input("vehicleid") vehicleId: string;

  SearcheCriteria: SearcheCriteria = new SearcheCriteria();
  cssData: CssData;
  img_vehicle: any;
  eco_geo_icon: any;
  innerWidth: any;
  results: any;
  disabled: boolean = true;
  isFirstDisplay: boolean = true;
  zipCode: string;
  resultsConfirmation: string;
  vehs: any[];
  vehsTranslation: any[];
  zip_code_place_holder: string;
  submit_button: string;
  eco_first_step_title: string;
  eco_first_step_description: string;
  eco_adviso_legal_mentions: string;
  error_msg_no_incentives_for_actual_zip_code: string;
  use_other_zip_code: string;
  eco_price_purchasing_assistance_with_regional_return_label: string;
  eco_price_conversion_bonus_national_label: string;
  eco_preferential_parking_rate_label: string;
  eco_gray_card_certificate_of_registration_label: string;
  eco_help_with_the_installation_of_a_charging_station_label: string;
  eco_avantage_fiscaux_label: string;
  eco_contibution_nationale_aux_infrastructures_de_charge_label: string;
  eco_cite_credit_d_impot_transition_d_energetique_label: string;
  eco_more_link_label: string;
  constructor(
    private levService: LevServiceService,
    private data: Data,
    private ecoAdvisorService: EcoAdvisorService,
    private translationsService: TranslationsService
  ) {
    this.cssData = new CssData();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.survey();
    if (
      this.brand != undefined &&
      this.country != undefined &&
      this.langage != undefined &&
      this.brandmvsspe != undefined
    )
      this.getECODataByCulture();
  }

  //init input params
  survey() {
    if (
      this.brand == undefined &&
      this.langage == undefined &&
      this.country == undefined &&
      this.brandmvsspe == undefined
    ) {
      this.SearcheCriteria = this.data.storage;
      this.initFromSearchCriteria();
    }
  }

  /**
   * get all required data
   * needed for eco advisor widget
   *
   * this function checks if params are available
   */
  getECODataByCulture() {
    this.brand = this.brand.toLowerCase();
    this.country = this.country.toLowerCase();
    this.langage = this.langage.toLowerCase();
    const nameFile =
      "levdata/levdata-eco/levdata_" + this.country + "_" + this.langage;
    try {
      this.levService
        .getDataWithPromise(nameFile)
        .then((data) => {
          this.vehs = data["vehs"].filter(
            (veh) => veh.LEV_ECO_BRAND.toLowerCase() === this.brand
          );
        })
        .then(() => {
          this.setDefaultValues();
          this.getECOTranslationsByCulture();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  getECODescriptionData() {
    this.brand = this.brand.toLowerCase();
    this.country = this.country.toLowerCase();
    this.langage = this.langage.toLowerCase();
    const nameFile = "labels_eco/labels_" + this.country + "_" + this.langage;
    try {
      this.levService
        .getDataWithPromise(nameFile)
        .then((data) => {
          this.vehsTranslation = data["vehsTranslation"].filter(
            (veh) => veh.LEV_ECO_BRAND.toLowerCase() === this.brand
          );
        })
        .then(() => {
          this.setDefaultValues();
          this.getECOTranslationsByCulture();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  getECOTranslationsByCulture() {
    try {
      this.translationsService
        .getTranslationsByWidgetAndBrandAndCulture(
          "ECO",
          this.brand.toUpperCase(),
          this.country.toUpperCase(),
          this.langage.toLowerCase()
        )
        .then((data) => {
          for (const [key, translation] of Object.entries(data.values))
            this[key] = translation;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * setting up default values after getting
   * all required data needed for integration version
   *
   * @return void
   */
  setDefaultValues() {
    this.eco_first_step_title = this.vehs[0].LEV_ECO_LABEL;
    this.img_vehicle = API_CONFIG.server_url + this.vehs[0].LEV_ECO_PICTURE;
    this.eco_geo_icon = API_CONFIG.server_url + this.vehs[0].LEV_ECO_GEO_ICON;
  }

  initFromSearchCriteria() {
    if (this.SearcheCriteria) {
      this.country = this.SearcheCriteria.countryCode;
      this.langage = this.SearcheCriteria.langageCode;
      this.brand = this.SearcheCriteria.marque;
      this.brandmvsspe = this.SearcheCriteria.brandmvsspe;
      this.vehicleId = this.SearcheCriteria.vehicleid;
    }
  }

  /**
   * function to setup the zip code for the user
   *
   * this function use google api to get the current
   * zip code related to the position of the user
   *
   * @return void
   */
  getZipcode(): void {
    tagGeolocation();
    this.getCurrentGeoPosition();
  }

  /**
   * get zip code using google maps API
   * this function do get request while calling
   * other function retrievePostalCode
   *
   * @param latitude
   * @param longitude
   *
   * @return void
   */
  getPostal(latitude: number, longitude: number): void {
    const apiKeyAp = "AIzaSyBYDdyjaQnTM8HmNbKzHffhdqovvVLOYTI";
    this.levService.getZipCode(latitude, longitude, apiKeyAp).subscribe(
      async (response) => {
        const { results, status } = await response;
        if (status === "OK" && results.length) {
          this.zipCode = this.retrievePostalCode(results)
            ? this.retrievePostalCode(results)
            : "";
        }
      },
      (error) => console.error(error),
      () => this.inputChange()
    );
  }

  getCurrentGeoPosition(): void {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    const success = ({ coords }) =>
      this.getPostal(coords.latitude, coords.longitude);
    const error = ({ code, message }) =>
      console.error(`Error > (${code}): ${message}`);
    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  /**
   * function lunched on submit action
   * get incentivesData data related to region or zip code
   *
   * @return void
   */
  submitZipCode() {
    tagSearchIncentives();
    this.getIncentivesByBrandAndCulture();
    this.isFirstDisplay = false;
    // this.results = null;
  }

  /**
   * return incentives related to a specific
   * brand, country, and langage by retrieving them
   * from incentivesData files
   *
   * @return void
   */
  getIncentivesByBrandAndCulture() {
    this.results = [];
    let container = [];
    let sum = 0;
    this.ecoAdvisorService
      .getEcoAdvisorByZipeCode(this.zipCode)
      .then((res) => {
        if (res && res.length >= 1) {
          res[0].incentives.map((item) => {
            if (!isNaN(Number(item.value))) {
              container.push(item.value);
            }
          });
          if (!/^\d+$/.test(res[0].incentives[0]["value"])) {
            container.forEach((item) => {
              sum += parseInt(item);
            });
            Object.values(res[0].incentives)[0]["value"] = sum;
            this.results = res[0].incentives;
          } else {
            Object.values(res[0].incentives)[0]["value"] = Math.max(
              ...container
            );
            this.results = res[0].incentives;
          }
          this.resultsConfirmation = "goodResults";
        } else this.resultsConfirmation = "noResults";
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * action on mouse hover to change the icon
   * icon related to geolocation event
   *
   * @return void
   */
  geoLocationHover(brand: string): void {
    switch (brand) {
      case "fiat":
      case "jeep":
        this.eco_geo_icon = API_CONFIG.server_url + "shared/eco-geo-2.png";
        break;
      default:
        this.eco_geo_icon =
          API_CONFIG.server_url + this.vehs[0].LEV_ECO_GEO_ICON;
        break;
    }
  }

  /**
   * action on mouse leave to change the icon
   * icon related to geolocation event
   *
   * @return void
   */
  geoLocationLeave(brand: string) {
    if (brand) {
      this.eco_geo_icon = API_CONFIG.server_url + this.vehs[0].LEV_ECO_GEO_ICON;
    }
  }

  /**
   * action of zip code input
   * used to enable/disable form
   *
   * @param event
   */
  inputChange(event?) {
    if (event && event.target) this.zipCode = event.target.value;
    if (this.validateZipCode()) return;
    const validZipCode = undefined !== this.zipCode && this.zipCode.length > 3;
    this.disabled = !validZipCode;
  }

  /**
   * check the validity of zip code
   *
   * @param event
   */
  validateZipCode(event?) {
    const regex = /^\d+$/;
    if (event && !this.zipCode.match(regex)) {
      this.zipCode = "";
      if (event && event.target) event.target.value = "";
      return (this.disabled = true);
    }
    return (this.disabled = false);
  }

  /**
   * this fucntion is used to get postal code
   * from results by looping on all types
   *
   * it helps to find postal code from large list of types
   *
   * @param results
   * @return void
   */
  retrievePostalCode(results: any): string | null {
    let postalCode = null;
    for (const { address_components } of results) {
      for (const { types, long_name } of address_components) {
        for (const type of types) {
          if (type === "postal_code") {
            postalCode = long_name;
          }
        }
      }
    }
    return postalCode;
  }

  /**
   * check if window dimensions fit mobile expectation
   *
   * return result of check : true / false
   *
   * @return boolean
   */
  deviceIsMobile() {
    return this.innerWidth ? this.innerWidth < 813 : true;
  }

  /**
   * set up first display mode
   * if client click back or modify
   * in the second step of eco advisor widget
   *
   * @return void
   * @param firstDisplayMode : boolean
   */
  changeDisplayFirst(firstDisplayMode: boolean): void {
    this.isFirstDisplay = firstDisplayMode;
    this.resultsConfirmation = "";
  }
}
