<main *ngIf="loadUI">
  <app-speed-slider
    [defaultChargeType]="'private'"
    [initialSliderValue]="75"
    [fuelValues]="currentFuelValues"
    [electricityPrices]="currentElectricityPrices"
    [fuelSavingData]="fuelSavingData"
    [labelCulture]="labelCulture"
    [cs_peugeot]="cs_peugeot"
    [cs_citroen]="cs_citroen"
    [cs_fiat]="cs_fiat"
    [cs_fiatPro]="cs_fiatPro"
    [cs_alfaRomeo]="cs_alfaRomeo"
    [cs_ds]="cs_ds"
    [cs_jeep]="cs_jeep"
    [brand]="brand"
  >
  </app-speed-slider>

  <div class="container-cost-fuel">
    <p
      class="electricity-fuel-cost-text"
      [class.AP]="cs_peugeot"
      [class.AC]="cs_citroen"
      [class.FT]="cs_fiat"
      [class.FO]="cs_fiatPro"
      [class.AR]="cs_alfaRomeo"
      [class.DS]="cs_ds"
      [class.JE]="cs_jeep"
    >
      {{ labelCulture.title_customize }}
    </p>
    <div class="electricity-fuel-cost">
      <div class="electricity-cost">
        <div
          class="electricity-cost-title"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <svg
            width="12"
            height="16"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.93582 0.25L0.5 8.78125H9.20968L1.19117 16H2.67742L11.75 7.79688H3.04032L11.3871 0.25H9.93582Z"
              fill="#034FA0"
            />
          </svg>
          <span
            >{{ labelCulture.energy_cost }} ({{
              labelCulture.cs_energy_cost_unit
            }})</span
          >
        </div>
        <div class="electricity-cost-components">
          <app-chargercost
            [step]="0.01"
            [label]="labelCulture.cs_home_charging"
            [startingValue]="0.25"
            [cs_peugeot]="cs_peugeot"
            [cs_fiat]="cs_fiat"
            [cs_fiatPro]="cs_fiatPro"
            [cs_alfaRomeo]="cs_alfaRomeo"
            [cs_ds]="cs_ds"
            [cs_jeep]="cs_jeep"
            [cs_citroen]="cs_citroen"
            (valueChange)="onValueChange($event, 'Private-charge')"
          ></app-chargercost>
          <app-chargercost
            [step]="0.01"
            [label]="labelCulture.cs_public_chargers"
            [startingValue]="0.4"
            [cs_peugeot]="cs_peugeot"
            [cs_fiat]="cs_fiat"
            [cs_fiatPro]="cs_fiatPro"
            [cs_alfaRomeo]="cs_alfaRomeo"
            [cs_ds]="cs_ds"
            [cs_jeep]="cs_jeep"
            [cs_citroen]="cs_citroen"
            (valueChange)="onValueChange($event, 'Public-standard-charge')"
          ></app-chargercost>
          <app-chargercost
            [step]="0.01"
            [label]="labelCulture.cs_fast_chargers"
            [startingValue]="0.65"
            [cs_peugeot]="cs_peugeot"
            [cs_fiat]="cs_fiat"
            [cs_fiatPro]="cs_fiatPro"
            [cs_alfaRomeo]="cs_alfaRomeo"
            [cs_ds]="cs_ds"
            [cs_jeep]="cs_jeep"
            [cs_citroen]="cs_citroen"
            (valueChange)="onValueChange($event, 'Public-fast-charge')"
          ></app-chargercost>
        </div>
      </div>

      <div class="fuel-cost">
        <div
          class="fuel-cost-title"
          [class.AP]="cs_peugeot"
          [class.AC]="cs_citroen"
          [class.FT]="cs_fiat"
          [class.FO]="cs_fiatPro"
          [class.AR]="cs_alfaRomeo"
          [class.DS]="cs_ds"
          [class.JE]="cs_jeep"
        >
          <svg
            width="13"
            height="18"
            viewBox="0 0 13 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 12.125C12 7.25 6.75 1.25 6.75 1.25C6.75 1.25 1.5 7.25 1.5 12.125C1.5 17 6.75 17 6.75 17C6.75 17 12 17 12 12.125Z"
              stroke="#034FA0"
              stroke-width="1.5"
            />
          </svg>
          <span
            >{{ labelCulture.fuel_cost }} ({{
              labelCulture.cs_fuel_cost_unit
            }})</span
          >
        </div>
        <div class="fuel-cost-components">
          <app-chargercost
            [step]="0.05"
            [label]="labelCulture.cs_cost_per_liter"
            [startingValue]="1.8"
            [cs_peugeot]="cs_peugeot"
            [cs_fiat]="cs_fiat"
            [cs_fiatPro]="cs_fiatPro"
            [cs_alfaRomeo]="cs_alfaRomeo"
            [cs_ds]="cs_ds"
            [cs_jeep]="cs_jeep"
            [cs_citroen]="cs_citroen"
            (valueChange)="onValueChangeFuel($event, 'Cost-per-liter')"
          ></app-chargercost>
          <app-chargercost
            [step]="0.1"
            [label]="labelCulture.cs_consumption_100"
            [typeOfCost]="'Fuel'"
            [startingValue]="6"
            [cs_peugeot]="cs_peugeot"
            [cs_fiat]="cs_fiat"
            [cs_fiatPro]="cs_fiatPro"
            [cs_alfaRomeo]="cs_alfaRomeo"
            [cs_ds]="cs_ds"
            [cs_jeep]="cs_jeep"
            [cs_citroen]="cs_citroen"
            (valueChange)="onValueChangeFuel($event, 'Consomation')"
          ></app-chargercost>
        </div>
      </div>
    </div>
  </div>

  <div class="disclaimer">
    <div class="horizontal-ligne"></div>
    <p class="disclaimer-text" [class.AP]="cs_peugeot" [class.AC]="cs_citroen">
      {{ labelCulture.disclaimer_fuel_saving }}
    </p>
  </div>
</main>
<div
  class="error-handling"
  [class.AP]="cs_peugeot"
  [class.AC]="cs_citroen"
  [class.FT]="cs_fiat"
  [class.FO]="cs_fiatPro"
  [class.AR]="cs_alfaRomeo"
  [class.DS]="cs_ds"
  [class.JE]="cs_jeep"
  *ngIf="!loadUI"
>
  <svg
    fill="#17234d"
    height="50px"
    width="200px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 330 330"
    xml:space="preserve"
    style="display: block; margin: auto"
  >
    <path
      id="XMLID_224_"
      d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
	l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
	C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
    />
  </svg>
  Please Select a new Model Name
</div>
