<div class="eco-advisor" [class.disabled]="!isFirstDisplay">
  <div class="eco-advisor-first-step" [ngClass]="[brand, country]">
    <div class="eco-img-padding">
      <div class="eco-advisor-img">
        <img
          *ngIf="img_vehicle"
          [src]="img_vehicle"
          class="img-responsive eco-responsive"
          alt=""
        />
      </div>
    </div>
    <div class="col-md-7 col-sm-12 eco-form">
      <div class="eco-first-section-title" [ngClass]="[brand, country]">
        {{ eco_first_step_title }}
      </div>
      <div class="eco-first-section-description" [ngClass]="[brand, country]">
        {{ eco_first_step_description }}
      </div>
      <div class="eco-form-ctas" [ngClass]="[brand, country]">
        <input
          type="text"
          name="zip-code-text-input"
          [placeholder]="zip_code_place_holder"
          [(ngModel)]="zipCode"
          (input)="inputChange($event)"
          class="eco-advisor-zip-input"
          [ngClass]="[brand, country]"
          autocomplete="off"
        />
        <input
          class="eco-advisor-submit"
          [value]="submit_button"
          type="button"
          [class.disabled]="disabled"
          [disabled]="disabled"
          (click)="submitZipCode()"
          [ngClass]="[brand, country]"
          (keyup.enter)="submitZipCode()"
        />
      </div>
    </div>
    <div
      *ngIf="!deviceIsMobile()"
      class="col-md-1 eco-advisor-rect"
      [class.disabled]="disabled"
      [ngClass]="[brand, country]"
    ></div>
    <div
      *ngIf="deviceIsMobile()"
      class="eco-advisor-rect-horizontal"
      [class.disabled]="disabled"
      [ngClass]="[brand, country]"
    ></div>
  </div>
</div>

<div class="eco-advisor-results" [class.disabled]="isFirstDisplay">
  <app-results
    [brand]="brand"
    [isMobileResults]="deviceIsMobile()"
    (onBackToStepOne)="changeDisplayFirst($event)"
    [resultsConfirmation]="resultsConfirmation"
    [results]="results"
    [country]="country"
    [langage]="langage"
    (onBackToStepOneFromError)="changeDisplayFirst(true)"
    [error_msg_no_incentives_for_actual_zip_code]="
      error_msg_no_incentives_for_actual_zip_code
    "
    [use_other_zip_code]="use_other_zip_code"
    [zipCode]="zipCode"
  ></app-results>
</div>
<div
  *ngIf="eco_adviso_legal_mentions"
  class="eco_adviso_legal_mentions"
  [ngClass]="[brand, country]"
  [class.result-mentions]="!isFirstDisplay"
  [innerHTML]="
    eco_adviso_legal_mentions +
    (results ? (results[0] ? results[0].description : '') : '')
  "
></div>