var CTA1 =
  "CTA1 Label1,https://fr-preprod.dspp.awsmpsa.com/lev-tec.html;CTA 1 Label2,https://fr-preprod.dspp.awsmpsa.com/lev-tec.html";
var CTA2 = "CTA2 Label1,https://fr-preprod.dspp.awsmpsa.com/lev-tec.html";
var CTA3 =
  "CTA3 Label1,https://fr-preprod.dspp.awsmpsa.com/lev-tec.html;CTA3Label2,https://fr-preprod.dspp.awsmpsa.com/lev-tec.html;CTA3Label3,https://fr-preprod.dspp.awsmpsa.com/lev-tec.html;";
var CTA4 =
  "CTA4 Label1,https://fr-preprod.dspp.awsmpsa.com/lev-tec.html;CTA4Label2,https://fr-preprod.dspp.awsmpsa.com/lev-tec.html";
export class SearcheCriteria {
  countryCode: string = "";
  langageCode: string = "";
  marque: string = "";
  cta: string = CTA1 + "|" + CTA2 + "|" + CTA3 + "|" + CTA4;
  vehicleid: string = "";
  lcdv10: string = "";
  mlcdv: string = "";
  vehicleVT: string = "";
  vehicleVE: string = "";
  priceVT!: number;
  priceVE!: number;
  localBonus!: number;
  localBring!: number;
  carbPrice: string = "";
  energyPrice: string = "";
  displayMode: string = "";
  defaultOBC: string = "true";
  isPro: string = "true";
  isd1: string = "false";
  isspoticar: string = "false";
  isManual: boolean = false;
  brandmvsspe: string = "";
  value1: string = "";
  value2: string = "";
  value3: string = "";
  value4: string = "";
  value5: string = "";
  value6: string = "";
  value7: string = "";
  value8: string = "";
  value9: string = "";
  value10: string = "";
  value11: string = "";
  value12: string = "";
  label1: string = "";
  label2: string = "";
  is4x4: string = "false";
  is4x2: string = "false";
  typeVehicle: string = "";
  linkOBC: string = "";
  linkprise: string = "";
}
