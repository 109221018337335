import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_CONFIG } from "src/assets/plugSurfing_params/api_url_config";

@Injectable({
  providedIn: "root",
})
export class EcoAdvisorService {
  headers: HttpHeaders = new HttpHeaders;

  constructor(private http: HttpClient) {}

  getEcoAdvisorByZipeCode(zipCode: string) {
    const frontServer = API_CONFIG.server_url;
    var backendEndPointServer = "";
    if (frontServer.includes("www-rec."))
      backendEndPointServer = "admin.lev.awsmpsa.com";
    else if (frontServer.includes("www-preprod."))
      backendEndPointServer = "admin.lev.awsmpsa.com";
    else backendEndPointServer = "admin.lev.awsmpsa.com";
    return this.http
      .get<any>(
        `https://${backendEndPointServer}/api/v1/eco-advisor/zipcode/${zipCode}`
      )
      .toPromise()
      .catch((err) => {
        console.error(JSON.stringify(err));
      });
  }
}
