
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { of, Observable } from "rxjs";
import { API_CONFIG } from "@assets/plugSurfing_params/api_url_config";
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EsolutionFetchService {
  private apiPostUrl =
    API_CONFIG.esolutionBackend + "/api/v1/cognitoAuth/auth/searchpoi";
  private apiGetUrl =
    API_CONFIG.esolutionBackend +
    "/api/v1/cognitoAuth/auth/getpoi?chargePointIds=";

  constructor(private http: HttpClient) {}

  postData(body: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post(`${this.apiPostUrl}`, body, { headers });
  }

  postDataTest(): Observable<any> {
    const data = {
      charging_stations: [
        {
          charging_station_id: "BE-BEC-E041503001",
          location_id: "LOC-123456789",
          favorite: true,
          rating_average: 3.5,
          rating_total: 2,
          address: {
            address: "F.Rooseveltlaan 3A",
            city: "Gent",
            postal_code: "9000",
            country: "BEL",
          },
          hotline_number: "+4980024274363",
          is_rfid_reader: true,
          is_remote_control: false,
          is_plugin_charge: false,
          status: "AVAILABLE",

          is_creditCard_payable: true,
          is_all_e_payable: false,
          last_update: "2020-06-21T09:00:00Z",
          printed_name: "1",
          location_name: "Gent Zuid",
          parking_type: "ON_STREET",
          is_reservable: true,
          coordinates: {
            latitude: "48.864716",
            longitude: "2.349014",
            distance: 1.4,
          },
          parking_restrictions: "EV_ONLY",
          location_facilities: ["RESTAURANT"],
          energy_mix: {
            is_green_energy: false,
            supplier_name: "E.ON Energy Deutschland",
            energy_product_name: "E.ON DirektStrom eco",
            energy_sources: [
              { source: "GAS", percentage: 50 },
              { source: "GENERAL_FOSSIL", percentage: 50 },
            ],
            environ_impact: [{ category: "CARBON_DIOXIDE", amount: 25.4 }],
          },
          operator: {
            name: "BeCharged",
            website: "",
            logo: {
              url: "",
              thumbnail: "",
              category: "OPERATOR",
              type: "jpeg",
              width: 1,
              height: 2,
            },
          },
          owner: {
            name: "OwnerName",
            website: "",
            logo: {
              url: "",
              thumbnail: "",
              category: "OWNER",
              type: "jpeg",
              width: 1,
              height: 2,
            },
          },
          connectors: [
            {
              id: "1",
              format: "CABLE",
              power_type: "AC_3_PHASE",
              standard: "IEC_62196_T2",
              max_electric_power: 42,
              max_voltage: 250,
            },
          ],
        },
        {
          charging_station_id: "BE-BEC-E041503001",
          location_id: "LOC-123456789",
          favorite: true,
          rating_average: 3.5,
          rating_total: 2,
          address: {
            address: "F.Rooseveltlaan 3A",
            city: "Gent",
            postal_code: "9000",
            country: "BEL",
          },
          hotline_number: "+4980024274363",
          is_rfid_reader: true,
          is_remote_control: false,
          is_plugin_charge: false,
          status: "AVAILABLE",

          is_creditCard_payable: true,
          is_all_e_payable: false,
          last_update: "2020-06-21T09:00:00Z",
          printed_name: "1",
          location_name: "Gent Zuid",
          parking_type: "ON_STREET",
          is_reservable: true,
          coordinates: {
            latitude: "32",
            longitude: "-5",
            distance: 1.4,
          },
          parking_restrictions: "EV_ONLY",
          location_facilities: ["RESTAURANT"],
          energy_mix: {
            is_green_energy: false,
            supplier_name: "E.ON Energy Deutschland",
            energy_product_name: "E.ON DirektStrom eco",
            energy_sources: [
              { source: "GAS", percentage: 50 },
              { source: "GENERAL_FOSSIL", percentage: 50 },
            ],
            environ_impact: [{ category: "CARBON_DIOXIDE", amount: 25.4 }],
          },
          operator: {
            name: "BeCharged",
            website: "",
            logo: {
              url: "",
              thumbnail: "",
              category: "OPERATOR",
              type: "jpeg",
              width: 1,
              height: 2,
            },
          },
          owner: {
            name: "OwnerName",
            website: "",
            logo: {
              url: "",
              thumbnail: "",
              category: "OWNER",
              type: "jpeg",
              width: 1,
              height: 2,
            },
          },
          connectors: [
            {
              id: "1",
              format: "CABLE",
              power_type: "AC_3_PHASE",
              standard: "IEC_62196_T2",
              max_electric_power: 42,
              max_voltage: 250,
            },
          ],
        },
        {
          charging_station_id: "BE-BEC-E041503001",
          location_id: "LOC-123456789",
          favorite: true,
          rating_average: 3.5,
          rating_total: 2,
          address: {
            address: "F.Rooseveltlaan 3A",
            city: "Gent",
            postal_code: "9000",
            country: "BEL",
          },
          hotline_number: "+4980024274363",
          is_rfid_reader: true,
          is_remote_control: false,
          is_plugin_charge: false,
          status: "AVAILABLE",

          is_creditCard_payable: true,
          is_all_e_payable: false,
          last_update: "2020-06-21T09:00:00Z",
          printed_name: "1",
          location_name: "Gent Zuid",
          parking_type: "ON_STREET",
          is_reservable: true,
          coordinates: {
            latitude: "28",
            longitude: "3",
            distance: 1.4,
          },
          parking_restrictions: "EV_ONLY",
          location_facilities: ["RESTAURANT"],
          energy_mix: {
            is_green_energy: false,
            supplier_name: "E.ON Energy Deutschland",
            energy_product_name: "E.ON DirektStrom eco",
            energy_sources: [
              { source: "GAS", percentage: 50 },
              { source: "GENERAL_FOSSIL", percentage: 50 },
            ],
            environ_impact: [{ category: "CARBON_DIOXIDE", amount: 25.4 }],
          },
          operator: {
            name: "BeCharged",
            website: "",
            logo: {
              url: "",
              thumbnail: "",
              category: "OPERATOR",
              type: "jpeg",
              width: 1,
              height: 2,
            },
          },
          owner: {
            name: "OwnerName",
            website: "",
            logo: {
              url: "",
              thumbnail: "",
              category: "OWNER",
              type: "jpeg",
              width: 1,
              height: 2,
            },
          },
          connectors: [
            {
              id: "1",
              format: "CABLE",
              power_type: "AC_3_PHASE",
              standard: "IEC_62196_T2",
              max_electric_power: 42,
              max_voltage: 250,
            },
          ],
        },
      ],
    };

    return of(data);
  }

  getData(params?: any): Observable<any> {
    return this.http.get(`${this.apiGetUrl}${params}`);
  }

  getDataTest(): Observable<any> {
    const data = {
      charging_stations: [
        {
          countryCode: "IT",
          address: {
            address: "Corso Stati Uniti 44",
            city: "Torino",
            country: "Italy",
            postal_code: "10128",
          },
          status: "AVAILABLE",
          coordinates: {
            latitude: "45.06455",
            longitude: "7.66613",
          },
          owner: null,
          operator: {
            name: "POWY",
          },
          favorite: false,
          discount: true,
          connectors: [
            {
              id: "0",
              standard: "IEC_62196_T2",
              format: "SOCKET",
              power_type: "AC_3_PHASE",
              max_voltage: 400,
              max_amperage: 32,
              max_electric_power: 22000,
              tariffs: [
                {
                  id: "30092",
                  currency: "EUR",
                  elements: [
                    {
                      price_components: [
                        {
                          type: "ENERGY",
                          price: 0.578,
                          vat: 0.22,
                          step_size: 1,
                        },
                      ],
                    },
                  ],
                  country_code: "IT",
                  party_id: "PWY",
                  last_updated: "2023-10-26T13:47:58Z",
                },
              ],
              last_updated: "2023-06-13T10:02:12.329Z",
            },
            {
              id: "1",
              standard: "IEC_60309_2",
              format: "DOMESTIC",
              power_type: "AC_3_PHASE",
              max_voltage: 400,
              max_amperage: 32,
              max_electric_power: 32000,
              tariffs: [
                {
                  id: "30092",
                  currency: "EUR",
                  elements: [
                    {
                      price_components: [
                        {
                          type: "ENERGY",
                          price: 0.578,
                          vat: 0.22,
                          step_size: 1,
                        },
                      ],
                    },
                  ],
                  country_code: "IT",
                  party_id: "PWY",
                  last_updated: "2023-10-26T13:47:58Z",
                },
              ],
              last_updated: "2023-06-13T10:02:12.329Z",
            },
          ],
          cpoCode: null,
          platform: "BOSCH",
          visibility: "EXTERNAL",
          partyId: null,
          charging_station_id: "IT*PWY*EP0006*01*01",
          is_rfid_reader: true,
          is_remote_control: true,
          is_plugin_charge: false,
          opening_times: {
            twentyfourseven: true,
          },
          is_creditCard_payable: false,
          is_all_e_payable: true,
          last_update: "2023-06-13T10:02:12.329Z",
          floor_level: null,
          printed_name: null,
          location_name: null,
          parking_type: null,
          is_reservable: false,
          parking_restrictions: [],
          location_facilities: [],
          energy_mix: {
            is_green_energy: true,
          },
          hotline_number: "+39800596704",
          rating_average: 0.0,
          rating_total: 0,
          location_id: "db173bdc-65c0-4737-a00a-0b7584b96952",
        },
      ],
    };

    // Simulate a delay of 1000 milliseconds (1 second)
    return of(data).pipe(delay(1000));
  }
}
